import i18next from "i18next";

export enum TimeUnitEnum {
    DAYS = 'days',
    HOURS = 'hours',
    MINUTES = 'minutes',
    SECONDS = 'seconds',
}

export const TimeUnitLabel = (key: string) => ({ 
    [TimeUnitEnum.DAYS]: i18next.t('DAYS'),
    [TimeUnitEnum.HOURS]: i18next.t('HOURS'),
    [TimeUnitEnum.MINUTES]: i18next.t('MINUTES'),
    [TimeUnitEnum.SECONDS]: i18next.t('SECONDS')
})[key];

export const getTimeUnits = () => {
    return Object.values(TimeUnitEnum).map((element, index) => {
        return {
            key: index,
            value: element,
            label: TimeUnitLabel(element) ?? ""
        }
    });

}