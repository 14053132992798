import { ArrowPathIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SellsFilter } from "../../enums/SellsFilter";
import { fetchAgentTotalMembers } from "../../state/dashboard/actions";
import { AppDispatch, RootState } from "../../state/store";
import { getStartEndDates } from "../../utils/functions";
import Button from "../Button";
import { LoadingMask } from "../LoadingMask";
import Widget from "./Widget";

interface Props {
  className?: string;
}

export const MembersSellsWidget = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector(
    (state: RootState) => state.users.currentUser
  );
  const { agentTotalMembers, membersSellFilter } = useSelector(
    (state: RootState) => state.dashboard
  );
  const [reloadingAgentTotalMembers, setReloadingAgentTotalMembers] =
    useState(false);
  const [sellFilter, setSellFilter] = useState(
    membersSellFilter ?? SellsFilter.day
  );

  useEffect(() => {
    if (currentUser) {
      const { start, end, timeZone } = getStartEndDates(sellFilter);

      const params = {
        startDate: start,
        endDate: end,
        timeZone: timeZone,
        sellsFliter: sellFilter,
      };
      dispatch(fetchAgentTotalMembers(params));
    }
  }, [currentUser, dispatch, sellFilter]);

  const handleChangeSellsFilter = (filter: SellsFilter) => {
    setSellFilter(filter);
    reloadAgentTotalMembers(filter);
  };

  const reloadAgentTotalMembers = (filter: SellsFilter) => {
    setReloadingAgentTotalMembers(true);
    const { start, end, timeZone } = getStartEndDates(sellFilter);

    const params = {
      startDate: start,
      endDate: end,
      timeZone: timeZone,
      sellsFliter: filter,
    };
    dispatch(fetchAgentTotalMembers(params)).then(() => {
      setReloadingAgentTotalMembers(false);
    });
  };

  return (
    <>
      {reloadingAgentTotalMembers && <LoadingMask />}
      <Widget>
        <Button
          iconOnly
          onClick={() => reloadAgentTotalMembers(sellFilter)}
          isTerciary
          classNames="absolute top-5 start-5"
        >
          <div className="w-6 aspect-square">
            <ArrowPathIcon />
          </div>
        </Button>
        <div className="text-gray-500 text-3xl mt-10 mb-7">
          {t("TOTAL_MEMBER_SELLS")}
        </div>
        <div className="text-gold text-8xl font-semibold">
          {agentTotalMembers.totalMembers}
        </div>
        <div className="text-blue font-light text-2xl my-4">
          {t("POLICIES_COUNT")}: {agentTotalMembers.totalPolicies}
        </div>
        <div className="flex flex-row items-center justify-center mb-8">
          <div
            className={classNames({
              "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-5 py-1":
                true,
              "bg-neutral-200": sellFilter === SellsFilter.day,
            })}
            style={{ margin: "-1px" }}
            onClick={() => handleChangeSellsFilter(SellsFilter.day)}
          >
            <div className="text-gray-500">{t("SHOW")}</div>
            <div className="text-blue text-3xl font-medium">1D</div>
          </div>
          <div className="bg-neutral-200 h-10 w-px"></div>
          <div
            className={classNames({
              "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-5 py-1":
                true,
              "bg-neutral-200": sellFilter === SellsFilter.week,
            })}
            style={{ margin: "-1px" }}
            onClick={() => handleChangeSellsFilter(SellsFilter.week)}
          >
            <div className="text-gray-500">{t("SHOW")}</div>
            <div className="text-blue text-3xl font-medium">1W</div>
          </div>
          <div className="bg-neutral-200 h-10 w-px"></div>
          <div
            className={classNames({
              "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-5 py-1":
                true,
              "bg-neutral-200": sellFilter === SellsFilter.month,
            })}
            style={{ margin: "-1px" }}
            onClick={() => handleChangeSellsFilter(SellsFilter.month)}
          >
            <div className="text-gray-500">{t("SHOW")}</div>
            <div className="text-blue text-3xl font-medium">1M</div>
          </div>
          <div className="bg-neutral-200 h-10 w-px"></div>
          <div
            className={classNames({
              "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-5 py-1":
                true,
              "bg-neutral-200": sellFilter === SellsFilter.all,
            })}
            style={{ margin: "-1px" }}
            onClick={() => handleChangeSellsFilter(SellsFilter.all)}
          >
            <div className="text-gray-500">{t("SHOW")}</div>
            <div className="text-blue text-3xl font-medium">{t("ALL")}</div>
          </div>
        </div>
      </Widget>
    </>
  );
};
