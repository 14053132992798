import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { shortLocalDateFormat } from "../../utils/functions";
import Button from "../../components/Button";
import { BookOpenIcon, FunnelIcon, PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import FilteredTable from "../../components/FilteredTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { deleteCampaign, fetchCampaigns, pastPage } from "../../state/campaigns/actions";
import { CampaignModal } from "../../components/campaigns/CampaignModal";
import { LoadingMask } from "../../components/LoadingMask";
import { Campaign } from "../../models/Campaign";
import { DeleteCampaignModal } from "../../components/campaigns/DeleteCampaignModal";
import { toast } from "react-toastify";
import { Toast } from "../../components/Toast";
import { CampaignEnum, CampaignLabel } from "../../enums/CampaignType";
import { CampaignFilterModal } from "../../components/campaigns/CampaignFilterModal";
import { CampaignInformationModal } from "../../components/campaigns/CampaignInformationModal";

export const Campaigns = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const pageCampaigns = useSelector((state: RootState) => state.campaigns.pageCampaigns);
    const paginatedOptions = useSelector((state: RootState) => state.campaigns.paginatedOptions);
    const filters = useSelector((state: any) => state.campaigns.filters);
    const isLoading = useSelector((state: RootState) => state.campaigns.isLoading);
    const error = useSelector((state: any) => state.campaigns.error);
    const [showDCampaignError, setDCampaignError] = useState(false);
    const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);

    const [showCampaignInfoModal, setShowCampaignInfoModal] = useState<{
      show: boolean;
      campaign?: Campaign;
      index?: number;
    }>({ show: false });

    const [showCampaignModal, setShowCampaignModal] = useState<{
      show: boolean;
      update: boolean;
      campaign?: Campaign;
      index?: number;
    }>({ show: false, update: false });

    const [ showDeleteCampaignModal, setShowDeleteCampaignModal] = useState<{
      campaign?: Campaign;
      show: boolean;
    }>({ show: false, campaign: undefined });

    useEffect(() => {
      if (showDCampaignError) {
        Toast(t(error?.reason ?? "SOMETHING_WENT_WRONG"), t(error?.cause_info));
        setDCampaignError(false);
      }
    }, [setDCampaignError, error, t]);

    const columns = useMemo(() => {
        const showColumns = [
          {
            Header: t("NAME"),
            accessor: "name",
          },
          {
            Header: t("CODE"),
            accessor: "code",
          },
          {
            Header: t("CAMPAIGN_TYPE"),
            Cell: (row: any) => `${CampaignLabel(row.row.original.campaign_type_id)}`,
          },
          {
            Header: t("POLICY_TYPE"),
            Cell: (row: any) => row.row.original.policy_type != null ? `${row.row.original.policy_type}` : '-',
          },
          {
            Header: t("COST"),
            Cell: (row: any) => {
              if(row.row.original.campaign_type_id === CampaignEnum.FIXED_PRICE) {
                return row.row.original.fixed_price.total 
                  ? `${row.row.original.fixed_price.total}` : "-";
              } else if(row.row.original.campaign_type_id === CampaignEnum.FOR_TIME) {
                return row.row.original.for_time.cost != null
                  ? `${row.row.original.for_time.cost}` : "-";
              }
            }
          },
          {
            Header: t("NUMBER_OF_LEADS"),
            Cell: (row: any) => {
              if(row.row.original.campaign_type_id === CampaignEnum.FIXED_PRICE) {
                return row.row.original.fixed_price.amount_of_leads
                  ? `${row.row.original.fixed_price.amount_of_leads}` : "0";
              } else if(row.row.original.campaign_type_id === CampaignEnum.FOR_TIME) {
                return row.row.original.for_time.amount_of_leads != null 
                  ? `${row.row.original.for_time.amount_of_leads}` : "0";
              }
            }
          },
          {
            Header: t("CREATION_DATE"),
            Cell: (row: any) =>
              shortLocalDateFormat(`${row.row.original.created_at}`),
          },
        ];
    
        const actions = {
          Header: " ",
          Cell: (row: any) => {
            return (
              <div className="flex flex-row justify-center divide-x">
                <Button
                  isTerciary
                  tooltip={t("CAMPAIGN_INFORMATION")}
                  iconOnly
                  onClick={() => setShowCampaignInfoModal({ show: true, campaign: row.row.original })}
                  classNames="mr-2"
                >
                  <BookOpenIcon className="h-5 aspect-square text-gold" />
                </Button>
                <Button
                  isTerciary
                  tooltip={t("UPDATE_CAMPAIGN")}
                  iconOnly
                  onClick={() => setShowCampaignModal({ show: true, update: true, campaign: row.row.original })}
                  classNames="mr-2"
                >
                  <PencilIcon className="h-5 aspect-square text-blue" />
                </Button>
                <Button
                  isTerciary
                  tooltip={t("DELETE_CAMPAIGN")}
                  iconOnly
                  onClick={() => setShowDeleteCampaignModal({show : true, campaign: row.row.original})}
                  classNames="mr-2"
                >
                  <TrashIcon className="h-5 aspect-square text-red-600" />
                </Button>
              </div>
            );
          },
        };
        return [...showColumns, actions];
    }, [t]);

    const getPaginationOptions = useMemo(() => {
        return {
            page: paginatedOptions.page,
            pageSize: paginatedOptions.pageSize,
            totalPages: paginatedOptions.totalPages,
            totalCount: paginatedOptions.totalCount,
            setPage: (value: number) => {
                value < paginatedOptions.page
                ? dispatch(pastPage())
                : dispatch(fetchCampaigns({page: value,pageSize: paginatedOptions.pageSize, filters: filters}));
            },
            setPageSize: (value: number) => dispatch(fetchCampaigns({page: paginatedOptions.page,pageSize: value, filters: filters})),
        };
    }, [dispatch, paginatedOptions]);

    const onDeleteCampaign = (campaign: Campaign) => {
      dispatch(deleteCampaign({campaignId: campaign.id!, page: paginatedOptions.page, pageSize: paginatedOptions.pageSize,})).then((e) => {
          if (e.type === "agencies/deleteCampaign/rejected") {
            setDCampaignError(true);
          } else {
            toast(t("SUCCESSFULLY_DELETE_CAMPAIGN"));
          }
      });
    };

    return(
        <>
            {isLoading && <LoadingMask />}
            <CampaignInformationModal
                info={showCampaignInfoModal}
                onClose={() => setShowCampaignInfoModal({ show: false })}
            />
            <CampaignModal
                info={showCampaignModal}
                onClose={() => setShowCampaignModal({ show: false, update: false })}
            />
            <DeleteCampaignModal
              info={showDeleteCampaignModal}
              onClose={() => setShowDeleteCampaignModal({show : false, campaign: undefined})}
              deleteCampaign={(campaign: Campaign) => onDeleteCampaign(campaign)}
            />
            <FilteredTable
                columns={columns}
                data={pageCampaigns}
                isPaginated
                paginationOptions={getPaginationOptions}
                secondaryActionButton={
                    <Button onClick={() => setShowCampaignModal({ show: true, update: false })}>
                        <span className="flex items-center font-semibold pr-3">
                        <div className="w-8 p-1 aspect-square mr-2">
                            <PlusIcon />
                        </div>
                        {t("NEW_CAMPAIGN")}
                        </span>
                    </Button>
                }
                buttonAction={
                  <Button
                    onClick={() => setFilterModalOpen(true)}
                    iconOnly
                    classNames="w-9 p-1 aspect-square mr-4 "
                  >
                    <FunnelIcon className=""></FunnelIcon>
                  </Button>
                }
            />
            <CampaignFilterModal
              isFilterModalOpen={isFilterModalOpen}
              setFilterModalOpen={() => setFilterModalOpen(false)}
            />
        </>
    )
}