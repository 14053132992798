import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosClient } from "../axiosClient";
import { DocumentEnvelope, DownloadDocument } from "../../models/ConsentDocument";

export const fetchClientDocumentEnvelopes = createAsyncThunk('documentEnvelopes', async (clientId: number, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocumentEnvelope[]> = await (await axiosClient()).get(`/docusign/clients/${clientId}/envelopes`);
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const sendClientConsentDocument = createAsyncThunk('sendClientConsentDocument', async (params: {data: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocumentEnvelope> = await (await axiosClient()).post(`/docusign/clients/send-envelope`, {...params.data});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const getEnvelopeDownloadDocument = createAsyncThunk('getDownloadDocument', async (envelopeId: string) => {
    const response: AxiosResponse<DownloadDocument> = await (await axiosClient()).get(`/docusign/download/document/${envelopeId}`);
    return response.data;
});

export const voidClientConsentDocument = createAsyncThunk('voidClientConsentDocument', async (params: {documentId: number, clientId: number, data: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocumentEnvelope[]> = await (await axiosClient()).put(`/docusign/clients/${params.clientId}/envelopes/${params.documentId}/void`, {...params.data});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const needToLoadClientConsentDocument = createAsyncThunk("client/needToLoadClientConsentDocument", async (needLoad: boolean) => {
    return needLoad;
});

export const fetchLeadDocumentEnvelopes = createAsyncThunk('leadDocumentEnvelopes', async (leadId: number) => {
    const response: AxiosResponse<DocumentEnvelope[]> = await (await axiosClient()).get(`/docusign/leads/${leadId}/envelopes`);
    return response.data
});

export const sendLeadConsentDocument = createAsyncThunk('sendLeadConsentDocument', async (params: {data: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocumentEnvelope> = await (await axiosClient()).post(`/docusign/leads/send-envelope`, {...params.data});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const voidLeadConsentDocument = createAsyncThunk('voidLeadConsentDocument', async (params: {documentId: number, leadId: number, data: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocumentEnvelope[]> = await (await axiosClient()).put(`/docusign/leads/${params.leadId}/envelopes/${params.documentId}/void`, {...params.data});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});