import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Beneficiary } from "../../../../models/Beneficiary";
import { shortDateFormat } from "../../../../utils/functions";
import { RelationshipLabel } from "../../../../enums/Relationship";
import Button from "../../../Button";
import FilteredTable from "../../../FilteredTable";
import { PolicyBeneficiaryModal } from "./PolicyBeneficiaryModal";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

interface Props {
  //isNewClient:boolean
  beneficiaries: Beneficiary[];
  removeBeneficiary: (index: number) => void;
  updateBeneficiary: (index: number, beneficiary: Beneficiary) => void;
}

export const PolicyBeneficiariesTable: React.FC<Props> = ({
    //isNewClient,
    beneficiaries,
    removeBeneficiary,
    updateBeneficiary,
}) => {
    const { t } = useTranslation();

    const columns = useMemo(
        () => [
            {
                Header: t("FIRST_NAME"),
                accessor: "first_name",
            },
            {
                Header: t("LAST_NAME"),
                accessor: "last_name",
            },
            {
                Header: t("DATE_OF_BIRTH"),
                Cell: (row: any) => row.row.original.date_of_birth ? `${shortDateFormat(row.row.original.date_of_birth)}` : "-",
            },
            {
                Header: t("RELATIONSHIP"),
                Cell: (row: any) => row.row.original.relationship_id ? `${RelationshipLabel(row.row.original.relationship_id)}` : "-",
            },
            {
                id: "actions",
                Header: "",
                Cell: (row: any) => {
                    return (
                        <div className="flex flex-row justify-center divide-x">
                        <Button
                            isTerciary
                            iconOnly
                            tooltip={t("UPDATE_BENEFICIARY")}
                            onClick={() => updateBeneficiary(row.row.index, row.row.original)}
                            classNames="mr-2"
                        >
                            <PencilIcon className="h-5 aspect-square text-blue" />
                        </Button>
                        <Button
                            isTerciary
                            iconOnly
                            tooltip={t("DELETE_BENEFICIARY")}
                            onClick={() => removeBeneficiary(row.row.index)}
                            classNames="mr-2"
                        >
                            <TrashIcon className="h-5 aspect-square text-blue" />
                        </Button>
                        </div>
                    );
                },
            },
        ],
        [removeBeneficiary, t, updateBeneficiary]
    );

    return (
        <FilteredTable
            columns={columns}
            data={beneficiaries}
            hasSearchBar={false}
            noPadding={true}
        />
    );
};