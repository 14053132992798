import { useDispatch, useSelector } from "react-redux";
import { Client } from "../../../models/Client";
import { Dependent, newDependentInitialValues } from "../../../models/Dependant";
import { AppDispatch, RootState } from "../../../state/store";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { Toast } from "../../Toast";
import { useForm } from "react-hook-form";
import { createClientDependent, updateClientDependent } from "../../../state/dependents/actions";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "../../Modal";
import { ReactiveFormInput } from "../../form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../form/ReactiveFormSelect";
import { getGenders } from "../../../enums/Gender";
import { getRelationships } from "../../../enums/Relationship";
import { ReactiveFormPhoneInput } from "../../form/ReactiveFormPhoneInput";
import { LoadingMask } from "../../LoadingMask";

interface Props {
    info: {
        show: boolean;
        update: boolean;
        dependent?: Dependent;
        index?: number;
        client?: Client;
      };
    onSubmited: (dependent: Dependent, index?:number) => void;
    onClose: () => void;
}

export const ClientDepedentModal: React.FC<Props> = ({
    info,
    onSubmited,
    onClose
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const isLoading = useSelector((state: RootState) => state.dependents.isLoading);
    const error = useSelector((state: any) => state.dependents.error);
    const [showDependentError, setShowDependentError] = useState(false);

    useEffect(() => {
        if (showDependentError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showDependentError, error, t]);

    const methods = useForm<Dependent>({
        defaultValues: newDependentInitialValues
    })
    const { handleSubmit, control, reset } = methods;
    
    useEffect(() => {
        info.dependent ? reset(info.dependent) : reset(newDependentInitialValues);
    }, [info.dependent, reset]);

    const onSubmit = (data: Dependent) => {
        if (info.update) {
            //TODO: update
            dispatch(updateClientDependent({editDependent: data, dependentId: info.dependent?.id!, clientId: info.client?.id!})).then((e) => {
                if (e.type === "client/updateClientDependent/rejected") {
                    setShowDependentError(true);
                } else {
                    let dependent = (e.payload || {}) as Dependent;
                    toast(t("SUCCESSFULLY_UPDATE_CLIENT_DEPENDENT"));
                    onSubmited(dependent, info.index)
                    closeModal();
                }
            });
        } else {
            //Todo: add
            dispatch(createClientDependent({newDependent: data, clientId: info.client?.id!})).then((e) => {
                if (e.type === "client/createClientDependent/rejected") {
                    setShowDependentError(true);
                } else {
                    let dependent = (e.payload || {}) as Dependent;
                    toast(t("SUCCESSFULLY_SAVED_CLIENT_DEPENDENT"));
                    onSubmited(dependent)
                    closeModal();
                }
            });
        }
    }
    
    const closeModal = () => {
        reset({});
        onClose();
    };

    return (<>
        <Modal
            isOpen={info.show}
            onClose={closeModal}
            label={info.update ? t("UPDATE_DEPENDENT") : t("ADD_DEPENDENT")}
            onSave={handleSubmit(onSubmit)}
        >
            <div className="w-full flex flex-wrap">
                <Fragment>
                    <ReactiveFormInput
                        control={control}
                        className="md:w-1/3"
                        label={t("FULL_NAME")}
                        name={`name`}
                    />
                    <ReactiveFormInput
                        control={control}
                        className="md:w-1/3"
                        label={t("SOCIAL_SECURITY_NUMBER")}
                        name={`social_security_number`}
                    />
                    <ReactiveFormInput
                        control={control}
                        type="email"
                        className="md:w-1/3"
                        label={t("EMAIL")}
                        name={`email`}
                    />
                    <ReactiveFormSelect
                        className="md:w-1/4"
                        name={`gender_id`}
                        label={t("GENDER")}
                        options={getGenders()}
                        control={control}
                    />
                    <ReactiveFormInput
                        control={control}
                        type="date"
                        className="md:w-1/4"
                        label={t("DATE_OF_BIRTH")}
                        name={`date_of_birth`}
                    />
                    <ReactiveFormSelect
                        className="md:w-1/4"
                        name={`relationship_id`}
                        label={t("RELATIONSHIP")}
                        options={getRelationships()}
                        control={control}
                    />
                    <ReactiveFormPhoneInput
                        control={control}
                        className="md:w-1/4"
                        label={t("PHONE")}
                        name={`phone`}
                    />
                </Fragment>
            </div>
        </Modal>
        <ToastContainer progressStyle={{ background: "#D4AF37" }} />
        { isLoading && <LoadingMask />}
    </>)
}