import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { useEffect, useState } from "react";
import moment from "moment";
import { EditReminderModal, Reminder } from "../../../models/Reminder";
import { ModalInformation } from "../../ModalInformation";
import { localDateFormat, localDayFormat, localDayMFormat, localHourFormat, localTimeFormat } from "../../../utils/functions";
import { CheckIcon, ClockIcon, ExclamationTriangleIcon, PlusIcon } from "@heroicons/react/24/outline";
import Button from "../../Button";

enum ReminderStatus {
    pending,
    coming,
    finished
}

interface Props {
    info: {
        show: boolean;
    };
    onClose: () => void;
    addReminder: () => void;
    updateReminder: (index: number, policy: EditReminderModal) => void;
}

export const UserAllRemindersModal: React.FC<Props> = ({
    info,
    onClose,
    addReminder,
    updateReminder
}) => {
    const { t } = useTranslation();
    const reminders = useSelector((state: RootState) => state.reminders.reminders);
    const [todayReminders, setTodayReminders] = useState<Reminder[]>([]);
    const [othersReminders, setOthersReminders] = useState<Reminder[]>([]);

    useEffect(() => {
        if (reminders.length > 0) {
            const todayDate = moment().hours(0).minutes(0).seconds(0).format("YYYY-MM-DD");
            const todayR = reminders.filter((r) => {
                const rDate = moment(r.date).format("YYYY-MM-DD");
                return moment(rDate).isSame(todayDate);
            }).sort((a, b) => {
                if (moment(a.date).isBefore(new Date()) && moment(b.date).isBefore(new Date())) {
                    if (moment(a.date).isBefore(b.date)) {
                        return -1;
                    } else if (moment(a.date).isAfter(b.date)) {
                        return 1;
                    }
                    return 0;
                } else if (moment(a.date).isBefore(new Date()) && moment(b.date).isAfter(new Date())) {
                    return 1;
                } else if (moment(a.date).isAfter(new Date()) && moment(b.date).isBefore(new Date())) {
                    return -1;
                } else {
                    if (moment(a.date).isBefore(b.date)) {
                        return -1;
                    } else if (moment(a.date).isAfter(b.date)) {
                        return 1;
                    }
                    return 0;
                }

            });
            setTodayReminders(todayR)

            const othersR = reminders.filter((r) => {
                const rDate = moment(r.date).format("YYYY-MM-DD");
                return moment(rDate).isAfter(todayDate);
            }).sort((a, b) => moment(a.date).isBefore(b.date) ? -1 : 1);
            setOthersReminders(othersR)
        }
    }, [reminders]);

    const getReminderStatus = (reminder: Reminder) => {
        const actualDate = moment(new Date());
        const reminderDate = moment(reminder.date);

        let status: ReminderStatus

        if (reminderDate.isBefore(actualDate)) {
            status = ReminderStatus.finished
        } else {
            if (reminderDate.diff(actualDate, 'minutes') <= 15) {
                status = ReminderStatus.coming
            } else {
                status = ReminderStatus.pending;
            }
        }

        switch (status) {
            case ReminderStatus.coming:
                return <div className="bg-yellow-200 rounded-lg px-3 py-1">
                    <span className='text-yellow-600 flex items-center pr-1'>
                        <div className='w-8 p-1 aspect-square stroke-2'><ExclamationTriangleIcon /></div>
                        <div className="max-md:hidden font-medium text-lg">{t('COMING')}</div>
                    </span>
                </div>
            case ReminderStatus.pending:
                return (
                    <div className="bg-neutral-200 rounded-lg px-3 py-1">
                        <span className='text-gray-600 flex items-center pr-1'>
                            <div className='w-8 p-1 aspect-square stroke-2'><ClockIcon /></div>
                            <div className="max-md:hidden font-medium text-lg">{t('PENDING')}</div>
                        </span>
                    </div>
                )
            case ReminderStatus.finished:
                return <div className="bg-green-200 rounded-lg px-3 py-1">
                    <span className='text-green-600 flex items-center pr-1'>
                        <div className='w-8 p-1 aspect-square stroke-2'><CheckIcon /></div>
                        <div className="max-md:hidden font-medium text-lg">{t('FINISHED')}</div>
                    </span>
                </div>
        }
    }

    const updateUserReminder = (reminder: Reminder, index: number) => {
        if (moment(reminder.date).isSameOrAfter(new Date())) {
            const date = localDateFormat(`${reminder.date}`);
            const time = localTimeFormat(`${reminder.date}`);
            const remiderEditModal: EditReminderModal = {
                id: reminder.id,
                message: reminder.message,
                date: date,
                time: time,
                is_to_myself: "1",
                agent: reminder.agent_id,
                client: reminder.client_id ? reminder.client_id : undefined,
                client_name: reminder.client_id ? reminder.client_name : undefined
            };
            updateReminder(index, remiderEditModal)
        }
    }

    return (
        <ModalInformation
            isOpen={info.show}
            onClose={onClose}
            label={t('REMINDERS')}
            cancelButton={false}
            saveButton={{
                label: t("NEW_REMINDER"),
                onClick: addReminder,
                icon: <PlusIcon />
            }}
        >
            <div className="w-full flex flex-wrap mb-3">
                {todayReminders.length > 0 &&
                    <div className="w-full">
                        <div className="text-gray-700 mx-5 mb-2">{t("TODAY")}</div>
                        {todayReminders.map((reminder, index) => {
                            return <div className="mx-5 mb-3 cursor-pointer" onClick={() => updateUserReminder(reminder, index)}>
                                <div className="w-full flex flex-row justify-between">
                                    <div className="flex flex-row items-center">
                                        <div className="flex flex-col items-center justify-center">
                                            <span className="text-gold text-xl leading-none">{localDayFormat(`${reminder.date}`)}</span>
                                            <span className="text-gold text-3xl font-semibold leading-none">{localDayMFormat(`${reminder.date}`)}</span>
                                        </div>
                                        <div className="text-blue ml-3 text-xl">{reminder.message}</div>
                                    </div>
                                    <div className="flex flex-row items-center ml-5">
                                        <div className="text-end text-gray-500 font-light text-xl mr-3 min-w-24">{localHourFormat(`${reminder.date}`)}</div>
                                        {getReminderStatus(reminder)}
                                    </div>
                                </div>
                                {index !== todayReminders.length - 1 && <hr className="w-full mt-3"></hr>}
                            </div>
                        })
                        }
                    </div>
                }
                {
                    othersReminders.length > 0 &&
                    <div className="w-full mt-7">
                        <div className="text-gray-700 mx-5 mb-2">{t("OTHER")}</div>
                        {othersReminders.map((reminder, index) => {
                            return <div className="mx-5 mb-3 cursor-pointer" onClick={() => { }}>
                                <div className="w-full flex flex-row justify-between">
                                    <div className="flex flex-row items-center">
                                        <div className="flex flex-col items-center justify-center">
                                            <span className="text-gold text-xl leading-none">{localDayFormat(`${reminder.date}`)}</span>
                                            <span className="text-gold text-3xl font-semibold leading-none">{localDayMFormat(`${reminder.date}`)}</span>
                                        </div>
                                        <div className="text-blue ml-3 text-xl">{reminder.message}</div>
                                    </div>
                                    <div className="flex flex-row items-center ml-5">
                                        <div className="text-end text-gray-500 font-light text-xl mr-3 min-w-24">{localHourFormat(`${reminder.date}`)}</div>
                                        {getReminderStatus(reminder)}
                                    </div>
                                </div>
                                {index !== othersReminders.length - 1 && <hr className="w-full mt-3"></hr>}
                            </div>
                        })}
                    </div>
                }
            </div>
        </ModalInformation>
    )
}