import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosClient } from "../axiosClient";
import { AgencyPolicyType, AllPolicyTypes, PolicyMarket, PolicyType, UpdateAgencyPolicyTypes } from "../../models/PolicyMarket";

export const fetchAgencyPolicyTypes = createAsyncThunk('agencies/fetchAgencyPolicyTypes', async (agencyId: number, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<AgencyPolicyType[]> = await (await axiosClient()).get(`/agencies/${agencyId}/policy-types`);
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
});

export const updateAgencyPolicyTypes = createAsyncThunk('agencies/updateAgencyPolicyTypes', async (params: {agencyId: number, policyTypeData: UpdateAgencyPolicyTypes}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<AgencyPolicyType[]> = await (await axiosClient()).post(`/agencies/${params.agencyId}/policy-types`, {...params.policyTypeData});
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
});

export const fetchPolicyMarkets = createAsyncThunk('market/fetchPolicyMarkets', async () => {
    const response: AxiosResponse<PolicyMarket[]> = await (await axiosClient()).get(`/policy-markets/`);
    return response.data
});

export const fetchMarketPolicyTypes = createAsyncThunk('market/fetchMarketPolicyTypes', async (marketId: number, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<PolicyType[]> = await (await axiosClient()).get(`/policy-markets/${marketId}/policy-types`);
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
});

export const fetchAllPolicyTypes = createAsyncThunk('market/fetchAllPolicyTypes', async () => {
    const response: AxiosResponse<AllPolicyTypes[]> = await (await axiosClient()).get(`/policy-markets/all-policy-types`);
    return response.data
});