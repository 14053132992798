import { useDispatch, useSelector } from "react-redux";
import { Client } from "../../../models/Client";
import { DocumentEnvelope, DocumentTemplate, NewSendDocument, NewSendDocumentAgent, NewSendDocumentClient } from "../../../models/ConsentDocument";
import { AppDispatch, RootState } from "../../../state/store";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Toast } from "../../Toast";
import { useForm } from "react-hook-form";
import { Modal } from "../../Modal";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { ReactiveFormRadioButtonText } from "../../form/ReactiveFormRadioButtonText";
import { ReactiveFormSelect } from "../../form/ReactiveFormSelect";
import { ReactiveFormSMSelect } from "../../form/ReactiveFormSMSelect";
import { RoleEnum } from "../../../enums/Role";
import { ReactiveFormInput } from "../../form/ReactiveFormInput";
import { toast, ToastContainer } from "react-toastify";
import { LoadingMask } from "../../LoadingMask";
import { actualDateFormat, dateFormat } from "../../../utils/functions";
import { sendClientConsentDocument } from "../../../state/consentDocuments/actions";

interface Props {
    info: {
        show: boolean;
        client?: Client;
      };
    onSubmited: (consentDocument: DocumentEnvelope) => void;
    onClose: () => void;
}

export const ClientConsentDocumentModal: React.FC<Props> = ({
    info,
    onSubmited,
    onClose
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const users = useSelector((state: any) => state.users.users);
    const templates = useSelector((state: RootState) => state.templates.templates);
    const [isLoading, setIsLoading] = useState(false)
    const error = useSelector((state: any) => state.consentDocuments.error);
    const [showSendDocumentError, setShowSendDocumentError] = useState(false);

    useEffect(() => {
        if (showSendDocumentError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showSendDocumentError, error, t]);

    const methods = useForm<any>({
        defaultValues: {}
    })
    const { handleSubmit, control, reset } = methods;

    const getTemplates = useMemo(() => {
        return templates.map((template: DocumentTemplate) => {
          return {
            label: `${template.name}`,
            value: `${template.id}`,
            key: template.id,
          };
        });
    }, [templates]);

    const agents = useMemo(() => {
        let list = users.filter((x: any) => x.id !== currentUser?.id);
        return list.map((x: any) => {
          return {
            label: `${x.first_name} ${x.last_name}`,
            value: x.id,
          };
        });
    }, [users]);

    const onSubmit = (data: any) => {
        //Todo: add
        if (data.template_id === undefined || data.template_id === null) {
            toast(t("PLEASE_SELECT_A_TEMPLATE"));
        } else if (
            data.requested_date === undefined ||
            data.requested_date === null
        ) {
            toast(t("PLEASE_SET_EFFECTIVE_DATE_REQUESTED"));
        } else {
            setIsLoading(true);
    
            let template = templates.find((tem) => tem.id === Number(data.template_id!));
            const agentData: NewSendDocumentAgent = {};
    
            if (currentUser?.role_id !== RoleEnum.AGENT) {
                if (data.agent === null || data.agent === undefined || data.agent === 0) {
                    let agentName = currentUser.first_name + " " + currentUser.last_name;
        
                    agentData.id = currentUser.id;
                    agentData.name = agentName.trim();
                    agentData.email = currentUser.email;
                    agentData.phone = currentUser.phone;
                    agentData.npn = currentUser.national_producer_number;
                } else {
                    let agentSelect = users.find((a: any) => a.id === data.agent);
                    if (agentSelect !== undefined) {
                        let agentName =
                            agentSelect.first_name + " " + agentSelect.last_name;
            
                        agentData.id = agentSelect.id;
                        agentData.name = agentName.trim();
                        agentData.email = agentSelect.email;
                        agentData.phone = agentSelect.phone;
                        agentData.npn = agentSelect.national_producer_number;
                    } else {
                        let agentName =
                            currentUser.first_name + " " + currentUser.last_name;
            
                        agentData.id = currentUser.id;
                        agentData.name = agentName.trim();
                        agentData.email = currentUser.email;
                        agentData.phone = currentUser.phone;
                        agentData.npn = currentUser.national_producer_number;
                    }
                }
            } else {
                let agentName = currentUser.first_name + " " + currentUser.last_name;
        
                agentData.id = currentUser.id;
                agentData.name = agentName.trim();
                agentData.email = currentUser.email;
                agentData.phone = currentUser.phone;
                agentData.npn = currentUser.national_producer_number;
            }
    
            let name = info.client?.first_name;
            if (info.client?.middle_name !== undefined) {
                name = name + " " + info.client?.middle_name;
            }
            name = name + " " + info.client?.last_name;
            if (info.client?.second_last_name) {
                name = name + " " + info.client?.second_last_name;
            }
    
            const clientData: NewSendDocumentClient = {
                id: info.client?.id,
                name: name.trim(),
                email: info.client?.email,
                birthday: dateFormat(`${info.client?.date_of_birth}`),
                phone: info.client?.phone,
            };
    
            const sendDocumentData: NewSendDocument = {
                client: clientData,
                agent: agentData,
                requested_date: dateFormat(data.requested_date!),
                template_id: Number(data.template_id!),
                template_docusign_id: template!.docusign_template_id,
                sending_method: data.sending_method ?? "email",
                document_url: template!.document_url,
                document_name: template!.document_name,
                template_language: template!.language,
                date: actualDateFormat(),
            };
    
            dispatch(sendClientConsentDocument({ data: sendDocumentData })).then((e) => {
                if (e.type === "sendClientConsentDocument/rejected") {
                    setShowSendDocumentError(true);
                    setIsLoading(false);
                } else {
                    let consentDocument = (e.payload || {}) as DocumentEnvelope;
                    toast(t("SUCCESSFULLY_SENT_CLIENT_CONSENT_DOCUMENT"));
                    setIsLoading(false);
                    onSubmited(consentDocument)
                    closeModal();
                }
            });
        }
    }
    
    const closeModal = () => {
        reset({});
        onClose();
    };

    return (<>
        <Modal
            isOpen={info.show}
            onClose={closeModal}
            label={t("SEND_DOCUMENT")}
            saveButton={{
                label: t("SEND"),
                icon: <PaperAirplaneIcon />,
                onClick: handleSubmit(onSubmit),
            }}
        >
            <div className="w-full flex flex-wrap">
                <Fragment>
                    <ReactiveFormRadioButtonText
                        className={"md:w-1/1"}
                        name="sending_method"
                        label={t("DELIVERY_METHOD")}
                        control={control}
                        isRequired
                        options={[
                            { label: t("EMAIL"), value: "email" },
                            { label: t("SMS"), value: "sms" },
                            { label: t("WHATSAPP"), value: "whatsapp" },
                        ]}
                    />
                    <ReactiveFormSelect
                        className="md:w-1/2"
                        name="template_id"
                        label={t("TEMPLATE")}
                        control={control}
                        options={getTemplates}
                        isRequired
                    />
                    <ReactiveFormInput
                        className="md:w-1/2"
                        control={control}
                        name="requested_date"
                        type="date"
                        label={t("EFFECTIVE_DATE_REQUESTED")}
                        isRequired
                    />
                    {currentUser?.role_id !== RoleEnum.AGENT && (
                        <ReactiveFormSMSelect
                            isMulti={false}
                            className={"md:w-1/2"}
                            selectClassName={"basic-single mb-3"}
                            name="agent"
                            label={t("AGENT")}
                            control={control}
                            options={agents}
                            menuPosition={"fixed"}
                            placeholder={"Select agents"}
                        />
                    )}
                </Fragment>
            </div>
        </Modal>
        <ToastContainer progressStyle={{ background: "#D4AF37" }} />
        { isLoading && <LoadingMask />}
    </>)
}