import { toast, ToastContainer } from "react-toastify";
import { Client } from "../../../models/Client";
import { DocumentEnvelope, VoidDocumentData } from "../../../models/ConsentDocument";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { Toast } from "../../Toast";
import { useForm } from "react-hook-form";
import { Modal } from "../../Modal";
import { ReactiveFormInputArea } from "../../form/ReactiveFormInputArea";
import { LoadingMask } from "../../LoadingMask";
import { NoSymbolIcon } from "@heroicons/react/24/outline";
import { voidClientConsentDocument } from "../../../state/consentDocuments/actions";

interface Props {
    info: {
        show: boolean;
        client?: Client;
        consentDocument?: DocumentEnvelope
      };
    onVoided: (consentDocuments: DocumentEnvelope[]) => void;
    onClose: () => void;
}

export const VoidClientConsentDocumentModal: React.FC<Props> = ({
    info,
    onVoided,
    onClose
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const error = useSelector((state: any) => state.consentDocuments.error);
    const [showVoidDocumentError, setShowVoidDocumentError] = useState(false);

    useEffect(() => {
        if (showVoidDocumentError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showVoidDocumentError, error, t]);

    const methods = useForm<any>({
        defaultValues: {}
    })
    const { handleSubmit, control, reset } = methods;

    const onSubmit = (data: any) => {
        //Todo: add
        if (data.void_reason == null || data.void_reason == undefined || data.void_reason == "") {
            toast(t("VOID_CONSENT_DOCUMENT_MESSAGE"));
        } else {
            setIsLoading(true);
    
            const voidData: VoidDocumentData = {
                docusign_envelope_id: info.consentDocument?.docusign_envelope_id!,
                void_reason: data.void_reason,
            };
    
            dispatch(voidClientConsentDocument({documentId: info.consentDocument?.id!, clientId: info.client?.id!, data: voidData})).then((e) => {
                if (e.type === "voidClientConsentDocument/rejected") {
                    setShowVoidDocumentError(true);
                    setIsLoading(false);
                } else {
                    let consentDocuments = (e.payload || []) as DocumentEnvelope[];
                    toast(t("SUCCESSFULLY_VOIDED_CLIENT_CONSENT_DOCUMENT"));
                    setIsLoading(false);
                    onVoided(consentDocuments)
                    closeModal();
                }
            });
        }
    }
    
    const closeModal = () => {
        reset({});
        onClose();
    };

    return (<>
        <Modal
            isOpen={info.show}
            onClose={closeModal}
            label={t("VOID_DOCUMENT")}
            saveButton={{
                label: t("VOID"),
                icon: <NoSymbolIcon />,
                onClick: handleSubmit(onSubmit),
            }}
        >
            <div className="w-full flex flex-wrap">
                <Fragment>
                    <ReactiveFormInputArea
                        className="md:w-1/1"
                        control={control}
                        name="void_reason"
                        label={t("VOID_DOCUMENT_MESSAGE")}
                        isRequired
                    />
                </Fragment>
            </div>
        </Modal>
        <ToastContainer progressStyle={{ background: "#D4AF37" }} />
        { isLoading && <LoadingMask />}
    </>)
}