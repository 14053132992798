import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocusignConfig } from "../../models/DocusignConfig";
import { createAgencyDocusignConfig, deleteAgencyDocusignConfig, fetchAgencyDocusignConfig, fetchAgencyDocusignConfigById, setAgencyMainDocusignConfig, updateAgencyDocusignConfig } from "./actions";

interface DocusignConfigState {
    docusignConfigs: DocusignConfig[],
    currentDocusignConfig?: DocusignConfig,
    isLoading: boolean,
    error: string | null,
}

const initialState: DocusignConfigState = {
    docusignConfigs: [],
    isLoading: false,
    error: null
}

// slice
const docusignConfigsSlice = createSlice({
  name: "clients",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgencyDocusignConfig.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchAgencyDocusignConfig.fulfilled, (state, action: PayloadAction<DocusignConfig[]>) => {
        state.isLoading = false;
        state.docusignConfigs = action.payload;
      })
      .addCase(fetchAgencyDocusignConfig.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createAgencyDocusignConfig.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createAgencyDocusignConfig.fulfilled, (state, action: PayloadAction<DocusignConfig>) => {
        state.isLoading = false;
        state.docusignConfigs = state.docusignConfigs.concat(action.payload);
      })
      .addCase(createAgencyDocusignConfig.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateAgencyDocusignConfig.pending, (state, action) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateAgencyDocusignConfig.fulfilled, (state, action: PayloadAction<DocusignConfig>) => {
        state.isLoading = false;
        state.error = null;
        const updatedConfig = action.payload;
        const updatedDocusignConfigs = state.docusignConfigs.map((config) => {
          return config.id === updatedConfig.id ? updatedConfig : config
        }
        );
        state.docusignConfigs = updatedDocusignConfigs;
      })
      .addCase(updateAgencyDocusignConfig.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteAgencyDocusignConfig.pending, (state, action) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(deleteAgencyDocusignConfig.fulfilled, (state, action: PayloadAction<DocusignConfig[]>) => {
        state.isLoading = false;
        state.error = null;
        state.docusignConfigs = action.payload;
      })
      .addCase(deleteAgencyDocusignConfig.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAgencyDocusignConfigById.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchAgencyDocusignConfigById.fulfilled, (state, action: PayloadAction<DocusignConfig>) => {
        state.isLoading = false;
        state.currentDocusignConfig = action.payload;
      })
      .addCase(fetchAgencyDocusignConfigById.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(setAgencyMainDocusignConfig.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(setAgencyMainDocusignConfig.fulfilled, (state, action: PayloadAction<DocusignConfig[]>) => {
        state.isLoading = false;
        state.docusignConfigs = action.payload;
      })
      .addCase(setAgencyMainDocusignConfig.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default docusignConfigsSlice.reducer;