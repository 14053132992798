import {
  ChatBubbleBottomCenterIcon,
  FunnelIcon,
  PencilIcon,
  PhoneIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Button from "../../components/Button";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import FilteredTable, { TableFilter } from "../../components/FilteredTable";
import { LoadingMask } from "../../components/LoadingMask";
import { Toast } from "../../components/Toast";
import {
  ConversationsPath,
  NewClientPath,
  UpdateClientPath,
} from "../../constants/Routes";
import { RoleEnum } from "../../enums/Role";
import { TwilioDeviceState } from "../../enums/TwilioDeviceState";
import { Client } from "../../models/Client";
import {
  deleteClient,
  fetchClients,
  pastPage,
} from "../../state/clients/actions";
import { AppDispatch, RootState } from "../../state/store";
import {
  createConversation,
  fetchConversationMessages,
} from "../../state/twilio/actions";
import { shortLocalDateFormat } from "../../utils/functions";
import { FilterModal } from "./FilterModal";
import { ConversationType } from "../../enums/ConversationType";
import { BellAlertIcon } from "@heroicons/react/24/solid";
import moment from "moment";

interface Props {
  makePhoneCall: (phone: string) => void;
}

export const Clients = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageClients = useSelector((state: any) => state.clients.pageClients);
  const paginatedOptions = useSelector(
    (state: any) => state.clients.paginatedOptions
  );
  const filters = useSelector((state: any) => state.clients.filters);
  const error = useSelector((state: any) => state.clients.error);
  const isLoading = useSelector((state: any) => state.clients.isLoading);
  const users = useSelector((state: any) => state.users.users);
  const conversations = useSelector((state: any) => state.twilio.conversations);
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const deviceState = useSelector(
    (state: any) => state.twilio.twilioDeviceState
  );
  const twilioChatState = useSelector(
    (state: any) => state.twilio.areTwilioCredentialsValid
  );
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);
  const [seletedClient, setSelectedClient] = useState<Client | null>(null);
  const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const todayReminders = useSelector((state: RootState) => state.reminders.todayReminders);

  useEffect(() => {
    if (showError) {
      Toast(t(error?.reason ?? "SOMETHING_WENT_WRONG"), t(error?.cause_info));
      setShowError(false);
    }
  }, [showError, error, t]);

  const agents = useMemo(() => {
    return users.map((x: any) => {
      return {
        label: `${x.first_name} ${x.last_name}`,
        value: x.id,
      };
    });
  }, [users]);

  const openConfirmationModal = (client: any) => {
    setIsConfirmationModalOpen(true);
    setSelectedClient(client);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setSelectedClient(null);
  };

  const makeConversation = useCallback(
    (phone: string) => {
      if (conversations && currentUser) {
        const selectedConversation = conversations.find(
          (x: any) => x.friendlyName === phone
        );
        if (selectedConversation) {
          dispatch(
            fetchConversationMessages({
              conversationSid: selectedConversation.sid,
            })
          );
        } else {
          dispatch(
            createConversation({
              newConversation: {
                from: currentUser.main_twilio_number?.twilio_phone,
                to: phone,
              },
              conversationType: ConversationType.CLIENTS,
            })
          );
        }
        navigate(ConversationsPath);
      }
    },
    [conversations, currentUser, dispatch, navigate]
  );

  const clientHasAReminder = (client: Client) => {
    const todayHour = moment(new Date());
    let reminder = todayReminders.find((r) => r.client_id === client.id);
    if(reminder !== undefined) {
      const reminderHour = moment(reminder.date);
      return reminderHour.isSameOrAfter(todayHour);
    } else {
      return false;
    }
  }

  const columns = useMemo(() => {
    const showColumns = [
      /*{
        Header: "-",
        Cell: (row: any) => clientHasAReminder(row.row.original)
        ? <BellAlertIcon className="h-5 aspect-square text-gold ml-2" />
        : ""
      },*/
      {
        Header: t("NAME"),
        accessor: "first_name",
      },
      {
        Header: t("LAST_NAME"),
        accessor: "last_name",
      },
      {
        Header: t("PHONE"),
        accessor: "phone",
      },
      {
        Header: t("EMAIL"),
        accessor: "email",
      },
      {
        Header: t("CREATION_DATE"),
        Cell: (row: any) =>
          shortLocalDateFormat(`${row.row.original.creation_date}`),
      },
    ];

    const actions = {
      Header: " ",
      Cell: (row: any) => {
        return (
          <div className="flex flex-row justify-center divide-x">
            <Button
              isTerciary
              isDisabled={!twilioChatState}
              tooltip={t("START_CONVERSATION")}
              iconOnly
              onClick={() => makeConversation(row.row.original.phone)}
              classNames="pl-2"
            >
              <ChatBubbleBottomCenterIcon className="h-5 aspect-square text-gold" />
            </Button>
            <Button
              isTerciary
              isDisabled={deviceState !== TwilioDeviceState.READY}
              tooltip={t("CALL_CLIENT")}
              iconOnly
              onClick={() => props.makePhoneCall(row.row.original.phone)}
              classNames="pl-2"
            >
              <PhoneIcon className="h-5 aspect-square text-gold" />
            </Button>
            <Button
              isTerciary
              tooltip={t("UPDATE_CLIENT")}
              iconOnly
              onClick={() => updateClient(row.row.original)}
              classNames="mr-2"
            >
              <PencilIcon className="h-5 aspect-square text-blue" />
            </Button>
            <Button
              isTerciary
              tooltip={t("DELETE_CLIENT")}
              iconOnly
              onClick={() => openConfirmationModal(row.row.original)}
              classNames="mr-2"
            >
              <TrashIcon className="h-5 aspect-square text-red-600" />
            </Button>
          </div>
        );
      },
    };
    if (currentUser?.role_id !== RoleEnum.AGENT) {
      return [
        ...showColumns,
        {
          Header: t("AGENT"),
          Cell: (row: any) => {
            const { agent } = row.row.original;
            return `${agent?.first_name ?? ""} ${agent?.last_name ?? ""}`;
          },
        },
        actions,
      ];
    }
    return [...showColumns, actions];
  }, [deviceState, makeConversation, props, t, currentUser, todayReminders]);

  const updateClient = (row: any) => {
    navigate(UpdateClientPath, { state: { client: row } });
  };

  const agentsFilter = () => {
    return currentUser?.role_id !== RoleEnum.AGENT
      ? ({
          label: t("AGENTS"),
          options: agents,
          name: "agent",
          setOption: setSelectedAgentIds,
        } as TableFilter)
      : undefined;
  };

  const showDeleteConfirmationModal = () => {
    return (
      <ConfirmationModal
        isDeleteConfirm={true}
        isOpen={isConfirmationModalOpen}
        onClose={closeConfirmationModal}
        label={t("DELETE_CLIENT")}
        saveButton={{
          label: t("DELETE"),
          icon: <TrashIcon />,
          onClick: onDeleteClient,
        }}
      >
        {isConfirmationLoading && <LoadingMask />}
        <div className="w-full flex flex-wrap items-center justify-center">
          <label className="text-lg font-semibold py-4">
            {t("DELETE_CLIENT_MESSAGE")}
          </label>
        </div>
      </ConfirmationModal>
    );
  };

  const onDeleteClient = () => {
    setIsConfirmationLoading(true);

    dispatch(
      deleteClient({
        clientId: seletedClient!.id!,
        page: paginatedOptions.page,
        pageSize: paginatedOptions.pageSize,
      })
    ).then((e) => {
      if (e.type === "clients/deleteClient/rejected") {
        setIsConfirmationLoading(false);
        setShowError(true);
      } else {
        toast(t("SUCCESSFULLY_DELETE_CLIENT"));
        setIsConfirmationLoading(false);
        closeConfirmationModal();
      }
    });
  };

  const getPaginationOptions = useMemo(() => {
    return {
      page: paginatedOptions.page,
      pageSize: paginatedOptions.pageSize,
      totalPages: paginatedOptions.totalPages,
      totalCount: paginatedOptions.totalCount,
      setPage: (value: number) => {
        value < paginatedOptions.page
          ? dispatch(pastPage())
          : dispatch(
              fetchClients({
                page: value,
                pageSize: paginatedOptions.pageSize,
                filters: filters,
              })
            );
      },
      setPageSize: (value: number) =>
        dispatch(
          fetchClients({
            page: paginatedOptions.page,
            pageSize: value,
            filters: filters,
          })
        ),
    };
  }, [dispatch, paginatedOptions]);

  // Función de resaltado para clientes
  const highlightClientRow = (client: Client) => {
    return clientHasAReminder(client); // Resalta filas si tiene un recordatorio
  };

  return (
    <>
      {isLoading && <LoadingMask />}
      {showDeleteConfirmationModal()}
      <FilteredTable
        columns={columns}
        filter={agentsFilter()}
        data={pageClients}
        highlightRow={highlightClientRow}
        isPaginated
        paginationOptions={getPaginationOptions}
        hasSearchBar={false}
        secondaryActionButton={
          <Button onClick={() => navigate(NewClientPath)}>
            <span className="flex items-center font-semibold pr-3">
              <div className="w-8 p-1 aspect-square mr-2">
                <UserPlusIcon />
              </div>
              {t("NEW_CLIENT")}
            </span>
          </Button>
        }
        buttonAction={
          <Button
            onClick={() => setFilterModalOpen(true)}
            iconOnly
            classNames="w-9 p-1 aspect-square mr-4 "
          >
            <FunnelIcon className=""></FunnelIcon>
          </Button>
        }
      />
      <FilterModal
        isFilterModalOpen={isFilterModalOpen}
        setFilterModalOpen={() => setFilterModalOpen(false)}
      />
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
    </>
  );
};
