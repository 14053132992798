import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { sendEmail } from "./actions";

interface EnvelopeState {
    sendEmail: any,
    isLoading: boolean,
    error: string | null,
}
  
const initialState: EnvelopeState = {
    sendEmail: "",
    isLoading: false,
    error: null,
}

// slice
const emailSlice = createSlice({
    name: 'emails',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(sendEmail.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(sendEmail.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.sendEmail = action.payload;
            })
            .addCase(sendEmail.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    }
});

export default emailSlice.reducer;