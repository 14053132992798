import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { AsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { set } from "react-hook-form";
import { AppDispatch } from "../../state/store";
import { debounce } from "../../utils/functions";

interface Props {
    name: string;
    value: string;
    label: string;
    dataSourceAsyncThunk: AsyncThunk<{
        label: string;
        value: number;
    }[], {
        q: string;
        agencyId: number;
    }, any>;
    defaultItem?: {label: string, value: string};
    data: any;
    className?: string;
    placeholder?: string;
    error?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    onBlur?: (value: any) => void;
    onChange?: (item: {label: string, value: string}) => void;
}

export const FormRemoteSearchableSelect = (props: Props) => {
    const { t } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchText, setSearchText] = useState<string | undefined>();
    const [filteredOptions, setFilteredOptions] = useState<{ value: string; label: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    
    const dropdownRef = useRef<any>(null);

    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

    const fetchOptions = async (q: string) => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = setTimeout(async () => {
            try {
                setLoading(true);
                const options = await dispatch(props.dataSourceAsyncThunk({q: q, ...props.data}));
    
                setFilteredOptions(options.payload as { value: string; label: string }[]);
            } catch(e) {
                setFilteredOptions([]);
            } finally {
                setLoading(false);
            }
        }, 500);
    }
    

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsDropdownOpen(true);

        let q = e.target.value

        if (q && q.length > 2) {
            fetchOptions(q);
        }

        setSearchText(e.target.value);
    };

    const handleOptionClick = (option: { value: string; label: string }) => {
        props.onChange && props.onChange!(option);
        setSearchText(option.label);
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (e: Event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div ref={dropdownRef} className={"relative w-full px-3 " + props.className}>
            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                {props.label}
            </label>
            <input
                id="searchableInput"
                type="text"
                placeholder={t("SEARCH_&_SELECT")}
                value={searchText ?? props.defaultItem?.label}
                onChange={handleInputChange}
                onBlur={(e) => {
                    setTimeout(() => {
                        let input:any = document.getElementById("searchableInput")
                        props.onBlur && props.onBlur(input.value)
                    }, 100)
                }}
                className={classNames({
                    "appearance-none block w-full border border-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-blue": true,
                    "border-red-500": props.error,
                    "bg-slate-100": props.isDisabled == true
                })}
            />

            {isDropdownOpen && (
                <div className="absolute top-10 left-0 bg-white border border-gray-300 rounded mt-9 w-full z-[20]">
                    {loading && (
                        <div className="p-2">
                            <span>{t("LOADING")}...</span>
                        </div>
                    )}
                    
                    {!loading && filteredOptions && filteredOptions.map((option) => (
                        <div
                            key={option.value}
                            className="p-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}

            {props.error && <p className="text-red-500 text-xs italic">{props.error}</p>}
        </div>
    );
};