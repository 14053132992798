import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../state/store";
import { useEffect, useMemo, useState } from "react";
import { User } from "../../models/User";
import { LeadsFilters } from "../../models/Lead";
import { SubmitHandler, useForm } from "react-hook-form";
import { fetchLeads, fetchLeadsPool } from "../../state/leads/actions";
import { Modal } from "../../components/Modal";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Form } from "../../components/form/Form";
import { RoleEnum } from "../../enums/Role";
import Select from "react-select";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { getLeadStatuses } from "../../enums/LeadStatus";

interface Props {
    isFilterModalOpen: boolean;
    setFilterModalOpen: (x: boolean) => void;
    isPoolFilter?: boolean;
}

export const LeadFilterModal = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const users = useSelector((state: any) => state.users.users);
    const leadsState = useSelector((state: any) => state.leads);
    const [currentAgents, setCurrentAgents] = useState<any[]>();

    const selectedAgents = useMemo(() => {
        return leadsState.filters?.agents?.map((x: number) => {
            const user = users.find((y: User) => y.id === x);
            return {
                label: `${user.first_name} ${user.last_name}`,
                value: user.id
            }
        })
    }, [leadsState.filters?.agents, users]);

    useEffect(() => {
        setCurrentAgents(selectedAgents)
    }, [selectedAgents]);

    const agents = useMemo(() => {
        return users.map((x: any) => {
            return {
                label: `${x.first_name} ${x.last_name}`,
                value: x.id
            }
        })
    }, [users]);

    const methods = useForm<LeadsFilters>({
        defaultValues: {
            name: leadsState.filters?.name,
            lastName: leadsState.filters?.lastName,
            phone: leadsState.filters?.phone,
            email: leadsState.filters?.email,
            agents: leadsState.filters?.agents,
            status: leadsState.filters?.status
        }
    });

    const { handleSubmit, control, reset } = methods;

    const onSubmit: SubmitHandler<LeadsFilters> = async (data: LeadsFilters) => {
        if (currentAgents?.length && currentAgents?.length > 0) {
            data.agents = currentAgents?.map((x: any) => x.value);
        }
        let dataCopy:any = data
        dataCopy["last_name"] = data.lastName
        console.log("dataCopy",dataCopy)
        if(props.isPoolFilter) {
            dispatch(fetchLeadsPool({ page: 0, pageSize: leadsState.paginatedOptionsPool.pageSize, filters: dataCopy }));
        } else {
            dispatch(fetchLeads({ page: 0, pageSize: leadsState.paginatedOptions.pageSize, filters: dataCopy }));
        }
        props.setFilterModalOpen(false);
    };

    return (
        <Modal isOpen={props.isFilterModalOpen} onClose={() => props.setFilterModalOpen(false)} label={t("FILTERS")} onSave={handleSubmit(onSubmit)} thirdButton={{
            label: t("CLEAR_FILTERS"),
            icon: <TrashIcon />,
            onClick: () =>  {
                reset({ name: '', lastName: '', phone: '', email: '', status: '', agents: undefined });
                setCurrentAgents([]);
            }
        }}>
            <Form handleOnSubmit={handleSubmit(onSubmit)}>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {!props.isPoolFilter && currentUser?.role_id !== RoleEnum.AGENT &&
                        <div className={"w-full px-3"}>
                            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                                {t("AGENTS")}
                            </label>
                            <Select
                                isMulti
                                isSearchable
                                isClearable
                                isLoading={agents.length === 0}
                                onChange={(inputValue: any) => setCurrentAgents(inputValue)}
                                name="agent"
                                options={agents}
                                value={currentAgents}
                                placeholder="Select agents"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                maxMenuHeight={200}
                                classNames={{
                                    control: (state) =>
                                        'bg-white opacity-90 py-1 px-1.5 rounded shadow w-full min-w-[200px]',
                                }}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: 'grey-50',
                                    }),
                                }}
                            />
                        </div>
                    }
                    <ReactiveFormInput
                        control={control}
                        value={leadsState.filters?.name ?? ""}
                        label={t("NAME")}
                        name="name" />
                    <ReactiveFormInput
                        control={control}
                        value={leadsState.filters?.last_name}
                        label={t("LAST_NAME")}
                        name="lastName" />
                    <ReactiveFormInput
                        control={control}
                        value={leadsState.filters?.email}
                        label={t("EMAIL")}
                        name="email" />
                    <ReactiveFormInput
                        control={control}
                        value={leadsState.filters?.phone}
                        label={t("PHONE")}
                        name="phone" />
                    {
                        !props.isPoolFilter &&
                        <ReactiveFormSelect
                            name="status"
                            label={t("STATUS")}
                            options={getLeadStatuses().filter((s) => s.value !== "5")}
                            control={control} />
                    }
                </div>
            </Form>
        </Modal>
    );
}