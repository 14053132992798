import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { Address, NewAddress } from "../../models/Address";
import { axiosClient } from "../axiosClient";

export const fetchAddressesByClientId = createAsyncThunk("client/fetchAddressesByClientId", async (params: {clientId: number}) => {
    const response: AxiosResponse<Address[]> = await (await axiosClient()).get(`/clients/${params.clientId}/addresses`);
    return response.data
});

export const createClientAddress = createAsyncThunk("client/createClientAddress", async (params:{newAddress: Address, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Address> = await (await axiosClient()).post(`/clients/${params.clientId}/addresses`, {...params.newAddress});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateClientAddress = createAsyncThunk("client/updateClientAddress", async (params:{editAddress: Address, addressId: number, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Address> = await (await axiosClient()).patch(`/clients/${params.clientId}/addresses/${params.addressId}`, {...params.editAddress});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteClientAddress = createAsyncThunk("client/deleteClientAddress", async (params:{addressId: number, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Address[]> = await (await axiosClient()).delete(`/clients/${params.clientId}/addresses/${params.addressId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const needToLoadClientAddress = createAsyncThunk("client/needToLoadClientAddress", async (needLoad: boolean) => {
    return needLoad;
});