import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";

interface Props {
    name: string
    label: string
    options: any[]
    className?: string
    selectClassName?: string
    placeholder?: string
    error?: string
    isDisabled?: boolean
    isMulti?: boolean
    menuPosition?: any
    control: any
}

export const ReactiveFormSMSelect = (props: Props) => {
    const { t } = useTranslation();
    return (
        <Controller
            name={props.name}
            control={props.control}
            render={({
                field: { onChange, value },
                fieldState: { error },
            }) => {
                return (
                    <div className={"w-full px-3 " + props.className ?? ""}>
                        <label className="block tracking-wide text-gray-700 text-sm mb-2">
                            {props.label}
                        </label>
                        <Select
                            isMulti={props.isMulti}
                            isClearable
                            isSearchable
                            isLoading={props.options.length === 0}
                            onChange={(val) => val !== null ? onChange(val.value) : onChange(null)}
                            name={props.name}
                            options={props.options}
                            placeholder={props.placeholder}
                            className={props.selectClassName}
                            classNamePrefix="select"
                            menuPosition={props.menuPosition}
                            classNames={{
                                control: (state) =>
                                    'bg-white opacity-90 py-0.5 px-1.5 rounded shadow w-full',
                            }}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: 'grey-50',
                                }),
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Adjust the zIndex to ensure the dropdown is on top
                            }}
                        />
                    </div>
                )
            }}
        />
    )
}