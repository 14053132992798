import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CDocument } from "../../models/Document";
import { createClientDocument, deleteClientDocument, fetchDocumentsByClientId, needToLoadClientDocuments } from "./actions";

interface AttachmentState {
    attachments: CDocument[],
    isLoading: boolean,
    error: string | null,
    needToLoadAttachments: boolean
}
  
const initialState: AttachmentState = {
    attachments: [],
    isLoading: false,
    error: null,
    needToLoadAttachments: true
}

// slice
const attachmentsSlice = createSlice({
    name: "attachments",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchDocumentsByClientId.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(fetchDocumentsByClientId.fulfilled, (state, action: PayloadAction<CDocument[]>) => {
          state.isLoading = false;
          state.attachments = action.payload;
        })
        .addCase(fetchDocumentsByClientId.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        })
        .addCase(createClientDocument.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(createClientDocument.fulfilled, (state, action: PayloadAction<CDocument[]>) => {
          state.isLoading = false;
          state.attachments = action.payload;
        })
        .addCase(createClientDocument.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        })
        .addCase(deleteClientDocument.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(deleteClientDocument.fulfilled, (state, action: PayloadAction<CDocument[]>) => {
          state.isLoading = false;
          state.attachments = action.payload;
        })
        .addCase(deleteClientDocument.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        })
        .addCase(needToLoadClientDocuments.fulfilled, (state, action: PayloadAction<boolean>) => {
          state.needToLoadAttachments = action.payload;
          if(action.payload) {
            state.attachments = [];
          }
        });
    },
});
  
export default attachmentsSlice.reducer;