import { useTranslation } from "react-i18next";
import { ClientExists } from "../../models/ClientExists";
import { ModalInformation } from "../ModalInformation";
import { docDateFormat } from "../../utils/functions";
import { RoleEnum } from "../../enums/Role";
import { EyeIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { UpdateClientPath } from "../../constants/Routes";
import { useNavigate } from "react-router-dom";

interface Props {
  info: {
    client?: ClientExists,
    currentUser?: any,
    show: boolean
  };
  onClose: () => void;
}

export const ClientInformationModal : React.FC<Props> = ({
  info,
  onClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [canViewClient, setCanViewClient] = useState(false);

  useEffect(() => {
    if(info.client !== undefined && info.currentUser !== undefined) {
      if(info.currentUser.role_id == RoleEnum.ADMIN || info.currentUser.role_id == RoleEnum.SUPER_ADMIN) {
          setCanViewClient(true);
      } else {
        if(info.client.agent.id == info.currentUser.id) {
          setCanViewClient(true);
        } else {
          setCanViewClient(false);
        }
      }
    }
  }, [info.client, info.currentUser]);

  const closeModal = () => {
    onClose();
  };

  const viewClient = () => {
    closeModal();
    navigate(UpdateClientPath, { state: { client: info.client! } });
  }

  return (
    <ModalInformation
      isOpen={info.show}
      onClose={closeModal}
      cancelButton={true}
      label={t("CLIENT_INFORMATION")}
      saveButton={ canViewClient ? {
        label: t("VIEW_CLIENT"),
        onClick: viewClient,
        icon: <EyeIcon/>
      } : undefined }
    >
      <div className="w-full flex flex-wrap my-3">
        <div className="w-full px-3 md:w-1/3 mb-4">
          <label className="block tracking-wide text-sm text-gray-700">
            {t("FIRST_NAME")}
          </label>
          <label className="text-black font-medium">
            {info.client?.first_name}
          </label>
        </div>
        <div className="w-full px-3 md:w-1/3 mb-4">
          <label className="block tracking-wide text-sm text-gray-700">
            {t("MIDDLE_NAME")}
          </label>
          <label className="text-black font-medium">
            {info.client?.middle_name ?? "-"}
          </label>
        </div>
        <div className="w-full px-3 md:w-1/3 mb-4">
          <label className="block tracking-wide text-gray-700 text-sm">
            {t("LAST_NAME")}
          </label>
          <label className="text-black font-medium">
            {info.client?.last_name}
          </label>
        </div>
        <div className="w-full px-3 md:w-1/3 mb-4">
          <label className="block tracking-wide text-gray-700 text-sm">
            {t("DATE_OF_BIRTH")}
          </label>
          <label className="text-black font-medium">
            {docDateFormat(`${info.client?.date_of_birth}`)}
          </label>
        </div>
        <div className="w-full px-3 md:w-1/3 mb-4">
          <label className="block tracking-wide text-gray-700 text-sm">
            {t("COUNTRY_OF_BIRTH")}
          </label>
          <label className="text-black font-medium">
            {info.client?.country_of_birth}
          </label>
        </div>
        <div className="w-full px-3 md:w-1/3">
          <label className="block tracking-wide text-gray-700 text-sm">
            {t("SOCIAL_SECURITY_NUMBER")}
          </label>
          <label className="text-black font-medium">
            { 
              info.client?.immigration_status && info.client?.immigration_status.social_security_number 
              ? info.client?.immigration_status.social_security_number
              : " - "
            }
          </label>
        </div>
        <div className="w-full px-3 md:w-1/3 mb-4">
          <label className="block tracking-wide text-gray-700 text-sm">
            {t("PHONE")}
          </label>
          <label className="text-black font-medium">
            {info.client?.phone}
          </label>
        </div>
        <div className="w-full px-3 md:w-1/3 mb-4">
          <label className="block tracking-wide text-gray-700 text-sm">
            {t("EMAIL")}
          </label>
          <label className="text-black font-medium">
            {info.client?.email}
          </label>
        </div>
      </div>
    </ModalInformation>
  );
};