import i18next from "i18next";

export enum PolciyMarketEnum {
    HEALTH = 1,
    LIFE
}

export const PolicyMarketLabel = (key: string) => ({
    [PolciyMarketEnum.HEALTH]: i18next.t('HEALTH'),
    [PolciyMarketEnum.LIFE]: i18next.t('LIFE')
})[key];

export const getPolicyMarkets = () =>
    Object.values(PolciyMarketEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: PolicyMarketLabel(value) ?? ""
        }
    });