export interface NewAddress {
    address_line_1: string;
    address_line_2?: string;
    city: string;
    state: string;
    zip_code: string;
    country?: string;
    is_main_address: boolean;
};

export const newAddressInitialValues: NewAddress = {
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    zip_code: "",
    is_main_address: false
};

export interface Address {
    id: number;
    address_line_1: string;
    address_line_2?: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
    is_main_address?: boolean;
    client_id?: number;
    delete_at?: Date;
}

export interface EditAddress {
    id?: number;
    address_line_1?: string;
    address_line_2?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    country?: string;
    is_main_address?: boolean;
}