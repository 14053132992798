export interface NewIncomeSource {
    employee_type_id?: string;
    employer_name?: string;
    phone_number: string;
    occupation: string;
    income: number;
};

export const newIncomeSourceInitialValues = {
    employer_name: "",
    phone_number: "",
    occupation: "",
    income: 0,
}

export interface IncomeSource {
    id: number;
    employee_type_id: number;
    employer_name?: string;
    phone_number: string;
    occupation: string;
    income: number;
}

export interface EditIncomeSource {
    id?: number;
    employee_type_id?: number;
    employer_name?: string;
    phone_number?: string;
    occupation?: string;
    income?: number;
}