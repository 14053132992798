import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosClient } from "../axiosClient";
import { DocusignConfig } from "../../models/DocusignConfig";

export const fetchAgencyDocusignConfig = createAsyncThunk('agencies/fetchAgencyDocusignConfig', async (agencyId: number, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocusignConfig[]> = await (await axiosClient()).get(`/agencies/${agencyId}/docusign-config`);
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
});

export const fetchAgencyDocusignConfigById = createAsyncThunk('agencies/fetchAgencyDocusignConfigById', async (params:{agencyId: number, configId: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocusignConfig> = await (await axiosClient()).get(`/agencies/${params.agencyId}/docusign-config/${params.configId}`);
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
});

export const createAgencyDocusignConfig = createAsyncThunk('agencies/createAgencyDocusignConfig', async (params:{agencyId: number, configData: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocusignConfig> = await (await axiosClient()).post(`/agencies/${params.agencyId}/docusign-config`, {...params.configData});
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
});

export const updateAgencyDocusignConfig = createAsyncThunk('agencies/updateAgencyDocusignConfig', async (params:{agencyId: number, configId: number, configData: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocusignConfig> = await (await axiosClient()).patch(`/agencies/${params.agencyId}/docusign-config/${params.configId}`, {...params.configData});
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
});

export const deleteAgencyDocusignConfig = createAsyncThunk('agencies/deleteAgencyDocusignConfig', async (params:{agencyId: number, configId: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocusignConfig[]> = await (await axiosClient()).delete(`/agencies/${params.agencyId}/docusign-config/${params.configId}`);
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
});

export const setAgencyMainDocusignConfig = createAsyncThunk('agencies/setAgencyMainDocusignConfig', async (params:{agencyId: number, configId: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocusignConfig[]> = await (await axiosClient()).patch(`/agencies/${params.agencyId}/main-docusign-config/${params.configId}`);
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
});