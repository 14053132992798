import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { EditPolicy, MatchedPolicyWithCommission, NewPolicy, Policy, PolicyResponse } from "../../models/Policies";
import { Beneficiary } from "../../models/Beneficiary";

export const fetchPoliciesByClientId = createAsyncThunk("client/policies", async (params: {clientId: number}) => {
    const response: AxiosResponse<Policy[]> = await (await axiosClient()).get(`/clients/${params.clientId}/policies`);
    return response.data
});

export const createClientPolicy = createAsyncThunk("client/createClientPolicy", async (params:{policy: NewPolicy, clientId:number, hasToSendRenewalMessage?: boolean}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Policy> = await (await axiosClient()).post(`/clients/${params.clientId}/policies?hasToSendRenewalMessage=${params.hasToSendRenewalMessage}`, {...params.policy});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateClientPolicy = createAsyncThunk("client/updateClientPolicy", async (params:{policy: EditPolicy, policyId: number, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Policy> = await (await axiosClient()).patch(`/clients/${params.clientId}/policies/${params.policyId}`, {...params.policy});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteClientPolicy = createAsyncThunk("client/deleteClientPolicy", async (params:{policyId: number, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Policy[]> = await (await axiosClient()).delete(`clients/${params.clientId}/policies/${params.policyId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchPolicies = createAsyncThunk("agent/policies", async (data: {page: number, pageSize: number}) => {
    const response: AxiosResponse<PolicyResponse> = await (await axiosClient()).get(`/policies?page=${data.page}&pageSize=${data.pageSize}`);
    return response.data
});

export const updatePolicy = createAsyncThunk("policies/update", async (params:{policy: EditPolicy}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Policy[]> = await (await axiosClient()).patch(`/policies/${params.policy.id}`, {...params.policy});
        return response.data[0];
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const pastPage = createAsyncThunk("agent/policies/pastPage", async () => {
    return true;
});

export const needToLoadClientPolicy = createAsyncThunk("client/needToLoadClientPolicy", async (needLoad: boolean) => {
    return needLoad;
});

export const fetchPolicyBeneficiaries = createAsyncThunk("client/fetchPolicyBeneficiaries", async (params: {policyId: number}) => {
    const response: AxiosResponse<Beneficiary[]> = await (await axiosClient()).get(`/policies/${params.policyId}/beneficiaries`);
    return response.data
});