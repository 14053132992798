import {
  DocumentArrowDownIcon,
  FunnelIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import saveAs from "file-saver";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as xlsx from "xlsx";
import logo from "../../assets/images/asureis-side.png";
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { LoadingMask } from "../../components/LoadingMask";
import { ProfilePath } from "../../constants/Routes";
import { PolicyStatusLabel } from "../../enums/PolicyState";
import { RoleLabel } from "../../enums/Role";
import {
  fetchAllPolicySells,
  fetchPolicySells,
  pastPage,
} from "../../state/policySells/actions";
import { AppDispatch, RootState } from "../../state/store";
import {
  getLocalTimeZone,
  longLocalDateFormat,
  shortDateFormat,
  shortLocalDateFormat,
} from "../../utils/functions";
import { PolicySellsFilterModal } from "./PolicySellsFilterModal";

interface Props {}

export const PolicySellsReport = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const pagePolicies = useSelector(
    (state: any) => state.policySells.pagePolicySells
  );
  const paginatedOptions = useSelector(
    (state: any) => state.policySells.paginatedOptions
  );
  const policySells = useSelector(
    (state: any) => state.policySells.policySells
  );
  const totalMembersInsured = useSelector(
    (state: RootState) => state.policySells.totalMembersInsured
  );
  const policyAgentsSummary = useSelector(
    (state: any) => state.policySells.policyAgentsSummary
  );
  const isLoading = useSelector((state: any) => state.policySells.isLoading);
  const error = useSelector((state: any) => state.policySells.error);
  const [isExportingToExcel, setIsExportingToExcel] = useState<boolean>(false);
  const dates = useSelector((state: any) => state.policySells.dates);
  const filters = useSelector((state: any) => state.policySells.filters);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      const timeZone = getLocalTimeZone();

      const endDate = moment(new Date())
        .hours(23)
        .minutes(59)
        .seconds(59)
        .toDate();
      const startDate = moment(endDate).hours(0).minutes(0).seconds(0).toDate();

      const end = moment(endDate).format("YYYY-MM-DD");
      const start = moment(startDate).format("YYYY-MM-DD");

      dispatch(
        fetchPolicySells({
          startDate: start,
          endDate: end,
          timeZone: timeZone,
          page: 0,
          pageSize: paginatedOptions.pageSize,
          filters: {},
        })
      );
    }
  }, [dispatch, currentUser]);

  const columns = useMemo(() => {
    const showColumns = [
      {
        Header: t("POLICY_NUMBER"),
        Cell: (row: any) => `${row.row.original.number ?? "-"}`,
      },
      {
        Header: t("CLIENT_FIRST_NAME"),
        accessor: "client_first_name",
      },
      {
        Header: t("CLIENT_LAST_NAME"),
        accessor: "client_last_name",
      },
      {
        Header: t("CLIENT_PHONE"),
        accessor: "client_phone",
      },
      {
        Header: t("CLIENT_DATE_OF_BIRTH"),
        Cell: (row: any) =>
          shortDateFormat(`${row.row.original.client_date_of_birth}`),
      },
      {
        Header: t("CARRIER"),
        Cell: (row: any) => `${row.row.original.carrier}`,
      },
      {
        Header: t("EFFECTIVE_START_DATE"),
        Cell: (row: any) =>
          shortDateFormat(`${row.row.original.effective_start_date}`),
      },
      {
        Header: t("MEMBERS_INSURED"),
        Cell: (row: any) => `${row.row.original.members_insured ?? "-"}`,
      },
      {
        Header: t("POLICY_TYPE"),
        Cell: (row: any) =>
          row.row.original.policy_type != null
            ? `${row.row.original.policy_type}`
            : "-",
      },
      {
        Header: t("FREE_PLAN"),
        Cell: (row: any) =>
          row.row.original.free_plan == "1" ? t("YES") : t("NO"),
      },
      {
        Header: t("AGENT"),
        accessor: "agent",
      },
      {
        Header: t("AGENT_PHONE"),
        accessor: "agent_phone",
      },
      {
        Header: t("STATUS"),
        Cell: (row: any) =>
          row.row.original.policy_state != null
            ? PolicyStatusLabel(row.row.original.policy_state)
            : "-",
      },
      {
        Header: t("SOLD_DATE"),
        Cell: (row: any) =>
          shortLocalDateFormat(`${row.row.original.sold_at}`),
      },
      {
        Header: t("LAST_UPDATE"),
        Cell: (row: any) =>
          row.row.original.last_update != null
            ? longLocalDateFormat(`${row.row.original.last_update}`)
            : "-",
      },
    ];

    /*const actions = {
            Header: " ",
            Cell: (row: any) => {
                return <div className="flex flex-row justify-center divide-x">
                    <Button
                        isTerciary
                        tooltip={t("UPDATE_POLICY")}
                        iconOnly
                        onClick={() => openUpdatePolicyModal(row.row.original)} classNames="mr-2"><PencilIcon className="h-5 aspect-square stroke-blue" />
                    </Button>
                </div>
            }
        };*/
    return [...showColumns];
  }, [t, currentUser]);

  const getPaginationOptions = useMemo(() => {
    const timeZone = getLocalTimeZone();
    return {
      page: paginatedOptions.page,
      pageSize: paginatedOptions.pageSize,
      totalPages: paginatedOptions.totalPages,
      totalCount: paginatedOptions.totalCount,
      setPage: (value: number) => {
        value < paginatedOptions.page
          ? dispatch(pastPage())
          : dispatch(
              fetchPolicySells({
                startDate: dates.startDate,
                endDate: dates.endDate,
                timeZone: timeZone,
                page: value,
                pageSize: paginatedOptions.pageSize,
                filters: filters,
              })
            );
      },
      setPageSize: (value: number) =>
        dispatch(
          fetchPolicySells({
            startDate: dates.startDate,
            endDate: dates.endDate,
            timeZone: timeZone,
            page: paginatedOptions.page,
            pageSize: value,
            filters: filters,
          })
        ),
    };
  }, [dispatch, paginatedOptions]);

  const exportReportToExcel = async () => {
    setIsExportingToExcel(true);
    const timeZone = getLocalTimeZone();

    const allData: any[] = [];
    policySells.forEach((policy: any) => {
      allData.push(...policy.pagePolicies);
    });

    if (allData.length == paginatedOptions.totalCount) {
      const exportData = allData.map((d: any) => {
        return {
          number: d.number ?? "-",
          client_first_name: d.client_first_name,
          client_last_name: d.client_last_name,
          client_phone: d.client_phone,
          client_date_of_birth: shortDateFormat(`${d.client_date_of_birth}`),
          effective_start_date: shortDateFormat(`${d.effective_start_date}`),
          carrier: d.carrier,
          members_insured: d.members_insured ?? "-",
          free_plan: d.free_plan == "1" ? t("YES") : t("NO"),
          agent: d.agent,
          agent_phone: d.agent_phone,
          status:
            d.policy_state != null ? PolicyStatusLabel(d.policy_state) : "-",
          policy_type: d.policy_type !== null ? `${d.policy_type}` : "-",
          sold_at: d.sold_at != null ? shortLocalDateFormat(`${d.sold_at}`) : "-",
          created_at: shortLocalDateFormat(`${d.created_at}`),
          last_update: longLocalDateFormat(`${d.last_update}`),
        };
      });
      convertAndDowloadToExcel(exportData);
    } else {
      const offset = allData.length;
      const limit = paginatedOptions.totalCount - allData.length + 10;

      const result = await dispatch(
        fetchAllPolicySells({
          startDate: dates.startDate,
          endDate: dates.endDate,
          timeZone: timeZone,
          limit: limit,
          offset: offset,
          filters: filters,
        })
      ).unwrap();
      allData.push(...result);

      const exportData = allData.map((d: any) => {
        return {
          number: d.number ?? "-",
          client_first_name: d.client_first_name,
          client_last_name: d.client_last_name,
          client_phone: d.client_phone,
          client_date_of_birth: shortDateFormat(`${d.client_date_of_birth}`),
          effective_start_date: shortDateFormat(`${d.effective_start_date}`),
          carrier: d.carrier,
          members_insured: d.members_insured ?? "-",
          free_plan: d.free_plan == "1" ? t("YES") : t("NO"),
          agent: d.agent,
          agent_phone: d.agent_phone,
          status:
            d.policy_state != null ? PolicyStatusLabel(d.policy_state) : "-",
          policy_type: d.policy_type !== null ? `${d.policy_type}` : "-",
          sold_at: d.sold_at != null ? shortLocalDateFormat(`${d.sold_at}`) : "-",
          created_at: shortLocalDateFormat(`${d.created_at}`),
          last_update: longLocalDateFormat(`${d.last_update}`),
        };
      });
      convertAndDowloadToExcel(exportData);
    }
  };

  const convertAndDowloadToExcel = (data: any[]) => {
    let Heading = [
      [
        t("POLICY_NUMBER"),
        t("CLIENT_FIRST_NAME"),
        t("CLIENT_LAST_NAME"),
        t("CLIENT_PHONE"),
        t("CLIENT_DATE_OF_BIRTH"),
        t("EFFECTIVE_START_DATE"),
        t("CARRIER"),
        t("MEMBERS_INSURED"),
        t("FREE_PLAN"),
        t("AGENT"),
        t("AGENT_PHONE"),
        t("STATUS"),
        t("POLICY_TYPE"),
        t("SOLD_DATE"),
        t("CREATION_DATE"),
        t("LAST_UPDATE"),
      ],
    ];

    //Had to create a new workbook and then add the header
    const wb = xlsx.utils.book_new();
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet([]);
    xlsx.utils.sheet_add_aoa(ws, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    xlsx.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `PoliciesSellsReport-${Date.now()}.xlsx`);

    setIsExportingToExcel(false);
  };

  const exportSummaryToExcel = async () => {
    setIsExportingToExcel(true);

    const excelData = policyAgentsSummary.map((a: any) => {
      return {
        "agent": a.agent,
        "phone": a.agentPhone,
        "total_members": a.totalMembers
      }
    })
    convertAndDowloadSummaryToExcel(excelData);
  };

  const convertAndDowloadSummaryToExcel = (data: any[]) => {
    let Heading = [
      [
        t("AGENT"),
        t("AGENT_PHONE"),
        t("TOTAL_MEMBERS")
      ],
    ];

    //Had to create a new workbook and then add the header
    const wb = xlsx.utils.book_new();
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet([]);
    xlsx.utils.sheet_add_aoa(ws, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    xlsx.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `PoliciesAgentsSummary-${Date.now()}.xlsx`);

    setIsExportingToExcel(false);
  };

  return (
    <>
      <div className="bg-zinc-100 min-h-screen w-full">
        {isLoading && <LoadingMask />}
        {isExportingToExcel && <LoadingMask />}
        <div
          className={classNames({
            "bg-white text-zinc-500": true, // colors
            "flex items-center": true, // layout
            "w-screen md:w-full sticky z-10 px-4 shadow-sm h-[70px] top-0 ":
              true, //positioning & styling
          })}
        >
          <img src={logo} alt="" className="h-6 mr-12" />
          <div className="font-bold text-lg mr-auto">
            {t("POLICY_SALES_REPORT")}
          </div>
          <div
            className="md:flex flex-row items-center gap-2 mr-2 cursor-pointer"
            onClick={() => navigate(ProfilePath)}
          >
            <div className="hidden md:block">
              <div className="text-end text-blue font-medium">{`${
                currentUser?.first_name ?? ""
              } ${currentUser?.last_name ?? ""}`}</div>
              <div className="text-end">
                {RoleLabel(currentUser?.role_id ?? "")}
              </div>
            </div>
            <div className="relative flex justify-center items-center">
              <UserCircleIcon className="h-8  aspect-square" />
            </div>
          </div>
        </div>
        <div className="w-full pt-5 pb-10">
          <FilteredTable
            columns={columns}
            data={pagePolicies}
            isPaginated
            paginationOptions={getPaginationOptions}
            hasSearchBar={false}
            title={
              totalMembersInsured
                ? `Total Members Insured: ${totalMembersInsured}`
                : ""
            }
            secondaryActionButton={
              <Button onClick={() => exportSummaryToExcel()} classNames="mr-3">
                <span className="flex items-center font-semibold pr-3">
                  <div className="w-8 p-1 aspect-square mr-2">
                    <DocumentArrowDownIcon />
                  </div>
                  {t("DOWNLOAD_AGENT_SUMMARY")}
                </span>
              </Button>
            }
            tertiaryActionButton={
              <Button onClick={() => exportReportToExcel()}>
                <span className="flex items-center font-semibold pr-3">
                  <div className="w-8 p-1 aspect-square mr-2">
                    <DocumentArrowDownIcon />
                  </div>
                  {t("DOWNLOAD_REPORT")}
                </span>
              </Button>
            }
            buttonAction={
              <Button
                onClick={() => setFilterModalOpen(true)}
                iconOnly
                classNames="w-9 p-1 aspect-square mr-4 "
              >
                <FunnelIcon className=""></FunnelIcon>
              </Button>
            }
          />
        </div>
        <PolicySellsFilterModal
          isFilterModalOpen={isFilterModalOpen}
          setFilterModalOpen={() => setFilterModalOpen(false)}
        />
      </div>
    </>
  );
};
