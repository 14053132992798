import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { Dependent } from "../../models/Dependant";

export const fetchDependentsByClientId = createAsyncThunk("client/fetchDependentsByClientId", async (params: {clientId: number}) => {
    const response: AxiosResponse<Dependent[]> = await (await axiosClient()).get(`/clients/${params.clientId}/dependents`);
    return response.data
});

export const createClientDependent = createAsyncThunk("client/createClientDependent", async (params:{newDependent: Dependent, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Dependent> = await (await axiosClient()).post(`/clients/${params.clientId}/dependents`, {...params.newDependent});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateClientDependent = createAsyncThunk("client/updateClientDependent", async (params:{editDependent: Dependent, dependentId: number, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Dependent> = await (await axiosClient()).patch(`/clients/${params.clientId}/dependents/${params.dependentId}`, {...params.editDependent});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteClientDependent = createAsyncThunk("client/deleteClientDependent", async (params:{dependentId: number, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Dependent[]> = await (await axiosClient()).delete(`/clients/${params.clientId}/dependents/${params.dependentId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const needToLoadClientDependent = createAsyncThunk("client/needToLoadClientDependent", async (needLoad: boolean) => {
    return needLoad;
});