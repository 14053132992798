import { useTranslation } from "react-i18next";
import { Address } from "../../../models/Address";
import { useEffect, useMemo, useState } from "react";
import Button from "../../Button";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import FilteredTable from "../../FilteredTable";
import { ClientAddressModal } from "./ClientAddressModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { deleteClientAddress, fetchAddressesByClientId, needToLoadClientAddress } from "../../../state/addresses/actions";
import { Client } from "../../../models/Client";
import { LoadingMask } from "../../LoadingMask";
import { Toast } from "../../Toast";
import { toast, ToastContainer } from "react-toastify";
import { DeleteClientAddressModal } from "./DeleteClientAddressModal";


interface Props {
    client: Client | null
}

export const ClientAddressTable = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [addresses, setAddress] = useState<Address[]>([]);
    const clientAddresses = useSelector((state: RootState) => state.addresses.addresses);
    const isLoadingAddressess = useSelector((state: RootState) => state.addresses.isLoading);
    const needLoadClientAddresses = useSelector((state: RootState) => state.addresses.needToLoadAddresses);
    const error = useSelector((state: any) => state.addresses.error);
    const [showAddressError, setShowAddressError] = useState(false);

    useEffect(() => {
        if (showAddressError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showAddressError, error, t]);

    const [clientAddressInfoModal, setClientAddressInfoModal] = useState<{
        show: boolean;
        update: boolean;
        address?: Address;
        index?: number;
        client?: Client;
    }>({ show: false, update: false });

    const [ showDeleteClientAddressModal, setShowDeleteClientAddressModal] = useState<{
        address?: Address;
        show: boolean;
    }>({ show: false, address: undefined });

    useEffect(() => {
        //TODO: Review state and get data
        if(needLoadClientAddresses) {
            dispatch(fetchAddressesByClientId({clientId: props.client?.id!})).then((e) => {
                let addressess = (e.payload || []) as Address[];
                setAddress(addressess);
            });
            dispatch(needToLoadClientAddress(false));
        } else {
            setAddress(clientAddresses);
        }
    }, []);

    const addAdress = (address: Address) => {
        setAddress(addresses => [...addresses, address])
    }

    const updateAddress = (index: number, address: Address) => {
        setAddress(addresses => addresses.map((ele, i) => {
            if (i === index) { return address }
            return ele
        }))
    }

    const removeAddress = (address: Address) => {
        //TODO: remove address from database
        dispatch(deleteClientAddress({ clientId: props.client?.id!, addressId: address.id })).then((e) => {
            if (e.type === "client/deleteClientAddress/rejected") {
                setShowAddressError(true);
            } else {
                toast(t("SUCCESSFULLY_DELETE_CLIENT_ADDRESS"));
                let addresses = (e.payload || []) as Address[];
                setAddress(addresses);
            }
        });
    }

    const columns = useMemo(
        () => [
            {
                Header: t("STREET_ADDRESS"),
                accessor: "address_line_1",
            },
            {
                Header: t("STREET_ADDRESS2"),
                accessor: "address_line_2",
            },
            {
                Header: t("CITY"),
                accessor: "city",
            },
            {
                Header: t("STATE"),
                accessor: "state",
            },
            {
                Header: t("ZIP_CODE"),
                accessor: "zip_code",
            },
            {
                id: "actions",
                Header: "",
                Cell: (row: any) => {
                    return (
                        <div className="flex flex-row justify-center divide-x">
                            <Button
                                isTerciary
                                iconOnly
                                tooltip={t("UPDATE_ADDRESS")}
                                onClick={() => 
                                    setClientAddressInfoModal({
                                        show: true,
                                        update: true,
                                        address: row.row.original,
                                        index: row.row.index,
                                        client: props.client!
                                    })
                                }
                                classNames="mr-2"
                            >
                                <PencilIcon className="h-5 aspect-square text-blue" />
                            </Button>
                            <Button
                                isTerciary
                                iconOnly
                                tooltip={t("DELETE_ADDRESS")}
                                onClick={() => setShowDeleteClientAddressModal({ show: true, address: row.row.original })}
                                classNames="mr-2"
                            >
                                <TrashIcon className="h-5 aspect-square text-blue" />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [removeAddress, t, updateAddress]
    );

    return (
        <>
            <FilteredTable
                columns={columns}
                data={addresses}
                hasSearchBar={false}
                secondaryActionButton={
                    <Button onClick={() => setClientAddressInfoModal({ show: true, update: false, address: undefined, client: props.client!, index:undefined })}>
                        <span className="flex items-center font-semibold pr-3">
                            <div className="w-8 p-1 aspect-square mr-2">
                                <PlusIcon />
                            </div>
                            {t("ADD_ADDRESS")}
                        </span>
                    </Button>
                }
            />
            <ClientAddressModal
                info={clientAddressInfoModal}
                onSubmited={(address, index) => {
                    if (index !== undefined) {
                        updateAddress(index, address)
                    } else {
                        addAdress(address)
                    }
                }}
                onClose={() => setClientAddressInfoModal({ show: false, update: false, address: undefined, index:undefined })}
            ></ClientAddressModal>
            <DeleteClientAddressModal
              info={showDeleteClientAddressModal}
              onClose={() => setShowDeleteClientAddressModal({show : false, address: undefined})}
              deleteAddress={(address: Address) => removeAddress(address)}
            />
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            { isLoadingAddressess && <LoadingMask />}
        </>
    )
}