import React from 'react';

import { ToastContainer, toast } from 'react-toastify';

export function Toast(title:string, subTitle?:string){
  let toastBody = (
    <div>
      <div className='font-semibold'>{title}</div>
      {subTitle && <div className='font-light'>{subTitle}</div>}
    </div>
  )
  
  toast(toastBody);
}