import { useTranslation } from "react-i18next";
import { ClientExists } from "../../models/ClientExists";
import { Modal } from "../Modal";
import { PaperAirplaneIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
    info: {
        show: boolean;
        clientExists?: ClientExists;
        phoneExists?: string;
    };
    onClose: () => void;
    onContinueCreate: () => void;
}

export const ClientExistsConfirmationModal: React.FC<Props> = ({
    info,
    onClose,
    onContinueCreate
}) => {

    const { t } = useTranslation();

    return(
        <Modal
            isOpen={info.show}
            label={t("CLIENT_ALREADY_EXISTS")}
            onClose={onClose}
            hideCancelButton={true}
            thirdButton={{
                label: t("CONTINUE_ANYWAY"),
                icon: <PaperAirplaneIcon />,
                onClick: onContinueCreate,
            }}
            saveButton={{
                label: t("CLOSE"),
                icon: <XMarkIcon />,
                onClick: onClose,
            }}
        >
            <div className="w-full grid justify-items-center">
                <div className="text-center font-medium mx-2">
                    {`${t('A_CLIENT_WITH_PHONE')}`} {`${info.phoneExists}`} {`${t('IS_BEING_CONTACTED_BY', {agentName: info.clientExists?.agent.first_name, agentLastName: info.clientExists?.agent.last_name})}`}
                </div>
                <div className="text-center font-medium mx-2 my-5">
                    {t('CONTINUE_CREATING_CLIENT')}
                </div>
            </div>
        </Modal>
    )
}