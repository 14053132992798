import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentEnvelope, DownloadDocument } from "../../models/ConsentDocument";
import { fetchClientDocumentEnvelopes, fetchLeadDocumentEnvelopes, getEnvelopeDownloadDocument, needToLoadClientConsentDocument, sendClientConsentDocument, sendLeadConsentDocument, voidClientConsentDocument, voidLeadConsentDocument } from "./actions";

interface EnvelopeState {
    envelopes: DocumentEnvelope[],
    leadEnvelopes: DocumentEnvelope[],
    isLoading: boolean,
    error: string | null,
    isLoadingDownload: boolean;
    errorDownload: string | null,
    needToLoadEnvelopes: boolean
}
  
const initialState: EnvelopeState = {
    envelopes: [],
    leadEnvelopes: [],
    isLoading: false,
    error: null,
    isLoadingDownload: false,
    errorDownload: null,
    needToLoadEnvelopes: true
}

// slice
const documentSlice = createSlice({
    name: 'documents',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchClientDocumentEnvelopes.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchClientDocumentEnvelopes.fulfilled, (state, action: PayloadAction<DocumentEnvelope[]>) => {
                state.isLoading = false;
                state.envelopes = action.payload;
            })
            .addCase(fetchClientDocumentEnvelopes.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(sendClientConsentDocument.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(sendClientConsentDocument.fulfilled, (state, action: PayloadAction<DocumentEnvelope>) => {
                state.isLoading = false;
                state.envelopes = state.envelopes.concat(action.payload);
            })
            .addCase(sendClientConsentDocument.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getEnvelopeDownloadDocument.pending, (state) => {
                state.isLoadingDownload = true;
                state.errorDownload = null;
            })
            .addCase(getEnvelopeDownloadDocument.fulfilled, (state, action: PayloadAction<DownloadDocument>) => {
                state.isLoadingDownload = false;
                state.errorDownload = null;
            })
            .addCase(getEnvelopeDownloadDocument.rejected, (state, action: any) => {
                state.isLoadingDownload = false;
                state.errorDownload = action.payload;
            })
            .addCase(voidClientConsentDocument.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(voidClientConsentDocument.fulfilled, (state, action: PayloadAction<DocumentEnvelope[]>) => {
                state.isLoading = false;
                state.envelopes = action.payload;
            })
            .addCase(voidClientConsentDocument.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchLeadDocumentEnvelopes.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchLeadDocumentEnvelopes.fulfilled, (state, action: PayloadAction<DocumentEnvelope[]>) => {
                state.isLoading = false;
                state.leadEnvelopes = action.payload;
            })
            .addCase(fetchLeadDocumentEnvelopes.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(sendLeadConsentDocument.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(sendLeadConsentDocument.fulfilled, (state, action: PayloadAction<DocumentEnvelope>) => {
                state.isLoading = false;
                state.leadEnvelopes = state.envelopes.concat(action.payload);
            })
            .addCase(sendLeadConsentDocument.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(voidLeadConsentDocument.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(voidLeadConsentDocument.fulfilled, (state, action: PayloadAction<DocumentEnvelope[]>) => {
                state.isLoading = false;
                state.leadEnvelopes = action.payload;
            })
            .addCase(voidLeadConsentDocument.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(needToLoadClientConsentDocument.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.needToLoadEnvelopes = action.payload;
                if(action.payload) {
                state.envelopes = [];
                }
            });
    }
});

export default documentSlice.reducer;