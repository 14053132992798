import {
  ChevronLeftIcon,
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  DocumentCurrencyDollarIcon,
  DocumentIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import {
  ChangeEvent,
  createRef,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from "react";
import { set, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import stringSimilarity from "string-similarity";
import * as xlsx from "xlsx";
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { FormSelect } from "../../components/form/FormSelect";
import { LoadingMask } from "../../components/LoadingMask";
import { Modal } from "../../components/Modal";
import {
  downloadComissionsReport,
  generateComissionsReport,
  getComissionsReport,
  pastPage,
} from "../../state/commissions/action";
import { AppDispatch, RootState } from "../../state/store";
import { Toast } from "../../components/Toast";
import { fetchCommissionStatementMap, saveCommissionStatementMap } from "../../state/metadata/action";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { SpinCircle } from "../../components/SpinCircle";
import { ReviewUnmatchedPolicyModal } from "./ReviewUnmatchedPolicyModal";
import { ModalInformation } from "../../components/ModalInformation";
import { CheckIcon } from "@heroicons/react/24/solid";
import { BillingPeriod } from "../../models/Agency";
import { setFilters } from "../../state/commissions/slice";
import jsPDF from "jspdf";
import autoTable, { RowInput, UserOptions } from "jspdf-autotable";
import { Commission } from "../../models/Commission";
import { RoleEnum } from "../../enums/Role";
import { EditCommissionRecordModal } from "./EditCommissionRecordModal";

interface Props {}

export const Commissions = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const agencyCarriers = useSelector((state: RootState) => state.carriers.agencyCarriers);
  const isLoading = useSelector((state: any) => state.commissions.isLoading);
  const [isOpenFilePreviewModal, setIsOpenFilePreviewModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const inputFile: RefObject<HTMLInputElement> = createRef();
  const commissionsError = useSelector((state: any) => state.commissions.error);
  const billingPeriods = useSelector(
    (state: any) => state.agencies.billingPeriods
  );

  const currentAgency = useSelector(
    (state: any) => state.users.currentUser?.agency
  );

  const storedFilters = useSelector(
    (state: any) => state.commissions.filters
  );

  const [reportFilters, setReportFilters] = useState<{
    billingPeriod: string,
    status: string,
    carrier: string,
  }>(storedFilters);

  const [showCommissionsError, setShowCommissionsError] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedCarrierCommissionStatementMap, setSelectedCarrierCommissionStatementMap] = useState<any[]>([]);
  const [openReviewUpdateModal, setOpenReviewModalModal] = useState({
    show: false,
    policy: undefined,
  })
  
  const [openEditCommissionRecordModal, setOpenEditCommissionRecordModal] = useState({
    show: false,
    policy: undefined,
  })

  const [isExporting, setIsExporting] = useState(false);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);

  const [showPolicyNotReportedModal, setShowPolicyNotReportedModal] = useState(false);

  const [loadingMap, setLoadingMap] = useState(false);

  const [uploadingFile, setUploadingFile] = useState(false);
  const [generatingReport, setGeneratingReport] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);

  const pageCommissions = useSelector(
    (state: any) => state.commissions.pageCommissions
  );
  const paginatedOptions = useSelector(
    (state: any) => state.commissions.paginatedOptions
  );

  const methods = useForm<{}>();
  const { control } = methods;

  const startMethods = useForm<{}>();
  const { handleSubmit: handleStartSubmit, control: startControl } =
    startMethods;

  const columns = useMemo(
    () => [
      {
        Header: t("POLICY_NUMBER"),
        accessor: "policy_number",
      },
      {
        Header: t("CARRIER"),
        Cell: (data: any) => <>{t(data.row.original.carrier)}</>,
      },
      {
        Header: t("BILLING_PERIOD"),
        accessor: "billing_period",
      },
      {
        Header: t("CLIENT"),
        accessor: "client",
      },
      {
        Header: t("COMMISSION_TYPE"),
        accessor: "commission_rule_type",
      },
      {
        Header: t("COMMISSION_TYPE_VALUE"),
        accessor: "commission_rule_type_value",
      },
      {
        Header: t("AGENT"),
        accessor: "agent",
      },
      {
        Header: t("TOTAL_COMMISSION"),
        accessor: "total_income",
        Cell: (data: any) => <>{`$${data.row.original.total_income}`}</>,
      },
      {
        Header: t("AGENT_COMMISSION"),
        accessor: "agent_commission",
        Cell: (data: any) => <>{`$${data.row.original.agent_commission}`}</>,
      },
      {
        Header: t("AGENCY_COMMISSION", {agency_name: currentAgency?.name}),
        accessor: "agency_commission",
        Cell: (data: any) => <>{`$${data.row.original.agency_commission}`}</>,
      },
      {
        Header: " ",
        Cell: (row: any) => {
          return row.row.original.commission_rule_type_id !== 4 && (
            <div className="flex flex-row justify-center divide-x">
              <Button
                isTerciary
                iconOnly
                tooltip={t("EDIT")}
                classNames="mr-2"
                onClick={() => {
                  setOpenEditCommissionRecordModal({ show: true, policy: {...row.row.original } })}
                }
                >
                <PencilIcon className="h-5 aspect-square stroke-gold" />
              </Button>
            </div>
          );
        },
      },
    ],
    [t, currentAgency]
  );

  const unmatchedColumns = useMemo(
    () => [
      {
        Header: t("POLICY_NUMBER"),
        accessor: "policy_number",
      },
      {
        Header: t("STATEMENT_CLIENT_NAME"),
        accessor: "client",
      },
      {
        Header: t("CARRIER"),
        Cell: (data: any) => <>{t(data.row.original.carrier)}</>,
      },
      {
        Header: t("BILLING_PERIOD"),
        accessor: "billing_period",
      },
      {
        Header: t("STATEMENT_AGENT_NAME"),
        accessor: "statement_agent_name",
      },
      {
        Header: t("TOTAL_COMMISSION"),
        accessor: "total_income",
        Cell: (data: any) => <>{`$${data.row.original.total_income}`}</>,
      },
      {
        Header: t("REVIEW_REASON"),
        accessor: "unmatch_reason",
        Cell: (data: any) => <>{t(data.row.original.unmatch_reason)}</>,
      },
      {
        Header: " ",
        Cell: (row: any) => {
          return (
            <div className="flex flex-row justify-center divide-x">
              <Button
                isTerciary
                iconOnly
                tooltip={t("REVIEW")}
                classNames="mr-2"
                onClick={() => {
                  if (row.row.original.unmatch_reason === "POLICY_NOT_REPORTED") {
                    setShowPolicyNotReportedModal(true);
                  } else {
                    setOpenReviewModalModal({ show: true, policy: {...row.row.original } })}
                  }
                }
                >
                <ExclamationCircleIcon className="h-5 aspect-square stroke-gold" />
              </Button>
            </div>
          );
        },
      },
    ],
    [t]
  );

  useEffect(() => {
    if (showCommissionsError) {
      Toast(
        t(commissionsError?.reason ?? "SOMETHING_WENT_WRONG"),
        t(commissionsError?.cause_info)
      );
      setShowCommissionsError(false);
    }
  }, [commissionsError, showCommissionsError, t]);

  useEffect(() => {
    if (selectedCarrier && selectedCarrier !== "") {
      setLoadingMap(true);
      dispatch(fetchCommissionStatementMap({ agencyId: currentUser.agency_id, carrierId: +selectedCarrier }))
      .then((e: any) => {
        const payload = e.payload as any[];
        setSelectedCarrierCommissionStatementMap(payload);
      })
      .finally(() => setLoadingMap(false));
    }
  }, [selectedCarrier]);

  useEffect(() => {
    setReportFilters(prev => ({...prev, billingPeriod: billingPeriods[0]?.id ?? "" }));
  }, [billingPeriods]);

  useEffect(() => {
    if(uploadProgress === 1) {
      setGeneratingReport(true);
      setUploadingFile(false);
    }
  }, [uploadProgress])

  const openFilePreviewModal = () => {
    setIsOpenFilePreviewModal(true);
  };

  const closeFilePreviewModal = () => {
    setIsOpenFilePreviewModal(false);
    setSelectedFile(null);
    setSelectedCarrier("");
    setUploadingFile(false);
    setUploadProgress(0);
    setGeneratingReport(false)
    if (inputFile.current) {
      inputFile.current.value = "";
    }
  };

  const onFileUploadSubmitted = () => {
    let matches = selectedFile.format;

    /* let data = selectedFile.fileData.map((obj: any) =>
      matches.reduce((acc: any, m: any) => {
        if (m.match && m.match.key in obj) {
          acc[m.key] = obj[m.match.key]; // Only add the keys that exist in the object
        }
        return acc;
      }, {})
    );

    data = data.map((obj: any) => {
      return {
        ...obj,
        commission: +obj.commission,
        statement_date: moment(obj.statement_date).format("YYYY-MM-DD"),
        billing_date: moment(obj.billing_date).format("YYYY-MM-DD"),
        members_insured: isNaN(obj.members_insured) ? null : +obj.members_insured,
      };
    });
 */
    dispatch(saveCommissionStatementMap({
      agencyId: currentUser.agency_id,
      carrierId: +selectedCarrier,
      map: matches
    }));

    setUploadingFile(true);
    dispatch(
      generateComissionsReport({ agencyId: currentUser.agency_id, 
        carrierId: +selectedCarrier, 
        matches: matches, 
        file: selectedFile.file, 
        progressCallback: (progress: number) => setUploadProgress(progress) })  
    ).then((e) => {
      if (e.type === "commissions/generateComissionsReport/rejected") {
        setShowCommissionsError(true);
      } else {
        toast(t("COMMISSIONS_UPLOADED_SUCCESFULLY"));
        setReportFilters(prev => ({...prev, status: "confirmed"}));
        closeFilePreviewModal();
      }
    }).finally(() => {
      setUploadingFile(false);
      setUploadProgress(0);
      setGeneratingReport(false)
    });
  };

  const exportReport = async () => {
    let total = paginatedOptions.totalCount;

    setIsExporting(true);

    let commissions = await dispatch(
      downloadComissionsReport({
        agencyId: currentUser.agency_id,
        billingPeriodId: +storedFilters.billingPeriod,
        carrierId: storedFilters.carrier && !isNaN(Number(storedFilters.carrier)) ? +storedFilters.carrier : undefined,
        pageSize: total,
        status: storedFilters.status,
      })
    );

    const wb = xlsx.utils.book_new();

    //Convert HTML table to a worksheet
    const ws = xlsx.utils.json_to_sheet((commissions.payload as any).commissions);

    //Append the worksheet to the workbook
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");

    //Generate and download the XLSX file
    xlsx.writeFile(wb, "table.xlsx");

    setIsExporting(false);
  };

  const generateSummary = async () => {

    setIsGeneratingSummary(true);

    let commissions = await dispatch(
      downloadComissionsReport({
        agencyId: currentUser.agency_id,
        billingPeriodId: +reportFilters.billingPeriod,
        pageSize: -1,
      })
    );

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    let selectedBp = billingPeriods.find((x: BillingPeriod) => x.id === +reportFilters.billingPeriod);

    let billingPeriod = moment({ month: selectedBp?.month, year: selectedBp?.year }).format("MMMM, yyyy");
    
    const data = (commissions.payload as any).commissions as Commission[];

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    let title: string = "";
    let body: any[] = []
    let headers: RowInput[] = [];

    if (currentUser.role_id === RoleEnum.SUPER_ADMIN) {
      // take the data and grouop it by carrier, then by agent and sum the total commission of each agent
      title = `${t("COMMISSIONS_SUMMARY")} | ${currentAgency.name} - ${billingPeriod}`;
      headers = [[t("CARRIER"), t("AGENT"), t("AGENT_COMMISSION"), t("AGENCY_COMMISSION", {agency_name: currentAgency.name}), t("TOTAL_COMMISSION") ]] as RowInput[];
      let groupedData = data.reduce((acc: any, obj: Commission) => {
        let key = obj.carrier!;
        let subKey = obj.agent!;
        let total = +obj.total_income!;
        let agent_total = +obj.agent_commission!;
        let agency_total = +obj.agency_commission!;
  
        if (!acc[key]) {
          acc[key] = {};
        }
  
        if (!acc[key][subKey]) {
          acc[key][subKey] = {total: 0, agent: 0, agency: 0};
        }
  
        acc[key][subKey].total += total;
        acc[key][subKey].agent += agent_total;
        acc[key][subKey].agency += agency_total;
  
        return acc;
      }, {});
    
      Object.keys(groupedData).forEach((key: string) => {
        var rowCount = Object.keys(groupedData[key]).length + 2;
  
        Object.keys(groupedData[key]).forEach((subKey: string, i: number) => {
          var row: any[] = [];
  
          if (i === 0) {
            row.push({content: t(key), rowSpan: rowCount, styles: { valign: "top" }}, 
                      subKey, 
                      {content: USDollar.format(groupedData[key][subKey].agent), styles: { halign: "right" }},
                      {content: USDollar.format(groupedData[key][subKey].agency), styles: { halign: "right" }},
                      {content: USDollar.format(groupedData[key][subKey].total), styles: { halign: "right" }},
                    );
          } else {
            row.push(subKey, 
                    {content: USDollar.format(groupedData[key][subKey].agent), styles: { halign: "right" }},
                    {content: USDollar.format(groupedData[key][subKey].agency), styles: { halign: "right" }},
                    {content: USDollar.format(groupedData[key][subKey].total), styles: { halign: "right" }},
                  );
          }
  
          body.push(row);
  
        });

        // Subtotal per carrier
        let total = Object.keys(groupedData[key]).map(l => groupedData[key][l].total).reduce((o, n) => o + n);
        let agentTotal = Object.keys(groupedData[key]).map(l => groupedData[key][l].agent).reduce((o, n) => o + n);
        let agencyTotal = Object.keys(groupedData[key]).map(l => groupedData[key][l].agency).reduce((o, n) => o + n);

        body.push(["", "", "", ""])
        body.push([{content: t("CARRIER_TOTAL"), styles: { fontStyle: 'bold' }}, 
          {content: USDollar.format(+agentTotal), styles: { halign: "right", fontStyle: 'bold' }},
          {content: USDollar.format(+agencyTotal), styles: { halign: "right", fontStyle: 'bold' }},
          {content: USDollar.format(+total), styles: { halign: "right", fontStyle: 'bold' }}])
      });

      let total = Object.keys(groupedData).flatMap((k) => Object.keys(groupedData[k]).map(l => groupedData[k][l].total)).reduce((o, n) => o + n);
      let agentTotal = Object.keys(groupedData).flatMap((k) => Object.keys(groupedData[k]).map(l => groupedData[k][l].agent)).reduce((o, n) => o + n);
      let agencyTotal = Object.keys(groupedData).flatMap((k) => Object.keys(groupedData[k]).map(l => groupedData[k][l].agency)).reduce((o, n) => o + n);

      body.push(["", "", "", ""])
      body.push([{content: t("TOTAL"), styles: { fontStyle: 'bold', fontSize: 12 }}, 
          "", 
          {content: USDollar.format(+agentTotal), styles: { halign: "right", fontStyle: 'bold', fontSize: 12 }},
          {content: USDollar.format(+agencyTotal), styles: { halign: "right", fontStyle: 'bold', fontSize: 12 }},
          {content: USDollar.format(+total), styles: { halign: "right", fontStyle: 'bold', fontSize: 12 }}])
  
    } else {
      // take the data and group it by carrier
      title = `${t("COMMISSIONS_STATEMENT")} - ${billingPeriod}`;
      headers = [[t("CARRIER"), t("POLICY_NUMBER"), t("CLIENT"), t("COMMISSION") ]] as RowInput[];

      let groupedData = data.reduce((acc: any, obj: Commission) => {
        let key = obj.carrier!;
        let total = +obj.agent_commission!;
  
        if (!acc[key]) {
          acc[key] = {total: 0, commissions: []};
        }
  
        acc[key].total += total;
        acc[key].commissions.push(obj);
  
        return acc;
      }, {});

      Object.keys(groupedData).forEach((key: string) => {
        groupedData[key].commissions.forEach((obj: Commission, i: number) => {
          var row: any[] = [];
  
          row.push(t(key), obj.policy_number, obj.client, {content: USDollar.format(+obj.agent_commission!), styles: { halign: "right" }});
  
          body.push(row);
  
        });
      });

      body.push(["", "", "", ""])

      let total = Object.keys(groupedData).map((k) => groupedData[k].total).reduce((o, n) => o + n);

      Object.keys(groupedData).forEach((key: string) => {
        let carrierTotal = groupedData[key].commissions.map((c: Commission) => +c.agent_commission!).reduce((o:number, n:number) => o + n);

        body.push([t("CARRIER_TOTAL_FORMAT", {carrier: t(key)}), "", "", {content: USDollar.format(+carrierTotal), styles: { halign: "right"}}])
      });

      body.push([{content: t("TOTAL"), styles: { fontStyle: 'bold' }}, "", "", {content: USDollar.format(+total), styles: { halign: "right", fontStyle: 'bold' }}])
    }

    let content: UserOptions = {
      startY: 80,
      head: headers,
      headStyles: { fillColor: "#003052", textColor: "#ffffff" },
      body: body,
      theme: "striped",
      didParseCell: (data) => {
        if (data.cell && data.cell.section === 'head') {
            if (data.cell.raw?.toString().includes(t("COMMISSION"))) {
              data.cell.styles.halign = 'right';
            }
        }
      }
    };

    doc.text(title, marginLeft, 40);
    
    if (currentUser.role_id !== RoleEnum.SUPER_ADMIN) {
      doc.setFontSize(10);
      doc.text(t("AGENT_LINE", {agent: currentUser.first_name + ' ' + currentUser.last_name }), marginLeft, 60);
    }

    autoTable(doc, content);
    doc.save(`commissions_summary_${billingPeriod}_${currentUser.role_id !== RoleEnum.SUPER_ADMIN ? currentUser.first_name + '_' + currentUser.last_name : ''}.pdf`);

    setIsGeneratingSummary(false);
  };


  const missingMappingValues = () => {
    if (!selectedFile) return true;

    return selectedFile.format.some((x: any) => !x.match && x.key !== "members_insured")
  }

  const addFilePreviewModal = () => {
    return (
      <Modal
        isOpen={isOpenFilePreviewModal}
        onClose={closeFilePreviewModal}
        hideCloseButton={uploadingFile || generatingReport}
        label={t("UPLOAD_COMMISIONS")}
        saveButton={{
          label: t("UPLOAD"),
          icon: <DocumentArrowUpIcon />,
          onClick: onFileUploadSubmitted,
          disabled: missingMappingValues() || uploadingFile || generatingReport
        }}
        thirdButton={selectedFile && !uploadingFile && !generatingReport ? {
          label: t("BACK"),
          icon: <ChevronLeftIcon />,
          onClick: () => setSelectedFile(null),
        } : undefined}
        hideCancelButton
      >
        {selectedFile && !uploadingFile && !generatingReport && (<>
          <label className="text-sm text-slate-600">
            {t("EXPECTED_COMMISSION_FORMAT")}
          </label>
          <div className="flex flex-col divide-y mt-5">
            {selectedFile.format.map((x: any) => (
              <div className="flex grow justify-between">
                <div className="md:w-1/2 content-center">
                  <label>{x.header}{x.key !== "members_insured" && ' *'}</label>
                </div>

                <FormSelect
                  label=""
                  name=""
                  options={selectedFile.fileColumns.map((item: any) => ({
                    label: item,
                    value: item,
                  }))}
                  selectedValue={x.match?.key}
                  handleOnChange={(e) => {
                    setSelectedFile((prev: any) => {
                      let idx = prev.format.indexOf(
                        (y: any) => y.key === x.key
                      );

                      x.match = {};
                      x.match.key = e.target.value;
                      x.match.score = 2;

                      prev.format[idx] = x;

                      return { ...prev };
                    });
                  }}
                />
              </div>
            ))}
          </div>
          </>
        )}

        {selectedFile && uploadingFile && <>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div className="bg-blue h-2.5 rounded-full" style={{"width": `${uploadProgress*100}%`}}></div>
          </div>

          <label className="text-sm">
            {t("UPLOADING_STATEMENT", {progress: Math.round(uploadProgress*100)})}
          </label></>
        }

        {selectedFile && generatingReport && (
          <div className="flex flex-row gap-x-3">
            <SpinCircle />
            <label className="text-sm">
              {t("GENERATING_REPORT")}
            </label></div>
        )}

        {!selectedFile && <>
          <div className="flex flex-row items-center">
            <FormSelect
              className='md:w-1/2'
              label={t("PLEASE_CHOOSE_CARRIER")}
              selectedValue={selectedCarrier}
              name="carrier_id"
              options={agencyCarriers.map((c) => { return {label: c.value, value: `${c.id}`} })}
              handleOnChange={(e) => setSelectedCarrier(e.target.value)} /> 

            {loadingMap && <span className="mt-3"><SpinCircle/></span>}
          </div>

          <Button onClick={() => showUploadDialog()} classNames="ml-3" isDisabled={!selectedCarrier || loadingMap}>
            <input
              type="file"
              id="file"
              ref={inputFile}
              accept=".csv, .xls, .xlsx, .numbers, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => handleFileSelected(e)}
              multiple={false}
              style={{ display: "none" }}
            />
            <span className="flex items-center font-semibold pr-3">
              <div className="w-8 p-1 aspect-square mr-2">
                <DocumentArrowUpIcon />
              </div>
              {t("UPLOAD_FILE")}
            </span>
          </Button>
        </>}
      </Modal>
    );
  };

  const addReviewUnmatchedPolicyModal = () => {
    return <><ReviewUnmatchedPolicyModal
      info={openReviewUpdateModal}
      onClose={() =>
        setOpenReviewModalModal({ show: false, policy: undefined })
      }
    />
    <ModalInformation isOpen={showPolicyNotReportedModal} onClose={() => setShowPolicyNotReportedModal(false)} label={t("REVIEW_STATEMENT")} children={
      <p>{t("PLEASE_UPLOAD_STATEMENT_FOR_POLICY")}</p>
    } 
    saveButton={{label: t("OK"), onClick: () => setShowPolicyNotReportedModal(false), icon: <CheckIcon />}}
    cancelButton={false}/>
    </>
  }

  const addEditCommissionRecordModal = () => {
    return <EditCommissionRecordModal
    info={openEditCommissionRecordModal}
    onClose={() =>
      setOpenEditCommissionRecordModal({ show: false, policy: undefined })
    }
  />
  }

  const showUploadDialog = () => {
    inputFile.current!.click();
  };

  const findBestMatches = (
    source1Keys: string[],
    source2Keys: string[],
    threshold = 0.5
  ) => {
    const matches: any = {};

    const fileMatchedKeys: any = {};

    source1Keys.forEach((key1) => {
      const bestMatch = stringSimilarity.findBestMatch(
        key1.replaceAll("_", " "),
        source2Keys
      );

      const bestMatchKey = bestMatch.bestMatch.target;
      const bestMatchRating = bestMatch.bestMatch.rating;

      if (bestMatchRating >= threshold) {
        if (!fileMatchedKeys[bestMatchKey]) {
          matches[key1] = { key: bestMatchKey, score: bestMatchRating };
          fileMatchedKeys[bestMatchKey] = { key: key1, score: bestMatchRating };
        } else {
          let prevValue = fileMatchedKeys[bestMatchKey];

          if (prevValue.score < bestMatchRating) {
            matches[key1] = { key: bestMatchKey, score: bestMatchRating };
            matches[prevValue.key] = { key: null, score: 0 };
            fileMatchedKeys[bestMatchKey] = bestMatchRating;
          }
        }
      }
    });

    return matches;
  };

  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];

    const reader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const data = e?.target?.result;

      let workbook = xlsx.read(data);

      let requiredFields = [
        { key: "policy_number", header: t("POLICY_NUMBER") },
        { key: "statement_date", header: t("STATEMENT_DATE") },
        { key: "billing_date", header: t("BILLING_DATE") },
        { key: "client_name", header: t("CLIENT_NAME") },
        { key: "carrier_name", header: t("CARRIER") },
        { key: "agent_name", header: t("AGENT_NAME") },
        { key: "effective_start_date", header: t("EFFECTIVE_START_DATE") },
        { key: "members_insured", header: t("MEMBERS_INSURED") },
        { key: "commission", header: t("COMMISSION") },
      ];

      /* let fileData = xlsx.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        { raw: false}
      );

      let ds2 = Object.keys(fileData.at(0) as any); */

      var headers = [];
      var sheet = workbook.Sheets[workbook.SheetNames[0]];

      var range = xlsx.utils.decode_range(sheet['!ref']!);
      var C, R = range.s.r; /* start in the first row */
      /* walk every column in the range */
      for(C = range.s.c; C <= range.e.c; ++C) {
          var cell = sheet[xlsx.utils.encode_cell({c:C, r:R})] /* find the cell in the first row */

          var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
          if(cell && cell.t) hdr = xlsx.utils.format_cell(cell);

          headers.push(hdr);
      }

      let matches = findBestMatches(requiredFields.map(x => x.key), headers);

      let format:any;
      
      if (selectedCarrierCommissionStatementMap.length === 0) {
        format = requiredFields.map((x) => {
          return {
            ...x,
            match: matches[x.key],
          };
        });
      } else {
        format = selectedCarrierCommissionStatementMap;
      }

      setSelectedFile({
        file: file,
        format: format,
        fileColumns: headers,
      });

      openFilePreviewModal();
    };

    reader.readAsArrayBuffer(file);
  };

  const getPaginationOptions = useMemo(() => {
    return {
      page: paginatedOptions.page,
      pageSize: paginatedOptions.pageSize,
      totalPages: paginatedOptions.totalPages,
      totalCount: paginatedOptions.totalCount,
      setPage: (value: number) => {
        value < paginatedOptions.page
          ? dispatch(pastPage())
          : dispatch(
              getComissionsReport({
                agencyId: currentUser.agency_id,
                billingPeriodId: +reportFilters.billingPeriod,
                carrierId: reportFilters.carrier && !isNaN(Number(reportFilters.carrier)) ? +reportFilters.carrier : undefined,
                page: value,
                pageSize: paginatedOptions.pageSize,
                status: reportFilters.status,
              })
            );
      },
      setPageSize: (value: number) =>
        dispatch(
          getComissionsReport({
            agencyId: currentUser.agency_id,
            billingPeriodId: +reportFilters.billingPeriod,
            carrierId: reportFilters.carrier && !isNaN(Number(reportFilters.carrier)) ? +reportFilters.carrier : undefined,
            page: 0,
            pageSize: value,
            status: reportFilters.status,
          })
        ),
    };
  }, [dispatch, paginatedOptions]);

  return (
    <>
      {isLoading && <LoadingMask />}
      {addFilePreviewModal()}
      {addReviewUnmatchedPolicyModal()}
      {addEditCommissionRecordModal()}
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <FilteredTable
        columns={storedFilters.status == "unmatched" ? unmatchedColumns : columns}
        data={pageCommissions}
        isPaginated
        paginationOptions={getPaginationOptions}
        secondRow={
          <div className="flex flex-row w-full justify-between">
            <div className="flex justify-start gap-2">
            <FormSelect 
              label={t("BILLING_PERIOD")}
              name=""
              options={billingPeriods.map((x: any) => ({
                value: x.id,
                label: moment({ month: x.month, year: x.year }).format(
                  "MMMM, yyyy"
                ),
              }))}
              selectedValue={reportFilters.billingPeriod}
              handleOnChange={(e) => {
                setReportFilters(prev => ({...prev, billingPeriod: e.target.value}));
              }}
            />

            <FormSelect
              label={t("CARRIER")}
              name=""
              options={[{value: "-1", label: t("ALL_CARRIERS")}, ...agencyCarriers.map((c) => { return {label: c.value, value: `${c.id}`} })]}
              selectedValue={reportFilters.carrier}
              handleOnChange={(e) => {
                setReportFilters(prev => ({...prev, carrier: e.target.value}));
              }}
            />

            {currentUser && currentUser.role_id === RoleEnum.SUPER_ADMIN && <FormSelect
              label={t("STATUS")}
              name=""
              options={[
                { value: "confirmed", label: t("CONFIRMED") },
                { value: "unmatched", label: t("PENDING_REVIEW") },
              ]}
              selectedValue={reportFilters.status}
              handleOnChange={(e) => {
                setReportFilters(prev => ({...prev, status: e.target.value}));
              }}
            />}

            <div className="px-3 w-full">
            <Button classNames="mt-8  text-nowrap" onClick={() => {
                if (reportFilters.billingPeriod !== "" && (reportFilters.status === "unmatched" || reportFilters.status === "confirmed")) {
                  dispatch(
                    getComissionsReport({
                      agencyId: currentUser.agency_id,
                      billingPeriodId: +reportFilters.billingPeriod,
                      carrierId: reportFilters.carrier && !isNaN(Number(reportFilters.carrier)) ? +reportFilters.carrier : undefined,
                      pageSize: paginatedOptions.pageSize,
                      page: 0,
                      status: reportFilters.status,
                    })
                  );

                  dispatch(setFilters(reportFilters))
                }
              }}>
                <span className="flex items-center font-semibold pr-3">
                  <div className="w-8 p-1 aspect-square mr-2">
                    <TableCellsIcon />
                  </div>
                  {t("GET_REPORT")}
                </span>
            </Button>
            </div>
            

            </div>

            <div className="flex gap-2 mt-8">

              <Button
                isTerciary={true}
                classNames="ml-3"
                onClick={exportReport}
                isDisabled={pageCommissions.length === 0 || isExporting || isGeneratingSummary}
              >
                <span className="flex items-center font-semibold pr-3">
                  <div className="w-8 p-1 aspect-square mr-2">
                    {isExporting ? <SpinCircle/> : <DocumentArrowDownIcon /> }
                  </div>
                  {t("EXPORT")}
                </span>
              </Button>

              <Button
                isTerciary={true}
                classNames="ml-3"
                onClick={generateSummary}
                isDisabled={isGeneratingSummary || isExporting || reportFilters.billingPeriod === ""}
              >
                <span className="flex items-center font-semibold pr-3">
                  <div className="w-8 p-1 aspect-square mr-2">
                    {isGeneratingSummary ? <SpinCircle/> : <DocumentCurrencyDollarIcon /> }
                  </div>
                  { currentUser && currentUser.role_id === RoleEnum.SUPER_ADMIN ? t("GENERATE_SUMMARY") : t("GENERATE_MY_STATEMENT")}
                </span>
              </Button>
              
            </div>
          </div>
        }
        buttonAction={
          currentUser && currentUser.role_id === RoleEnum.SUPER_ADMIN ? <Button onClick={() => openFilePreviewModal()}>
            <span className="flex items-center font-semibold pr-3">
              <div className="w-8 p-1 aspect-square mr-2">
                <DocumentArrowUpIcon />
              </div>
              {t("ADD_COMMISSION_STATEMENT")}
            </span>
          </Button> : undefined
        }
      />
    </>
  );
};
