import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AgencyPolicyType, AllPolicyTypes, PolicyMarket, PolicyType } from "../../models/PolicyMarket";
import { fetchAgencyPolicyTypes, fetchAllPolicyTypes, fetchMarketPolicyTypes, fetchPolicyMarkets, updateAgencyPolicyTypes } from "./actions";

interface PolicyMarketState {
    policyMarkets: PolicyMarket[],
    policyTypes: PolicyType[],
    agencyPolicyTypes: AgencyPolicyType[],
    allPolicyTypes: AllPolicyTypes[],
    isLoading: boolean,
    error: string | null,
}

const initialState: PolicyMarketState = {
    policyMarkets: [],
    policyTypes: [],
    agencyPolicyTypes: [],
    allPolicyTypes: [],
    isLoading: false,
    error: null
}

// slice
const policyMarketSlice = createSlice({
  name: "policyMarket",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPolicyMarkets.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchPolicyMarkets.fulfilled, (state, action: PayloadAction<PolicyMarket[]>) => {
        state.isLoading = false;
        state.policyMarkets = action.payload;
      })
      .addCase(fetchPolicyMarkets.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchMarketPolicyTypes.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchMarketPolicyTypes.fulfilled, (state, action: PayloadAction<PolicyType[]>) => {
        state.isLoading = false;
        state.policyTypes = action.payload;
      })
      .addCase(fetchMarketPolicyTypes.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAgencyPolicyTypes.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchAgencyPolicyTypes.fulfilled, (state, action: PayloadAction<AgencyPolicyType[]>) => {
        state.isLoading = false;
        state.agencyPolicyTypes = action.payload;
      })
      .addCase(fetchAgencyPolicyTypes.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateAgencyPolicyTypes.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateAgencyPolicyTypes.fulfilled, (state, action: PayloadAction<AgencyPolicyType[]>) => {
        state.isLoading = false;
        state.agencyPolicyTypes = action.payload;
      })
      .addCase(updateAgencyPolicyTypes.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllPolicyTypes.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchAllPolicyTypes.fulfilled, (state, action: PayloadAction<AllPolicyTypes[]>) => {
        state.isLoading = false;
        state.allPolicyTypes = action.payload;
      })
      .addCase(fetchAllPolicyTypes.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default policyMarketSlice.reducer;