import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Carrier } from "../../models/Carrier";
import { createAgencyCarrier, deleteAgencyCarrier, fetchAgencyCarriers, fetchCarriersForPolicyType, updateAgencyCarrier } from "./actions";

interface CarrierState {
  isLoading: boolean,
  error: string | null
  carriers: Carrier[],
  agencyCarriers: Carrier[]
}

const initialState: CarrierState = {
    isLoading: false,
    error: null,
    carriers: [],
    agencyCarriers: []
}

// slice
const carriersSlice = createSlice({
  name: "carriers",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgencyCarriers.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchAgencyCarriers.fulfilled, (state, action: PayloadAction<Carrier[]>) => {
        state.isLoading = false;
        state.agencyCarriers = action.payload;
      })
      .addCase(fetchAgencyCarriers.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createAgencyCarrier.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createAgencyCarrier.fulfilled, (state, action: PayloadAction<Carrier>) => {
        state.isLoading = false;
        state.agencyCarriers = state.agencyCarriers.concat(action.payload);
      })
      .addCase(createAgencyCarrier.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateAgencyCarrier.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateAgencyCarrier.fulfilled, (state, action: PayloadAction<Carrier>) => {
        state.isLoading = false;
        const updatedCarrier = action.payload;
        const updatedCarriers = state.agencyCarriers.map((carrier) => {
                return carrier.id === updatedCarrier.id ? updatedCarrier : carrier
            }
        );
        state.agencyCarriers = updatedCarriers;
      })
      .addCase(updateAgencyCarrier.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteAgencyCarrier.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(deleteAgencyCarrier.fulfilled, (state, action: PayloadAction<Carrier[]>) => {
        state.isLoading = false;
        state.agencyCarriers = action.payload;
      })
      .addCase(deleteAgencyCarrier.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCarriersForPolicyType.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchCarriersForPolicyType.fulfilled, (state, action: PayloadAction<Carrier[]>) => {
        state.isLoading = false;
        state.carriers = action.payload;
      })
      .addCase(fetchCarriersForPolicyType.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default carriersSlice.reducer;