import { useDispatch, useSelector } from "react-redux";
import { Client } from "../../../models/Client";
import { IncomeSource, newIncomeSourceInitialValues } from "../../../models/IncomeSource";
import { AppDispatch, RootState } from "../../../state/store";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { Toast } from "../../Toast";
import { useForm } from "react-hook-form";
import { createClientIncomeSource, updateClientIncomeSource } from "../../../state/incomeSources/actions";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "../../Modal";
import { ReactiveFormInput } from "../../form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../form/ReactiveFormSelect";
import { getEmployeeTypes } from "../../../enums/EmployeerType";
import { ReactiveFormPhoneInput } from "../../form/ReactiveFormPhoneInput";
import { LoadingMask } from "../../LoadingMask";

interface Props {
    info: {
        show: boolean;
        update: boolean;
        income?: IncomeSource;
        index?: number;
        client?: Client;
      };
    onSubmited: (income: IncomeSource, index?:number) => void;
    onClose: () => void;
}

export const ClientIncomeModal: React.FC<Props> = ({
    info,
    onSubmited,
    onClose
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const isLoading = useSelector((state: RootState) => state.incomeSources.isLoading);
    const error = useSelector((state: any) => state.incomeSources.error);
    const [showIncomeError, setShowIncomeError] = useState(false);

    useEffect(() => {
        if (showIncomeError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showIncomeError, error, t]);

    const methods = useForm<IncomeSource>({
        defaultValues: newIncomeSourceInitialValues
    })
    const { handleSubmit, control, reset } = methods;
    
    useEffect(() => {
        info.income ? reset(info.income) : reset(newIncomeSourceInitialValues);
    }, [info.income, reset]);

    const onSubmit = (data: IncomeSource) => {
        if (info.update) {
            //TODO: update
            dispatch(updateClientIncomeSource({editIncome: data, incomeId: info.income?.id!, clientId: info.client?.id!})).then((e) => {
                if (e.type === "client/updateClientIncomeSource/rejected") {
                    setShowIncomeError(true);
                } else {
                    let income = (e.payload || {}) as IncomeSource;
                    toast(t("SUCCESSFULLY_UPDATE_CLIENT_INCOME_SOURCE"));
                    onSubmited(income, info.index)
                    closeModal();
                }
            });
        } else {
            //Todo: add
            dispatch(createClientIncomeSource({newIncome: data, clientId: info.client?.id!})).then((e) => {
                if (e.type === "client/createClientIncomeSource/rejected") {
                    setShowIncomeError(true);
                } else {
                    let income = (e.payload || {}) as IncomeSource;
                    toast(t("SUCCESSFULLY_SAVED_CLIENT_INCOME_SOURCE"));
                    onSubmited(income)
                    closeModal();
                }
            });
        }
    }
    
    const closeModal = () => {
        reset({});
        onClose();
    };

    return (<>
        <Modal
            isOpen={info.show}
            onClose={closeModal}
            label={info.update ? t("UPDATE_INCOME_SOURCE") : t("ADD_INCOME_SOURCE")}
            onSave={handleSubmit(onSubmit)}
        >
            <div className="w-full flex flex-wrap">
                <Fragment>
                    <ReactiveFormSelect
                        className="md:w-1/2"
                        name={`employee_type_id`}
                        label={t("EMPLOYEE_TYPE")}
                        options={getEmployeeTypes()}
                        control={control}
                    />
                    <ReactiveFormInput
                        control={control}
                        className="md:w-1/2"
                        label={t("EMPLOYER_NAME")}
                        name={`employer_name`}
                    />
                    <ReactiveFormPhoneInput
                        control={control}
                        className="md:w-1/3"
                        label={t("PHONE")}
                        name={`phone_number`}
                    />
                    <ReactiveFormInput
                        control={control}
                        className="md:w-1/3"
                        label={t("OCCUPATION")}
                        name={`occupation`}
                    />
                    <ReactiveFormInput
                        control={control}
                        type="number"
                        className="md:w-1/3"
                        label={t("INCOME")}
                        name={`income`}
                    />
                </Fragment>
            </div>
        </Modal>
        <ToastContainer progressStyle={{ background: "#D4AF37" }} />
        { isLoading && <LoadingMask />}
    </>)
}