import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline"
import Button from "./Button"

interface Column {
    header: string
    className?: string
}

interface TableData {
    columns: { value: JSX.Element, className?: string }[]
}

interface Props {
    data: any[]
    columns: Column[]
    addItemOnLastLine?: boolean;
    onRowClick?: (index: number) => void;
    editAction?: (index: number) => void;
    removeAction?: (index: number) => void;
    addAction?: () => void;
}

const Table = (props: Props) => {
    return <table className="w-full text-sm text-left text-gray-500 ">
        <thead className="text-xs text-gray-700   ">
            <tr className="text-neutral-600 text-sm sticky top-0 bg-white pt-3">
                {props.columns.map((column) => {
                    return <th key={column.header} className={"px-6 pt-4 " + (column.className ?? "")}>{column.header}</th>
                })}
                {(props.editAction || props.removeAction) &&
                    <th key={1}></th>
                }
            </tr>
        </thead>
        <tbody>
            {props.data.map((row: TableData, index: number) =>
                <tr key={index} className="bg-white border-b border-gray-200 hover:bg-neutral-100 cursor-pointer" onClick={() => props.onRowClick ? props.onRowClick(index) : undefined}>
                    {row.columns.map((column: { value: JSX.Element, className?: string }) =>
                        <td className={"px-6 py-4 " + (column.className ?? "")}>{column.value}</td>
                    )}
                    {(props.editAction || props.removeAction) &&
                        <td className="py-4 flex flex-row justify-center divide-x">
                            {props.removeAction && <Button isTerciary iconOnly onClick={() => props.removeAction ? props.removeAction(index) : undefined} classNames="pl-2"><TrashIcon className="h-5 aspect-square stroke-gold" /></Button>}
                            {props.editAction !== undefined && <Button isTerciary iconOnly onClick={() => props.editAction ? props.editAction(index) : undefined} classNames="mr-2"><PencilSquareIcon className="h-5 aspect-square stroke-blue" /></Button>}
                        </td>}
                </tr>
            )}
        </tbody>
        <tfoot>
            <tr className="sticky bottom-0 bg-white px-6 py-4">
                <td colSpan={props.columns.length + 1} className="flex flex-row justify-end ">

                </td>
            </tr>
        </tfoot>
    </table>
}

export default Table;