import { AllCampaigns, Campaign, CampaignAdditionalInformation, CampaignFilters, CampaignResponse } from "../../models/Campaign";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearCampaigns, clearForm, createCampaign, deleteCampaign, fetchCampaigns, getCampaignAdditionalInformation, pastPage, updateCampaign } from "./actions";

interface CampaignState {
  isLoading: boolean,
  error: string | null
  campaigns: AllCampaigns[],
  pageCampaigns: Campaign[],
  paginatedOptions: {
    page: number,
    pageSize: number,
    totalPages: number,
    totalCount: number
  },
  filters?: CampaignFilters | null,
}

const initialState: CampaignState = {
    isLoading: false,
    error: null,
    campaigns: [],
    pageCampaigns: [],
    paginatedOptions: {
      page: 0,
      pageSize: 10,
      totalPages: 0,
      totalCount: 0,
    },
    filters: null
}

// slice
const campaignsSlice = createSlice({
  name: "campaigns",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchCampaigns.pending, (state) => {
            state.isLoading = true;
            state.error = null
        })
        .addCase(fetchCampaigns.fulfilled, (state, action: PayloadAction<CampaignResponse>) => {
            state.isLoading = false;
            state.campaigns = state.campaigns.concat({ pageNumber: action.payload.page, pageCampaigns: action.payload.campaigns});
            state.pageCampaigns = action.payload.campaigns;
            state.paginatedOptions.page = action.payload.page;
            state.paginatedOptions.pageSize = action.payload.pageSize;
            state.paginatedOptions.totalPages = action.payload.totalPages;
            state.paginatedOptions.totalCount = action.payload.totalCount;
            state.filters = action.payload.filters;
        })
        .addCase(fetchCampaigns.rejected, (state, action: any) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase(createCampaign.pending, (state) => {
            state.isLoading = true;
            state.error = null
        })
        .addCase(createCampaign.fulfilled, (state, action: PayloadAction<Campaign>) => {
            state.isLoading = false;
            state.error = null;

            state.campaigns[0].pageCampaigns.unshift(action.payload)
            if(state.campaigns[0].pageCampaigns.length > state.paginatedOptions.pageSize) {
                state.campaigns[0].pageCampaigns.pop()
            }
            state.pageCampaigns = state.campaigns[0].pageCampaigns;
            state.paginatedOptions.page = 0;
        })
        .addCase(createCampaign.rejected, (state, action: any) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase(updateCampaign.pending, (state, action) => {
            state.isLoading = true;
            state.error = null
        })
        .addCase(updateCampaign.fulfilled, (state, action: PayloadAction<Campaign>) => {
            state.isLoading = false;
            state.error = null;
            //if (state.paginatedOptions.page === state.paginatedOptions.totalPages) {
            const updatedCampaign = action.payload;
            const updatedPageCampaigns = state.pageCampaigns.map((campaign) => {
                return campaign.id === updatedCampaign.id ? updatedCampaign : campaign
            }
            );
            const updatedCampaigns = state.campaigns;
            updatedCampaigns[state.paginatedOptions.page].pageCampaigns = updatedPageCampaigns;
            state.campaigns = updatedCampaigns;
            state.pageCampaigns = updatedPageCampaigns;
            //}
        })
        .addCase(updateCampaign.rejected, (state, action: any) => {
            state.isLoading = false;
            state.error = action.error.message;
        })
        .addCase(deleteCampaign.pending, (state, action) => {
            state.isLoading = true;
            state.error = null
        })
        .addCase(deleteCampaign.fulfilled, (state, action: PayloadAction<CampaignResponse>) => {
            state.isLoading = false;
            state.error = null;
            const updatedCampaigns = state.campaigns;
            updatedCampaigns[state.paginatedOptions.page].pageCampaigns = action.payload.campaigns;
            state.campaigns = updatedCampaigns;
            state.pageCampaigns = action.payload.campaigns;
            state.paginatedOptions.page = action.payload.page;
            state.paginatedOptions.pageSize = action.payload.pageSize;
            state.paginatedOptions.totalPages = action.payload.totalPages;
            state.paginatedOptions.totalCount = action.payload.totalCount;
        })
        .addCase(deleteCampaign.rejected, (state, action: any) => {
            state.isLoading = false;
            state.error = action.error.message;
        })
        .addCase(getCampaignAdditionalInformation.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getCampaignAdditionalInformation.fulfilled, (state, action: PayloadAction<CampaignAdditionalInformation[]>) => {
            state.isLoading = false;
        })
        .addCase(getCampaignAdditionalInformation.rejected, (state, action: any) => {
            state.isLoading = false;
            state.error = action.error.message;
        })
        .addCase(pastPage.pending, (state) => {
            state.isLoading = true;
            state.error = null
        })
        .addCase(pastPage.fulfilled, (state) => {
            state.isLoading = false;
            state.error = null
            const result = state.campaigns.find(x => x.pageNumber === state.paginatedOptions.page - 1);
            if (result) {
                state.pageCampaigns = result.pageCampaigns;
                state.paginatedOptions.page = state.paginatedOptions.page - 1;
            }
        })
        .addCase(clearCampaigns.fulfilled, (state) => {
            state.campaigns = [];
        })
        .addCase(clearForm.fulfilled, (state) => {
            state.filters = null
        });
  },
});

export default campaignsSlice.reducer;