import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { IncomeSource } from "../../models/IncomeSource";

export const fetchIncomeSourcesByClientId = createAsyncThunk("client/fetchIncomeSourcesByClientId", async (params: {clientId: number}) => {
    const response: AxiosResponse<IncomeSource[]> = await (await axiosClient()).get(`/clients/${params.clientId}/income-sources`);
    return response.data
});

export const createClientIncomeSource = createAsyncThunk("client/createClientIncomeSource", async (params:{newIncome: IncomeSource, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<IncomeSource> = await (await axiosClient()).post(`/clients/${params.clientId}/income-sources`, {...params.newIncome});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateClientIncomeSource = createAsyncThunk("client/updateClientIncomeSource", async (params:{editIncome: IncomeSource, incomeId: number, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<IncomeSource> = await (await axiosClient()).patch(`/clients/${params.clientId}/income-sources/${params.incomeId}`, {...params.editIncome});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteClientIncomeSource = createAsyncThunk("client/deleteClientIncomeSource", async (params:{incomeId: number, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<IncomeSource[]> = await (await axiosClient()).delete(`/clients/${params.clientId}/income-sources/${params.incomeId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const needToLoadClientIncomeSource = createAsyncThunk("client/needToLoadClientIncomeSource", async (needLoad: boolean) => {
    return needLoad;
});