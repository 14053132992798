import i18next from "i18next";

export enum CampaignEnum {
    FIXED_PRICE = 1,
    FOR_TIME
}

export const CampaignLabel = (key: string) => ({ 
    [CampaignEnum.FIXED_PRICE]: i18next.t('FIXED_PRICE'),
    [CampaignEnum.FOR_TIME]: i18next.t('FOR_TIME')
})[key];

export const getCampaigns = () =>
    Object.values(CampaignEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: CampaignLabel(value) ?? ""
        }
    });