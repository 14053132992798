import { useTranslation } from "react-i18next";
import { ClientNote, NewClientNote } from "../../../models/ClientNotes";
import { shortDateFormat, shortLocalDateFormat } from "../../../utils/functions";
import { ReactiveFormInputArea } from "../../form/ReactiveFormInputArea";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { AppDispatch } from "../../../state/store";
import { createClientNote, fetchNotesByClientId, needToLoadClientNotes } from "../../../state/clientNotes/actions";
import { Client } from "../../../models/Client";
import { useEffect, useState } from "react";
import { Toast } from "../../Toast";
import { toast, ToastContainer } from "react-toastify";
import Button from "../../Button";
import { LoadingMask } from "../../LoadingMask";

interface Props {
    client: Client | null
}
export const ClientNotes = (props: Props) => {
    const { t } = useTranslation();
    const clientNotesData = useSelector((state: any) => state.clientNotes);
    const dispatch = useDispatch<AppDispatch>();
    const [showClientNotesError, setShowClientNotesError] = useState(false);

    const methods = useForm<NewClientNote>({
        defaultValues: {
            note: "",
        },
    });
    const { control, reset, watch } = methods;

    useEffect(() => {
        if (showClientNotesError) {
            Toast(
                t(clientNotesData?.error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(clientNotesData?.error?.cause_info)
            );
            setShowClientNotesError(false);
        }
    }, [showClientNotesError, clientNotesData?.error, t]);

    useEffect(() => {
        //TODO: Review state and get data
        if(clientNotesData?.needToLoadNotes) {
            dispatch(fetchNotesByClientId({clientId: props.client?.id!}));
            dispatch(needToLoadClientNotes(false));
        }
    }, []);

    const saveNote = () => {
        if (!props.client?.id) { return }

        dispatch(
            createClientNote({
                clietnNote: { note: `${watch("note")}` },
                clientId: props.client!.id!,
            })
        ).then((e) => {
            if (e.type === "client/createClientNote/rejected") {
                setShowClientNotesError(true);
            } else {
                toast(t("SAVED_SUCCESFULLY"));
                reset();
            }
        });
    };

    return (
        <div className="w-full flex flex-col">
            {clientNotesData.clientNotes.map((note: ClientNote, index: number) => {
                return (
                    <div className="pb-5 px-3">
                        <div className="flex flex-row items-center">
                            <div className="text-sm font-medium text-black mb-0.5">
                                {t("CREATED_BY")} {note.creation_user.first_name}{" "}
                                {note.creation_user.last_name}
                            </div>
                            <span className="bg-asureisGray w-1 h-1 rounded-md text-center mx-1.5"></span>
                            <div className="text-asureisGray text-sm">
                                {shortLocalDateFormat(note.creation_date)}
                            </div>
                        </div>
                        <div className="flex flex-row items-center mt-2">
                            <div className="border border-slate-300 rounded-md bg-slate-100 shadow-sm py-2 px-3 min-w-64 min-h-15">
                                {note.note}
                            </div>
                        </div>
                    </div>
                );
            })}
            <ReactiveFormInputArea
                className="mt-10"
                name={"note"}
                label={t("NEW_NOTE")}
                control={control}
                placeholder="Write something here..."
            />
            <div className="w-full flex justify-end px-3 py-2">
                <Button onClick={saveNote}>
                    <span className="flex items-center font-semibold pr-3">
                        <div className="w-8 p-1 aspect-square mr-2">
                            <CheckIcon />
                        </div>
                        {t("SAVE_NOTE")}
                    </span>
                </Button>
            </div>
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            {clientNotesData?.isLoading && <LoadingMask />}
        </div>
    )
}