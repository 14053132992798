import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { Modal } from "../../components/Modal";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import moment from 'moment';
import { toast, ToastContainer } from "react-toastify";
import { getLocalTimeZone } from "../../utils/functions";
import { clearCampaigns, clearForm, fetchCampaigns } from "../../state/campaigns/actions";
import { CampaignFilters } from "../../models/Campaign";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Form } from "../form/Form";
import { ReactiveFormInput } from "../form/ReactiveFormInput";
import { ReactiveFormSelect } from "../form/ReactiveFormSelect";
import { getCampaigns } from "../../enums/CampaignType";
import { PolicyMarket, PolicyType } from "../../models/PolicyMarket";
import { Fragment } from "react/jsx-runtime";
import { fetchMarketPolicyTypes } from "../../state/policyMarkets/actions";

interface Props {
    isFilterModalOpen: boolean;
    setFilterModalOpen: (x: boolean) => void;
}

export const CampaignFilterModal = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const campaignState = useSelector((state: RootState) => state.campaigns);

    const policyMarkets = useSelector((state: RootState) => state.policyMarkets.policyMarkets);
    const [policyTypes, setPolicyTypes] = useState<PolicyType[]>([]);
    const [loadingTypes, setLoadingTypes] = useState(false);

    const methods = useForm<CampaignFilters>({
        defaultValues: {
            code: campaignState.filters?.code,
            campaign_type: campaignState.filters?.campaign_type,
            policy_type: campaignState.filters?.policy_type,
            policy_market: campaignState.filters?.policy_market,
            start_date: campaignState.filters?.start_date,
            end_date: campaignState.filters?.end_date
        }
    });

    const { handleSubmit, control, reset } = methods;

    const onSubmit: SubmitHandler<CampaignFilters> = async (data: CampaignFilters) => {
        console.log(data);
        if(moment(data.start_date).isAfter(data.end_date)) {
            toast(t("START_DATE_BEFORE_END_DATE_MESSAGE"));
        } else {
            data.time_zone = getLocalTimeZone();
            
            dispatch(clearCampaigns())
            dispatch(fetchCampaigns({
                page: 0,
                pageSize: campaignState.paginatedOptions.pageSize,
                filters: data 
            }));
            props.setFilterModalOpen(false);
        }
    };

    return(
        <div>
            <ToastContainer progressStyle={{"background": "#D4AF37"}} />
            <Modal isOpen={props.isFilterModalOpen} onClose={() => props.setFilterModalOpen(false)} label={t("FILTERS")} onSave={handleSubmit(onSubmit)} thirdButton={{
                label: t("CLEAR_FILTERS"),
                icon: <TrashIcon />,
                onClick: () =>  {
                    console.log("llego");
                    reset({
                        code: '',
                        campaign_type: '', 
                        policy_market: '',
                        policy_type: '',
                        start_date: '',
                        end_date: ''
                    });
                }
            }}>
                <div className="w-full flex flex-wrap">
                    <Fragment>
                        <ReactiveFormInput
                            className="md:w-1/3"
                            control={control}
                            label={t("CODE")}
                            name="code" />
                        <ReactiveFormSelect
                            className="md:w-1/3"
                            name="campaign_type"
                            label={t("CAMPAIGN_TYPE")}
                            options={getCampaigns()}
                            control={control} />
                        <ReactiveFormInput
                            className="md:w-1/3"
                            control={control}
                            type='date'
                            label={t("CREATION_BEGIN_DATE")}
                            name="start_date"/>
                        <ReactiveFormInput
                            className="md:w-1/3"
                            control={control}
                            type='date'
                            label={t("CREATION_END_DATE")}
                            name="end_date"/>
                        {
                            policyMarkets.length > 0 &&
                            <Fragment>
                                <ReactiveFormSelect
                                    className="md:w-1/3"
                                    name={`policy_market`}
                                    label={t("POLICY_MARKET")}
                                    options={policyMarkets.map((x: PolicyMarket) => { return {label: `${x.name}`, value: `${x.id}`} })}
                                    control={control}
                                    handleOnChange={(x: any) => {
                                        methods.resetField("policy_type")
                                        setLoadingTypes(true)
                                        dispatch(fetchMarketPolicyTypes(+x.target.value)).then((e) => {
                                        setPolicyTypes((e.payload || []) as PolicyType[])
                                        setLoadingTypes(false)
                                        })
                                    }}
                                />
                                <ReactiveFormSelect
                                    className="md:w-1/3"
                                    name={`policy_type`}
                                    label={t("POLICY_TYPE")}
                                    options={policyTypes.map((t: PolicyType) => { return {label: `${t.name}`, value: `${t.id}`} })}
                                    control={control}
                                    isDisabled={methods.getValues("policy_market") === undefined || loadingTypes}
                                />
                            </Fragment>
                        }
                    </Fragment>
                </div>
            </Modal>
        </div>
    )
}