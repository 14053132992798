import { useTranslation } from "react-i18next";
import { EditCommissionRecordPayload, EditPolicy, MatchedPolicyWithCommission, Policy } from "../../models/Policies";
import { set, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch, RootState } from "../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { AgentCommissionRule } from "../../models/AgentCommissionRule";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "../../components/Modal";
import { DocumentPlusIcon, PaperAirplaneIcon, PencilIcon } from "@heroicons/react/24/outline";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { getPolicyStates } from "../../enums/PolicyState";
import { ReactiveFormRadioButtonSelect } from "../../components/form/ReactiveFormRadioButtonSelect";
import { RoleEnum } from "../../enums/Role";
import { User } from "../../models/User";
import { fetchAgencyUserCommissionRules } from "../../state/agencies/action";
import { fetchPoliciesByClientId, updatePolicy } from "../../state/policies/actions";
import { Toast } from "../../components/Toast";
import { LoadingMask } from "../../components/LoadingMask";
import { ReactiveFormSearchableSelect } from "../../components/form/ReactiveFormSearchableSelect";
import { ReactiveFormRemoteSearchableSelect } from "../../components/form/ReactiveFormRemoteSearchableSelect";
import { fetchClientById, searchClients } from "../../state/clients/actions";
import { Client } from "../../models/Client";
import { createMatchedPolicy, editCommissionRecord } from "../../state/commissions/action";
import { ReactiveFormCheckbox } from "../../components/form/ReactiveFormCheckbox";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { SpinCircle } from "../../components/SpinCircle";
import moment from "moment";

interface Props {
  info: {
    show: boolean;
    policy?: any;
  };
  onClose: () => void;
}

export const EditCommissionRecordModal: React.FC<Props> = ({ info, onClose }) => {

  const { t } = useTranslation();
  const [isPolicyUpdateLoading, setIsPolicyUpdateLoading] = useState(false);
  const agencyCarriers = useSelector((state: RootState) => state.carriers.agencyCarriers);

  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const agents = useSelector((state: any) => state.users.users);
  const [selectedPossibleClient, setSelectedPossibleClient] = useState<any | null>(null);
  const [loadingPossibleClient, setLoadingPossibleClient] = useState(false);
  const [loadingPolicies, setLoadingPolicies] = useState(false);

  const [commissionRules, setCommissionRules] = useState<AgentCommissionRule[]>(
    []
  );
  const [selectedCommissionRule, setSelectedCommissionRule] = useState<
    AgentCommissionRule | undefined
  >(undefined);
  const [loadingRules, setLoadingRules] = useState(false);

  const [selectedClientPolicies, setSelectedClientPolicies] = useState<Policy[]>([]);

  const methods = useForm<EditCommissionRecordPayload>({
    defaultValues: {}
  });
  const { handleSubmit, control, getValues, reset } = methods;

  useEffect(() => {
    info.policy ? reset(info.policy) : reset({});

    if (info.policy && info.policy.possible_agent_id) {
      if (agents.find((x: any) => x.id === info.policy!.possible_agent_id)) {
        methods.setValue("agent_id", info.policy.possible_agent_id);
        loadCommissionRules(+info.policy.possible_agent_id!, info.policy.commission_rule_id);
      } else {
        info.policy.agent_id = undefined;
      }
    }

  }, [info.policy, reset]);
  
  const loadCommissionRules = (agentId: number, defaulRuleId?: number) => {
    setLoadingRules(true);
        dispatch(
          fetchAgencyUserCommissionRules({
            agencyId: currentUser.agency_id,
            userId: agentId,
          })
        ).then((e) => {
          let rules = (e.payload || []) as AgentCommissionRule[];
          setCommissionRules(rules);

          let selectedRule;

          if (defaulRuleId) {
            selectedRule = rules.find((x: AgentCommissionRule) => x.id === defaulRuleId);
          } else {
            selectedRule = rules.find((x: AgentCommissionRule) => x.is_default);
          }

          setSelectedCommissionRule(selectedRule);
          methods.setValue("agent_commission_rule_id", selectedRule!.id!);
        
          setLoadingRules(false);
        });
  };
  const onSubmitInvalid: SubmitErrorHandler<EditPolicy> = (data) => {
    toast(t("PLEASE_ENTER_ALL_POLICY_REQUIRED_FIELDS"));
  };

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    const payload: EditCommissionRecordPayload = {
      id: +info.policy.id,
      agent_id: +data.agent_id,
      agent_commission_rule_id: +data.agent_commission_rule_id
    };

    setIsPolicyUpdateLoading(true);

    dispatch(editCommissionRecord({ body: payload, agencyId: currentUser.agency_id })).then((e) => {
      if (e.type === "commissions/editCommissionRecord/rejected") {
        let error = e.payload as any;
        Toast(
          t(error.reason ?? "SOMETHING_WENT_WRONG"),
          t(error.cause_info)
        );
        setIsPolicyUpdateLoading(false);
      } else {
        toast(t("SAVED_SUCCESFULLY"));
        setIsPolicyUpdateLoading(false);
        closeModal();
      }
    });
  };

  const closeModal = () => {
    reset();
    setSelectedCommissionRule(undefined);
    setCommissionRules([]);
    setSelectedPossibleClient(null);
    setSelectedClientPolicies([]);
    onClose();
  };

  return (
    <>
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <Modal
        isOpen={info.show}
        onClose={closeModal}
        label={t("EDIT_COMMISSION")}
        saveButton={{
          disabled: loadingRules,
          label: t("SAVE"),
          icon: <PaperAirplaneIcon />,
          onClick: handleSubmit(onSubmit, onSubmitInvalid),
        }}

      >
        {isPolicyUpdateLoading && <LoadingMask />}
        <div className="w-full flex flex-wrap">
          <Fragment>
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("POLICY_NUMBER")}
              name={`policy_number`}
              isDisabled
              isRequired
            />
            <ReactiveFormSelect
              className="md:w-1/3"
              name={`carrier_id`}
              label={t("CARRIER")}
              options={agencyCarriers.map((c) => { return {label: c.value, value: `${c.id}`} })}
              control={control}
              isRequired
              isDisabled
            />

            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("CLIENT_NAME")}
              name={`client`}
              isDisabled={true}
            />
          </Fragment>
          <Fragment>
            <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_id`}
                label={t("AGENT")}
                options={agents.map((x: User) => {
                  return {
                    label: `${x.first_name} ${x.last_name}`,
                    value: `${x.id}`,
                  };
                })}
                control={control}
                handleOnChange={(e: any) => {
                  loadCommissionRules(+e.target.value);
                }}
                isRequired
            />

              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_commission_rule_id`}
                label={t("AGENT_COMMISSION_RULE")}
                options={commissionRules.map((r: AgentCommissionRule) => {
                  return {
                    label: `${r.name} - ${
                      r.is_relative ? r.value! * 100 + "%" : "$" + r.value
                    }`,
                    value: `${r.id}`,
                  };
                })}
                control={control}
                isDisabled={
                  loadingRules
                }
                isRequired
                handleOnChange={(e: any) =>
                  setSelectedCommissionRule(
                    commissionRules.find(
                      (x: AgentCommissionRule) => x.id === +e.target.value
                    )
                  )
                }
              />

              {selectedCommissionRule !== undefined && (
                <p className="text-slate-500 text-xs px-3">
                  {selectedCommissionRule.description}
                </p>
              )}
            </Fragment>
        </div>
      </Modal>
    </>
  );
};
