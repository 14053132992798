import { Bars3Icon, BellIcon, DeviceTabletIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AgencyPath,
  AutomationPath,
  CampaignPath,
  ClientsPath,
  CommissionsPath,
  ConversationsPath,
  DashboardPath,
  DocumentsPath,
  LeadsPath,
  MetadataPath,
  NewAutomationPath,
  NewClientPath,
  NewLeadPath,
  NewUserPath,
  PhonePath,
  PoliciesPath,
  PolicySellsReportPath,
  ProfilePath,
  UpdateAutomationPath,
  UpdateClientPath,
  UpdateLeadPath,
  UpdateUserPath,
  UsersPath,
} from "../../constants/Routes";
import { RoleLabel } from "../../enums/Role";
import { TwilioDeviceState } from "../../enums/TwilioDeviceState";
import Button from "../Button";
import { Keypad } from "../Calls/Keypad";
import { RootState } from "../../state/store";
import { ViewNotificationRemindersModal } from "../dashboard/reminders/ViewNotificationRemindersModal";
import { BellAlertIcon } from "@heroicons/react/24/solid";

type Props = {
  onMenuButtonClick(): void;
};

const Navbar = (props: Props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const currentUser = useSelector((state: RootState) => state.users.currentUser);
  const twilio = useSelector((state: RootState) => state.twilio);
  const navigate = useNavigate();
  const [isKeypadOpen, setIsKeypadOpen] = useState(false);
  const notificationReminders = useSelector((state: RootState) => state.reminders.noticationReminders);
  const [newReminderNotifications, setNewReminderNotifications] = useState(0);
  const [isNotificationROpen, setIsNotificationROpen] = useState(false);

  useEffect(() => {
    if(notificationReminders.length > 0) {
      setNewReminderNotifications(notificationReminders.length);
    } else {
      setNewReminderNotifications(0);
    }
  }, [notificationReminders]);

  const openKeypad = useCallback(() => {
    setIsKeypadOpen((prevValue) => !prevValue);
  }, []);

  const openNotificationR = useCallback(() => {
    setIsNotificationROpen((prevValue) => !prevValue);
  }, []);

  const getTitle = useMemo(() => {
    switch (location.pathname) {
      case ClientsPath:
        return t("CLIENTS");
      case NewClientPath:
        return t("NEW_CLIENT");
      case ConversationsPath:
        return t("CONVERSATION");
      case PhonePath:
        return t("PHONE");
      case LeadsPath:
        return t("LEADS");
      case NewLeadPath:
        return t("NEW_LEAD");
      case DocumentsPath:
        return t("DOCUMENTS");
      case UsersPath:
        return t("USERS");
      case NewUserPath:
        return t("NEW_USER");
      case ProfilePath:
        return t("PROFILE");
      case UpdateClientPath:
        return t("UPDATE_CLIENT");
      case UpdateLeadPath:
        return t("UPDATE_LEAD");
      case UpdateUserPath:
        return t("UPDATE_USER");
      case AutomationPath:
        return t("LEAD_AUTOMATIONS");
      case NewAutomationPath:
        return t("NEW_LEAD_AUTOMATION");
      case UpdateAutomationPath:
        return t("UPDATE_LEAD_AUTOMATION");
      case AgencyPath:
        return t("AGENCY");
      case DashboardPath:
        return t("DASHBOARD");
      case MetadataPath:
        return t("METADATA");
      case CommissionsPath:
        return t("COMMISSIONS");
      case PolicySellsReportPath:
        return t("POLICY_SELLS_REPORT");
      case PoliciesPath:
        return t("POLICIES");
      case CampaignPath:
        return t("CAMPAIGNS");
    }
  }, [location, t]);

  return (
    <nav
      className={classNames({
        "bg-white text-zinc-500": true, // colors
        "flex items-center": true, // layout
        "w-screen md:w-full sticky z-10 px-4 py-[15px] shadow-sm h-[73px] top-0 ":
          true, //positioning & styling
      })}
    >
      <div className="font-bold text-blue text-lg">{getTitle}</div>
      <div className="flex-grow"></div>
      <div className="relative flex lg:justify-center lg:items-center gap-2 mr-2 cursor-pointer">
        <Button
          isDisabled={twilio.twilioDeviceState !== TwilioDeviceState.READY}
          iconOnly
          onClick={openKeypad}
          isTerciary
        >
          <div className="w-6 aspect-square">
            <DeviceTabletIcon />
          </div>
        </Button>
        {isKeypadOpen && <Keypad closeKeypad={openKeypad} />}
      </div>
      <div className="relative flex lg:justify-center lg:items-center gap-2 mr-2">
        <Button
          classNames="cursor-pointer"
          iconOnly
          onClick={openNotificationR}
          isTerciary
        >
          <div className="w-6 aspect-square">
            {
              newReminderNotifications > 0 &&
              <div>
                <BellAlertIcon className="text-blue" />
                <span
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'red',
                    color: 'white',
                    borderRadius: '50%',
                    padding: '2px 8px',
                    fontSize: '14px',
                    fontWeight: 'bold'
                  }} 
                >{newReminderNotifications}</span></div>
            }
            {newReminderNotifications === 0 && <BellIcon />}
          </div>
        </Button>
        {isNotificationROpen && <ViewNotificationRemindersModal closeModal={openNotificationR} notificationReminders={notificationReminders} />}
      </div>
      <button
        className="hidden md:block bg-asureis-gray-2 rounded-full text-start px-4 py-1 text-blue min-h-full"
        onClick={() => navigate(ProfilePath)}
      >
        <div className="text-xs leading-[auto]">{`${
          currentUser?.first_name ?? ""
        } ${currentUser?.last_name ?? ""}`}</div>
        <div className="text-[0.625rem] leading-[auto]">
          {RoleLabel(`${currentUser?.role_id ?? ""}`)}
        </div>
      </button>
      <button className="md:hidden" onClick={props.onMenuButtonClick}>
        <Bars3Icon className="h-6 w-6" />
      </button>
    </nav>
  );
};
export default Navbar;
