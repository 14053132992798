import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form } from "../form/Form";
import { ReactiveFormRadioButton } from "../form/ReactiveFormRadioButton";
import { ReactiveFormInput } from "../form/ReactiveFormInput";
import { ReactiveFormSelect } from "../form/ReactiveFormSelect";
import { ReactiveFormPhoneInput } from "../form/ReactiveFormPhoneInput";
import { getCountries } from "../../i18n/countries";
import { getGenders } from "../../enums/Gender";
import { getMaritalStatuses } from "../../enums/MaritalStatus";
import { Client } from "../../models/Client";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import { SpinCircle } from "../SpinCircle";
import classNames from "classnames";
import { Lead } from "../../models/Lead";
import { ClientInformationAssignAgentModal } from "./ClientInformationAssignAgentModal";
import { RoleEnum } from "../../enums/Role";
import { AppDispatch } from "../../state/store";
import { checkClientPhoneNumber, updateClient } from "../../state/clients/actions";
import { Toast } from "../Toast";
import { toast, ToastContainer } from "react-toastify";
import { LoadingMask } from "../LoadingMask";
import { ClientExists } from "../../models/ClientExists";
import { ClientExistsConfirmationModal } from "./ClientExistsConfirmationModal";
import { ClientInformationModal } from "./ClientInformationModal";

interface Props {
    client: Client | null
    lead: Lead | null
    isNewClient: boolean
    clientHasChange?: (client:Client, formIsValid:boolean) => void
    clientExistsHasChange?: (exists: boolean, clientPhone?: ClientExists) => void
    submitNewClient?: (client: Client) => void
    submitUpdateClient?: (client: Client) => void
}

export const ClientInformationForm = (props: Props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const [isLoading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const [clientPhoneNumber, setClientPhoneNumber] = useState(null);
    const [isPhoneNumberEnter, setIsPhoneNumberEnter] = useState(false);
    const [clientPhoneAlreadyExist, setClientPhoneAlreadyExist] = useState(false);
    const [clientExistsWithPhone, setClientExistsWithPhone] = useState<ClientExists>();
    const [phoneNumberExist, setPhoneNumberExist] = useState<string>();

    const [showClientInformationModal, setShowClientInformationModal] = useState<{
        client?: ClientExists;
        currentUser?: any;
        show: boolean;
    }>({ show: false, client: undefined });

    const clients = useSelector((state: any) => state.clients.clients);
    const users = useSelector((state: any) => state.users.users);
    const [assignToAgent, setAssignToAgent] = useState<any>(null);

    const error = useSelector((state: any) => state.clients.error);
    const [showClientError, setShowClientError] = useState(false);
    
    useEffect(() => {
        if (showClientError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showClientError, error, t]);

    const [clientInformationAssignAgentModal, setClientInformationAssignAgentModal] = useState<{
        show: boolean;
        agents?: any;
    }>({ show: false, agents: [] });

    type ClientInput = {
        first_name: string;
        middle_name?: string;
        last_name: string;
        second_last_name?: string;
        email: string;
        phone: string;
        date_of_birth: Date;
        gender_id?: string;
        marital_status_id?: string;
        country_of_birth?: string;
        weight?: number;
        height?: number;
        referred_by?: string;
        doctor_full_name?: string;
        doctor_city?: string;
        tobacco?: boolean;
        po_box?: boolean;
        file_tax_return?: boolean;
    }
    const methods = useForm<Client>({
        mode: 'onChange',
        defaultValues: props.client ? props.client
            : props.lead 
            ? {
                first_name: props.lead.first_name,
                middle_name: undefined,
                last_name: props.lead.last_name,
                second_last_name: undefined,
                email: props.lead.email,
                phone: props.lead.phone,
                date_of_birth: props.lead.dob,
                gender_id: undefined,
                marital_status_id: undefined,
                country_of_birth: undefined,
                weight: undefined,
                height: undefined,
                referred_by: undefined,
                doctor_full_name: undefined,
                doctor_city: undefined,
                tobacco: undefined,
                po_box: undefined,
                file_tax_return: undefined
            } : {
                first_name: "",
                middle_name: undefined,
                last_name: "",
                second_last_name: undefined,
                email: "",
                phone: "",
                date_of_birth: undefined,
                gender_id: undefined,
                marital_status_id: undefined,
                country_of_birth: undefined,
                weight: undefined,
                height: undefined,
                referred_by: undefined,
                doctor_full_name: undefined,
                doctor_city: undefined,
                tobacco: undefined,
                po_box: undefined,
                file_tax_return: undefined
            }
    });

    const { handleSubmit, control, watch, trigger, getValues, formState: { isValid }}  = methods;

    useEffect(() => {
        const { unsubscribe } = watch(async() => {
          if(props.isNewClient && props.clientHasChange) {
            const valid = await trigger();
            const values = getValues();
            props.clientHasChange(values as Client, valid)
        }
        })
        return () => unsubscribe()
      }, [watch, trigger, getValues])

    const onSubmit: SubmitHandler<Client> = async (data: Client) => {
        if (props.isNewClient) {
            if (props.submitNewClient) {
                if (assignToAgent !== null) {
                    data.assigned_to = assignToAgent.id;
                }
                props.submitNewClient(data)
            }
        }
        else {
            setLoading(true);
            //TODO:- Implement update client
            dispatch(updateClient({client: data, clientId: props.client?.id!})).then((e) => {
                if (e.type === "clients/updateClient/rejected") {
                    setLoading(false)
                    setShowClientError(true);
                } else {
                    let client = (e.payload || {}) as Client;
                    setLoading(false)
                    toast(t("SUCCESSFULLY_UPDATE_CLIENT"));
                    if(props.submitUpdateClient) {
                        props.submitUpdateClient(client);
                    }
                }
            });
        }
    };

    useEffect(() => {
        async function checkPhoneNumber() {
            const phone = "1" + clientPhoneNumber;
            const result = await dispatch(
                checkClientPhoneNumber({ phone: phone })
            ).unwrap();
            if (result.length > 0) {
                const client = result[0];
                setClientPhoneAlreadyExist(true);
                setClientExistsWithPhone(client);
                setPhoneNumberExist(phone);
        
                toast(t("CLIENT_WITH_PHONE_NUMBER_ALREADY_EXIST"));
                window.scrollTo(0, 0);

                if(props.clientExistsHasChange) {
                    props.clientExistsHasChange(true, client);
                }
            } else {
                setClientPhoneAlreadyExist(false);
                setClientExistsWithPhone(undefined);
                setPhoneNumberExist("");

                if(props.clientExistsHasChange) {
                    props.clientExistsHasChange(false, undefined);
                }
            }
        
            setIsPhoneNumberEnter(false);
        }
    
        if (clientPhoneNumber != null && isPhoneNumberEnter) {
            checkPhoneNumber();
        }
    }, [clientPhoneNumber, isPhoneNumberEnter]);

    const handleChangePhone = (phone: any) => {
        if (phone.length == 10) {
            setClientPhoneNumber(phone);
        }
    };

    const handleBlurPhone = (e: any) => {
        setIsPhoneNumberEnter(true);
    };

    const showClientExistsInformation = () => {
        setShowClientInformationModal({
          show: true,
          client: clientExistsWithPhone,
          currentUser: currentUser,
        });
    };

    const getClients = useMemo(() => {
        return clients.map((client: Client) => {
            return {
                label: `${client.first_name} ${client.last_name}`,
                value: `${client.first_name} ${client.last_name}`,
                key: client.id,
            };
        });
    }, [clients]);

    const agents = useMemo(() => {
        return users.map((x: any) => {
          return {
            label: `${x.first_name} ${x.last_name}`,
            value: x.id,
          };
        });
    }, [users]);

    return (
        <>
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            { isLoading && <LoadingMask />}
            <Form handleOnSubmit={handleSubmit(onSubmit)}>
                {clientPhoneAlreadyExist && (
                    <div className="w-full grow px-3 pb-6 flex flex-col">
                        <div className="p-5 bg-amber-400 rounded-lg shadow grid justify-items-center">
                            <div className="text-white text-lg text-center font-medium">
                                {`${t("A_CLIENT_WITH_PHONE")}`}{" "}
                                <label
                                    className="underline cursor-pointer"
                                    onClick={() => showClientExistsInformation()}
                                    >{`+${phoneNumberExist}`}</label>{" "}
                                {`${t("IS_BEING_CONTACTED_BY", {
                                    agentName: clientExistsWithPhone?.agent.first_name,
                                    agentLastName: clientExistsWithPhone?.agent.last_name,
                                })}`}
                            </div>
                        </div>
                    </div>
                )}
                <div className="text-xl font-bold text-blue w-full p-3">
                    {t("PERSONAL_DETAILS")}
                </div>
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/2 lg:w-1/3"
                    label={t("FIRST_NAME")}
                    name="first_name"
                    isRequired
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/2 lg:w-1/3"
                    label={t("MIDDLE_NAME")}
                    name="middle_name"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/2 lg:w-1/3"
                    label={t("LAST_NAME")}
                    name="last_name"
                    isRequired
                />
                <ReactiveFormPhoneInput
                    control={control}
                    className="md:w-1/2 lg:w-1/3"
                    name="phone"
                    label={t("PHONE")}
                    handleOnChange={handleChangePhone}
                    handleOnBlur={handleBlurPhone}
                    isRequired
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/2 lg:w-1/3"
                    label={t("EMAIL")}
                    name="email"
                    type="email"
                    isRequired
                />
                <ReactiveFormInput
                    control={control}
                    type="date"
                    className="md:w-1/2 lg:w-1/3"
                    label={t("DATE_OF_BIRTH")}
                    name="date_of_birth"
                    isRequired
                />
                <ReactiveFormSelect
                    className="md:w-1/2 lg:w-1/3"
                    name="country_of_birth"
                    label={t("COUNTRY_OF_BIRTH")}
                    options={getCountries()}
                    control={control}
                />
                <ReactiveFormSelect
                    className="md:w-1/2 lg:w-1/3"
                    name="gender_id"
                    label={t("GENDER")}
                    options={getGenders()}
                    control={control}
                />
                <ReactiveFormSelect
                    className="md:w-1/2 lg:w-1/3"
                    name="marital_status_id"
                    label={t("MARITAL_STATUS")}
                    options={getMaritalStatuses()}
                    control={control}
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/2 lg:w-1/3"
                    label={t("HEIGHT")}
                    name="height"
                    type="number"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/2 lg:w-1/3"
                    label={t("WEIGHT")}
                    name="weight"
                    type="number"
                />
                <ReactiveFormSelect
                    control={control}
                    className={"w-full px-3 md:w-1/2 lg:w-1/3"}
                    name="referred_by"
                    label={t("REFERENCE")}
                    options={getClients}
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/2 lg:w-1/3"
                    label={t("DOCTOR_FULL_NAME")}
                    name="doctor_full_name"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/2 lg:w-1/3"
                    label={t("DOCTOR_CITY")}
                    name="doctor_city"
                />
                <ReactiveFormRadioButton
                    className={"w-full px-3 md:w-1/2 lg:w-1/3"}
                    name="tobacco"
                    label={t("4_OR_MORE_TOBACCO")}
                    control={control}
                    options={[
                        { label: t("YES"), value: "1" },
                        { label: t("NO"), value: "0" },
                    ]}
                />
                <ReactiveFormRadioButton
                    className={"w-full px-3 md:w-1/2 lg:w-1/3"}
                    name="po_box"
                    label={t("PO_BOX")}
                    control={control}
                    options={[
                        { label: t("YES"), value: "1" },
                        { label: t("NO"), value: "0" },
                    ]}
                />
                <ReactiveFormRadioButton
                    className={"w-full px-3 md:w-1/2 lg:w-1/3"}
                    name="file_tax_return"
                    label={t("WILL_YOU_FILE_TAX_RETURN")}
                    control={control}
                    options={[
                        { label: t("YES"), value: "1" },
                        { label: t("NO"), value: "0" },
                    ]}
                />

                <div className="w-full flex items-center justify-end px-3 pb-1">
                    {
                       (props.isNewClient && currentUser && (currentUser.role_id === RoleEnum.ADMIN || currentUser.role_id == RoleEnum.SUPER_ADMIN)) &&
                        <div className="flex flex-col mr-5">
                            <label className="grid justify-items-end text-gray-600">{`${t("AGENT_OF_RECORD")}:`}</label>
                            <label
                                className="grid justify-items-end underline cursor-pointer text-lg text-sky-600"
                                onClick={() => setClientInformationAssignAgentModal({ show: true, agents: agents })}
                            >
                                {   
                                    assignToAgent !== null
                                    ? `${assignToAgent?.first_name ?? ""} ${assignToAgent?.last_name ?? ""}`
                                    : `${currentUser?.first_name ?? ""} ${currentUser?.last_name ?? ""}`
                                }
                            </label>
                        </div>
                    }
                    <Button
                        classNames='w-full text-lg font-semibold py-2 flex flex-row justify-center mx-3'
                        isTypeSubmit
                        isDisabled={isLoading}>
                        <div className={classNames({ 'mr-2': isLoading })}>Save</div>
                        {isLoading && <SpinCircle />}
                    </Button>
                </div>
            </Form>
            <ClientInformationAssignAgentModal
                info={clientInformationAssignAgentModal}
                onSubmited={(selectAgent) => {
                    const sAgent = users.find((e: any) => e.id === selectAgent);
                    if(sAgent.id === currentUser.id) {
                        setAssignToAgent(null);
                    } else {
                        setAssignToAgent(sAgent);
                    }
                }}
                onClose={() => setClientInformationAssignAgentModal({ show: false, agents: [] })}
            />
            <ClientInformationModal
                info={showClientInformationModal}
                onClose={() =>
                    setShowClientInformationModal({ show: false, client: undefined })
                }
            />
        </>
    )
}