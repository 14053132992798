import {
    ChatBubbleBottomCenterIcon,
    DocumentArrowDownIcon,
    DocumentArrowUpIcon,
    FunnelIcon,
    PencilIcon,
    PhoneIcon,
    PlayIcon,
    TrashIcon,
    UserPlusIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { LoadingMask } from "../../components/LoadingMask";
import {
    ConversationsPath,
    NewLeadPath,
    UpdateLeadPath,
} from "../../constants/Routes";
import { LeadStatusEnum, LeadStatusLabel } from "../../enums/LeadStatus";
import { Modal } from "../../components/Modal";
import { ReactiveFormUploadFile } from "../../components/form/ReactiveFormUploadFile";
import { useForm } from "react-hook-form";
import * as xlsx from "xlsx";
import { Lead, NewUploadLead } from "../../models/Lead";
import { AppDispatch, RootState } from "../../state/store";
import { ToastContainer, toast } from "react-toastify";
import { assignLeadFromPool, fetchLeadsPool, pastPage, pastPoolPage, startLeadAutomation, uploadAgencyLeads, uploadAgentLeads } from "../../state/leads/actions";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { Toast } from "../../components/Toast";
import { TwilioDeviceState } from "../../enums/TwilioDeviceState";
import {
    createConversation,
    fetchConversationMessages,
    fetchConversations,
} from "../../state/twilio/actions";
import { ConversationType } from "../../enums/ConversationType";
import uploadLeadTemplate from "../../assets/templates/uploadLeadTemplate.xlsx";
import { RoleEnum } from "../../enums/Role";
import { dateFormat, shortDateFormat } from "../../utils/functions";
import { LeadFilterModal } from "./LeadFilterModal";
import { AssingLeadPoolModal } from "../../components/leads/AssignLeadPoolModal";
import { MessageTypeEnum } from "../../enums/MessageType";
import { SuccessErrorModal } from "../../components/SuccessErrorModal";

interface Props {
    makePhoneCall: (phone: string) => void;
}
export const LeadsPool = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const pageLeads = useSelector((state: RootState) => state.leads.pageLeadsPool);
    const isLoading = useSelector((state: any) => state.leads.isLoading);
    const error = useSelector((state: any) => state.leads.error);
    const paginatedOptions = useSelector((state: RootState) => state.leads.paginatedOptionsPool);
    const filters = useSelector((state: any) => state.leads.filters);
    const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
    const [isUploadingLeads, setIsUploadingLeads] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const [isOpenStartAutomationModal, setIsOpenStartAutomationModal] =
        useState(false);
    const [isStartingAutomation, setIsStartingAutomation] = useState(false);
    const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
    const automations = useSelector(
        (state: any) => state.automations.automations
    );
    const twilioChatState = useSelector(
        (state: any) => state.twilio.areTwilioCredentialsValid
    );
    const deviceState = useSelector(
        (state: any) => state.twilio.twilioDeviceState
    );
    const twilio = useSelector((state: any) => state.twilio);
    const [showError, setShowError] = useState(false);

    const [ showAssignLeadPoolModal, setShowAssignLeadPoolModal] = useState<{
        lead?: Lead;
        show: boolean;
    }>({ show: false, lead: undefined });

    const [showSuccessErrorModal, setShowSuccessErrorModal] = useState<{
        show: boolean;
        messageType?: number;
        message?: string;
    }>({ show: false, messageType: undefined, message: undefined });

    useEffect(() => {
        if (showError) {
            setShowSuccessErrorModal({
                show: true,
                messageType: MessageTypeEnum.FAILED,
                message: t(error?.cause_info ?? "SOMETHING_WENT_WRONG")
            });
        }
    }, [showError, error, t]);

    const methods = useForm<{}>();
    const { control } = methods;

    const startMethods = useForm<{}>();
    const { handleSubmit: handleStartSubmit, control: startControl } =
        startMethods;

    const makeConversation = useCallback(
        (phone: string) => {
            if (twilio.conversations && currentUser) {
                const selectedConversation = twilio.conversations.find(
                    (x: any) => x.friendlyName === phone
                );
                if (selectedConversation) {
                    dispatch(
                        fetchConversationMessages({
                            conversationSid: selectedConversation.sid,
                        })
                    );
                } else {
                    dispatch(
                        createConversation({
                            newConversation: {
                                from: currentUser.main_twilio_number?.twilio_phone,
                                to: phone,
                            },
                            conversationType: ConversationType.LEADS,
                        })
                    );
                }
                navigate(ConversationsPath);
            }
        },
        [twilio.conversations, currentUser, dispatch, navigate]
    );

    const columns = useMemo(() => {
        const showColumns = [
            {
                Header: t("NAME"),
                Cell: (row: any) => {
                    const { first_name, last_name } = row.row.original;
                    return `${first_name ?? ""} ${last_name ?? ""}`;
                },
            },
            {
                Header: t("PHONE"),
                accessor: "phone",
            },
            {
                Header: t("EMAIL"),
                accessor: "email",
            },
            {
                Header: t("STATUS"),
                Cell: (row: any) => LeadStatusLabel(`${row.row.original.status_id}`),
            }
        ];

        const actions = {
            Header: " ",
            Cell: (row: any) => {
                return (
                    <div className="flex flex-row justify-center divide-x">
                        
                        <Button
                            isTerciary
                            isDisabled={!twilioChatState}
                            tooltip={t("START_CONVERSATION")}
                            iconOnly
                            onClick={() => makeConversation(row.row.original.phone)}
                            classNames="pl-2"
                        >
                            <ChatBubbleBottomCenterIcon className="h-5 aspect-square stroke-gold" />
                        </Button>
                        <Button
                            isTerciary
                            isDisabled={deviceState !== TwilioDeviceState.READY}
                            tooltip={t("CALL_LEAD")}
                            iconOnly
                            onClick={() => props.makePhoneCall(row.row.original.phone)}
                            classNames="pl-2"
                        >
                            <PhoneIcon className="h-5 aspect-square stroke-gold" />
                        </Button>
                        <Button
                            isTerciary
                            tooltip={t("ASSIGN_LEAD")}
                            iconOnly
                            onClick={() => setShowAssignLeadPoolModal({show : true, lead: row.row.original})}
                            classNames="pl-2"
                        >
                            <UserPlusIcon className="h-5 aspect-square stroke-blue" />
                        </Button>
                        
                    </div>
                );
            },
        };

        return [...showColumns, actions];
    }, [t, twilioChatState, deviceState]
    );

    const canRunAutomation = (row: any) => {
        if (row.status_id === LeadStatusEnum.CLIENT) {
            return true;
        } else {
            const conversation = twilio.conversations.find(
                (conv: any) => conv.friendlyName === row.phone
            );
            if (conversation !== undefined) {
                if (conversation.attributes["isInAutomation"]) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    };

    const updateLead = (row: any) => {
        navigate(UpdateLeadPath, { state: { lead: row } });
    };

    const openUploadLeadsModal = () => {
        setIsOpenUploadModal(true);
    };

    const closeUploadLeadsModal = () => {
        setIsOpenUploadModal(false);
        setSelectedFile(null);
    };

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = link.href = uploadLeadTemplate;
        link.download = "uploadLeadsTemplate.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const showUploadLeadsModal = () => {
        return (
            <Modal
                thirdButton={{
                    label: t("UPLOAD_EXAMPLE"),
                    onClick: () => handleDownload(),
                    icon: <DocumentArrowDownIcon />,
                }}
                isOpen={isOpenUploadModal}
                onClose={closeUploadLeadsModal}
                label={t("UPLOAD_LEADS")}
                saveButton={{
                    label: t("UPLOAD"),
                    icon: <DocumentArrowUpIcon />,
                    onClick: handleSubmitSendDocument,
                }}
            >
                {isUploadingLeads && <LoadingMask />}
                <div className="w-full flex flex-wrap">
                    {!selectedFile && (
                        <ReactiveFormUploadFile
                            control={control}
                            className="md:w-1/1"
                            label={t("LEADS_FILE")}
                            name="leads_file"
                            handleOnChange={handlePlanQuotedFiles}
                        />
                    )}
                    {selectedFile && (
                        <div className="w-full px-4 mb-3">
                            <div className="flex justify-between">
                                <label className="text-base font-semibold self-center">
                                    {selectedFile.name}
                                </label>
                                <Button
                                    iconOnly
                                    classNames="h-7 w-7 self-end"
                                    onClick={handleRemoveUploadLeadFile}
                                >
                                    <TrashIcon className="h-3 w-3" />
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        );
    };

    const handlePlanQuotedFiles = (e: any) => {
        setSelectedFile(e[0]);
    };

    const handleRemoveUploadLeadFile = () => {
        setSelectedFile(null);
    };

    const handleSubmitSendDocument = async () => {
        setIsUploadingLeads(true);
        const reader = new FileReader();
        reader.readAsBinaryString(selectedFile);
        reader.onload = (e: any) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, {
                type: "binary",
                cellDates: true,
                cellNF: false,
                cellText: false,
            });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = xlsx.utils.sheet_to_json(sheet);
            uploadFileLeads(parsedData);
        };
    };

    const uploadFileLeads = async (xlsxData: any[]) => {
        const leads = xlsxData.map((row) => {
            const d: NewUploadLead = {
                first_name: row["FirstName"],
                last_name: row["LastName"],
                dob: row["DOB"] != undefined ? dateFormat(row["DOB"]) : undefined,
                phone: `+${row["Phone"]}`,
                email: row["Email"],
                address: row["Address"],
                state: row["State"],
                city: row["City"],
                zip_code: row["ZipCode"],
                source_id: row["Source"],
                referred_by: row["ReferredBy"],
                referred_amount: row["ReferredAmount"],
                automation_code: row["AutomationCode"],
                campaign_code: row["CampaignCode"]
            };
            return d;
        });

        if (currentUser.role_id === RoleEnum.AGENT) {
            dispatch(uploadAgentLeads(leads)).then((e) => {
                if (e.type === "leads/uploadAgentLeads/rejected") {
                    setShowError(true);
                    setIsUploadingLeads(false);
                } else {
                    toast(t("SAVED_SUCCESFULLY"));
                    closeUploadLeadsModal();
                    setIsUploadingLeads(false);
                    //navigate(LeadsPath)
                }
            });
        } else {
            dispatch(uploadAgencyLeads({ agencyId: currentUser.agency.id, isFromPool: true, leads: leads })).then((e) => {
                if (e.type === "leads/uploadAgencyLeads/rejected") {
                    setShowError(true);
                    setIsUploadingLeads(false);
                } else {
                    toast(t("SAVED_SUCCESFULLY"));
                    closeUploadLeadsModal();
                    setIsUploadingLeads(false);
                    //navigate(LeadsPath)
                }
            });
        }
    };

    const openStartAutomationModal = (lead: Lead) => {
        setSelectedLead(lead);
        setIsOpenStartAutomationModal(true);
    };

    const closeStartAutomationModal = () => {
        setIsOpenStartAutomationModal(false);
        setSelectedFile(null);
    };

    const showRunAutomationModal = () => {
        return (
            <Modal
                isOpen={isOpenStartAutomationModal}
                onClose={closeStartAutomationModal}
                label={t("RUN_AUTOMATION")}
                onSave={handleStartSubmit(onStartAutomationSubmit)}
            >
                {isStartingAutomation && <LoadingMask />}
                <div className="w-full flex flex-wrap">
                    <div className="w-full">
                        <ReactiveFormSelect
                            className="md:w-1/1"
                            name={`automation_id`}
                            label={t("AUTOMATION")}
                            options={getAutomations}
                            control={startControl}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    const onStartAutomationSubmit = (data: any) => {
        setIsStartingAutomation(true);

        dispatch(
            startLeadAutomation({
                phone: selectedLead!.phone,
                automationId: data.automation_id!,
            })
        ).then((e) => {
            if (e.type === "leads/startAutomation/rejected") {
                setShowError(true);
                setIsStartingAutomation(false);
            } else {
                toast(t("AUTOMATION_STARTED_SUCCESFULLY"));
                dispatch(
                    fetchConversations(currentUser.main_twilio_number?.twilio_phone)
                );
                closeStartAutomationModal();
                setIsStartingAutomation(false);
                setSelectedLead(null);
            }
        });
    };

    const getAutomations = useMemo(() => {
        return automations.map((a: any) => {
            return {
                label: `${a.name}`,
                value: `${a.id}`,
                key: a.id,
            };
        });
    }, [automations]);

    const getPaginationOptions = useMemo(() => {
        return {
            page: paginatedOptions.page,
            pageSize: paginatedOptions.pageSize,
            totalPages: paginatedOptions.totalPages,
            totalCount: paginatedOptions.totalCount,
            setPage: (value: number) => {
                value < paginatedOptions.page
                    ? dispatch(pastPoolPage())
                    : dispatch(fetchLeadsPool({ page: value, pageSize: paginatedOptions.pageSize, filters: filters }));
            },
            setPageSize: (value: number) => dispatch(fetchLeadsPool({ page: paginatedOptions.page, pageSize: value, filters: filters })),
        };
    }, [dispatch, paginatedOptions]);

    const onAssignLead = (lead: Lead) => {
        console.log(lead);
        dispatch(assignLeadFromPool({leadId: lead!.id})).then((e) => {
            if (e.type === "leads/assignLeadFromPool/rejected") {
                setShowError(true);
            } else {
                setShowSuccessErrorModal({
                    show: true,
                    messageType: MessageTypeEnum.SUCCESS,
                    message: t("SUCCESSFULLY_ASSING_LEAD")
                });
            }
        });
    };

    const handleCloseSuccessErrorModal = () => {
        if (showSuccessErrorModal.messageType === MessageTypeEnum.SUCCESS) {
            setShowSuccessErrorModal({
                show: false,
                messageType: undefined,
                message: undefined,
            });
            navigate(-1);
        } else if (showSuccessErrorModal.messageType === MessageTypeEnum.FAILED) {
            setShowSuccessErrorModal({
                show: false,
                messageType: undefined,
                message: undefined,
            });
            setShowError(false);
        }
    };

    return (
        <>
            {isLoading && <LoadingMask />}
            {showUploadLeadsModal()}
            {showRunAutomationModal()}
            <SuccessErrorModal
                info={showSuccessErrorModal}
                onClose={handleCloseSuccessErrorModal}
            />
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            <FilteredTable
                columns={columns}
                data={pageLeads}
                isPaginated
                paginationOptions={getPaginationOptions}
                hasSearchBar={false}
                buttonAction={
                    <Button
                        onClick={() => setFilterModalOpen(true)}
                        iconOnly
                        classNames="w-9 p-1 aspect-square mr-4 "
                    >
                        <FunnelIcon className=""></FunnelIcon>
                    </Button>
                }
                /*secondaryActionButton={
                    <Button onClick={() => navigate(NewLeadPath)}>
                        <span className="flex items-center font-semibold pr-3">
                            <div className="w-8 p-1 aspect-square mr-2">
                                <UserPlusIcon />
                            </div>
                            {t("NEW_LEAD")}
                        </span>
                    </Button>
                }*/
                tertiaryActionButton={
                    currentUser?.role_id === RoleEnum.SUPER_ADMIN
                    ? <Button
                        isTerciary={true}
                        classNames="ml-3"
                        onClick={openUploadLeadsModal}
                    >
                        <span className="flex items-center font-semibold pr-3">
                            <div className="w-8 p-1 aspect-square mr-2">
                                <DocumentArrowUpIcon />
                            </div>
                            {t("UPLOAD_LEADS")}
                        </span>
                    </Button>
                    : <div></div>
                }
            />
            <LeadFilterModal
                isFilterModalOpen={isFilterModalOpen}
                setFilterModalOpen={() => setFilterModalOpen(false)}
                isPoolFilter={true}
            />
            <AssingLeadPoolModal
                info={showAssignLeadPoolModal}
                onClose={() => setShowAssignLeadPoolModal({show : false, lead: undefined})}
                assignLead={(lead: Lead) => onAssignLead(lead)}
            />
        </>
    );
};