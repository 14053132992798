import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../state/store";
import { Modal } from "../../components/Modal";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Form } from '../../components/form/Form';
import Select from "react-select";
import { RoleEnum } from "../../enums/Role";
import { useEffect, useMemo, useState } from "react";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { useForm } from "react-hook-form";

interface Props {
    isFilterModalOpen: boolean;
    setFilterModalOpen: (x: boolean) => void;
}

export const PolicyFilterModal = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const users = useSelector((state: any) => state.users.users);
    const [currentAgents, setCurrentAgents] = useState<any[]>();

    /*const selectedAgents = useMemo(() => {
        return clientsState.filters?.agents?.map((x: number) => {
            const user = users.find((y: User) => y.id === x);
            return {
                label: `${user.first_name} ${user.last_name}`,
                value: user.id
            }
        })
    }, [clientsState.filters?.agents, users]);

    useEffect(() => {
        setCurrentAgents(selectedAgents)
    }, [selectedAgents]);*/

    const agents = useMemo(() => {
        return users.map((x: any) => {
            return {
                label: `${x.first_name} ${x.last_name}`,
                value: x.id
            }
        })
    }, [users]);

    const methods = useForm<any>({
        defaultValues: {
            carrier: "",//clientsState.filters?.name,
            client: "",//clientsState.filters?.lastName,
            start_date: "",//clientsState.filters?.phone,
            status: "",//clientsState.filters?.email,
            agents: "",//clientsState.filters?.agents
        }
    });

    const { handleSubmit, control, reset } = methods;

    return(
        <Modal isOpen={props.isFilterModalOpen} onClose={() => props.setFilterModalOpen(false)} label={t("FILTERS")} onSave={() => {}} thirdButton={{
            label: t("CLEAR_FILTERS"),
            icon: <TrashIcon />,
            onClick: () =>  {
                //reset({ name: '', lastName: '', phone: '', email: '', gender: '', agents: undefined });
                //setCurrentAgents([]);
            }
        }}>
            <Form handleOnSubmit={() => {}}>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {currentUser?.role_id !== RoleEnum.AGENT &&
                        <div className={"w-full px-3"}>
                            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                                {t("AGENTS")}
                            </label>
                            <Select
                                isMulti
                                isSearchable
                                isClearable
                                isLoading={agents.length === 0}
                                onChange={(inputValue: any) => setCurrentAgents(inputValue)}
                                name="agent"
                                options={agents}
                                value={currentAgents}
                                placeholder="Select agents"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                maxMenuHeight={200}
                                classNames={{
                                    control: (state) =>
                                        'bg-white opacity-90 py-1 px-1.5 rounded shadow w-full min-w-[200px]',
                                }}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: 'grey-50',
                                    }),
                                }}
                            />
                        </div>
                    }
                    <ReactiveFormInput
                        control={control}
                        value={""}
                        label={t("CARRIER")}
                        name="carrier" />
                    <ReactiveFormInput
                        control={control}
                        value={""}
                        label={t("CLIENT")}
                        name="client" />
                    <ReactiveFormInput
                        control={control}
                        value={""}
                        label={t("START_DATE")}
                        name="start_date" />
                    <ReactiveFormInput
                        control={control}
                        value={""}
                        label={t("STATUS")}
                        name="status" />
                </div>
            </Form>
        </Modal>
    )
}