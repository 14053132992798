import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createClientIncomeSource, deleteClientIncomeSource, fetchIncomeSourcesByClientId, needToLoadClientIncomeSource, updateClientIncomeSource } from "./actions";
import { IncomeSource } from "../../models/IncomeSource";

interface IncomeSourceState {
  incomeSources: IncomeSource[],
  isLoading: boolean,
  error: string | null,
  needToLoadIncomes: boolean
}

const initialState: IncomeSourceState = {
  incomeSources: [],
  isLoading: false,
  error: null,
  needToLoadIncomes: true
}

// slice
const incomeSourcesSlice = createSlice({
  name: "leads",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncomeSourcesByClientId.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchIncomeSourcesByClientId.fulfilled, (state, action: PayloadAction<IncomeSource[]>) => {
        state.isLoading = false;
        state.incomeSources = action.payload;
      })
      .addCase(fetchIncomeSourcesByClientId.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createClientIncomeSource.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createClientIncomeSource.fulfilled, (state, action: PayloadAction<IncomeSource>) => {
        state.isLoading = false;
        state.incomeSources = state.incomeSources.concat(action.payload);
      })
      .addCase(createClientIncomeSource.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateClientIncomeSource.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateClientIncomeSource.fulfilled, (state, action: PayloadAction<IncomeSource>) => {
        state.isLoading = false;
        const updatedIncome = action.payload;
        const updatedIncomes = state.incomeSources.map((income) => {
                return income.id === updatedIncome.id ? updatedIncome : income
            }
        );
        state.incomeSources = updatedIncomes;
      })
      .addCase(updateClientIncomeSource.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteClientIncomeSource.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(deleteClientIncomeSource.fulfilled, (state, action: PayloadAction<IncomeSource[]>) => {
        state.isLoading = false;
        state.incomeSources = action.payload;
      })
      .addCase(deleteClientIncomeSource.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(needToLoadClientIncomeSource.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.needToLoadIncomes = action.payload;
        if(action.payload) {
          state.incomeSources = [];
        }
      });
  },
});

export default incomeSourcesSlice.reducer;