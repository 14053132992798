import { useTranslation } from "react-i18next";
import { Beneficiary } from "../../../../models/Beneficiary";
import { Policy } from "../../../../models/Policies";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../state/store";
import { Fragment, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "../../../Modal";
import { ReactiveFormInput } from "../../../form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../../form/ReactiveFormSelect";
import { getRelationships } from "../../../../enums/Relationship";
import { LoadingMask } from "../../../LoadingMask";
import { CheckIcon } from "@heroicons/react/24/outline";

interface Props {
  addBeneficiary: (beneficiary: Beneficiary) => void;
  updateBeneficiary: (index: number, beneficiary: Beneficiary) => void;
  info: {
    show: boolean;
    update: boolean;
    beneficiary?: Beneficiary;
    //policy?: Policy;
    index?: number;
    //isNewClient:boolean;
  };
  onClose: () => void;
}

export const PolicyBeneficiaryModal: React.FC<Props> = ({
  addBeneficiary,
  info,
  onClose,
  updateBeneficiary,
}) => {
  const { t } = useTranslation();

  const methods = useForm<Beneficiary>({
    defaultValues: {},
  });
  const { handleSubmit, control, setValue, getValues, reset } = methods;

  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: any) => state.users.currentUser);

  //const isLoading = useSelector((state: RootState) => state.policies.isLoading);
  //const error = useSelector((state: any) => state.dependents.error);
  //const [showPolicyError, setShowPolicyError] = useState(false);

  /*useEffect(() => {
      if (showPolicyError) {
          Toast(
              t(error?.reason ?? "SOMETHING_WENT_WRONG"),
              t(error?.cause_info)
          );
      }
  }, [showPolicyError, error, t]);*/

  useEffect(() => {
    info.beneficiary ? reset(info.beneficiary) : reset({});
  }, [info.beneficiary, reset]);
  
  const onSubmitInvalid: SubmitErrorHandler<Beneficiary> = (data) => {
    toast(t("PLEASE_ENTER_ALL_POLICY_REQUIRED_FIELDS"));
  }

  const onSubmit: SubmitHandler<Beneficiary> = async (data: Beneficiary) => {
    //if(info.isNewClient) {
      info.update ? updateBeneficiary(Number(info.index), data) : addBeneficiary(data);
      closeModal();
    //}
      /*if(info.isNewClient) {
        info.update ? updatePolicy(Number(info.index), data) : addPolicy(data);
        closeModal();
      } else {
        if(info.update) {
          //TODO: update
          dispatch(updateClientPolicy({policy: data, policyId: info.policy?.id!, clientId: info.client?.id!})).then((e) => {
              if (e.type === "client/updateClientPolicy/rejected") {
                setShowPolicyError(true);
              } else {
                let policy = (e.payload || {}) as Beneficiary;
                toast(t("SUCCESSFULLY_UPDATE_CLIENT_POLICY"));
                updatePolicy(Number(info.index), policy)
                closeModal();
              }
          });
      }
    }*/
  };

  const closeModal = () => {
    reset({});
    onClose();
  };

  return (
    <>
        <Modal
            isOpen={info.show}
            onClose={closeModal}
            label={info.update ? t("UPDATE_BENEFICIARY") : t("ADD_BENEFICIARY")}
            saveButton={{
                label: info.update ? t("UPDATE") : t("ADD"),
                onClick:handleSubmit(onSubmit),
                icon: <CheckIcon />
            }}
        >
            <div className="w-full flex flex-wrap">
                <Fragment>
                    <ReactiveFormInput
                        control={control}
                        className="md:w-1/2"
                        label={t("FIRST_NAME")}
                        name={`first_name`}
                    />
                    <ReactiveFormInput
                        control={control}
                        className="md:w-1/2"
                        label={t("LAST_NAME")}
                        name={`last_name`}
                    />
                    <ReactiveFormInput
                        control={control}
                        type="date"
                        className="md:w-1/2"
                        label={t("DATE_OF_BIRTH")}
                        name={`date_of_birth`}
                    />
                    <ReactiveFormSelect
                        className="md:w-1/2"
                        name={`relationship_id`}
                        label={t("RELATIONSHIP")}
                        options={getRelationships()}
                        control={control}
                    />
                </Fragment>
            </div>
        </Modal>
        <ToastContainer progressStyle={{ background: "#D4AF37" }} />
        {/*isLoading && <LoadingMask />*/}
    </>
  );
};