import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createClientAddress, deleteClientAddress, fetchAddressesByClientId, needToLoadClientAddress, updateClientAddress } from "./actions";
import { Address } from "../../models/Address";

interface AddressState {
  addresses: Address[],
  isLoading: boolean,
  needToLoadAddresses: boolean,
  error: string | null
}

const initialState: AddressState = {
  addresses: [],
  isLoading: false,
  error: null,
  needToLoadAddresses: true
}

// slice
const addressSlice = createSlice({
  name: "addresses",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddressesByClientId.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchAddressesByClientId.fulfilled, (state, action: PayloadAction<Address[]>) => {
        state.isLoading = false;
        state.addresses = action.payload;
      })
      .addCase(fetchAddressesByClientId.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createClientAddress.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createClientAddress.fulfilled, (state, action: PayloadAction<Address>) => {
        state.isLoading = false;
        state.addresses = state.addresses.concat(action.payload);
      })
      .addCase(createClientAddress.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateClientAddress.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateClientAddress.fulfilled, (state, action: PayloadAction<Address>) => {
        state.isLoading = false;
        const updatedAddress = action.payload;
        const updatedAddresses = state.addresses.map((address) => {
                return address.id === updatedAddress.id ? updatedAddress : address
            }
        );
        state.addresses = updatedAddresses;
      })
      .addCase(updateClientAddress.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteClientAddress.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(deleteClientAddress.fulfilled, (state, action: PayloadAction<Address[]>) => {
        state.isLoading = false;
        state.addresses = action.payload;
      })
      .addCase(deleteClientAddress.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(needToLoadClientAddress.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.needToLoadAddresses = action.payload;
        if(action.payload) {
          state.addresses = [];
        }
      });
  },
});

export default addressSlice.reducer;