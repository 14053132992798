import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AllLeads, Lead, LeadResponse, LeadsFilters, UploadLeadResponse } from "../../models/Lead";
import { assignLeadFromPool, createAutomationLead, createLead, fetchLeads, fetchLeadsPool, pastPage, pastPoolPage, startLeadAutomation, updateLead, updateLeadClient, uploadAgencyLeads, uploadAgentLeads } from "./actions";
import { Client } from "../../models/Client";

interface LeadState {
  currentLead? : Lead,
  leads: AllLeads[],
  pageLeads: Lead[],
  leadsPool: AllLeads[],
  pageLeadsPool: Lead[],
  isLoading: boolean,
  error: string | null,
  paginatedOptions: {
    page: number,
    pageSize: number,
    totalPages: number,
    totalCount: number
  },
  paginatedOptionsPool: {
    page: number,
    pageSize: number,
    totalPages: number,
    totalCount: number
  },
  filters?: LeadsFilters
}

const initialState: LeadState = {
  leads: [],
  pageLeads: [],
  leadsPool: [],
  pageLeadsPool: [],
  isLoading: false,
  error: null,
  paginatedOptions: {
    page: 0,
    pageSize: 0,
    totalPages: 0,
    totalCount: 0
  },
  paginatedOptionsPool: {
    page: 0,
    pageSize: 0,
    totalPages: 0,
    totalCount: 0
  }
}

// slice
const leadsSlice = createSlice({
  name: "leads",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeads.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchLeads.fulfilled, (state, action: PayloadAction<LeadResponse>) => {
        state.isLoading = false;
        state.leads = state.leads.concat({pageNumber: action.payload.page, pageLeads: action.payload.leads });
        state.pageLeads = action.payload.leads;
        state.paginatedOptions.page = action.payload.page;
        state.paginatedOptions.pageSize = action.payload.pageSize;
        state.paginatedOptions.totalPages = action.payload.totalPages;
        state.paginatedOptions.totalCount = action.payload.totalCount;
        state.filters = action.payload.filters
      })
      .addCase(fetchLeads.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchLeadsPool.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchLeadsPool.fulfilled, (state, action: PayloadAction<LeadResponse>) => {
        state.isLoading = false;
        state.leadsPool = state.leadsPool.concat({pageNumber: action.payload.page, pageLeads: action.payload.leads });
        state.pageLeadsPool = action.payload.leads;
        state.paginatedOptionsPool.page = action.payload.page;
        state.paginatedOptionsPool.pageSize = action.payload.pageSize;
        state.paginatedOptionsPool.totalPages = action.payload.totalPages;
        state.paginatedOptionsPool.totalCount = action.payload.totalCount;
        state.filters = action.payload.filters
      })
      .addCase(fetchLeadsPool.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createAutomationLead.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createAutomationLead.fulfilled, (state, action: PayloadAction<Lead>) => {
        state.isLoading = false;

        state.leads[0].pageLeads.unshift(action.payload)
        if(state.leads[0].pageLeads.length > state.paginatedOptions.pageSize) {
          state.leads[0].pageLeads.pop()
        }
        state.pageLeads = state.leads[0].pageLeads;
        state.paginatedOptions.page = 0;
      })
      .addCase(createAutomationLead.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createLead.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createLead.fulfilled, (state, action: PayloadAction<Lead>) => {
        state.isLoading = false;
        state.leads[0].pageLeads.unshift(action.payload)
        if(state.leads[0].pageLeads.length > state.paginatedOptions.pageSize) {
          state.leads[0].pageLeads.pop()
        }
        state.pageLeads = state.leads[0].pageLeads;
        state.paginatedOptions.page = 0;
      })
      .addCase(createLead.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateLead.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateLead.fulfilled, (state, action: PayloadAction<Lead>) => {
        state.isLoading = false;
        state.error = null;
        const updatedLead = action.payload;
        const updatedPageLeads = state.pageLeads.map((lead) => {
          return lead.id === updatedLead.id ? updatedLead : lead
        }
        );
        const updatedCLeads = state.leads;
        updatedCLeads[state.paginatedOptions.page].pageLeads = updatedPageLeads;
        state.leads = updatedCLeads;
        state.pageLeads = updatedPageLeads;
      })
      .addCase(updateLead.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(uploadAgentLeads.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(uploadAgentLeads.fulfilled, (state, action: PayloadAction<Lead[]>) => {
        state.leads[0].pageLeads.unshift(...action.payload)
        while(state.leads[0].pageLeads.length > state.paginatedOptions.pageSize) {
          state.leads[0].pageLeads.pop()
        }
        state.pageLeads = state.leads[0].pageLeads;

        let total = state.paginatedOptions.totalCount;
        state.paginatedOptions.page = 0;
        state.paginatedOptions.totalCount = total + action.payload.length;
        state.paginatedOptions.totalPages = Math.ceil(state.paginatedOptions.totalCount / state.paginatedOptions.pageSize);
      })
      .addCase(uploadAgentLeads.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(uploadAgencyLeads.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(uploadAgencyLeads.fulfilled, (state, action: PayloadAction<UploadLeadResponse>) => {
        state.isLoading = false;
        if(action.payload.isFromPool) {
          state.leadsPool[0].pageLeads.unshift(...action.payload.leads)
          while(state.leadsPool[0].pageLeads.length > state.paginatedOptionsPool.pageSize) {
            state.leadsPool[0].pageLeads.pop()
          }
          state.pageLeadsPool = state.leadsPool[0].pageLeads;

          let total = state.paginatedOptionsPool.totalCount;
          state.paginatedOptionsPool.page = 0;
          state.paginatedOptionsPool.totalCount = total + action.payload.leads.length;
          state.paginatedOptionsPool.totalPages = Math.ceil(state.paginatedOptionsPool.totalCount / state.paginatedOptionsPool.pageSize);
        } else {
          state.leads[0].pageLeads.unshift(...action.payload.leads)
          while(state.leads[0].pageLeads.length > state.paginatedOptions.pageSize) {
            state.leads[0].pageLeads.pop()
          }
          state.pageLeads = state.leads[0].pageLeads;

          let total = state.paginatedOptions.totalCount;
          state.paginatedOptions.page = 0;
          state.paginatedOptions.totalCount = total + action.payload.leads.length;
          state.paginatedOptions.totalPages = Math.ceil(state.paginatedOptions.totalCount / state.paginatedOptions.pageSize);
        }
      })
      .addCase(uploadAgencyLeads.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(startLeadAutomation.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(startLeadAutomation.fulfilled, (state, action: PayloadAction<any>) => {
        const newLeads = state.pageLeads
        newLeads.forEach((lead) =>{
            if(lead.id === action.payload.lead_id) {
              lead.chat_sid = action.payload.lead_chat_sid
              lead.status_id = action.payload.lead_status
            }
          }
        );

        state.isLoading = false;
        state.pageLeads = newLeads;
      })
      .addCase(startLeadAutomation.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(pastPage.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(pastPage.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null
        const result = state.leads.find(x => x.pageNumber === state.paginatedOptions.page - 1);
        if (result) {
            state.pageLeads = result.pageLeads;
            state.paginatedOptions.page = state.paginatedOptions.page - 1;
        }
      })
      .addCase(pastPoolPage.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(pastPoolPage.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null
        const result = state.leadsPool.find(x => x.pageNumber === state.paginatedOptionsPool.page - 1);
        if (result) {
            state.pageLeadsPool = result.pageLeads;
            state.paginatedOptionsPool.page = state.paginatedOptionsPool.page - 1;
        }
      })
      .addCase(assignLeadFromPool.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(assignLeadFromPool.fulfilled, (state, action: PayloadAction<Lead>) => {
        state.isLoading = false;
        state.leads[0].pageLeads.unshift(action.payload)
        if(state.leads[0].pageLeads.length > state.paginatedOptions.pageSize) {
          state.leads[0].pageLeads.pop()
        }
        state.pageLeads = state.leads[0].pageLeads;
        state.paginatedOptions.page = 0;

        const deleteLead = action.payload;
        const pageLeads = state.pageLeadsPool;
        const deletedLeadIndex = state.pageLeadsPool.findIndex((lead) => lead.id === deleteLead.id);
        pageLeads.splice(deletedLeadIndex, 1);
        const poolLeads = state.leadsPool;

        poolLeads[state.paginatedOptionsPool.page].pageLeads = pageLeads;
        state.leadsPool = poolLeads;
        state.pageLeadsPool = pageLeads;
      })
      .addCase(updateLeadClient.fulfilled,  (state, action: PayloadAction<Client>) => {
        const newClient = action.payload;
        const leadPages = state.pageLeads;
        const index = state.pageLeads.findIndex((l) => l.id === newClient.lead_id);

        leadPages.splice(index, 1);
        const leads = state.leads;

        leads[state.paginatedOptions.page].pageLeads = leadPages;
        state.leads = leads;
        state.pageLeads = leadPages;
      });
  },
});

export default leadsSlice.reducer;