import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { CheckIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";
import classNames from "classnames";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  label: string;
  children: any;
}

export const ReminderModal: React.FC<ModalProps> = ({ label, isOpen, onClose, children, onSave, onCancel, onDelete }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center h-full overflow-auto bg-black bg-opacity-50">
          <div className="relative mx-auto p-4 rounded-md shadow-lg bg-white max-w-full max-h-[85%] flex flex-col overflow-hidden min-h-[50%] md:w-3/6 w-4/6">
            <div className="flex items-center justify-between p-4 border-b border-neutral-100">
              <h5 className="text-xl font-medium text-neutral-800">{label}</h5>
              <button
                type="button"
                onClick={onClose}
                className="p-1 border-none rounded-none hover:opacity-75 focus:opacity-100 focus:outline-none"
                aria-label="Close"
              >
                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="flex-auto overflow-y-auto p-4">
              {children}
            </div>
            <div className="flex items-center justify-end p-4 border-t border-neutral-100">
              { 
                onCancel && <Button isTerciary onClick={onCancel} classNames="text-black">
                  <span className="flex items-center font-semibold pr-3">
                      <div className="w-8 p-1 aspect-square mr-2"><XMarkIcon /></div>
                      {t('CLOSE')}
                  </span>
                </Button>
              }
              {
                onDelete && <Button onClick={onDelete} classNames='text-black bg-red-700 hover:bg-red-100 hover:text-red-700'>
                  <span className='flex items-center font-semibold pr-3'>
                    <div className='w-8 p-1 aspect-square mr-2'><TrashIcon/></div>
                    {t('DELETE')}
                  </span>
                </Button>
              }
              <Button onClick={onSave} classNames="ml-2">
                  <span className="flex items-center font-semibold pr-3">
                      <div className="w-8 p-1 aspect-square mr-2"><CheckIcon /></div>
                      {t('SAVE')}
                  </span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
