import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Tag } from "../../models/Tags";
import { createTag, deleteTag, fetchTags, updateTag } from "./action";

interface TagState {
  tags: Tag[],
  isLoading: boolean,
  error: string | null,
}

const initialState: TagState = {
  tags: [],
  isLoading: false,
  error: null,
}

// slice
const tagsSlice = createSlice({
  name: "tags",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTags.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchTags.fulfilled, (state, action: PayloadAction<Tag[]>) => {
        state.isLoading = false;
        action.payload.forEach((tag) => tag.tag_type = tag.is_personal ? "Personal Tag" : "Global Tag")
        state.tags = action.payload;
      })
      .addCase(fetchTags.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(createTag.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createTag.fulfilled, (state, action: PayloadAction<Tag[]>) => {
        state.isLoading = false;
        action.payload.forEach((tag) => tag.tag_type = tag.is_personal ? "Personal Tag" : "Global Tag")
        state.tags = state.tags.concat(action.payload);
      })
      .addCase(createTag.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(updateTag.pending, (state, action) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateTag.fulfilled, (state, action: PayloadAction<Tag>) => {
        const updatedTag = action.payload;
        updatedTag.tag_type = updatedTag.is_personal ? "Personal Tag" : "Global Tag";
        
        const updatedTags = state.tags.map((tag) => {
          return tag.id === updatedTag.id ? updatedTag : tag
        }
        );
        state.tags = updatedTags;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateTag.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(deleteTag.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(deleteTag.fulfilled, (state, action: PayloadAction<Tag[]>) => {
        state.isLoading = false;
        action.payload.forEach((tag) => tag.tag_type = tag.is_personal ? "Personal Tag" : "Global Tag")
        state.tags = action.payload;
      })
      .addCase(deleteTag.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default tagsSlice.reducer;