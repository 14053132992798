import i18next from "i18next";

export enum CallStatus {
    COMPLETED = 'completed',
    BUSY = 'busy',
    NO_ANSWER = 'no-answer',
    CANCELED = 'canceled',
    FAILED = 'failed',
}

export const CallStatusLabel = (key: string) => ({
    [CallStatus.COMPLETED]: i18next.t('COMPLETED'),
    [CallStatus.BUSY]: i18next.t('BUSY'),
    [CallStatus.NO_ANSWER]: i18next.t('NO_ANSWER'),
    [CallStatus.CANCELED]: i18next.t('CANCELED'),
    [CallStatus.FAILED]: i18next.t('FAILED')
})[key];

export const getCallStatus = () =>
    Object.values(CallStatus).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: CallStatusLabel(value) ?? ""
        }
    });