import {
  CheckIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import logoAsureis from "../../../assets/images/Empty_logo.svg";
import { EditReminderModal, Reminder } from "../../../models/Reminder";
import { fetchUserReminders, setUserTodayReminders } from "../../../state/reminders/action";
import { AppDispatch, RootState } from "../../../state/store";
import {
  getLocalTimeZone,
  localDateFormat,
  localDayFormat,
  localDayMFormat,
  localHourFormat,
  localMonthFormat,
  localTimeFormat,
} from "../../../utils/functions";
import Button from "../../Button";
import Widget from "../Widget";

enum ReminderStatus {
  pending,
  coming,
  finished,
}

interface Props {
  className?: string;
  addReminder: () => void;
  updateReminder: (index: number, policy: EditReminderModal) => void;
  allReminders: () => void;
}

export const UserRemindersWidget = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector(
    (state: RootState) => state.users.currentUser
  );
  const reminders = useSelector(
    (state: RootState) => state.reminders.reminders
  );
  const [todayReminders, setTodayReminders] = useState<Reminder[]>([]);
  const [othersReminders, setOthersReminders] = useState<Reminder[]>([]);

  useEffect(() => {
    if (currentUser) {
      const timeZone = getLocalTimeZone();
      let start;

      const startDate = moment().hours(0).minutes(0).seconds(0).toDate();
      start = moment(startDate).format("YYYY-MM-DD");

      dispatch(fetchUserReminders({ startDate: start, timeZone: timeZone }));
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (reminders.length > 0) {
      const todayDate = moment().hours(0).minutes(0).seconds(0).format("YYYY-MM-DD");
      const todayR = reminders.filter((r) => {
        const rDate = moment(r.date).format("YYYY-MM-DD");
        return moment(rDate).isSame(todayDate);
      }).sort((a, b) => {
        if (
          moment(a.date).isBefore(new Date()) &&
          moment(b.date).isBefore(new Date())
        ) {
          if (moment(a.date).isBefore(b.date)) {
            return -1;
          } else if (moment(a.date).isAfter(b.date)) {
            return 1;
          }
          return 0;
        } else if (
          moment(a.date).isBefore(new Date()) &&
          moment(b.date).isAfter(new Date())
        ) {
          return 1;
        } else if (
          moment(a.date).isAfter(new Date()) &&
          moment(b.date).isBefore(new Date())
        ) {
          return -1;
        } else {
          if (moment(a.date).isBefore(b.date)) {
            return -1;
          } else if (moment(a.date).isAfter(b.date)) {
            return 1;
          }
          return 0;
        }
      });
      dispatch(setUserTodayReminders(todayR));
      setTodayReminders(todayR.length > 4 ? todayR.slice(0, 4) : todayR);

      const othersR = reminders
        .filter((r) => {
          const rDate = moment(r.date).format("YYYY-MM-DD");
          return moment(rDate).isAfter(todayDate);
        })
        .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
      setOthersReminders(othersR.length > 4 ? othersR.slice(0, 4) : othersR);
    }
  }, [reminders]);

  const getReminderStatus = (reminder: Reminder) => {
    const actualDate = moment(new Date());
    const reminderDate = moment(reminder.date);

    let status: ReminderStatus;

    if (reminderDate.isBefore(actualDate)) {
      status = ReminderStatus.finished;
    } else {
      if (reminderDate.diff(actualDate, "minutes") <= 10) {
        status = ReminderStatus.coming;
      } else {
        status = ReminderStatus.pending;
      }
    }

    switch (status) {
      case ReminderStatus.coming:
        return (
          <div className="bg-yellow-200 rounded-lg px-3 py-1">
            <span className="text-yellow-600 flex items-center pr-1">
              <div className="w-8 p-1 aspect-square stroke-2">
                <ExclamationTriangleIcon />
              </div>
              <div className="max-md:hidden font-medium text-lg">
                {t("COMING")}
              </div>
            </span>
          </div>
        );
      case ReminderStatus.pending:
        return (
          <div className="bg-neutral-200 rounded-lg px-3 py-1">
            <span className="text-gray-600 flex items-center pr-1">
              <div className="w-8 p-1 aspect-square stroke-2">
                <ClockIcon />
              </div>
              <div className="max-md:hidden font-medium text-lg">
                {t("PENDING")}
              </div>
            </span>
          </div>
        );
      case ReminderStatus.finished:
        return (
          <div className="bg-green-200 rounded-lg px-3 py-1">
            <span className="text-green-600 flex items-center pr-1">
              <div className="w-8 p-1 aspect-square stroke-2">
                <CheckIcon />
              </div>
              <div className="max-md:hidden font-medium text-lg">
                {t("FINISHED")}
              </div>
            </span>
          </div>
        );
    }
  };

  const updateReminder = (reminder: Reminder, index: number) => {
    //if (moment(reminder.date).isSameOrAfter(new Date())) {
    const date = localDateFormat(`${reminder.date}`);
    const time = localTimeFormat(`${reminder.date}`);
    const remiderEditModal: EditReminderModal = {
      id: reminder.id,
      message: reminder.message,
      date: date,
      time: time,
      is_to_myself: "1",
      agent: reminder.agent_id,
      client: reminder.client_id ? reminder.client_id : undefined,
      client_name: reminder.client_id ? reminder.client_name : undefined
    };
    props.updateReminder(index, remiderEditModal);
    //}
  };

  return (
    <Widget doubleHeight={true}>
      <div className="flex flex-row items-center justify-between">
        <div className="text-blue text-xl font-bold p-5">
          {t("REMINDERS")}
        </div>
        <Button
          iconOnly
          tooltip={t("NEW_REMINDER")}
          isTerciary
          onClick={() => props.addReminder()}
          classNames="mr-5"
        >
          <div className="w-6 aspect-square">
            <PlusIcon />
          </div>
        </Button>
      </div>
      {reminders.length > 0 && (
        <div className="w-full mb-4">
          {todayReminders.length > 0 && (
            <div className="w-full">
              <div className="text-gray-700 mx-5 mb-2">{t("TODAY")}</div>
              {todayReminders.map((reminder, index) => {
                return (
                  <div
                    className="mx-5 mb-3 cursor-pointer"
                    onClick={() => updateReminder(reminder, index)}
                  >
                    <div className="w-full flex flex-row justify-between">
                      <div className="flex flex-row items-center">
                        <div className="flex flex-col items-center justify-center">
                          <span className="text-gold text-sm leading-none">
                            {localDayFormat(`${reminder.date}`)}
                          </span>
                          <span className="text-gold text-xl font-semibold leading-none">
                            {localDayMFormat(`${reminder.date}`)}
                          </span>
                          <span className="text-gold text-sm leading-none">
                            {localMonthFormat(`${reminder.date}`)}
                          </span>
                        </div>
                        <div className="ml-5 flex flex-col items-start justify-center">
                          <div className="text-blue text-start text-xl">
                            {reminder.message}
                          </div>
                          {
                            reminder.client_id &&
                            <div className="text-start text-gray-500">{`${t("CLIENT")}: ${reminder.client_name}`}</div>
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-center ml-5">
                        <div className="text-end text-gray-500 font-light text-xl mr-3 min-w-24">
                          {localHourFormat(`${reminder.date}`)}
                        </div>
                        {getReminderStatus(reminder)}
                      </div>
                    </div>
                    {index !== todayReminders.length - 1 && (
                      <hr className="w-full mt-3"></hr>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {othersReminders.length > 0 && (
            <div className="w-full mt-7">
              <div className="text-gray-700 mx-5 mb-2">{t("OTHER")}</div>
              {othersReminders.map((reminder, index) => {
                return (
                  <div
                    className="mx-5 mb-3 cursor-pointer"
                    onClick={() => updateReminder(reminder, index)}
                  >
                    <div className="w-full flex flex-row justify-between">
                      <div className="flex flex-row items-center">
                        <div className="flex flex-col items-center justify-center">
                          <span className="text-gold text-sm leading-none">
                            {localDayFormat(`${reminder.date}`)}
                          </span>
                          <span className="text-gold text-xl font-semibold leading-none">
                            {localDayMFormat(`${reminder.date}`)}
                          </span>
                          <span className="text-gold text-sm leading-none">
                            {localMonthFormat(`${reminder.date}`)}
                          </span>
                        </div>
                        <div className="ml-4 flex flex-col items-start justify-center">
                          <div className="text-blue text-xl">
                            {reminder.message}
                          </div>
                          {
                            reminder.client_id &&
                            <div className="text-gray-500">{`${t("CLIENT")}: ${reminder.client_name}`}</div>
                          }
                        </div>
                      </div>
                      <div className="flex flex-row items-center ml-5">
                        <div className="text-end text-gray-500 font-light text-xl mr-3 min-w-24">
                          {localHourFormat(`${reminder.date}`)}
                        </div>
                        {getReminderStatus(reminder)}
                      </div>
                    </div>
                    {index !== othersReminders.length - 1 && (
                      <hr className="w-full mt-3"></hr>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {reminders.length - (todayReminders.length + othersReminders.length) >
            0 && (
            <div className="flex justify-end">
              <label
                className="underline cursor-pointer text-blue font-semibold mx-6 mb-3 mt-4"
                onClick={() => props.allReminders()}
              >
                {t("SEE_ALL")}
              </label>
            </div>
          )}
        </div>
      )}
      {reminders.length === 0 && (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <img src={logoAsureis} alt="Asureis Logo" className="h-32" />
          <div className="text-gray-500 text-xl font-medium">
            {t("NO_REMINDERS_CREATED")}
          </div>
        </div>
      )}
    </Widget>
  );
};
