export interface Dependent {
    id: number;
    name: string;
    email: string,
    phone: string;
    relationship_id: number;
    social_security_number: string;
    gender_id: number;
    date_of_birth: Date;
}

export interface NewDependent {
    name: string;
    social_security_number: string,
    gender_id?: number;
    date_of_birth?: string;
    email: string,
    phone?: string;
    relationship_id?: number;
}

export interface EditDependent {
    id?:number;
    name: string;
    social_security_number: string,
    gender_id?: number;
    date_of_birth?: string;
    email: string,
    phone?: string;
    relationship_id?: number;
}

export const newDependentInitialValues = {
    name: "",
    social_security_number: "",
    email: "",
}