import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useMemo, useState } from "react";
import {
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import states from "states-us/dist";
import logo from "../assets/images/asureis-side.png";
import Button from "../components/Button";
import { LoadingMask } from "../components/LoadingMask";
import ProgressBar from "../components/ProgressBar";
import { Toast } from "../components/Toast";
import { Form } from "../components/form/Form";
import { ReactiveFormCheckbox } from "../components/form/ReactiveFormCheckbox";
import { ReactiveFormInput } from "../components/form/ReactiveFormInput";
import { ReactiveFormPhoneInput } from "../components/form/ReactiveFormPhoneInput";
import { ReactiveFormRadioButton } from "../components/form/ReactiveFormRadioButton";
import { ReactiveFormSelect } from "../components/form/ReactiveFormSelect";
import {
  ClientFormStepEnum,
  getClientFormSteps,
} from "../enums/ClientFormStep";
import { getGenders } from "../enums/Gender";
import { getMaritalStatuses } from "../enums/MaritalStatus";
import { getCountries } from "../i18n/countries";
import { NewClientModel } from "../models/Client";
import { newDependentInitialValues } from "../models/Dependant";
import { newIncomeSourceInitialValues } from "../models/IncomeSource";
import { newPolicyInitialValues } from "../models/Policies";
import { createClientFromSurvey } from "../state/clients/actions";
import { AppDispatch } from "../state/store";

export const ClientPublicForm = () => {
  const { t } = useTranslation();
  //const [formData, setFormData] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showError, setShowError] = useState(false);
  const { name, id, npn } = useParams();
  const isLoading = useSelector((state: any) => state.clients.isLoading);
  const error = useSelector((state: any) => state.clients.error);

  useEffect(() => {
    if (showError) {
      Toast(t(error?.reason ?? "SOMETHING_WENT_WRONG"), t(error?.cause_info));
      setShowError(false);
    }
  }, [showError, error, t]);

  const handleClearCanvas = () => {
    // Clear the canvas

    // Check if the canvas is empty
    setIsCanvasEmpty(true);
  };

  const handleDrawStart = () => {
    // When the user starts drawing, update the state to indicate the canvas is not empty
    setIsCanvasEmpty(false);
  };

  const methods = useForm<NewClientModel>({
    defaultValues: {
      country_of_birth: "US",
      income_sources: [newIncomeSourceInitialValues],
      policies: [newPolicyInitialValues],
      dependents: [newDependentInitialValues],
      addresses: [
        {
          address_line_1: "",
          address_line_2: "",
          city: "",
          state: "",
          zip_code: "",
          is_main_address: true,
        },
      ],
      dependents_claimed: "0",
      primary_care_doctor: "0",
      agent_consent_agreement: [],
    },
  });

  const { handleSubmit, control, getValues, watch } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "dependents" as never,
  });

  const dependentsClaimed = useWatch({
    control,
    name: "dependents_claimed", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "0", // default value before the render
  });

  const consent = useWatch({
    control,
    name: "agent_consent_agreement", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: [], // default value before the render
  });

  useEffect(() => {
    if (dependentsClaimed === "1") {
      append({
        name: "",
        last_name: "",
        social_security_number: "",
      });
    } else {
      remove();
    }
  }, [append, dependentsClaimed, remove]);

  const onSubmit: SubmitHandler<NewClientModel> = async (
    data: NewClientModel
  ) => {
    dispatch(createClientFromSurvey({ client: data, agentId: id ?? "" })).then(
      (e) => {
        if (e.type === "clients/createClient/survey/rejected") {
          setShowError(true);
        } else {
          toast(t("SAVED_SUCCESFULLY"));
          setIsSubmitted(true);
        }
      }
    );
  };
  const [currentStep, setCurrentStep] = useState(1);

  const handleNextStep = () => {
    if (!errorMessage && currentStep < getClientFormSteps().length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const getStates = useMemo(() => {
    return states.map((state) => {
      return {
        label: state.name,
        value: state.abbreviation,
        key: state.abbreviation,
      };
    });
  }, []);

  return (
    <div className="h-screen ">
      {isLoading && <LoadingMask />}
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <div className="absolute top-0 left-0 p-5">
        <img src={logo} alt="" className="h-10 mr-auto" />
      </div>
      <div className="h-full w-full py-20 px-20">
        {isSubmitted ? (
          <div className="container mx-auto mt-8 p-4 font-large flex flex-col text-center">
            <div>{t("INFORMATION_SUBMITTED_SUCCESSFULLY")}</div>
            <div>{t("THANK_YOU_FOR_REGISTER")}</div>
          </div>
        ) : (
          <div className="container mx-auto mt-8 p-4">
            <ProgressBar
              currentStep={currentStep}
              stepsLabels={getClientFormSteps().map((e) => e.label)}
            />
            <div className="grid my-8">
              {currentStep === ClientFormStepEnum.PERSONAL_DETAILS && (
                <Form handleOnSubmit={handleSubmit(onSubmit)}>
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/2"
                    label={t("FIRST_NAME")}
                    name="first_name"
                    isRequired
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/2"
                    label={t("LAST_NAME")}
                    name="last_name"
                    isRequired
                  />
                  <ReactiveFormSelect
                    className="md:w-1/4"
                    name="gender"
                    label={t("GENDER")}
                    options={getGenders()}
                    control={control}
                  />
                  <ReactiveFormSelect
                    className="md:w-1/4"
                    name="country_of_birth"
                    label={t("COUNTRY_OF_BIRTH")}
                    options={getCountries()}
                    control={control}
                  />
                  <ReactiveFormInput
                    control={control}
                    type="date"
                    className="md:w-1/4"
                    label={t("DATE_OF_BIRTH")}
                    name="date_of_birth"
                    isRequired
                  />
                  <ReactiveFormSelect
                    className="md:w-1/4"
                    name="marital_status"
                    label={t("MARITAL_STATUS")}
                    options={getMaritalStatuses()}
                    control={control}
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/3"
                    label={t("SOCIAL_SECURITY_NUMBER")}
                    name="ssn"
                    isRequired
                  />
                  <ReactiveFormPhoneInput
                    control={control}
                    className="md:w-1/3"
                    name="phone"
                    label={t("PHONE")}
                    isRequired
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/3"
                    label={t("EMAIL")}
                    name="email"
                    isRequired
                  />
                  <ReactiveFormRadioButton
                    className={"w-full px-3 md:w-1/2"}
                    name="tobacco"
                    label={t("4_OR_MORE_TOBACCO_CLIENT")}
                    control={control}
                    options={[
                      { label: t("YES"), value: "1" },
                      { label: t("NO"), value: "0" },
                    ]}
                  />
                  <ReactiveFormRadioButton
                    className={"w-full px-3 md:w-1/2"}
                    name="po_box"
                    label={t("PO_BOX_CLIENT")}
                    control={control}
                    options={[
                      { label: t("YES"), value: "1" },
                      { label: t("NO"), value: "0" },
                    ]}
                  />
                  <ReactiveFormInput
                    control={control}
                    label={t("ADDRESS")}
                    name="addresses.0.address_line_1"
                    isRequired
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/3"
                    label={t("CITY")}
                    name="addresses.0.city"
                    isRequired
                  />
                  <ReactiveFormSelect
                    className="md:w-1/3"
                    name="addresses.0.state"
                    label={t("STATE")}
                    options={getStates}
                    control={control}
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/3"
                    label={t("ZIP_CODE")}
                    name="addresses.0.zip_code"
                    isRequired
                  />
                </Form>
              )}
              {currentStep === ClientFormStepEnum.EMPLOYMENT_INFORMATION && (
                <Form handleOnSubmit={handleSubmit(onSubmit)}>
                  <ReactiveFormRadioButton
                    className={"w-full px-3"}
                    name="employment_information"
                    label={t("YOUR_EMPLOYMENT_INFORMATION")}
                    control={control}
                    options={[
                      { label: t("EMPLOYED"), value: "1" },
                      { label: t("UNEMPLOYED"), value: "2" },
                      { label: t("RECEIVING_SOCIAL_SECURITY"), value: "3" },
                    ]}
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/2"
                    label={t("YOUR_EMPLOYEE_NAME")}
                    name="employee_name"
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/2"
                    label={t("YOUR_ESTIMATED_MONTHLY_INCOME")}
                    name="estimated_montly_income"
                  />
                </Form>
              )}
              {currentStep === ClientFormStepEnum.DEPENDANTS && (
                <Form handleOnSubmit={handleSubmit(onSubmit)}>
                  <ReactiveFormRadioButton
                    className={"w-full px-3"}
                    name="dependents_claimed"
                    label={t("WILL_YOU_CLAIM_DEPENDANTS")}
                    control={control}
                    options={[
                      { label: t("YES"), value: "1" },
                      { label: t("NO"), value: "0" },
                    ]}
                  />

                  {fields?.map((dependent, index) => {
                    return (
                      <Fragment key={dependent.id}>
                        <ReactiveFormInput
                          control={control}
                          className="md:w-1/3"
                          label={t("FULL_NAME")}
                          name={`dependents.${index}.name`}
                          isRequired
                        />
                        <ReactiveFormInput
                          control={control}
                          className="md:w-1/5"
                          label={t("SOCIAL_SECURITY_NUMBER")}
                          name={`dependents.${index}.social_security_number`}
                          isRequired
                        />
                        <ReactiveFormSelect
                          className="md:w-1/5"
                          name={`dependents.${index}.gender`}
                          label={t("GENDER")}
                          options={getGenders()}
                          control={control}
                        />
                        <div className="flex md:w-1/4">
                          <ReactiveFormInput
                            control={control}
                            type="date"
                            className="mr-2"
                            label={t("DATE_OF_BIRTH")}
                            name={`dependents.${index}.date_of_birth`}
                            isRequired
                          />
                          <div className="self-end mb-3">
                            {(getValues("dependents")?.length
                              ? +getValues("dependents")!.length - 1
                              : -1) === +index ? (
                              <Button
                                iconOnly
                                classNames="h-10 w-10"
                                onClick={() => remove(index)}
                              >
                                <PlusIcon className="h-4 w-4 ml-1" />
                              </Button>
                            ) : (
                              <Button
                                iconOnly
                                classNames="h-10 w-10"
                                onClick={() =>
                                  append({
                                    name: "",
                                    last_name: "",
                                    social_security_number: "",
                                  })
                                }
                              >
                                <TrashIcon className="h-4 w-4 ml-1" />
                              </Button>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
                </Form>
              )}
              {currentStep === ClientFormStepEnum.DOCTOR && (
                <Form handleOnSubmit={handleSubmit(onSubmit)}>
                  <ReactiveFormRadioButton
                    className={"w-full px-3"}
                    name="primary_care_doctor"
                    label={t("PRIMARY_CARE_DOCTOR")}
                    control={control}
                    options={[
                      { label: t("YES"), value: "1" },
                      { label: t("NO"), value: "0" },
                    ]}
                  />
                  {watch("primary_care_doctor") === "1" && (
                    <Fragment>
                      <ReactiveFormInput
                        control={control}
                        className="md:w-1/2"
                        label={t("DOCTOR_FULL_NAME")}
                        name="doctor_full_name"
                        isRequired
                      />
                      <ReactiveFormInput
                        control={control}
                        className="md:w-1/2"
                        label={t("DOCTOR_CITY")}
                        name="doctor_city"
                        isRequired
                      />
                    </Fragment>
                  )}
                </Form>
              )}
              {currentStep === ClientFormStepEnum.ADDITIONAL_INFORMATION && (
                <Form handleOnSubmit={handleSubmit(onSubmit)}>
                  <ReactiveFormRadioButton
                    className={"w-full px-3 md:w-1/2"}
                    name="file_tax_return"
                    label={t("WILL_YOU_FILE_TAX_RETURN_CLIENT")}
                    control={control}
                    options={[
                      { label: t("YES"), value: "1" },
                      { label: t("NO"), value: "0" },
                    ]}
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/2"
                    label={t("WERE_YOU_REFERRED_BY")}
                    name="referred_by"
                  />
                  <ReactiveFormCheckbox
                    className={"w-full px-3"}
                    name="agent_consent_agreement"
                    label={t("AGENT_CONSENT_AGREEMENT_LABEL")}
                    control={control}
                    options={[
                      {
                        label: t("AGENT_CONSENT_AGREEMENT")
                          .replace(/{agent}/gi, name ?? "")
                          .replace(/{npn}/gi, npn ?? ""),
                        value: "1",
                      },
                    ]}
                  />
                </Form>
              )}
            </div>
            <div className="flex justify-between">
              <Button onClick={handlePrevStep} isDisabled={currentStep === 1}>
                <span className="flex items-center font-semibold pr-3">
                  <div className="w-8 p-1 aspect-square mr-2">
                    <ArrowLeftIcon />
                  </div>
                  {t("PREVIOUS")}
                </span>
              </Button>
              {currentStep === getClientFormSteps().length ? (
                <Button
                  isTypeSubmit={true}
                  onClick={handleSubmit(onSubmit)}
                  isDisabled={
                    (getValues("agent_consent_agreement") &&
                      getValues("agent_consent_agreement")!.length < 1) ||
                    isCanvasEmpty
                  }
                >
                  <span className="flex items-center font-semibold pl-3">
                    {t("SEND")}
                    <div className="w-8 p-1 aspect-square ml-2">
                      <ArrowRightIcon />
                    </div>
                  </span>
                </Button>
              ) : (
                <Button
                  isTypeSubmit={true}
                  onClick={handleSubmit(handleNextStep)}
                >
                  <span className="flex items-center font-semibold pl-3">
                    {t("NEXT")}
                    <div className="w-8 p-1 aspect-square ml-2">
                      <ArrowRightIcon />
                    </div>
                  </span>
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
