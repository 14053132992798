import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosClient } from "../axiosClient";
import { MetadataItem } from "../../models/Metadata";

export const fetchMetadataMenuItems = createAsyncThunk('metadata/getMenuItems', async () => {
    const response: AxiosResponse<MetadataItem[]> = await (await axiosClient()).get(`/metadata/menu`);
    return response.data
});

export const fetchCommissionStatementMap = createAsyncThunk('metadata/getCommissionStatementMap', async (query: { agencyId: number, carrierId: number }) => {
    const response: AxiosResponse<any[]> = await (await axiosClient())
        .get(`/metadata/commission-statement-map?carrierId=${query.carrierId}&agencyId=${query.agencyId}`);
    return response.data
});

export const saveCommissionStatementMap = createAsyncThunk('metadata/saveCommissionStatementMap', async (body: { agencyId: number, carrierId: number, map: any }) => {
    const response: AxiosResponse<any[]> = await (await axiosClient())
        .post(`/metadata/commission-statement-map`, body);
    return response.data
});
