import i18next from "i18next";

export enum BusinessEnum {
    OBAMA_CARE = 1,
    MEDICAL_CARE
}

export const BusinessLabel = (key: string) => ({ 
    [BusinessEnum.OBAMA_CARE]: i18next.t('OBAMA_CARE'),
    [BusinessEnum.MEDICAL_CARE]: i18next.t('MEDICAL_CARE')
})[key];

export const getBusiness = () =>
    Object.values(BusinessEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: BusinessLabel(value) ?? ""
        }
    });