import i18next from "i18next";

export enum LeadStatusEnum {
    NEW = 1,
    IN_CONTACT,
    IN_QUOTE,
    AGED,
    CLIENT,
    LOST
}

export const LeadStatusLabel = (key: string) => ({ 
    [LeadStatusEnum.NEW]: i18next.t('NEW'),
    [LeadStatusEnum.IN_CONTACT]: i18next.t('IN_CONTACT'),
    [LeadStatusEnum.IN_QUOTE]: i18next.t('IN_QUOTE'),
    [LeadStatusEnum.AGED]: i18next.t('AGED'),
    [LeadStatusEnum.CLIENT]: i18next.t('CLIENT'),
    [LeadStatusEnum.LOST]: i18next.t('LOST')
})[key];

export const getLeadStatuses = () =>
    Object.values(LeadStatusEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: LeadStatusLabel(value) ?? ""
        }
    });