import {
  CheckIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReactFlow, {
  Connection,
  Edge,
  Node,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import Button from "../../components/Button";
import { FormCheckbox } from "../../components/form/FormCheckbox";
import { FormInput } from "../../components/form/FormInput";
import { FormSelect } from "../../components/form/FormSelect";
import { LoadingMask } from "../../components/LoadingMask";
import { Toast } from "../../components/Toast";
import { EMAIL_REGEX } from "../../constants/constants";
import { AutomationPath } from "../../constants/Routes";
import {
  AutomationTriggerTypeEnum,
  AutomationTriggerTypeLabel,
  getAutomationTriggerTypes,
} from "../../enums/AutomationTriggerType";
import { LeadStatusEnum, getLeadStatuses } from "../../enums/LeadStatus";
import {
  AnswerTextType,
  StepAnswerTypeEnum,
  StepAnswerTypeLabel,
  getAnswerTextTypes,
  getStepAnswerTypes,
} from "../../enums/StepAnswerType";
import { TimeUnitEnum, getTimeUnits } from "../../enums/TimeUnits";
import {
  AnswerStruc,
  CreateAutomation,
  StepStruct,
  StepTrigger,
} from "../../models/Automation";
import { AutomationStep } from "../../models/AutomationStep";
import { AutomationStepAnswer } from "../../models/AutomationStepAnswer";
import { AutomationStepTrigger } from "../../models/AutomationStepTrigger";
import { createAutomation } from "../../state/automations/action";
import { AppDispatch } from "../../state/store";

const initialNodes = [
  { id: "0", position: { x: 400, y: 100 }, data: { label: "First Question" } },
];
const initialEdges: {
  id: string;
  source: string;
  target: string;
}[] = [];

interface FormData {
  name: string;
  code: string;
}

const initialState: FormData = {
  name: "",
  code: "",
};

const initialStateNode: StepStruct = {
  id: "0",
  question: "First Question",
  isLastStep: true,
  sendThisQuestion: true,
  isFirstStep: false,
  triggers: [],
};
const initialStateEdge: AnswerStruc = {
  id: "",
  type: StepAnswerTypeEnum.BOOL_TRUE,
};

export default function NewAutomation() {
  const [globalIdConut, setGlobalIdCOnt] = useState(1);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [steps, setSteps] = useState<StepStruct[]>([initialStateNode]);
  const [answers, setAnswers] = useState<AnswerStruc[]>([]);

  const [formData, setFormData] = useState<FormData>(initialState);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const Icon = collapsed ? ChevronDoubleRightIcon : ChevronDoubleLeftIcon;

  const [selectedStep, setSelectedStep] = useState<StepStruct | null>(null);
  const [selectedAnswer, setSelectedAnswer] = useState<AnswerStruc | null>(
    null
  );
  const [showError, setShowError] = useState(false);

  const toggleSidebar = () => setCollapsed(!collapsed);
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: any) => state.automations.isLoading);
  const error = useSelector((state: any) => state.automations.error);

  useEffect(() => {
    if (showError) {
      Toast(t(error?.reason ?? "SOMETHING_WENT_WRONG"), t(error?.cause_info));
      setShowError(false);
    }
  }, [showError, error, t]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleChangeNode = (event: any) => {
    const { name, value, checked } = event.target;
    var newValue: any;
    let isSelectedStepLast = isLastStep(selectedStep!);
    if (name === "isLastStep" || name === "sendThisQuestion") {
      newValue = checked;
    } else {
      newValue = value;
    }

    if (!isSelectedStepLast) {
      setSelectedStep((prevFormData) => ({
        ...prevFormData!,
        question: name === "question" ? newValue! : prevFormData?.question,
        isLastStep: isSelectedStepLast,
        sendThisQuestion: true,
      }));
    } else {
      setSelectedStep((prevFormData) => ({
        ...prevFormData!,
        [name]: newValue!,
        isLastStep: isSelectedStepLast,
      }));
    }
  };

  const addTrigger = (event: any) => {
    const { value } = event.target;
    setSelectedStep((prevFormData) => ({
      ...prevFormData!,
      triggers: prevFormData!.triggers.concat({
        step_trigger_type: value,
        state:
          `${value}` === `${AutomationTriggerTypeEnum.CHANGE_LEAD_STATE}`
            ? LeadStatusEnum.NEW
            : undefined,
      }),
    }));
  };

  const addTriggerState = (event: any) => {
    const { value } = event.target;
    const triggers = selectedStep?.triggers;
    const currentTriggerIndex = selectedStep?.triggers.findIndex(
      (x) =>
        `${x.step_trigger_type}` ===
        `${AutomationTriggerTypeEnum.CHANGE_LEAD_STATE}`
    );
    if (
      currentTriggerIndex !== undefined &&
      currentTriggerIndex > -1 &&
      triggers
    ) {
      triggers[currentTriggerIndex] = {
        state: value,
        step_trigger_type: AutomationTriggerTypeEnum.CHANGE_LEAD_STATE,
      };
      setSelectedStep((prevFormData) => ({
        ...prevFormData!,
        triggers: triggers,
      }));
    }
  };

  const removeTrigger = (currentTrigger: StepTrigger) => {
    let triggers = selectedStep?.triggers;
    const currentTriggerIndex = selectedStep?.triggers.findIndex(
      (x) => x === currentTrigger
    );
    if (
      currentTriggerIndex !== undefined &&
      currentTriggerIndex > -1 &&
      triggers
    ) {
      triggers.splice(currentTriggerIndex, 1);
      setSelectedStep((prevFormData) => ({
        ...prevFormData!,
        triggers: triggers ?? [],
      }));
    }
  };

  const handleChangeEdge = (event: any) => {
    const { name, value } = event.target;
    if (name === "type") {
      if (parseInt(value) === StepAnswerTypeEnum.TEXT) {
        setSelectedAnswer((prevFormData) => ({
          ...prevFormData!,
          [name]: parseInt(value),
          textType: AnswerTextType.plainText,
        }));
      } else {
        setSelectedAnswer((prevFormData) => ({
          ...prevFormData!,
          [name]: parseInt(value),
          textType: undefined,
          customTextConfig: undefined,
          unit:
            parseInt(value) === StepAnswerTypeEnum.NOT_ANSWER
              ? TimeUnitEnum.DAYS
              : undefined,
          timeNumber:
            parseInt(value) === StepAnswerTypeEnum.NOT_ANSWER ? 1 : undefined,
        }));
      }
    } else if (name === "textType") {
      if (parseInt(value) === AnswerTextType.custom) {
        setSelectedAnswer((prevFormData) => ({
          ...prevFormData!,
          [name]: parseInt(value),
          customTextConfig: "",
        }));
      } else {
        setSelectedAnswer((prevFormData) => ({
          ...prevFormData!,
          [name]: parseInt(value),
          customTextConfig: undefined,
        }));
      }
    } else {
      setSelectedAnswer((prevFormData) => ({
        ...prevFormData!,
        [name]: value,
      }));
    }
  };

  const isLastStep = (step: StepStruct) => {
    const sources = edges.map((element) => element.source);
    return !sources.includes(step.id);
  };

  useEffect(() => {
    const updatedElements = nodes.map((element) => {
      if (selectedStep && element.id === selectedStep.id) {
        return {
          ...element,
          data: {
            ...element.data,
            label: selectedStep.question,
          },
        };
      }
      return element;
    });
    const updatedSteps = steps.map((element) => {
      if (selectedStep && element.id === selectedStep.id) {
        //Modifications to selectedStep
        return {
          ...element,
          question: selectedStep.question,
          isLastStep: isLastStep(selectedStep),
          sendThisQuestion: selectedStep.sendThisQuestion,
          triggers: selectedStep.triggers,
        };
      } else {
        //Modifications if selectedStep affects others
        let isElementLastStep = isLastStep(element);
        return {
          ...element,
          isLastStep: isElementLastStep,
          sendThisQuestion: isElementLastStep ? element.sendThisQuestion : true,
        };
      }
    });

    setNodes(updatedElements);
    setSteps(updatedSteps);
  }, [selectedStep]);

  useEffect(() => {
    const updatedEdges = edges.map((element) => {
      if (selectedAnswer && element.id === selectedAnswer.id) {
        return {
          ...element,
          label: StepAnswerTypeLabel(selectedAnswer.type.toString()),
        };
      }
      return element;
    });
    const updatedAnswers = answers.map((element) => {
      if (selectedAnswer && element.id === selectedAnswer.id) {
        return {
          ...element,
          type: selectedAnswer.type,
          textType:
            selectedAnswer.type === StepAnswerTypeEnum.TEXT
              ? selectedAnswer.textType
              : undefined,
          customTextConfig:
            selectedAnswer.type === StepAnswerTypeEnum.TEXT &&
            selectedAnswer.textType === AnswerTextType.custom
              ? selectedAnswer.customTextConfig
              : undefined,
          timeNumber:
            selectedAnswer.type === StepAnswerTypeEnum.NOT_ANSWER
              ? selectedAnswer.timeNumber
              : undefined,
          unit:
            selectedAnswer.type === StepAnswerTypeEnum.NOT_ANSWER
              ? selectedAnswer.unit
              : undefined,
        };
      }
      return element;
    });

    setEdges(updatedEdges);
    setAnswers(updatedAnswers);
  }, [selectedAnswer]);

  useEffect(() => {
    if (selectedStep) {
      let isElementLastStep = isLastStep(selectedStep);
      setSelectedStep({
        id: selectedStep.id,
        isFirstStep: false,
        question: selectedStep.question,
        isLastStep: isElementLastStep,
        sendThisQuestion: isElementLastStep
          ? selectedStep.sendThisQuestion
          : true,
        triggers: selectedStep.triggers,
      });
    }
  }, [answers]);

  const addQuestion = () => {
    setGlobalIdCOnt(globalIdConut + 1);
    const id = `${globalIdConut}`;
    const newNode = {
      id,
      position: {
        x: 400,
        y: 150,
      },
      data: { label: `New Question ${id}` },
    };
    const newStep: StepStruct = {
      id,
      question: `New Question ${id}`,
      isLastStep: true,
      sendThisQuestion: true,
      isFirstStep: false,
      triggers: [],
    };
    setNodes((nds) => nds.concat(newNode));
    setSteps((steps) => steps.concat(newStep));
  };

  const handleNodeClick = (event: any, node: Node) => {
    setCollapsed(true);
    let step = steps.find((step) => step.id === node.id);
    setSelectedStep(step!);
    setSelectedAnswer(null);
  };

  const handleEdgeClick = (event: any, edge: Edge<any>) => {
    setCollapsed(true);
    let answer = answers.find((answer) => answer.id === edge.id);
    setSelectedAnswer(answer!);
    setSelectedStep(null);
  };

  const handleNodesDelete = (deletedNodes: Node[]) => {
    setSteps(
      steps.filter((step) => {
        let deleteNode = deletedNodes.find((node) => step.id === node.id);

        return deleteNode === undefined;
      })
    );

    setSelectedStep(null);
    setSelectedAnswer(null);
  };

  const handleEdgesDelete = (deletedEdges: Edge[]) => {
    setAnswers(
      answers.filter((answer) => {
        let deleteEdge = deletedEdges.find((edge) => answer.id === edge.id);

        return deleteEdge === undefined;
      })
    );

    setSelectedStep(null);
    setSelectedAnswer(null);
  };

  const onConnect = (params: Edge | Connection) => {
    const { source, target } = params;
    let newEdges = edges.map((e) => e);
    newEdges.push({
      id: `e${source}-${target}`,
      source: `${source}`,
      target: `${target}`,
    });
    // Prevent the edge creation and provide feedback to the user
    if (isLoop(source, target, newEdges)) {
      //LOOP FOUND
      return;
    }

    if (!edges.find((edge) => edge.target === target)) {
      const newEdge: Edge = {
        id: `e${source}-${target}`,
        source: source!,
        target: target!,
        label: StepAnswerTypeLabel(StepAnswerTypeEnum.BOOL_TRUE.toString()),
      };
      setEdges((eds) => addEdge(newEdge, eds));

      const newAnswer: AnswerStruc = {
        id: `e${source}-${target}`,
        type: StepAnswerTypeEnum.BOOL_TRUE,
      };
      setAnswers((ans) => ans.concat(newAnswer));
    }
  };

  const isLoop = (
    source: string | null,
    target: string | null,
    elements: Edge<any>[]
  ) => {
    const visited = new Set();
    const adjacencyList: any = getAdjacencyList(elements);

    function dfs(nodeId: any) {
      if (visited.has(nodeId)) {
        return true; // Loop detected
      }

      visited.add(nodeId);

      const neighbors = adjacencyList[nodeId] || [];
      for (const neighbor of neighbors) {
        if (dfs(neighbor)) {
          return true; // Loop detected
        }
      }

      visited.delete(nodeId);
      return false; // No loop detected
    }

    return dfs(target) || false;
  };

  const handleSubmit = () => {
    if (steps.length < 2) {
      toast(t("MISSING_STEPS"));
      return;
    }
    const roots = findNodesWithoutParents();
    if (roots.length !== 1) {
      toast(t("TWO_INITAL_QUESTIONS"));
      return;
    }

    const rootStepIndex = steps.findIndex((step) => step.id === roots[0].id);
    steps[rootStepIndex].isFirstStep = true;

    const leavesNodes = findNodesWithoutChildren();
    const leavesNodesIds = leavesNodes.map((e) => e.id);
    const leavesSteps = steps.filter((step) =>
      leavesNodesIds.includes(step.id)
    );

    if (!formData.name || !formData.code) {
      toast(t("MISSING_NAME_OR_CODE"));
      return;
    }

    const adjacencyEdgeList: any = getAdjacencyEdgesList(edges);

    let stepsFormated = steps.map((step) => formatStep(step));
    stepsFormated.forEach((step) => {
      if (adjacencyEdgeList[step.step_code]) {
        step.answers = adjacencyEdgeList[step.step_code].map((edge: Edge) => {
          let answer = answers.find((answer) => answer.id === edge.id);
          return formatAnswer(answer!, edge.target);
        });
      }
    });

    const automation: CreateAutomation = {
      name: formData.name,
      code: formData.code,
      steps: stepsFormated,
    };
    for (const x of automation.steps) {
      const typesSet = new Set();
      if (x.answers) {
        for (const obj of x.answers) {
          if (typesSet.has(obj.type_id)) {
            toast(t("REPEATED_ANSWER_TYPE"));
            return;
          }
          typesSet.add(obj.type_id);
        }
      }
    }

    dispatch(createAutomation(automation)).then((e: any) => {
      if (e.type === "automations/create/rejected") {
        setShowError(true);
      } else {
        toast(t("SAVED_SUCCESFULLY"));
        navigate(AutomationPath);
      }
    });
  };

  const findNodesWithoutParents = () => {
    const targets = edges.map((element) => element.target);
    const nodesWithoutParents = nodes.filter(
      (element) => !targets.includes(element.id)
    );
    return nodesWithoutParents;
  };

  const findNodesWithoutChildren = () => {
    const sources = edges.map((element) => element.source);
    const nodesWithoutChildren = nodes.filter(
      (element) => !sources.includes(element.id)
    );
    return nodesWithoutChildren;
  };

  const getAdjacencyList = (elements: Edge<any>[]) => {
    const adjacencyList: any = {};
    // Build an adjacency list from the elements
    elements.forEach((element: any) => {
      const sourceId = element.source;
      const targetId = element.target;

      if (!adjacencyList[sourceId]) {
        adjacencyList[sourceId] = [];
      }

      adjacencyList[sourceId].push(targetId);
    });

    return adjacencyList;
  };

  const getAdjacencyEdgesList = (elements: Edge<any>[]) => {
    const adjacencyList: any = {};
    // Build an adjacency list from the elements
    elements.forEach((element: any) => {
      const sourceId = element.source;
      const targetId = element.target;

      if (!adjacencyList[sourceId]) {
        adjacencyList[sourceId] = [];
      }

      adjacencyList[sourceId].push(element);
    });

    return adjacencyList;
  };

  const formatStep = (step: StepStruct): AutomationStep => {
    return {
      question: step.question,
      is_last_step: step.isLastStep,
      send_last_question: step.sendThisQuestion,
      step_code: `${step.id}`,
      is_first_step: step.isFirstStep,
      triggers: createStepConfiguration(step),
    };
  };

  const formatAnswer = (
    answer: AnswerStruc,
    nextStepCode: string
  ): AutomationStepAnswer => {
    return {
      answer: StepAnswerTypeLabel(answer.type.toString())!,
      type_id: answer.type,
      next_step_code: nextStepCode,
      configuration: createAnswerConfiguration(answer),
    };
  };

  const createStepConfiguration = (
    step: StepStruct
  ): AutomationStepTrigger[] | undefined => {
    if (step.triggers.length !== 0) {
      let triggers: AutomationStepTrigger[] = [];
      step.triggers.forEach((trigger) => {
        if (
          `${trigger.step_trigger_type}` ===
          `${AutomationTriggerTypeEnum.CHANGE_LEAD_STATE}`
        ) {
          triggers.push({
            step_trigger_type: AutomationTriggerTypeEnum.CHANGE_LEAD_STATE,
            configuration: JSON.stringify({ lead_state: trigger.state }),
          });
        } else if (
          `${trigger.step_trigger_type}` ===
          `${AutomationTriggerTypeEnum.CREATE_CLIENT}`
        ) {
          triggers.push({
            step_trigger_type: AutomationTriggerTypeEnum.CREATE_CLIENT,
          });
        }
      });

      return triggers.length !== 0 ? triggers : undefined;
    }
    return undefined;
  };

  const createAnswerConfiguration = (answer: AnswerStruc) => {
    switch (answer.type) {
      case StepAnswerTypeEnum.TEXT:
        switch (answer.textType) {
          case AnswerTextType.email:
            return JSON.stringify({ regex: EMAIL_REGEX });
          case AnswerTextType.custom:
            return JSON.stringify({ regex: answer.customTextConfig });
          default:
            return undefined;
        }
      case StepAnswerTypeEnum.NOT_ANSWER:
        return JSON.stringify({
          unit: answer.unit,
          timeNumber: answer.timeNumber,
        });
      default:
        return undefined;
    }
  };

  const filteredTriggers = useMemo(() => {
    if (selectedStep) {
      // Assuming getAutomationTriggerTypes() returns an array of all triggers
      const allTriggers = getAutomationTriggerTypes();
      // Filter out triggers that are already present in selectedStep.triggers
      const triggersNotInSelectedStep = allTriggers.filter(
        (trigger) =>
          !selectedStep.triggers.find(
            (x) => `${x.step_trigger_type}` === `${trigger.key}`
          )
      );
      return triggersNotInSelectedStep;
    }
    return [];
  }, [selectedStep]);

  return (
    <div className="flex grow">
      {isLoading && <LoadingMask />}
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <div className="flex-grow relative">
        {!collapsed && (
          <Button
            iconOnly
            classNames="fixed w-10 h-10 right-0 m-4 z-20"
            onClick={toggleSidebar}
          >
            <Icon className="w-5 h-5" />
          </Button>
        )}
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={handleNodeClick}
          onEdgeClick={handleEdgeClick}
          onNodesDelete={handleNodesDelete}
          onEdgesDelete={handleEdgesDelete}
        />
        <Button classNames="absolute right-3 bottom-4" onClick={addQuestion}>
          <span className="flex items-center font-semibold pr-3">
            <div className="w-8 p-1 aspect-square mr-2">
              <PlusIcon />
            </div>
            {t("ADD_QUESTION")}
          </span>
        </Button>
      </div>
      {collapsed && (
        <div className="md:w-1/3 w-full bg-white flex-shrink-0 p-4 transition-all duration-300 ease-in-out absolute md:relative h-full">
          <button
            className="grid text-asureis-gray content-center hover:text-black w-10 h-10 md:hidden"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon className="w-6 h-6" />
          </button>
          <div className="divide-y">
            <div>
              <div className="font-semibold text-blue text-xl px-3  pb-4">
                {t("NEW_AUTOMATION")}
              </div>
              <FormInput
                value={formData.code}
                name="code"
                handleOnChange={handleChange}
                label={t("CODE")}
              />
              <FormInput
                value={formData.name}
                name="name"
                handleOnChange={handleChange}
                label={t("NAME")}
              />
            </div>
            <div>
              {selectedStep && (
                <div>
                  <div className="font-semibold text-blue text-xl px-3 pt-3 pb-4">
                    {t("QUESTION")}
                  </div>
                  <FormInput
                    value={selectedStep.question}
                    name="question"
                    handleOnChange={handleChangeNode}
                    label={t("QUESTION")}
                  />
                  <FormCheckbox
                    isDisabled
                    checked={selectedStep.isLastStep}
                    name="isLastStep"
                    handleOnChange={handleChangeNode}
                    label={t("IS_LAST_STEP")}
                    inlineDisplay
                    className="mb-2"
                  />
                  <FormCheckbox
                    checked={selectedStep.sendThisQuestion}
                    isDisabled={!selectedStep.isLastStep}
                    name="sendThisQuestion"
                    handleOnChange={handleChangeNode}
                    label={t("SEND_THIS_QUESTION")}
                    inlineDisplay
                    tooltip={t("SEND_THIS_QUESTION_TOOLTIP")}
                  />
                  <div className="font-semibold text-blue text-xl px-3 pt-3 pb-4">
                    {t("TRIGGERS")}
                  </div>
                  <FormSelect
                    name="step_trigger_type"
                    options={filteredTriggers}
                    label={t("ADD_TRIGGER")}
                    handleOnChange={addTrigger}
                  />
                  <div className="grid gap-2 px-3 text-gray-700">
                    {selectedStep.triggers?.map((element) => {
                      return (
                        <div className="bg-neutral-100 text-sm">
                          <div className="flex flex-col justify-between align-center pt-3 relative">
                            {`${element.step_trigger_type}` ===
                            `${AutomationTriggerTypeEnum.CHANGE_LEAD_STATE}` ? (
                              <FormSelect
                                name="triggerUserState"
                                selectedValue={element.state}
                                options={getLeadStatuses()}
                                label={
                                  AutomationTriggerTypeLabel(
                                    `${element.step_trigger_type}`
                                  ) ?? t("STATUS")
                                }
                                handleOnChange={addTriggerState}
                              />
                            ) : (
                              <div className="p-3 pt-0">
                                {AutomationTriggerTypeLabel(
                                  `${element.step_trigger_type}`
                                )}
                              </div>
                            )}
                            <XMarkIcon
                              className="w-5 h-5 cursor-pointer absolute right-3"
                              onClick={() => removeTrigger(element)}
                            />
                            {`${element.step_trigger_type}` ===
                              `${AutomationTriggerTypeEnum.CREATE_CLIENT}` && (
                              <div className="p-3 pt-0 text-xs">
                                {t("CREATE_CLIENT_FROM_LEAD_EXPLANATION")}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {selectedAnswer && (
                <div>
                  <div className="font-semibold text-blue text-xl px-3 pt-3 pb-4">
                    {t("ANSWER")}
                  </div>
                  <FormSelect
                    name="type"
                    selectedValue={selectedAnswer.type}
                    options={getStepAnswerTypes()}
                    label={t("ANSWER_TYPE")}
                    handleOnChange={handleChangeEdge}
                  />
                  {selectedAnswer.type === StepAnswerTypeEnum.TEXT && (
                    <>
                      <FormSelect
                        name="textType"
                        selectedValue={selectedAnswer.textType}
                        options={getAnswerTextTypes()}
                        label={t("OPTIONS")}
                        handleOnChange={handleChangeEdge}
                      />
                      {selectedAnswer.textType === AnswerTextType.custom && (
                        <FormInput
                          name="customTextConfig"
                          value={selectedAnswer.customTextConfig}
                          label={t("CUSTOM")}
                          handleOnChange={handleChangeEdge}
                        />
                      )}
                    </>
                  )}
                  {selectedAnswer.type === StepAnswerTypeEnum.NOT_ANSWER && (
                    <>
                      <FormSelect
                        name="unit"
                        selectedValue={selectedAnswer.unit}
                        options={getTimeUnits()}
                        label={t("TIME_UNIT")}
                        handleOnChange={handleChangeEdge}
                      />
                      <FormInput
                        name="timeNumber"
                        type="number"
                        value={selectedAnswer.timeNumber}
                        label={t("TIME")}
                        handleOnChange={handleChangeEdge}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <Button onClick={handleSubmit} classNames="">
              <span className="flex items-center font-semibold pr-3">
                <div className="w-8 p-1 aspect-square mr-2">
                  <CheckIcon />
                </div>
                {t("SAVE")}
              </span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

/*
<div className="flex justify-between px-4 pb-4">
              
            </div>
            */
