import { useTranslation } from "react-i18next";
import { Client } from "../../../models/Client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { useEffect, useMemo, useState } from "react";
import { CDocument } from "../../../models/Document";
import { Toast } from "../../Toast";
import { deleteClientDocument, fetchDocumentsByClientId, getFileDownloadUrl, needToLoadClientDocuments } from "../../../state/documents/actions";
import { toast, ToastContainer } from "react-toastify";
import Button from "../../Button";
import FilteredTable from "../../FilteredTable";
import { ArrowDownTrayIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ClientDocumentModal } from "./ClientDocumentModal";
import { DeleteClientDocumentModal } from "./DeleteClientDocumentModal";
import { LoadingMask } from "../../LoadingMask";
import { shortDateFormat, shortLocalDateFormat } from "../../../utils/functions";

interface Props {
    client: Client | null
}

export const ClientDocumentTable = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [documents, setDocuments] = useState<CDocument[]>([]);
    const clientDocuments = useSelector((state: RootState) => state.documents.attachments);
    const isLoadingDocuments = useSelector((state: RootState) => state.documents.isLoading);
    const needLoadClientDocuments = useSelector((state: RootState) => state.documents.needToLoadAttachments);
    const error = useSelector((state: any) => state.documents.error);
    const [isDownloadingFile, setIsDownloadingFile] = useState(false);
    const [showDocumentError, setShowDocumentError] = useState(false);

    const [clientDocumentInfoModal, setClientDocumentInfoModal] = useState<{
        show: boolean;
        client?: Client;
    }>({ show: false });

    const [ showDeleteClientDocumentModal, setShowDeleteClientDocumentModal] = useState<{
        document?: CDocument;
        show: boolean;
    }>({ show: false, document: undefined });

    useEffect(() => {
        if (showDocumentError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showDocumentError, error, t]);

    useEffect(() => {
        //TODO: Review state and get data
        if(needLoadClientDocuments) {
            dispatch(fetchDocumentsByClientId({clientId: props.client?.id!})).then((e) => {
                let documents = (e.payload || []) as CDocument[];
                setDocuments(documents);
            });
            dispatch(needToLoadClientDocuments(false));
        } else {
            setDocuments(clientDocuments);
        }
    }, []);

    const addDocuments = (docs: CDocument[]) => {
        setDocuments(docs);
    }

    const removeDocument = (document: CDocument) => {
        //TODO: remove address from database
        dispatch(deleteClientDocument({ clientId: props.client?.id!, attachId: document.id! })).then((e) => {
            if (e.type === "client/deleteClientDocument/rejected") {
                setShowDocumentError(true);
            } else {
                toast(t("SUCCESSFULLY_DELETE_CLIENT_DOCUMENT"));
                let docs = (e.payload || []) as CDocument[];
                setDocuments(docs);
            }
        });
    }

    const downloadDocument = async (attach: any) => {
        try {
          setIsDownloadingFile(true);
          const result = await dispatch(getFileDownloadUrl({ fileName: attach.name })).unwrap();
    
          const response = await fetch(result.download_url, {
            method: "GET",
            headers: {
              "Content-Type": "application/octet-stream",
            },
          });
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const blob = await response.blob();
          var link = window.URL.createObjectURL(blob);
          const aElement = document.createElement("a");
          document.body.appendChild(aElement);
          aElement.style.display = "none";
          aElement.href = link;
          aElement.download = attach.name;
          aElement.click();
          document.body.removeChild(aElement);
    
          setIsDownloadingFile(false);
        } catch (error) {
          console.error("Error downloading the file:", error);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: t("DOCUMENT_NAME"),
                Cell: (row: any) => row.row.original.name ? `${getAttachmentName(row.row.original.name)}` : "-",
            },
            {
                Header: t("DOCUMENT_SIZE"),
                Cell: (row: any) => row.row.original.size ? `${formatBytes(row.row.original.size)}` : "-",
            },
            {
                Header: t("CREATION_DATE"),
                Cell: (row: any) => row.row.original.creation_date ? `${shortLocalDateFormat(row.row.original.creation_date)}` : "-",
            },
            {
                id: "actions",
                Header: "",
                Cell: (row: any) => {
                    return (
                        <div className="flex flex-row justify-center divide-x">
                            <Button
                                isTerciary
                                iconOnly
                                tooltip={t("DOWNLOAD_DOCUMENT")}
                                onClick={() => downloadDocument(row.row.original)}
                                classNames="mr-2"
                            >
                                <ArrowDownTrayIcon className="h-5 aspect-square text-blue" />
                            </Button>
                            <Button
                                isTerciary
                                iconOnly
                                tooltip={t("DELETE_DOCUMENT")}
                                onClick={() => setShowDeleteClientDocumentModal({ show: true, document: row.row.original })}
                                classNames="mr-2"
                            >
                                <TrashIcon className="h-5 aspect-square text-blue" />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [downloadDocument, removeDocument, t]
    );

    const getAttachmentName = (name: string | undefined) => {
        if (name != undefined) {
          const nameSplit = name.split("-");
          const lastSegment = nameSplit[nameSplit.length - 1];
          const lastSegmentSplit = lastSegment.split(".");
          const extension = lastSegmentSplit[lastSegmentSplit.length - 1];
    
          const newName = `${name.replaceAll(`-${lastSegment}`, "")}.${extension}`;
          return newName;
        }
        return "";
    };

    const formatBytes = (bytes: number, decimals = 2) => {
        if (!+bytes) return "0 Bytes";
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    return (
        <>
            <FilteredTable
                columns={columns}
                data={documents}
                hasSearchBar={false}
                secondaryActionButton={
                    <Button onClick={() => setClientDocumentInfoModal({ show: true, client: props.client!})}>
                        <span className="flex items-center font-semibold pr-3">
                            <div className="w-8 p-1 aspect-square mr-2">
                                <PlusIcon />
                            </div>
                            {t("ADD_DOCUMENTS")}
                        </span>
                    </Button>
                }
            />
            <ClientDocumentModal
                info={clientDocumentInfoModal}
                onSubmited={(documents) => {
                    addDocuments(documents)
                }}
                onClose={() => setClientDocumentInfoModal({ show: false})}
            ></ClientDocumentModal>
            <DeleteClientDocumentModal
              info={showDeleteClientDocumentModal}
              onClose={() => setShowDeleteClientDocumentModal({show : false, document: undefined})}
              deleteDocument={(document: CDocument) => removeDocument(document)}
            />
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            { (isLoadingDocuments || isDownloadingFile) && <LoadingMask />}
        </>
    )
}