import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PolicyStatusLabel } from "../../../enums/PolicyState";
import { EditClient } from "../../../models/Client";
import { EditPolicy, NewPolicy, Policy } from "../../../models/Policies";
import { shortDateFormat } from "../../../utils/functions";
import Button from "../../Button";
import FilteredTable from "../../FilteredTable";

interface Props {
  isNewClient:boolean
  policies: Policy[];
  addPolicy: () => void;
  removePolicy: (index: number) => void;
  updatePolicy: (index: number, policy: Policy) => void;
}

export const ClientPoliciesTable: React.FC<Props> = ({
  isNewClient,
  policies,
  addPolicy,
  removePolicy,
  updatePolicy,
}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
      },
      {
        Header: t("POLICY_NUMBER"),
        accessor: "number",
      },
      {
        Header: t("CARRIER"),
        Cell: (row: any) =>
          `${row.row.original.carrier ?? "-"}`,
      },
      {
        Header: t("EFFECTIVE_START_DATE"),
        Cell: (row: any) =>
          shortDateFormat(`${row.row.original.effective_start_date}`),
      },
      {
        Header: t("EFFECTIVE_END_DATE"),
        Cell: (row: any) =>
          row.row.original.effective_end_date ? shortDateFormat(`${row.row.original.effective_end_date}`) : "-",
      },
      {
        Header: t("MEMBERS_INSURED"),
        accessor: "members_insured",
      },
      {
        Header: t("POLICY_TYPE"),
        Cell: (row: any) =>
          row.row.original.policy_type != null
            ? `${row.row.original.policy_type}`
            : "-",
      },
      {
        Header: t("STATUS"),
        Cell: (row: any) =>
          row.row.original.policy_state != null
            ? PolicyStatusLabel(row.row.original.policy_state)
            : "-",
      },
      /*{
        Header: t("FREE_PLAN"),
        Cell: (row: any) =>
          `${Number(row.row.original.free_plan) === 1 ? t("YES") : t("NO")}`,
      },*/
      {
        id: "actions",
        Header: "",
        Cell: (row: any) => {
          return (
            <div className="flex flex-row justify-center divide-x">
              <Button
                isTerciary
                iconOnly
                tooltip={t("UPDATE_POLICY")}
                onClick={() => updatePolicy(row.row.index, row.row.original)}
                classNames="mr-2"
              >
                <PencilIcon className="h-5 aspect-square text-blue" />
              </Button>
              <Button
                isTerciary
                iconOnly
                tooltip={t("DELETE_POLICY")}
                onClick={() => removePolicy(row.row.index)}
                classNames="mr-2"
                isDisabled={(!isNewClient && policies.length === 1)}
              >
                <TrashIcon className="h-5 aspect-square text-blue" />
              </Button>
            </div>
          );
        },
      },
    ],
    [removePolicy, t, updatePolicy]
  );

  return (
    <FilteredTable
      columns={columns}
      data={policies}
      hasSearchBar={false}
      secondaryActionButton={
        <Button onClick={addPolicy}>
          <span className="flex items-center font-semibold pr-3">
            <div className="w-8 p-1 aspect-square mr-2">
              <PlusIcon />
            </div>
            {t("ADD_POLICY")}
          </span>
        </Button>
      }
    />
  );
};
