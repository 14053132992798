import { useTranslation } from "react-i18next";
import { Client } from "../../../models/Client"
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { useEffect, useState } from "react";
import { Toast } from "../../Toast";
import { createClientImmigrationStatus, fetchImmigrationStatusByClientId, needToLoadClientImmigrationStatus, updateClientImmigrationStatus } from "../../../state/immigrationStatus/actions";
import { ImmigrationStatus } from "../../../models/InmigrationStatus";
import { useForm } from "react-hook-form";
import Button from "../../Button";
import { SpinCircle } from "../../SpinCircle";
import classNames from "classnames";
import { ReactiveFormInput } from "../../form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../form/ReactiveFormSelect";
import { getImmigrationTypes } from "../../../enums/ImmigrationStatusType";
import { Form } from "../../form/Form";
import { LoadingMask } from "../../LoadingMask";
import { toast, ToastContainer } from "react-toastify";

interface Props {
    client: Client | null
}

export const ClientImmigrationStatusInformationForm = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const clientImmigrationStatus = useSelector((state: RootState) => state.immigrationStatus.immigrationStatus);
    const isLoadingImmigration = useSelector((state: RootState) => state.immigrationStatus.isLoadingImmigration);
    const isLoading = useSelector((state: RootState) => state.immigrationStatus.isLoading);
    const needLoadClientImmigration = useSelector((state: RootState) => state.immigrationStatus.needToLoadImmigration);
    const error = useSelector((state: any) => state.immigrationStatus.error);
    const [showImmigrationError, setShowImmigrationError] = useState(false);

    const methods = useForm<ImmigrationStatus>({
        defaultValues: {}
    })
    const { handleSubmit, control, reset } = methods;

    useEffect(() => {
        if (showImmigrationError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showImmigrationError, error, t]);

    useEffect(() => {
        //TODO: Review state and get data
        if(needLoadClientImmigration) {
            dispatch(fetchImmigrationStatusByClientId({clientId: props.client?.id!})).then((e) => {
                let immigrationStatus = (e.payload || {}) as ImmigrationStatus;
                reset(immigrationStatus);
            });
            dispatch(needToLoadClientImmigrationStatus(false));
        } else {
            clientImmigrationStatus ? reset(clientImmigrationStatus) : reset({});
        }
    }, [clientImmigrationStatus, reset]);

    const onSubmit = (data: ImmigrationStatus) => {
        if(clientImmigrationStatus) {
            //TODO: update
            dispatch(updateClientImmigrationStatus({editImmigration: data, immigrationId: clientImmigrationStatus.id!, clientId: props.client?.id!})).then((e) => {
                if (e.type === "client/updateClientImmigrationStatus/rejected") {
                    setShowImmigrationError(true);
                } else {
                    toast(t("SUCCESSFULLY_UPDATE_CLIENT_IMMIGRATION_STATUS"));
                }
            });
        } else {
            //Todo: add
            dispatch(createClientImmigrationStatus({newImmigration: data, clientId: props.client?.id!})).then((e) => {
                if (e.type === "client/createClientImmigrationStatus/rejected") {
                    setShowImmigrationError(true);
                } else {
                    toast(t("SUCCESSFULLY_SAVED_CLIENT_IMMIGRATION_STATUS"));
                }
            });
        }
    }
    
    return (
        <>
            <Form handleOnSubmit={handleSubmit(onSubmit)}>
                <div className="text-xl font-bold text-blue w-full p-3">
                    {t("IMMIGRATION_STATUS")}
                </div>
                <ReactiveFormSelect
                    className="md:w-1/3"
                    name="immigration_status_type_id"
                    label={t("IMMIGRATION_STATUS_TYPE")}
                    options={getImmigrationTypes()}
                    control={control}
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/3"
                    label={t("PASSPORT_NUMBER")}
                    name="passport_number"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/3"
                    label={t("USCIS_NUMBER")}
                    name="uscis_number"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/4"
                    label={t("SOCIAL_SECURITY_NUMBER")}
                    name="social_security_number"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/4"
                    label={t("SSN_ISSUE_DATE")}
                    name="ssn_issue_date"
                    type="date"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/4"
                    label={t("GREEN_CARD_NUMBER")}
                    name="green_card_number"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/4"
                    label={t("GC_EXPIRATION_DATE")}
                    name="gc_expiration_date"
                    type="date"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/4"
                    label={t("WORK_PERMIT_CARD")}
                    name="work_permit_card"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/4"
                    label={t("WPC_EXPIRATION_DATE")}
                    name="wpc_expiration_date"
                    type="date"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/4"
                    label={t("DRIVER_LICENSE")}
                    name="driver_license"
                />
                <ReactiveFormInput
                    control={control}
                    className="md:w-1/4"
                    label={t("DL_EXPIRATION_DATE")}
                    name="dl_expiration_date"
                    type="date"
                />

                <div className="w-full flex items center justify-end px-3">
                    <Button
                        classNames='w-full text-lg font-semibold py-2 flex flex-row justify-center mx-3'
                        isTypeSubmit
                        isDisabled={isLoading}>
                        <div className={classNames({ 'mr-2': isLoading })}>Save</div>
                        {isLoading && <SpinCircle />}
                    </Button>
                </div>
            </Form>
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            {isLoadingImmigration && <LoadingMask />}
        </>
    )
}