interface Props {
    isChecked?: boolean
    handleOnChange: (event: any) => void
}

export const Switch = (props: Props) => {
    return (
        <label className="relative items-center cursor-pointer w-14 h-7">
            <input
                type="checkbox"
                checked={props.isChecked}
                onChange={props.handleOnChange}
                className="opacity-0 w-0 h-0"
            />
            {/* Contenedor del interruptor con el color de fondo */}
            <span
                className={`slider absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-300 rounded-full transition-all duration-300
                ${props.isChecked ? 'bg-sky-600' : 'bg-gray-300'}`}
            ></span>
            {/* Círculo que se mueve */}
            <span
                className={`dot absolute left-1 top-1 w-5 h-5 bg-white rounded-full transition-all duration-300
                ${props.isChecked ? 'translate-x-7' : ''}`}
            ></span>
        </label>
    );
};