import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { Campaign, CampaignAdditionalInformation, CampaignFilters, CampaignResponse, NECampaign } from "../../models/Campaign";
import { axiosClient } from "../axiosClient";

export const fetchCampaigns = createAsyncThunk("campaign/fetchCampaigns", async (data: {page: number, pageSize: number, filters: CampaignFilters}) => {
    const response: AxiosResponse<CampaignResponse> = await (await axiosClient()).patch(`/campaigns?page=${data.page}&pageSize=${data.pageSize}`, {filters: data.filters});
    return {
        ...response.data,
        filters: data.filters
    };
});

export const createCampaign = createAsyncThunk("campaign/createCampaign", async (params:{campaignNew: NECampaign}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Campaign> = await (await axiosClient()).post(`/campaigns`, {...params.campaignNew});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateCampaign = createAsyncThunk("campaign/updateCampaign", async (params:{campaignId: number, campaignUpdate: NECampaign}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Campaign> = await (await axiosClient()).patch(`/campaigns/${params.campaignId}`, {...params.campaignUpdate});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteCampaign = createAsyncThunk("campaign/deleteCampaign", async (params:{campaignId: number, page: number, pageSize: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<CampaignResponse> = await (await axiosClient()).delete(`/campaigns/${params.campaignId}?page=${params.page}&pageSize=${params.pageSize}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const searchCampaigns = createAsyncThunk("campaign/searchCampaigns", async (data: {q: string, agencyId: number}) => {
    const response: AxiosResponse<Campaign[]> = await (await axiosClient()).get(`/campaigns/search?q=${data.q}&agency_id=${data.agencyId}`);
    return response.data.map((campaign: Campaign) => ({
        label: `${campaign.code} - ${campaign.name}`.trim().replaceAll("  ", " "), 
        value: campaign.id}));
});

export const getCampaignAdditionalInformation = createAsyncThunk("campaign/getCampaignAdditionalInformation", async (campaignId: number) => {
    const response: AxiosResponse<CampaignAdditionalInformation[]> = await (await axiosClient()).get(`/campaigns/${campaignId}/information`);
    return response.data;
});

export const pastPage = createAsyncThunk("campaign/pastPage", async () => {
    return true;
});

export const clearForm = createAsyncThunk("campaign/clearForm", async () => {
    return true;
});

export const clearCampaigns = createAsyncThunk("campaign/clearAll", async () => {
    return true;
});