import { useTranslation } from "react-i18next";
import { Modal } from "../Modal";
import { ReactiveFormSMSelect } from "../form/ReactiveFormSMSelect";
import { useForm } from "react-hook-form";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";

interface Props {
    info: {
        show: boolean;
        agents?: any;
      };
    onSubmited: (selectedAgent: any) => void;
    onClose: () => void;
}

export const ClientInformationAssignAgentModal: React.FC<Props> = ({
    info,
    onSubmited,
    onClose
}) => {
    const { t } = useTranslation();

    const methods = useForm<any>({
            defaultValues: {}
    })
    const { handleSubmit, control, reset } = methods;

    const onSubmit = (data: any) => {
        if(data.agent) {
            onSubmited(data.agent);
            closeModal();
        }
    }
    
    const closeModal = () => {
        reset({});
        onClose();
    };

    return (<>
        <Modal
            isOpen={info.show}
            onClose={closeModal}
            label={t("ASSIGN_CLIENT_TITLE")}
            saveButton={{
                label: t("ASSIGN"),
                icon: <ArrowsRightLeftIcon />,
                onClick: handleSubmit(onSubmit),
            }}
        >
            <div className="w-full pt-3 pb-5">
                <div className={"w-full px-3"}>
                    <ReactiveFormSMSelect
                        isMulti={false}
                        selectClassName={"basic-single mb-3"}
                        name="agent"
                        label={t("AGENT_OF_RECORD")}
                        control={control}
                        options={info.agents}
                        menuPosition={"fixed"}
                        placeholder={"Select agent"}
                    />
                    <label className="text-gray-600 text-xs mb-5">
                        {t("ASSIGN_CLIENT_MESSAGE")}
                    </label>
                </div>
            </div>
        </Modal>
    </>)
}