import { useTranslation } from "react-i18next";
import { Lead } from "../../models/Lead";
import { ConfirmationModal } from "../ConfirmationModal";
import { UserPlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
    info: {
        show: boolean;
        lead?: Lead;
    };
    onClose: () => void;
    assignLead: (lead: Lead) => void
}

export const AssingLeadPoolModal: React.FC<Props> = ({
    info,
    onClose,
    assignLead
}) => {

    const { t } = useTranslation();

    const onAssignLead = () => {
        assignLead(info.lead!)
        onClose();
    };

    return(
        <ConfirmationModal 
            label={t("ASSIGN_LEAD")}
            isDeleteConfirm={false}
            isOpen={info.show}
            onClose={onClose}
            cancelButton={{ label: t("CLOSE"), icon: <XMarkIcon />, onClick: onClose, }}
            saveButton={{ label: t("ASSIGN"), icon: <UserPlusIcon />, onClick: onAssignLead }}
        >
            <div className='w-full flex flex-wrap items-center justify-center'>
                <label className="text-lg font-semibold py-4 px-2 text-center">
                    {t('ASSING_LEAD_MESSAGE')}
                </label>
            </div>
        </ConfirmationModal>
    )
}