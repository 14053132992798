import { PhoneIcon, XMarkIcon } from "@heroicons/react/24/outline"
import Button from "../Button"
import { CallDetails } from "../../models/TwilioConversation";
import { useMemo } from "react";

interface Props {
    accept: () => void;
    decline: () => void;
    callDetails: CallDetails;
}

export const IncomingCall = (props: Props) => {

    const callName = useMemo(() => {
        if (props.callDetails.name && props.callDetails.name !== "") {
            return props.callDetails.name;
        }
        if (props.callDetails.phone) {
            return props.callDetails.phone;
        }
        return "Unknown";
    }, [props.callDetails]);
    
    return <div className="bg-blue fixed z-[201] top inset-1/2 -translate-y-2/4 -translate-x-2/4 rounded-lg w-fit h-fit p-4 text-white flex items-center">
        <div>
            <p className="opacity-40 text-sm">Incoming Call</p>
            <p className="text-xl font-semibold w-max mr-8">{callName}</p>
        </div>
        <div className="flex justify-end h-fit items-center">
            <Button onClick={props.decline} isSecondary iconOnly classNames="mr-4 bg-red-600 bg-opacity-100 hover:bg-opacity-80 hover:bg-red-600 rounded-full"><XMarkIcon className="w-6 h-6 cursor-pointer z-40" /></Button>
            <Button onClick={props.accept} isSecondary iconOnly classNames="bg-green-600 bg-opacity-100 hover:bg-opacity-80 hover:bg-green-600 rounded-full"><PhoneIcon className="w-6 h-6 cursor-pointer z-40" /></Button>
        </div>
    </div>
}