import { useEffect, useState } from "react";
import {
    ClientFormSection,
    ClientFormSectionEnum,
    getClientFormSections,
} from "../../enums/ClientFormSection";
import classNames from "classnames";
import { ClientInformationForm } from "../../components/clients/ClientInformationForm";
import { ChevronDoubleLeftIcon, ExclamationTriangleIcon, PaperAirplaneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Button from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { Client } from "../../models/Client";
import { ClientPoliciesForm } from "../../components/clients/policy/ClientPoliciesForm";
import { Policy } from "../../models/Policies";
import { LoadingMask } from "../../components/LoadingMask";
import { createClient } from "../../state/clients/actions";
import { AppDispatch } from "../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { ClientsPath } from "../../constants/Routes";
import { ClientAddressTable } from "../../components/clients/address/ClientAddressTable";
import { needToLoadClientAddress } from "../../state/addresses/actions";
import { needToLoadClientIncomeSource } from "../../state/incomeSources/actions";
import { ClientIncomeTable } from "../../components/clients/income/ClientIncomeTable";
import { ClientDependentTable } from "../../components/clients/dependent/ClientDependentTable";
import { needToLoadClientDependent } from "../../state/dependents/actions";
import { ClientNotes } from "../../components/clients/notes/ClientNotes";
import { needToLoadClientNotes } from "../../state/clientNotes/actions";
import { ClientImmigrationStatusInformationForm } from "../../components/clients/immigrationStatus/ClientImmigrationStatusInformationForm";
import { ClientDocumentTable } from "../../components/clients/document/ClientDocumentTable";
import { needToLoadClientImmigrationStatus } from "../../state/immigrationStatus/actions";
import { needToLoadClientDocuments } from "../../state/documents/actions";
import { ClientConsentDocumentTable } from "../../components/clients/consentDocument/ClientConsentDocumentTable";
import { needToLoadClientConsentDocument } from "../../state/consentDocuments/actions";
import { needToLoadClientPolicy } from "../../state/policies/actions";
import { Lead } from "../../models/Lead";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { Toast } from "../../components/Toast";
import { toast, ToastContainer } from "react-toastify";
import { MessageTypeEnum } from "../../enums/MessageType";
import { SuccessErrorModal } from "../../components/SuccessErrorModal";
import { ClientExists } from "../../models/ClientExists";
import { ClientExistsConfirmationModal } from "../../components/clients/ClientExistsConfirmationModal";
import { updateLeadClient } from "../../state/leads/actions";

export const ClientForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const navigate = useNavigate();

    const stateLead: Lead | null = location.state ? location.state.lead ?? null : null;
    const stateClient: Client | null = location.state ? location.state.client ?? null : null;
    const clientFromLead = stateLead !== null;
    const isNewClient = stateClient === null
    const sections = getClientFormSections()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isFormSelected, setIsFormSelected] = useState<boolean>(false)
    const [sectionSelected, setSectionSelected] = useState<ClientFormSectionEnum>(ClientFormSectionEnum.PERSONAL_DETAILS)

    const [client, setClient] = useState<Client | null>(stateClient)
    const [isClientValid, setClientValid] = useState(true)
    const [policies, setPolicies] = useState<Policy[]>([])
    const [clientAlreadyExists, setClientAlreadyExists] = useState<boolean>(false);
    const [clientExistsWithPhone, setClientExistsWithPhone] = useState<ClientExists>();
    const [isWRenewalConfirmationModalOpen, setIsWRenewalConfirmationModalOpen] = useState(false);
    let hasToSendWRenewalMessage = false;
    const [newClientContinueData, setNewClientContinueData] = useState<Client>();

    const [showSuccessErrorModal, setShowSuccessErrorModal] = useState<{
        show: boolean;
        messageType: number;
        message: string;
    }>({ show: false, messageType: 2, message: "" });

    const [
        showClientExistsConfirmationModal,
        setShowClientExistsConfirmationModal,
    ] = useState<{
        clientExists?: ClientExists;
        phoneExists?: string;
        show: boolean;
    }>({ show: false, clientExists: undefined });

    const error = useSelector((state: any) => state.clients.error);
    const [showClientError, setShowClientError] = useState(false);
    
    useEffect(() => {
        if (showClientError) {
            handleShowSuccessErrorModal(
                MessageTypeEnum.FAILED,
                t(error?.cause_info ?? "SOMETHING_WENT_WRONG")
            );
        }
    }, [showClientError, error, t]);

    useEffect(() => {
        //TODO: Review state and get data
        if(client && !isNewClient) {
            dispatch(needToLoadClientPolicy(true));
            dispatch(needToLoadClientAddress(true));
            dispatch(needToLoadClientIncomeSource(true));
            dispatch(needToLoadClientDependent(true));
            dispatch(needToLoadClientNotes(true));
            dispatch(needToLoadClientImmigrationStatus(true));
            dispatch(needToLoadClientDocuments(true));
            dispatch(needToLoadClientConsentDocument(true));
        }
        
    }, [client])

    const getFormSections = () => {
        if (!isNewClient) {
            return sections
        } else {
            return sections.filter(section => section.value === ClientFormSectionEnum.PERSONAL_DETAILS.toString() || section.value === ClientFormSectionEnum.POLICIES.toString())
        }
    }

    const selectFormSection = (section: ClientFormSection) => {
        setSectionSelected(section.key)

        setIsFormSelected(true)
    }

    const policiesHasChanged = (policies: Policy[]) => {
        setPolicies(policies)
    }
    
    const clientHasChange = (formClient:Client, isFormValid:boolean) => {
        setClientValid(isFormValid)
        setClient(formClient)
    }

    const clientExistsHasChange = (exists: boolean, clientPhone?: ClientExists) => {
        setClientAlreadyExists(exists);
        setClientExistsWithPhone(clientPhone);
    }

    const submitNewClient = async (formClient: Client) => {
        if(!formClient) { return }

        setClient(formClient)
        if (policies.length === 0) {
            setSectionSelected(ClientFormSectionEnum.POLICIES)
            return
        }

        var varClient = formClient
        varClient!.policies = policies
        varClient!.lead_id = stateLead ? stateLead!.id : undefined;

        if (isNewClient && clientAlreadyExists && clientExistsWithPhone != undefined) {
            setShowClientExistsConfirmationModal({
                show: true,
                clientExists: clientExistsWithPhone,
                phoneExists: clientExistsWithPhone?.phone,
            });
            setNewClientContinueData(varClient);
        } else {
            if (isNewClient && policies.length > 0) {
                setIsWRenewalConfirmationModalOpen(true);
                setNewClientContinueData(varClient);
            } else {
                createNewClient(varClient);
            }
        }
    }

    const submitNewPolicy = async () => {
        if(policies.length === 0) { return }

        if (!client) {
            setSectionSelected(ClientFormSectionEnum.PERSONAL_DETAILS)
            return
        }

        var varClient = client;
        varClient!.policies = policies
        varClient!.lead_id = stateLead ? stateLead!.id : undefined;

        if (isNewClient && clientAlreadyExists && clientExistsWithPhone != undefined) {
            setShowClientExistsConfirmationModal({
                show: true,
                clientExists: clientExistsWithPhone,
                phoneExists: clientExistsWithPhone?.phone,
            });
            setNewClientContinueData(varClient);
        } else {
            if (isNewClient && policies.length > 0) {
                setIsWRenewalConfirmationModalOpen(true);
                setNewClientContinueData(varClient);
            } else {
                createNewClient(varClient);
            }
        }
    }

    const createNewClient = async (client: Client) => {
        setIsLoading(true);
        let params;

        if(client.assigned_to !== null) {
            params = {client: client!, assignedAgentId: client.assigned_to, hasToSendRenewalMessage: hasToSendWRenewalMessage, fromLead: clientFromLead};
        } else {
            params = {client: client!, hasToSendRenewalMessage: hasToSendWRenewalMessage, fromLead: clientFromLead};
        }

        dispatch(createClient(params)).then((e) => {
            if (e.type === "clients/createClient/rejected") {
                setIsLoading(false)
                setShowClientError(true);
            } else {
                setIsLoading(false)

                if(e.payload !== undefined) {
                    let newClient = (e.payload || {}) as Client;
                    dispatch(updateLeadClient(newClient));
                }

                handleShowSuccessErrorModal(
                    MessageTypeEnum.SUCCESS,
                    t("SUCCESSFULLY_SAVED_CLIENT_INFORMATION")
                );
            }
        });
    }

    const submitUpdateClient = async (client: Client) => {
        if(!client) { return }
        setClient(client)
    }

    const showPolicyMessageConfirmationModal = () => {
        return (
          <ConfirmationModal
            isOpen={isWRenewalConfirmationModalOpen}
            onClose={cancelWelcomeRenewalMessage}
            label={t("WELCOME_RENEWAL_MESSAGE")}
            cancelButton={{
              label: t("NO"),
              icon: <XMarkIcon />,
              onClick: cancelWelcomeRenewalMessage,
            }}
            saveButton={{
              label: t("SEND"),
              icon: <PaperAirplaneIcon />,
              onClick: sendWelcomeRenewalMessage,
            }}
          >
            {/*isConfirmationLoading && <LoadingMask />*/}
            <div className="w-full flex flex-wrap items-center justify-center">
              <label className="text-lg font-medium py-4">
                {t("SEND_WELCOME_RENEWAL_MESSAGE")}
              </label>
            </div>
          </ConfirmationModal>
        );
    };

    const cancelWelcomeRenewalMessage = () => {
        setIsWRenewalConfirmationModalOpen(false);
        hasToSendWRenewalMessage = false;
        if (newClientContinueData != undefined) {
          createNewClient(newClientContinueData);
        }
    };
    
    const sendWelcomeRenewalMessage = () => {
        setIsWRenewalConfirmationModalOpen(false);
        hasToSendWRenewalMessage = true;
        if (newClientContinueData != undefined) {
            createNewClient(newClientContinueData);
        }
    };

    const handleShowSuccessErrorModal = (
        messageType: number,
        message: string
      ) => {
        setShowSuccessErrorModal({
          show: true,
          messageType: messageType,
          message: message,
        });
      };
    
    const handleCloseSuccessErrorModal = () => {
        if (showSuccessErrorModal.messageType === MessageTypeEnum.SUCCESS) {
          setShowSuccessErrorModal({
            show: false,
            messageType: MessageTypeEnum.FAILED,
            message: "",
          });
          navigate(ClientsPath);
        } else if (showSuccessErrorModal.messageType === MessageTypeEnum.FAILED) {
          setShowSuccessErrorModal({
            show: false,
            messageType: MessageTypeEnum.FAILED,
            message: "",
          });
          setShowClientError(false);
        }
    };

    const handleCloseClientExistsModal = () => {
        setShowClientExistsConfirmationModal({
            show: false,
            clientExists: undefined,
        });
        setNewClientContinueData(undefined);
    };
    
    const handleContinueClientExistsModal = () => {
        setShowClientExistsConfirmationModal({
            show: false,
            clientExists: undefined,
        });
        if (newClientContinueData != undefined) {
            if (isNewClient && policies.length > 0) {
                setIsWRenewalConfirmationModalOpen(true);
                setNewClientContinueData(newClientContinueData);
            } else {
                createNewClient(newClientContinueData);
            }
        }
    };

    return (
        <>
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            {isLoading && <LoadingMask />}
            {showPolicyMessageConfirmationModal()}
            <SuccessErrorModal
                info={showSuccessErrorModal}
                onClose={handleCloseSuccessErrorModal}
            />
            <ClientExistsConfirmationModal
                info={showClientExistsConfirmationModal}
                onClose={handleCloseClientExistsModal}
                onContinueCreate={handleContinueClientExistsModal}
            />
            <div key={12882} className="m-5 flex gap-5">
                <div className={classNames({
                    "bg-white rounded-xl p-3 shadow w-full lg:w-1/3 xl:w-1/4 h-fit": true,
                    "hidden": isFormSelected,
                    "block": !isFormSelected,
                    "lg:block": true
                })}>
                    {getFormSections().map((section, index) =>
                    (<div key={index}>
                        <button onClick={() => selectFormSection(section)} type="button"
                            className={classNames({
                                "w-full py-4 px-3 rounded-lg flex flex-row items-center justify-between": true,
                                "bg-neutral-200": sectionSelected === section.key,
                                "hover:bg-neutral-300": sectionSelected !== section.key
                            })} >
                            <div className="font-semibold">{section.label}</div>
                            {section.value === ClientFormSectionEnum.POLICIES.toString() && (policies.length === 0 && isNewClient) && <ExclamationTriangleIcon className="text-red-500 h-7 w-7" />}
                            {section.value === ClientFormSectionEnum.PERSONAL_DETAILS.toString() && !isClientValid && <ExclamationTriangleIcon className="text-red-500 h-7 w-7" />}
                        </button>
                        {index !== (getFormSections().length - 1) && <div className="w-full h-0.5 bg-neutral-200"></div>}
                    </div>
                    )
                    )}

                </div>

                <div className={classNames({
                    "w-full bg-white rounded-xl p-3 shadow": true,
                    "hidden": !isFormSelected,
                    "block": isFormSelected,
                    "lg:block": true
                })} >
                    <div className="flex flex-col justify-start gap-3">
                        <Button
                            isTerciary
                            onClick={() => setIsFormSelected(false)}
                            iconOnly
                            classNames="w-9 p-1 aspect-square mr-4 lg:hidden"
                        >
                            <ChevronDoubleLeftIcon />
                        </Button>
                        {sectionSelected === ClientFormSectionEnum.PERSONAL_DETAILS &&
                            <ClientInformationForm
                                isNewClient={isNewClient}
                                client={client}
                                lead={stateLead}
                                clientHasChange={clientHasChange}
                                clientExistsHasChange={clientExistsHasChange}
                                submitNewClient={submitNewClient}
                                submitUpdateClient={submitUpdateClient}
                            />}
                        {sectionSelected === ClientFormSectionEnum.POLICIES &&
                            <ClientPoliciesForm
                                isNewClient={isNewClient}
                                policies={isNewClient ? policies : []}
                                client={isNewClient ? null : client}
                                policiesHasChanged={policiesHasChanged}
                                submitNewPolicy={submitNewPolicy}
                            />}
                        {sectionSelected === ClientFormSectionEnum.ADDRESS && <ClientAddressTable client={client} />}
                        {sectionSelected === ClientFormSectionEnum.INCOME_SOURCES && <ClientIncomeTable client={client} />}
                        {sectionSelected === ClientFormSectionEnum.DEPENDENTS && <ClientDependentTable client={client} />}
                        {sectionSelected === ClientFormSectionEnum.NOTES && <ClientNotes client={client} />}
                        {sectionSelected === ClientFormSectionEnum.IMMIGRATION_STATUS && <ClientImmigrationStatusInformationForm client={client} />}
                        {sectionSelected === ClientFormSectionEnum.DOCUMENTS && <ClientDocumentTable client={client} />}
                        {sectionSelected === ClientFormSectionEnum.CONSENT_DOCUMENTS && <ClientConsentDocumentTable client={client} />}
                    </div>

                </div>
            </div>
        </>
    );
}