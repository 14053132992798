import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createCommissionRuleType, fetchCommissionRuleTypes, updateCommissionRuleType, deleteCommissionRuleType, getComissionsReport, generateComissionsReport, editCommissionRecord } from "./action";
import { CommissionRuleType } from "../../models/CommissionRule";
import { AllCommission, Commission, CommissionResponse } from "../../models/Commission";
import { pastPage } from "./action";

interface CommissionRuleTypesState {
    state: CommissionRuleType[],
    isLoading: boolean,
    error: string | null,
}

interface CommissionsState {
  commissions: AllCommission[],
  pageCommissions: Commission[],
  isLoading: boolean,
  error: string | null,
  filters: {
    billingPeriod: string,
    status: string,
    carrier: string,
  },
  paginatedOptions: {
    page: number,
    pageSize: number,
    totalPages: number,
    totalCount: number
  },
}

const commissionRuleTypeInitialState: CommissionRuleTypesState = {
    state: [],
    isLoading: false,
    error: null,
}

const commissionsInitialState: CommissionsState = {
  commissions: [],
  pageCommissions: [],
  isLoading: false,
  error: null,
  filters: {
    billingPeriod: "",
    status: "confirmed",
    carrier: "-1",
  },
  paginatedOptions: {
    page: 0,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
  }
}

// slice
export const commissionRuleTypesSliceReducer = createSlice({
    name: "commissionRuleTypes",
    initialState: commissionRuleTypeInitialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchCommissionRuleTypes.pending, (state) => {
          state.isLoading = true;
          state.state = [];
          state.error = null
        })
        .addCase(fetchCommissionRuleTypes.fulfilled, (state, action: PayloadAction<CommissionRuleType[]>) => {
          state.isLoading = false;
          state.state = action.payload;
          state.error = null
        })
        .addCase(fetchCommissionRuleTypes.rejected, (state, action: any) => {
          state.isLoading = false;
          state.state = [];
          state.error = action.payload;
        });

      builder
        .addCase(createCommissionRuleType.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(createCommissionRuleType.fulfilled, (state, action: PayloadAction<CommissionRuleType>) => {
          state.isLoading = false;
          state.state = state.state.concat(action.payload);
        })
        .addCase(createCommissionRuleType.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        });

      builder
        .addCase(updateCommissionRuleType.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(updateCommissionRuleType.fulfilled, (state, action: PayloadAction<CommissionRuleType>) => {
          const updatedRule = action.payload;
          state.isLoading = false;
          const idx = state.state.findIndex(x => x.id === updatedRule.id);
          state.state[idx] = updatedRule;
        })
        .addCase(updateCommissionRuleType.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        });

      builder
        .addCase(deleteCommissionRuleType.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(deleteCommissionRuleType.fulfilled, (state, action: PayloadAction<CommissionRuleType>) => {
          const deletedRule = action.payload;
          state.isLoading = false;
          const idx = state.state.findIndex(x => x.id === deletedRule.id);
          state.state.splice(idx, 1);
        })
        .addCase(deleteCommissionRuleType.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        });
    },
}).reducer;

const commissionSliceReducer = createSlice({
  name: "commissions",
  initialState: commissionsInitialState,
  reducers: {
    setFilters(state, action: PayloadAction<{billingPeriod: string, status: string, carrier: string}>) {
      state.filters = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getComissionsReport.pending, (state) => {
      state.isLoading = true;
      state.error = null
    })
    .addCase(getComissionsReport.fulfilled, (state, action: PayloadAction<CommissionResponse>) => {
      state.isLoading = false;
      state.commissions = state.commissions.concat({ pageNumber: action.payload.page, pageClients: action.payload.commissions })
      state.pageCommissions = action.payload.commissions;
      state.paginatedOptions.page = action.payload.page;
      state.paginatedOptions.pageSize = action.payload.pageSize;
      state.paginatedOptions.totalPages = action.payload.totalPages;
      state.paginatedOptions.totalCount = action.payload.totalCount;
      state.filters.status = action.payload.status;
    })
    .addCase(getComissionsReport.rejected, (state, action: any) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    builder
      .addCase(generateComissionsReport.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(generateComissionsReport.fulfilled, (state, action: PayloadAction<CommissionResponse>) => {
        state.isLoading = false;

        if(action.payload.page === 0) {
          state.commissions = []
        }
        
        state.commissions = state.commissions.concat({ pageNumber: action.payload.page, pageClients: action.payload.commissions })
        state.pageCommissions = action.payload.commissions;
        state.paginatedOptions.page = action.payload.page;
        state.paginatedOptions.pageSize = action.payload.pageSize;
        state.paginatedOptions.totalPages = action.payload.totalPages;
        state.paginatedOptions.totalCount = action.payload.totalCount;
      })
      .addCase(generateComissionsReport.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
    builder
      .addCase(pastPage.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(pastPage.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null
        const result = state.commissions.find(x => x.pageNumber === state.paginatedOptions.page - 1);
        if (result) {
          state.pageCommissions = result.pageClients;
          state.paginatedOptions.page = state.paginatedOptions.page - 1;
        }
      })

      builder
      .addCase(editCommissionRecord.fulfilled, (state, action: PayloadAction<any>) => {
        state.commissions.forEach((x, ix) => {
          x.pageClients.forEach((y, iy) => {
            if (y.id === action.payload.id) {
              state.commissions[ix].pageClients[iy] = { ...action.payload }
              return
            }
          })
        })

        let idx = state.pageCommissions.findIndex(x => x.id === action.payload.id)

        if (idx !== -1) {
          state.pageCommissions[idx] = { ...action.payload };
        }
      })
  },
});

export const { setFilters } = commissionSliceReducer.actions;
export default commissionSliceReducer.reducer;