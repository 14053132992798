import i18next from "i18next";

export enum AutomationTriggerTypeEnum {
    CHANGE_LEAD_STATE = 1,
    CREATE_CLIENT,
}

export const AutomationTriggerTypeLabel = (key: string) => ({ 
    [AutomationTriggerTypeEnum.CHANGE_LEAD_STATE]: i18next.t('CHANGE_LEAD_STATE'),
    [AutomationTriggerTypeEnum.CREATE_CLIENT]: i18next.t('CREATE_CLIENT')
})[key];

export const getAutomationTriggerTypes = () =>
    Object.values(AutomationTriggerTypeEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: AutomationTriggerTypeLabel(value) ?? ""
        }
    });