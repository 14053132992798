import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { Fragment, useEffect, useState } from "react";
import { Toast } from "../../Toast";
import { SmallModal } from "../../SmallModal";
import { toast, ToastContainer } from "react-toastify";
import { LoadingMask } from "../../LoadingMask";
import { Switch } from "../../Switch";
import { AgencyPolicyType, PolicyType, UpdateAgencyPolicyTypes } from "../../../models/PolicyMarket";
import { updateAgencyPolicyTypes } from "../../../state/policyMarkets/actions";

interface Props {
  info: {
    show: boolean;
    agencyAvailableMarkets?: AgencyPolicyType[];
  };
  onClose: () => void;
}

export const EditAgencyAvailableMarkteModal: React.FC<Props> = ({
  info,
  onClose,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: RootState) => state.users.currentUser);
    const isLoading = useSelector((state: RootState) => state.policyMarkets.isLoading);
    const allPolicyTypes = useSelector((state: RootState) => state.policyMarkets.allPolicyTypes);
    const error = useSelector((state: any) => state.policyMarkets.error);
    const [showAvailableMarketsError, setShowAvailableMarketsError] = useState(false);
    const [agencyPolicyTypes, setAgencyPolicyTypes] = useState<PolicyType[]>([]);
    const [selectedTypes, setSelectedTypes] = useState<PolicyType[]>([])
    const [deletedTypes, setDeletedTypes] = useState<PolicyType[]>([])

    useEffect(() => {
        if (showAvailableMarketsError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showAvailableMarketsError, error, t]);

    useEffect(() => {
        if(info.agencyAvailableMarkets && info.agencyAvailableMarkets.length > 0) {
            let aPolicyTypes: PolicyType[] = [];
            info.agencyAvailableMarkets.forEach((market) => {
                aPolicyTypes.push(...market.policy_types);
            });
            setAgencyPolicyTypes(aPolicyTypes);
        }
    }, [info.agencyAvailableMarkets])
    
    const onSubmit = async () => {
        let data: UpdateAgencyPolicyTypes = {};
        data.policy_types = selectedTypes;
        data.delete_policy_types = deletedTypes;

        dispatch(updateAgencyPolicyTypes({agencyId: currentUser!.agency!.id, policyTypeData: data})).then((e) => {
            if (e.type === "agencies/updateAgencyPolicyTypes/rejected") {
                setShowAvailableMarketsError(true);
            } else {
                toast(t("SUCCESSFULLY_SAVED_AGENCY_AVAILABLE_MARKETS"));
                closeModal();
            }
        });
    };
    
    const closeModal = () => {
        setSelectedTypes([]);
        setDeletedTypes([]);
        setAgencyPolicyTypes([]);
        onClose();
    };

    const onHandleCheckPolicyType = (item: PolicyType) => {
        let aIndex = agencyPolicyTypes.findIndex((type) => type.id === item.id);

        if(aIndex !== -1) {
            let deleteList = [...deletedTypes];
            let dIndex = deletedTypes.findIndex((type) => type.id === item.id); 
            if(dIndex === -1) {
                deleteList.push(item);
                setDeletedTypes(deleteList);
            } else {
                deleteList.splice(dIndex, 1);
                setDeletedTypes(deleteList);
            }
        } else {
            const list = [...selectedTypes];
            let index = list.findIndex((type) => type.id === item.id);
            if(index !== -1) {
                list.splice(index, 1);
                setSelectedTypes(list);
            } else {
                list.push(item);
                setSelectedTypes(list);
            }
        }
    }

    const isPolicyTypeSelected = (item: PolicyType) => {
        let aIndex = agencyPolicyTypes.findIndex((type) => type.id === item.id);
        let sIndex = selectedTypes.findIndex((type) => type.id === item.id);
        let dIndex = deletedTypes.findIndex((type) => type.id === item.id);

        return ((aIndex !== -1 && dIndex === -1) || sIndex !== -1)
    }

    return(
        <div>
            <SmallModal
                isOpen={info.show}
                onClose={closeModal}
                label={t("EDIT_AVAILABLE_MARKETS")}
                onSave={onSubmit}
            >
                <div className="w-full flex flex-wrap">
                    <Fragment>
                        <div className="text-lg font-bold text-black pl-3 mt-2 mb-3">
                            {t("MARKET_AVAILABLE")}
                        </div>
                        {
                            allPolicyTypes.map((market) => {
                                return (
                                    <div className="w-full">
                                        <div className="w-full text-gray-600 mb-2 pl-3 pr-3">
                                            {market.market_name}
                                        </div>
                                        <div className="w-full pl-3 pr-3 pb-5">
                                            <ul className="list-disc list-inside">
                                                {
                                                    market.policy_types.map((type, index) => {
                                                        return(
                                                            <div>
                                                                <div className="flex justify-between items-center">
                                                                    <li>{type.name}</li>
                                                                    <Switch isChecked={isPolicyTypeSelected(type)} handleOnChange={() => onHandleCheckPolicyType(type)} />
                                                                </div>
                                                                {index !== market.policy_types.length - 1 && (
                                                                    <hr className="mt-3 mb-3 bg-gray-100"></hr>
                                                                )}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })                            
                        }
                        <div className="mb-5"></div>
                    </Fragment>
                </div>
            </SmallModal>
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            {isLoading && <LoadingMask/>}
        </div>
    )
}