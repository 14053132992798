import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosClient } from "../axiosClient";
import { NewTagData, Tag } from "../../models/Tags";

export const fetchTags = createAsyncThunk('tags/getTags', async (agencyId: number) => {
    const response: AxiosResponse<Tag[]> = await (await axiosClient()).get(`/agencies/${agencyId}/tags`);
    return response.data
});

export const createTag = createAsyncThunk('tags/createTag', async (params: {data: NewTagData, agencyId: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Tag[]> = await (await axiosClient()).post(`/agencies/${params.agencyId}/tags`, {...params.data});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateTag = createAsyncThunk('tags/updateTag', async (params: {data: any, agencyId: number, tagId: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Tag[]> = await (await axiosClient()).patch(`/agencies/${params.agencyId}/tags/${params.tagId}`, {...params.data});
        return response.data[0];
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteTag = createAsyncThunk('tags/deleteTag', async (params: {agencyId: number, tagId: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Tag[]> = await (await axiosClient()).delete(`/agencies/${params.agencyId}/tags/${params.tagId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});