import { t } from "i18next";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getLeadStatuses } from "../enums/LeadStatus";
import { Lead } from "../models/Lead";
import { updateLead } from "../state/leads/actions";
import { AppDispatch } from "../state/store";
import { Toast } from "./Toast";
import { Form } from "./form/Form";
import { FormSelect } from "./form/FormSelect";

interface Props {
  leadId: number | null | undefined;
}

const LeadContactForm = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const leads = useSelector((state: any) => state.leads.leads);
  const error = useSelector((state: any) => state.leads.error);
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const [leadStatus, setLeadStatus] = useState<number>();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (showError) {
      Toast(t(error?.reason ?? "SOMETHING_WENT_WRONG"), t(error?.cause_info));
      setShowError(false);
    }
  }, [showError, error]);

  const lead = useMemo(() => {
    return leads?.find((x: Lead) => x.id === props.leadId);
  }, [leads, props.leadId]);

  useEffect(() => {
    setLeadStatus(lead?.status_id);
  }, [lead]);

  const handleStatusChange = (event: any) => {
    if (lead && currentUser) {
      dispatch(
        updateLead({
          lead: {
            ...lead,
            status_id: +event.target.value,
          },
          leadId: lead.id,
        })
      ).then((e) => {
        if (e.type === "leads/updateLead/rejected") {
          setShowError(true);
        } else {
          toast(t("SAVED_SUCCESFULLY"));
        }
      });
    }
    setLeadStatus(event.target.value);
  };

  return (
    <Fragment>
      {lead ? (
        <>
          <ToastContainer progressStyle={{ background: "#D4AF37" }} />
          <div className="text-xl font-semibold py-5 px-4 justify-center">
            {`${lead.first_name ?? ""} ${lead.last_name ?? ""}`}
          </div>
          <div
            className="overflow-auto flex flex-col relative md:overflow-auto pb-4"
            style={{ height: "calc(100vh - 220px)" }}
          >
            <Form handleOnSubmit={() => {}}>
              <FormSelect
                name="status"
                label={t("STATUS")}
                options={getLeadStatuses()}
                selectedValue={`${leadStatus}` ?? ""}
                handleOnChange={handleStatusChange}
              />
            </Form>
          </div>
        </>
      ) : (
        <div className="text-l text-center relative inset-y-2/4 lg:w-3/4 self-center">
          Phone number does not match any client
        </div>
      )}
    </Fragment>
  );
};

export default LeadContactForm;
