import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { PolicySell, PolicySellFilters, PolicySellResponse } from "../../models/PolicySell";

export const fetchPolicySells = createAsyncThunk("policies/sells", async (data: {startDate: string, endDate: string, timeZone: string, page: number, pageSize: number, filters: PolicySellFilters}) => {
    const response: AxiosResponse<PolicySellResponse> = await (await axiosClient()).patch(`/policies/sells-report?page=${data.page}&pageSize=${data.pageSize}`, {start: data.startDate, end: data.endDate, timeZone: data.timeZone, filters: data.filters});
    return {
        ...response.data,
        filters: data.filters,
        dates: {startDate: data.startDate, endDate: data.endDate}
    };
});

export const pastPage = createAsyncThunk("policies/sells/pastPage", async () => {
    return true;
});

export const clearForm = createAsyncThunk("policies/sells/clearForm", async () => {
    return true;
});

export const clearPolicySells = createAsyncThunk("policies/sells/clearAllSells", async () => {
    return true;
});

export const fetchAllPolicySells = createAsyncThunk("policies/sells/all", async (data: {startDate: string, endDate: string, timeZone: string, limit: number, offset: number, filters: PolicySellFilters}) => {
    const response: AxiosResponse<PolicySell[]> = await (await axiosClient()).patch(`/policies/sells-report-all?limit=${data.limit}&offset=${data.offset}`, {start: data.startDate, end: data.endDate, timeZone: data.timeZone, filters: data.filters});
    return response.data
});

export const fetchTotalPolicySells = createAsyncThunk("policies/sells/all", async (params: {startDate: string, endDate: string, timeZone: string}) => {
    const response: AxiosResponse<any> = await (await axiosClient()).get(`/policies/sells-report-total`, {params: {startDate: params.startDate, endDate: params.endDate, timeZone: params.timeZone}});
    return response.data
});

export const fetchPolicyAgentsSummary = createAsyncThunk("policies/agents/summary", async (data: {startDate: string, endDate: string, timeZone: string, filters: PolicySellFilters}) => {
    const response: AxiosResponse<PolicySellResponse> = await (await axiosClient()).patch(`/policies/agents-summary`, {start: data.startDate, end: data.endDate, timeZone: data.timeZone, filters: data.filters});
    return response.data;
});