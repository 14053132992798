import React, { useMemo } from 'react';
import classNames from "classnames";

interface Props {
    onClick?: (event: any) => void
    isDisabled?: boolean
    classNames?: string
    isSecondary?: boolean
    isTerciary?: boolean
    isDelete?: boolean
    isTypeSubmit?: boolean
    iconOnly?: boolean
    fullWidth?:boolean
    tooltip?: string
    tooltipClassName?: string
    children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined
}

const Button = (props: Props) => {
    const buttonClassName = useMemo(() => {
        const currentClassName = classNames({
            "transition-all duration-300 cursor-pointer": true,
            "bg-blue text-white rounded shadow-sm text-sm font-medium hover:bg-neutral-200 hover:text-blue": !props.isSecondary && !props.isTerciary && !props.isDelete, //default button config
            "text-white rounded shadow-md text-sm font-medium": props.isSecondary,
            "rounded bg-asureis-gray-2 hover:bg-neutral-200 mx-1": props.isTerciary,
            "bg-red-600 text-white rounded shadow-sm text-sm font-medium hover:bg-neutral-200 hover:text-red-600": props.isDelete,
            "px-4 py-1": !props.iconOnly,
            "rounded-full p-2": props.iconOnly,
            "opacity-30": props.isDisabled,
            "disabled:pointer-events-none": props.isDisabled
        })
        return props.classNames
            ? `${currentClassName} ${props.classNames}`
            : currentClassName;
    }, [props.isSecondary, props.isTerciary, props.isDelete, props.iconOnly, props.isDisabled, props.classNames]);

    return <span className={classNames({ "group relative self-center": props.tooltip,
        "w-full": props.fullWidth
     })}>
        <button
            type={props.isTypeSubmit ? 'submit' : 'button'}
            className={buttonClassName}
            onClick={props.onClick}
            disabled={props.isDisabled}>
            {props.children}
            {props.tooltip && <span className={"p-2 bg-blue text-white pointer-events-none absolute bottom-7 rounded-lg right-0 w-max opacity-0 transition-opacity group-hover:opacity-100 max-w-[300px] " + props.tooltipClassName}>
                {props.tooltip}
            </span>}

        </button>
    </span>
}

export default Button;