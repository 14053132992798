import { useTranslation } from "react-i18next";
import { Campaign, CampaignAdditionalInformation, CampaignCarrierAdditionalInformation } from "../../models/Campaign";
import { ModalInformation } from "../ModalInformation";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { LoadingMask } from "../LoadingMask";
import { ToastContainer } from "react-toastify";
import { CheckIcon } from "@heroicons/react/24/outline";
import { CampaignEnum, CampaignLabel } from "../../enums/CampaignType";
import { shortDateFormat } from "../../utils/functions";
import { getCampaignAdditionalInformation } from "../../state/campaigns/actions";
import Table from "../Table";
import { Fragment } from "react/jsx-runtime";

interface Props {
  info: {
    show: boolean;
    campaign?: Campaign;
    index?: number;
  };
  onClose: () => void;
}

export const CampaignInformationModal: React.FC<Props> = ({
    info,
    onClose
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.campaigns.isLoading);
  const [additionalInformation, setAdditionalInformation] = useState<CampaignAdditionalInformation>();
  const currentAgency = useSelector(
    (state: any) => state.users.currentUser?.agency
  );

  useEffect(() => {
    if(info.campaign) {
      dispatch(getCampaignAdditionalInformation(info.campaign.id)).then((e) => {
        let information = (e.payload || null) as CampaignAdditionalInformation;
        if(information !== null) {
          setAdditionalInformation(information);
        }
      })
    }
  }, [info.campaign]);

  const closeModal = () => {
    setAdditionalInformation(undefined);
    onClose();
  }

  const getAdditionalCarriersRows = useMemo(() => {
    let carriers = additionalInformation?.carriers!;
    return carriers?.map((carrier: CampaignCarrierAdditionalInformation) => {
      return {
        columns: [
          { value: `${carrier.carrier_name}`},
          { value: carrier?.total_policies !== undefined ? `${carrier?.total_policies}` : `0`},
          { value: carrier?.total_income !== undefined ? `$${carrier?.total_income}` : `$0`},
          { value: carrier?.agency_commission !== undefined ? `$${carrier?.agency_commission}` : `$0`},
          { value: carrier?.agent_commission !== undefined ? `$${carrier?.agent_commission}` : `$0`}
        ],
      };
    });
  }, [additionalInformation]);

  return(
    <>
      <ModalInformation
        isOpen={info.show}
        onClose={closeModal}
        cancelButton={false}
        label={t("CAMPAIGN_INFORMATION")}
        saveButton={{
          label: t("OK"),
          onClick: closeModal,
          icon: <CheckIcon/>
        }}
      >
        <div className="w-full flex flex-wrap">
          <Fragment>
            <div className="w-full px-3 md:w-1/3 mb-4">
              <label className="block tracking-wide text-sm text-gray-700">
                {t("CODE")}
              </label>
              <label className="text-black font-medium">
                {info.campaign?.code}
              </label>
            </div>

            <div className="w-full px-3 md:w-1/3 mb-4">
              <label className="block tracking-wide text-sm text-gray-700">
                {t("NAME")}
              </label>
              <label className="text-black font-medium">
                {info.campaign?.name}
              </label>
            </div>

            <div className="w-full px-3 md:w-1/3 mb-4">
              <label className="block tracking-wide text-sm text-gray-700">
                {t("CAMPAIGN_TYPE")}
              </label>
              <label className="text-black font-medium">
                {CampaignLabel(`${info.campaign?.campaign_type_id}`)}
              </label>
            </div>

            <div className="w-full px-3 md:w-1/3 mb-4">
              <label className="block tracking-wide text-sm text-gray-700">
                {t("POLICY_TYPE")}
              </label>
              <label className="text-black font-medium">
                {info.campaign?.policy_type ? `${info.campaign?.policy_type}` : '-'}
              </label>
            </div>

            {
              info.campaign?.campaign_type_id === CampaignEnum.FIXED_PRICE &&
              <Fragment>
                <div className="w-full px-3 md:w-1/3 mb-4">
                  <label className="block tracking-wide text-sm text-gray-700">
                    {t("COST")}
                  </label>
                  <label className="text-black font-medium">
                    {`$${info.campaign?.fixed_price?.total}`}
                  </label>
                </div>

                <div className="w-full px-3 md:w-1/3 mb-4">
                  <label className="block tracking-wide text-sm text-gray-700">
                    {t("NUMBER_OF_LEADS")}
                  </label>
                  <label className="text-black font-medium">
                    { info.campaign?.fixed_price?.amount_of_leads
                      ? info.campaign?.fixed_price?.amount_of_leads
                      : "0"
                    }
                  </label>
                </div>

                <div className="w-full px-3 md:w-1/3 mb-4">
                  <label className="block tracking-wide text-sm text-gray-700">
                    {t("COST_PER_LEAD")}
                  </label>
                  <label className="text-black font-medium">
                    {info.campaign?.fixed_price?.cost_per_lead
                      ? `$${info.campaign?.fixed_price?.cost_per_lead}`
                      : `$0`
                    }
                  </label>
                </div>
              </Fragment>
            }

            {
              info.campaign?.campaign_type_id === CampaignEnum.FOR_TIME &&
              <Fragment>
                <div className="w-full px-3 md:w-1/3 mb-4">
                  <label className="block tracking-wide text-sm text-gray-700">
                    {t("START_DATE")}
                  </label>
                  <label className="text-black font-medium">
                    {shortDateFormat(`${info.campaign?.for_time?.start_date}`)}
                  </label>
                </div>

                <div className="w-full px-3 md:w-1/3 mb-4">
                  <label className="block tracking-wide text-sm text-gray-700">
                    {t("END_DATE")}
                  </label>
                  <label className="text-black font-medium">
                    {shortDateFormat(`${info.campaign?.for_time?.end_date}`)}
                  </label>
                </div>

                <div className="w-full px-3 md:w-1/3 mb-4">
                  <label className="block tracking-wide text-sm text-gray-700">
                    {t("COST")}
                  </label>
                  <label className="text-black font-medium">
                    {`$${info.campaign?.for_time?.cost}`}
                  </label>
                </div>

                <div className="w-full px-3 md:w-1/3 mb-4">
                  <label className="block tracking-wide text-sm text-gray-700">
                    {t("NUMBER_OF_LEADS")}
                  </label>
                  <label className="text-black font-medium">
                    {info.campaign?.for_time?.amount_of_leads
                      ? info.campaign?.for_time?.amount_of_leads
                      : "0"
                    }
                  </label>
                </div>

                <div className="w-full px-3 md:w-1/3 mb-4">
                  <label className="block tracking-wide text-sm text-gray-700">
                    {t("COST_PER_LEAD")}
                  </label>
                  <label className="text-black font-medium">
                    {info.campaign.for_time?.cost && info.campaign.for_time.amount_of_leads
                      ? `$${(info.campaign?.for_time?.cost! / info.campaign?.for_time?.amount_of_leads!).toFixed(2)}`
                      : `$0`}
                  </label>
                </div>
              </Fragment>
            }

            {
              additionalInformation &&
              <Fragment>
                <div className="w-full px-3 md:w-1/3 mb-4">
                  <label className="block tracking-wide text-sm text-gray-700">
                    {t("TOTAL_POLICIES")}
                  </label>
                  <label className="text-black font-medium">
                    {additionalInformation?.total_policies !== undefined ? additionalInformation?.total_policies : 0}
                  </label>
                </div>

                {
                  info.campaign?.campaign_type_id === CampaignEnum.FIXED_PRICE &&
                  <div className="w-full px-3 md:w-1/3 mb-4">
                    <label className="block tracking-wide text-sm text-gray-700">
                      {`% ${t("CONVERSION")}`}
                    </label>
                    <label className="text-black font-medium">
                      {
                        additionalInformation?.total_policies !== undefined && info.campaign.fixed_price?.amount_of_leads !== undefined
                        ? `${((additionalInformation?.total_policies / info.campaign.fixed_price.amount_of_leads) * 100).toFixed(2)}%`
                        : `0%`}
                    </label>
                  </div>
                }

                {
                  info.campaign?.campaign_type_id === CampaignEnum.FOR_TIME &&
                  <div className="w-full px-3 md:w-1/3 mb-4">
                    <label className="block tracking-wide text-sm text-gray-700">
                      {`% ${t("CONVERSION")}`}
                    </label>
                    <label className="text-black font-medium">
                      {
                        additionalInformation?.total_policies !== undefined && info.campaign.for_time?.amount_of_leads !== undefined
                        ? `${((additionalInformation?.total_policies / info.campaign.for_time.amount_of_leads) * 100).toFixed(2)}%`
                        : `0%`}
                    </label>
                  </div>
                }
                
              </Fragment>
            }

            {
              additionalInformation &&
              <Fragment>
                <div className="w-full flex flex-wrap">
                  <div className="w-full ml-3 mt-3 mb-3 text-gray-500 font-medium">{t("CAMPAIGN_COMMISSIONS")}</div>
                  <div className="w-full px-3 md:w-1/3 mb-4">
                    <label className="block tracking-wide text-sm text-gray-700">
                      {t("TOTAL_INCOME")}
                    </label>
                    <label className="text-black font-medium">
                      {additionalInformation?.total_income !== undefined ? `$${additionalInformation?.total_income}` : `$0`}
                    </label>
                  </div>

                  <div className="w-full px-3 md:w-1/3 mb-4">
                    <label className="block tracking-wide text-sm text-gray-700">
                      {t("PAID_TO_AGENCY", {agency_name: currentAgency.name})}
                    </label>
                    <label className="text-black font-medium">
                      {additionalInformation?.agency_commission !== undefined ? `$${additionalInformation?.agency_commission}` : `$0`}
                    </label>
                  </div>

                  <div className="w-full px-3 md:w-1/3 mb-4">
                    <label className="block tracking-wide text-sm text-gray-700">
                      {t("PAID_TO_AGENT")}
                    </label>
                    <label className="text-black font-medium">
                      {additionalInformation?.agent_commission !== undefined ? `$${additionalInformation?.agent_commission}` : `$0`}
                    </label>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full ml-3 mt-3 text-gray-500 font-medium">{t("CARRIERS")}</div>
                  <Table
                    columns={[
                      { header: `${t("CARRIER")}` },
                      { header: `${t("TOTAL_POLICIES")}` },
                      { header: `${t("TOTAL_INCOME")}` },
                      { header: `${t("PAID_TO_AGENCY", {agency_name: currentAgency.name})}` },
                      { header: `${t("PAID_TO_AGENT")}` }
                    ]}
                    data={getAdditionalCarriersRows}
                  />
                </div>
              </Fragment>
            }
          </Fragment>
        </div>
      </ModalInformation>
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      {isLoading && <LoadingMask/>}
    </>
  )
}