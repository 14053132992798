import { useEffect, useState } from "react";
import { Policy } from "../../../models/Policies";
import { ClientPoliciesTable } from "./ClientPoliciesTable";
import { ClientPolicyModal } from "./ClientPolicyModal";
import { LoadingMask } from "../../LoadingMask";
import Button from "../../Button";
import classNames from "classnames";
import { SpinCircle } from "../../SpinCircle";
import { Form } from "../../form/Form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { useTranslation } from "react-i18next";
import { Toast } from "../../Toast";
import { deleteClientPolicy, fetchPoliciesByClientId, needToLoadClientPolicy } from "../../../state/policies/actions";
import { Client } from "../../../models/Client";
import { toast, ToastContainer } from "react-toastify";
import { DeleteClientPolicyModal } from "./DeleteClientPolicyModal";

interface Props {
    isNewClient:boolean
    policies?:Policy[]
    client: Client | null
    policiesHasChanged?: (policies:Policy[]) => void
    submitNewPolicy?: () => void
}

export const ClientPoliciesForm = (props:Props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    //const [cPolicies, setCPolicies] = useState<Policy[]>([]);
    const clientPolicies = useSelector((state: RootState) => state.policies.clientPolicies);
    const isLoadingPolicies = useSelector((state: RootState) => state.policies.isLoading);
    const needLoadClientPolicies = useSelector((state: RootState) => state.policies.needToLoadPolicies);
    const error = useSelector((state: any) => state.policies.error);
    const [showPolicyError, setShowPolicyError] = useState(false);

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [policies, setPolicies] = useState<Policy[]>(props.policies ? props.policies : [])
    const [showClientPolicyModal, setShowClientPolicyModal] = useState<{
        show: boolean;
        update: boolean;
        policy?: Policy;
        index?: number;
        isNewClient: boolean;
        client?: Client;
    }>({ show: false, update: false, isNewClient: props.isNewClient });

    const [ showDeleteClientPolicyModal, setShowDeleteClientPolicyModal] = useState<{
        policy?: Policy;
        show: boolean;
    }>({ show: false, policy: undefined });

    useEffect(() => {
        if (showPolicyError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showPolicyError, error, t]);

    useEffect(() => {
        //TODO: Review state and get data
        if(!props.isNewClient && props.client !== null) {
            if(needLoadClientPolicies) {
                dispatch(fetchPoliciesByClientId({clientId: props.client?.id!})).then((e) => {
                    let policies = (e.payload || []) as Policy[];
                    setPolicies(policies);
                });
                dispatch(needToLoadClientPolicy(false));
            } else {
                setPolicies(clientPolicies);
            }
        }
    }, []);

    const removePolicy = (index:number) => {
        setPolicies(value => value.filter((_, i) => i !== index))
    }

    const appendPolicy = (policy: Policy) => {
        setPolicies(policies => [...policies, policy])
    }

    const updatePolicy = (index:number, policy: Policy) => {
        setPolicies(policies => policies.map((ele,i) => {
            if(i === index) { return policy}
            return ele
        }))
    }

    useEffect(() => {
        if(props.policiesHasChanged){props.policiesHasChanged(policies)}
    }, [policies])

    const deletePolicy = (policy: Policy) => {
        //TODO: remove address from database
        dispatch(deleteClientPolicy({ clientId: props.client?.id!, policyId: policy.id! })).then((e) => {
            if (e.type === "client/deleteClientPolicy/rejected") {
                setShowPolicyError(true);
            } else {
                toast(t("SUCCESSFULLY_DELETE_CLIENT_POLICY"));
                let policies = (e.payload || []) as Policy[];
                setPolicies(policies);
            }
        });
    }

    return (
        <>
            <ClientPoliciesTable
                isNewClient={props.isNewClient}
                policies={policies}
                addPolicy={() =>
                    setShowClientPolicyModal({ 
                        show: true,
                        update: false,
                        isNewClient: props.isNewClient, 
                        client: props.client !== null ? props.client : undefined
                    })
                }
                removePolicy={
                    (index) => props.isNewClient
                    ? removePolicy(index) 
                    : setShowDeleteClientPolicyModal({policy: policies[index] , show: true})
                }
                updatePolicy={(index, policy) =>
                    setShowClientPolicyModal({
                        show: true,
                        update: true,
                        policy: policy,
                        index: index,
                        client: props.client !== null ? props.client : undefined,
                        isNewClient: props.isNewClient
                    })
                }
            />
            <ClientPolicyModal
                info={showClientPolicyModal}
                onClose={() => setShowClientPolicyModal({ show: false, update: false, isNewClient: props.isNewClient })}
                addPolicy={(policy: Policy) => appendPolicy(policy)}
                updatePolicy={(index: number, policy: Policy) =>
                    updatePolicy(index, policy)
                }
            />
            {props.isNewClient && <div className="w-full flex items center justify-end px-3">
                <Button
                    onClick={() => props.submitNewPolicy ? props.submitNewPolicy() : null}
                    classNames='w-full text-lg font-semibold py-2 flex flex-row justify-center mx-3'
                    isDisabled={isLoading}>
                    <div className={classNames({ 'mr-2': isLoading })}>Save</div>
                    {isLoading && <SpinCircle />}
                </Button>
            </div>}
            <DeleteClientPolicyModal
                info={showDeleteClientPolicyModal}
                onClose={() => setShowDeleteClientPolicyModal({show : false, policy: undefined})}
                deletePolicy={(policy: Policy) => deletePolicy(policy)}
            />
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            {isLoadingPolicies && <LoadingMask />}
        </>

    )
}