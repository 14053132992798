import { ArrowRightOnRectangleIcon, ArrowsPointingOutIcon, ArrowUturnLeftIcon, MicrophoneIcon, PhoneXMarkIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import { useMemo, useState } from "react";
import Draggable from "react-draggable";
import { CallDetails } from "../../models/TwilioConversation";
import Button from "../Button";
import { useSelector } from "react-redux";
import { HashtagIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";

interface Props {
    decline: () => void;
    callDetails: CallDetails;
    muteCall: () => void;
    unmuteCall: () => void;
    transfer: () => void;
    transferFinalStep: (accepted: boolean) => void;
    conference: () => void;
    sendDigits: (digit: string) => void;
    isMute?: boolean;
}
export const Call = (props: Props) => {
    const twilio = useSelector((state: any) => state.twilio);
    const [numberPadText, setNumberPadText] = useState("");
    const [showNumberPad, setShowNumberPad] = useState(false);

    const callName = useMemo(() => {
        if (props.callDetails.name && props.callDetails.name !== "") {
            return props.callDetails.name;
        }
        return "Unknown";
    }, [props.callDetails]);

    const sendDigits = (digit: string) => {
        setNumberPadText(numberPadText + digit);
        props.sendDigits(digit)
    }

    return <Draggable handle="strong">
        <div className="bg-blue absolute z-40 top inset-1/2 -translate-y-2/4 -translate-x-2/4 rounded-lg w-fit h-fit p-4 text-white min-w-[200px]">
            <div className="flex justify-between mb-4">
                <div>
                    <p className="text-l font-semibold">{callName}</p>
                    <p className="opacity-40 text-sm -mt-1">{props.callDetails.phone}</p>
                </div>
                <strong className="cursor"><ArrowsPointingOutIcon className="w-5 h-5 cursor-pointer z-40" /></strong>
            </div>
            {showNumberPad && <div className="w-full text-center font-light text-4xl px-4 py-2 max-w-64">{numberPadText.split("").join(" ")}</div>}
            {showNumberPad && <div className="grid-cols-3 grid gap-5 py-5 px-8">
                {["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].map((ele) =>
                    <div className="flex item-center justify-center">
                        <Button
                            tooltipClassName="bg-gray top-10 h-fit left-[-2rem]"
                            onClick={() => sendDigits(ele)}
                            isSecondary
                            iconOnly
                            classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20">
                            <div className="w-8 h-8 flex item-center justify-center text-2xl"><p>{ele}</p></div>
                        </Button>
                    </div>
                )}
            </div>}
            {showNumberPad && <div className="flex h-fit items-center justify-center pb-5">
                {props.isMute
                    ? <Button onClick={props.unmuteCall} isSecondary iconOnly classNames="bg-neutral-200 bg-opacity-40 hover:bg-opacity-20">
                        <div className="relative flex justify-center items-center">
                            <MicrophoneIcon className="w-7 h-7 cursor-pointer z-40 m-2" />
                            <div className="bg-neutral-200 h-2 aspect-square absolute transform rotate-45 h-6 w-[1px]"></div>
                        </div>
                    </Button>
                    : <Button onClick={props.muteCall} isSecondary iconOnly classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20">
                        <MicrophoneIcon className="w-7 h-7 cursor-pointer z-40 m-2" />
                    </Button>
                }
                {twilio?.transferConferenceId === "" && <Button onClick={props.decline} isSecondary iconOnly classNames="ml-4 bg-red-600 bg-opacity-100 hover:bg-opacity-80 hover:bg-red-600">
                    <PhoneXMarkIcon className="w-7 h-7 cursor-pointer z-40 m-2" />
                </Button>
                }
            </div>}
            <div className="flex justify-between">
                <div className="flex h-fit items-center">
                    {twilio?.transferConferenceId !== ""
                        ? <div className="flex gap-2">
                            <Button
                                tooltip={t("BACK_TO_CLIENT")}
                                tooltipClassName="bg-gray top-10 h-fit left-[-2rem]"
                                onClick={() => props.transferFinalStep(false)}
                                isSecondary
                                iconOnly
                                classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20">
                                <ArrowUturnLeftIcon className="w-5 h-5 cursor-pointer z-40" />
                            </Button>
                            <Button
                                tooltip={t("TRANSFER_CALL")}
                                tooltipClassName="bg-gray top-10 h-fit left-[-2rem]"
                                onClick={() => props.transferFinalStep(true)}
                                isSecondary
                                iconOnly
                                classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20">
                                <ArrowRightOnRectangleIcon className="w-5 h-5 cursor-pointer z-40" />
                            </Button>
                        </div>
                        : <Button
                            tooltip={t("TRANSFER_CALL")}
                            tooltipClassName="bg-gray top-10 h-fit left-[-2rem]"
                            onClick={props.transfer}
                            isSecondary
                            iconOnly
                            classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20">
                            <ArrowRightOnRectangleIcon className="w-5 h-5 cursor-pointer z-40" />
                        </Button>
                    }
                    <div>
                        <Button
                            tooltip={t("CONFERENCE")}
                            tooltipClassName="bg-gray top-10 h-fit left-[-2rem]"
                            onClick={props.conference}
                            isSecondary
                            iconOnly
                            classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20 ml-4">
                            <UserPlusIcon className="w-5 h-5 cursor-pointer z-40" />
                        </Button>
                    </div>
                </div>
                <div className="flex h-fit items-center ml-10">
                    <Button
                        onClick={() => setShowNumberPad((value) => !value)}
                        isSecondary
                        iconOnly
                        tooltip={t("NUMBER_PAD")}
                        classNames={classNames({
                            "bg-neutral-200 hover:bg-opacity-40 bg-opacity-20": true,
                            "mr-4": !showNumberPad
                        })}>
                        <HashtagIcon className="w-5 h-5 cursor-pointer z-40" />
                    </Button>
                    {!showNumberPad && <> {props.isMute
                        ? <Button onClick={props.unmuteCall} isSecondary iconOnly classNames="bg-neutral-200 bg-opacity-40 hover:bg-opacity-20">
                            <div className="relative flex justify-center items-center">
                                <MicrophoneIcon className="w-5 h-5 cursor-pointer z-40" />
                                <div className="bg-neutral-200 h-2 aspect-square absolute transform rotate-45 h-6 w-[1px]"></div>
                            </div>
                        </Button>
                        : <Button onClick={props.muteCall} isSecondary iconOnly classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20">
                            <MicrophoneIcon className="w-5 h-5 cursor-pointer z-40" />
                        </Button>
                    }
                        {twilio?.transferConferenceId === "" && <Button onClick={props.decline} isSecondary iconOnly classNames="ml-4 bg-red-600 bg-opacity-100 hover:bg-opacity-80 hover:bg-red-600">
                            <PhoneXMarkIcon className="w-5 h-5 cursor-pointer z-40" />
                        </Button>
                        }</>}
                </div>

            </div>
        </div>
    </Draggable>
}