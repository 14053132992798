export const ClientsPath = "/clients"
export const NewClientPath = ClientsPath + "/newClient"
export const UpdateClientPath = ClientsPath + "/updateClient"
export const ConversationsPath = "/conversations"
export const PhonePath = "/phone"
export const LeadsPath = "/leads"
export const NewLeadPath = LeadsPath + "/newLead"
export const UpdateLeadPath = LeadsPath + "/updateLead"
export const LeadsPoolPath = LeadsPath + "/pool"
export const LoginPath = "/login"
export const ForgotPasswordPath = "/forgotPassword"
export const ClientPublicFormPath = "/register/:id/:name/:npn"
export const DocumentsPath = "/documents"
export const UsersPath = "/users"
export const AgencyPath = "/agency"
export const NewUserPath = UsersPath + "/newUser"
export const UpdateUserPath = UsersPath + "/updateUser"
export const ProfilePath = "/profile"
export const AutomationPath = "/automations"
export const NewAutomationPath = AutomationPath + "/newAutomation"
export const UpdateAutomationPath = AutomationPath + "/updateAutomation"
export const ApplicationPublicFormPath = "/application/form"
export const TagsPath = "/tags"
export const DashboardPath = "/dashboard"
export const MetadataPath = "/metadata"
export const PoliciesPath = "/policies"
export const SalesPolicyLeadBoardPath = "/policy/leadboard"
export const CommissionsPath = "/commissions"
export const PolicySellsReportPath = "/policySellsReport"
export const CampaignPath = "/campaign"
