import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createClientDependent, deleteClientDependent, fetchDependentsByClientId, needToLoadClientDependent, updateClientDependent } from "./actions";
import { Dependent } from "../../models/Dependant";

interface DependentState {
  dependents: Dependent[],
  isLoading: boolean,
  error: string | null,
  needToLoadDependents: boolean
}

const initialState: DependentState = {
  dependents: [],
  isLoading: false,
  error: null,
  needToLoadDependents: true
}

// slice
const dependentsSlice = createSlice({
  name: "dependents",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDependentsByClientId.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchDependentsByClientId.fulfilled, (state, action: PayloadAction<Dependent[]>) => {
        state.isLoading = false;
        state.dependents = action.payload;
      })
      .addCase(fetchDependentsByClientId.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createClientDependent.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createClientDependent.fulfilled, (state, action: PayloadAction<Dependent>) => {
        state.isLoading = false;
        state.dependents = state.dependents.concat(action.payload);
      })
      .addCase(createClientDependent.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateClientDependent.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateClientDependent.fulfilled, (state, action: PayloadAction<Dependent>) => {
        state.isLoading = false;
        const updatedDependent = action.payload;
        const updatedDependents = state.dependents.map((dependent) => {
                return dependent.id === updatedDependent.id ? updatedDependent : dependent
            }
        );
        state.dependents = updatedDependents;
      })
      .addCase(updateClientDependent.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteClientDependent.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(deleteClientDependent.fulfilled, (state, action: PayloadAction<Dependent[]>) => {
        state.isLoading = false;
        state.dependents = action.payload;
      })
      .addCase(deleteClientDependent.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(needToLoadClientDependent.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.needToLoadDependents = action.payload;
        if(action.payload) {
          state.dependents = [];
        }
      });
  },
});

export default dependentsSlice.reducer;