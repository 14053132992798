import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { Client, EditClient, NewClientModel, ClientResponse, ClientsFilters } from "../../models/Client";
import { axiosClient, publicAxiosClient } from "../axiosClient";
import { LeadStatusEnum } from "../../enums/LeadStatus";
import { EditLead } from "../../models/Lead";
import { ClientExists } from "../../models/ClientExists";

export const fetchClients = createAsyncThunk("clients/getClients", async (data: {page: number, pageSize: number, filters: ClientsFilters}) => {
    const response: AxiosResponse<ClientResponse> = await (await axiosClient()).patch(`/clients?page=${data.page}&pageSize=${data.pageSize}`, {...data.filters});
    return {
        ...response.data,
        filters: data.filters
    };
});

export const fetchClientById = createAsyncThunk("clients/getClientById", async (id: number) => {
    const response: AxiosResponse<Client> = await (await axiosClient()).get(`/clients/${id}`);
    return response.data;
});

export const createClient = createAsyncThunk("clients/createClient", async (params: {client: Client, assignedAgentId?: number, hasToSendRenewalMessage: boolean, fromLead: boolean}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Client> = await (await axiosClient()).post(`/clients?assignedAgentId=${params.assignedAgentId}&hasToSendRenewalMessage=${params.hasToSendRenewalMessage}&fromLead=${params.fromLead}`, {...params.client});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const createClientFromSurvey = createAsyncThunk("clients/createClient/survey", async (params:{client: NewClientModel, agentId: string}, { rejectWithValue }) => {
    try{
        const response: AxiosResponse<Client> = await publicAxiosClient.post(`/clients_survey?agentId=${params.agentId}`, {...params.client});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateClient = createAsyncThunk("clients/updateClient", async (params: {client: Client, clientId: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Client> = await (await axiosClient()).patch(`/clients/${params.clientId}`, {...params.client});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteClient = createAsyncThunk("clients/deleteClient", async (params: {clientId: number, page: number, pageSize: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Client> = await (await axiosClient()).delete(`/clients/${params.clientId}?page=${params.page}&pageSize=${params.pageSize}`);
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const createClientFromLead = createAsyncThunk("leads/createClientFromLead", async (params: { lead: EditLead, leadId: number }, { rejectWithValue }) => {
    try {
        await (await axiosClient()).post(`/clients/leads/${params.leadId}`);
        return {
            ...params.lead,
            status_id: LeadStatusEnum.CLIENT,
            id: params.leadId
        };
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const pastPage = createAsyncThunk("clients/pastPage", async () => {
    return true;
});

export const clearForm = createAsyncThunk("clients/clearForm", async () => {
    return true;
});

export const checkClientPhoneNumber = createAsyncThunk("clients/checkPhone", async (data: {phone: string}) => {
    const response: AxiosResponse<ClientExists[]> = await (await axiosClient()).get(`/clients/check-phone?phone=${data.phone}`);
    return response.data;
});

export const checkClientSSN = createAsyncThunk("clients/checkSSN", async (data: {ssn: string}) => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/clients/check-ssn?ssn=${data.ssn}`);
    return response.data;
});

export const searchClients = createAsyncThunk("clients/searchClients", async (data: {q: string, agencyId: number}) => {
    const response: AxiosResponse<Client[]> = await (await axiosClient()).get(`/clients/search?q=${data.q}&agency_id=${data.agencyId}`);
    return response.data.map((client: Client) => ({
        label: `${client.first_name} ${client.middle_name ?? ""} ${client.last_name} ${client.second_last_name ?? ""}`.trim().replaceAll("  ", " "), 
        value: client.id!}));
});
