import { XMarkIcon } from '@heroicons/react/24/outline';
import { UserCredential, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/asureis-side.png';
import medico from '../../assets/images/medico.jpg';
import Button from '../../components/Button';
import { Form } from '../../components/form/Form';
import { ReactiveFormInput } from '../../components/form/ReactiveFormInput';
import { ForgotPasswordPath } from '../../constants/Routes';
import { SpinCircle } from '../../components/SpinCircle';

export const Login = () => {
    const { t } = useTranslation();
    //const [formData, setFormData] = useState({ email: "", password: "" });
    const [isLoading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    type LoginInput = {
        email: string,
        password: string
    }
    const methods = useForm<LoginInput>({
        defaultValues: {
            email: "",
            password: ""
        }
    });

    const { handleSubmit, control, getValues } = methods;

    const onSubmit: SubmitHandler<LoginInput> = async (data: LoginInput) => {
        try {
            setLoading(true)
            const auth = getAuth();
            let userCredential: UserCredential = await signInWithEmailAndPassword(auth, data.email.replace(/\s/g, ''), data.password)

        } catch (error: any) {
            const errorMessage = error.message;
            setErrorMessage(errorMessage)
        } finally {
            setLoading(false)
        }
    };

    return (
        <div className='h-screen flex flex-row  relative '>
            <div className="absolute top-0 left-0 p-5">
                <img src={logo} alt="Asureis Logo" className='h-10 mr-auto' />
            </div>
            <div className='md:w-1/2 w-full h-full flex flex-col justify-center px-20'>
                <div className='font-bold text-2xl px-3'>Sign In</div>
                <div className='text-neutral-400 font-light px-3'>New to Asureis? <a className='text-gold' href="">Sign up for free</a></div>

                {errorMessage && <div className='px-4 py-3 flex items-center border border-red-500 rounded mt-4 md:max-w-xl'>
                    <XMarkIcon className='text-red-500 h-6 w-6 mr-2' />
                    <div className='text-red-500'>{errorMessage}</div>
                </div>}
                <Form handleOnSubmit={handleSubmit(onSubmit)}>

                    <ReactiveFormInput
                        control={control}
                        className='px-0 mt-5'
                        label={t("EMAIL")}
                        name="email"
                        isRequired />

                    <ReactiveFormInput
                        control={control}
                        className='px-0 relative '
                        label={t('PASSWORD')}
                        name="password"
                        type='password'
                        isRequired />
                    <div className='w-full flex justify-between mt-2 flex-col md:flex-row'>
                        <Button
                            classNames='w-full text-lg font-semibold py-2 flex flex-row justify-center mx-3'
                            isTypeSubmit
                            isDisabled={isLoading}>
                            <div className=' mr-2'>Sign In</div>
                            {isLoading && <SpinCircle />}
                        </Button>
                        <div className='flex w-fit text-right self-center justify-end mr-3 mt-4 md:mt-0'>
                            <a href={ForgotPasswordPath} className='flex self-end text-sm text-gray-500 hover:text-gray-900 transition duration-300'>Forgot your password?</a>
                        </div>
                    </div>
                </Form>
            </div>
            <div className='hidden md:block grow relative'>
                <div
                    className='w-full h-full bg-no-repeat bg-center bg-cover bg-blue shadow-lg'
                    style={{ backgroundImage: `url('${medico}')` }}>
                </div>
                <div className='absolute bottom-0 left-0 ml-4 mb-6 max-w-xl'>
                    <div className='text-white text-4xl font-bold mb-3'>Safeguarding Your Future with Confidence</div>
                    <div className='text-white text-lg'>Welcome to Asureis! Here in our home, we don't just sell insurance; we build
                        bridges to peace of mind and security. Our commitment is to stand by your side,
                        offering personalized insurance solutions that align with your unique needs and
                        goals.
                    </div>
                </div>
            </div>
        </div>
    )
}