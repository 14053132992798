import { useCallback, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createLead } from "../state/leads/actions";
import { AppDispatch } from "../state/store";
import { cleanPhoneBurner, setStateLeadCreated } from "../state/twilio/actions";
import { Form } from "./form/Form";
import { ReactiveFormInput } from "./form/ReactiveFormInput";
import { ReactiveFormPhoneInput } from "./form/ReactiveFormPhoneInput";

interface Props {
    isSubmitted: boolean,
    onSuccess: () => void,
    setIsSubmittedFalse: () => void,
}

const NewLead = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const twilio = useSelector((state: any) => state.twilio);
    const { t } = useTranslation();

    const methods = useForm<any>({
        defaultValues: {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            start_automation: 'No'
        }
    });
    const { handleSubmit, control, reset } = methods;

    useEffect(() => {
        reset({
            first_name: '',
            last_name: '',
            phone: twilio?.phoneBurner?.currentCall?.phone,
            email: '',
            start_automation: 'No'
        })
        dispatch(setStateLeadCreated(false));
    }, [twilio?.phoneBurner?.currentCall]);

    const createLeadFunction = useCallback((data: any) => {
        dispatch(createLead(data))
        dispatch(setStateLeadCreated(true));
        if (twilio?.phoneBurner?.remainingList?.length === 0) {
            reset();
            dispatch(cleanPhoneBurner());
        } else {
            props.onSuccess();
        }
    }, [dispatch, props, reset, twilio?.phoneBurner?.remainingList?.length]);

    useEffect(() => {
        if (props.isSubmitted) {
            props.setIsSubmittedFalse()
            handleSubmit(createLeadFunction)()
        }
    }, [createLeadFunction, handleSubmit, props])

    return <div className="ml-4">
        <h2 className="w-full text-md font-semibold self-center px-3">New Lead</h2>
        <div className="w-full overflow-auto flex flex-col relative md:overflow-auto pb-4" style={{ height: 'calc(100vh - 220px)' }}>
            <Form handleOnSubmit={handleSubmit(createLeadFunction)} className="py-2">
                <ReactiveFormInput
                    control={control}
                    className='md:w-1/1'
                    label={t("FIRST_NAME")}
                    name="first_name"
                    isRequired />
                <ReactiveFormInput
                    control={control}
                    className='md:w-1/1'
                    label={t("LAST_NAME")}
                    name="last_name"
                    isRequired />
                <ReactiveFormPhoneInput
                    control={control}
                    className='md:w-1/1'
                    name="phone"
                    label={t("PHONE")}
                    isRequired />
                <ReactiveFormInput
                    control={control}
                    className='md:w-1/1'
                    label={t("EMAIL")}
                    name="email"
                    isRequired />
            </Form>
        </div>
    </div>
}

export default NewLead;