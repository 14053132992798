import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { UserCredential, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/asureis-side.png';
import medico from '../../assets/images/medico.jpg';
import Button from '../../components/Button';
import { SpinCircle } from '../../components/SpinCircle';
import { Form } from '../../components/form/Form';
import { ReactiveFormInput } from '../../components/form/ReactiveFormInput';
import { LoginPath } from '../../constants/Routes';
import { Modal } from '../../components/Modal';
import { useNavigate } from 'react-router-dom';
import FirebaseService from '../../state/firebase/FirebaseService';

export const ForgotPassword = () => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const navigate = useNavigate();

    type LoginInput = {
        email: string,
    }
    const methods = useForm<LoginInput>({
        defaultValues: {
            email: "",
        }
    });

    const { handleSubmit, control, getValues } = methods;

    const onSubmit: SubmitHandler<LoginInput> = async (data: LoginInput) => {
        setErrorMessage('');
        const email = data.email.replace(/\s/g, '');
        try {
            setLoading(true)
            await FirebaseService.passwordReset(email);
        } catch (error: any) {
            if (error.code === 'auth/user-not-found') {
                setErrorMessage(t('USER_NOT_FOUND'));
            }
            else if (error.code === 'auth/invalid-email') {
                setErrorMessage(t('INVALID_EMAIL'))
            }
            else {
                const errorMessage = error.message;
                setErrorMessage(errorMessage)
            }
        } finally {
            setLoading(false)
            if (errorMessage === '') {
                setShowSuccessModal(true);
            }
        }
    };

    const returnToLoginPage = () => {
        setShowSuccessModal(false);
        navigate(LoginPath);
    };

    return (
        <div className='h-screen flex flex-row  relative '>
            <Modal isOnlyText hideCancelButton saveButton={{ icon: <ChevronLeftIcon />, label: t('BACK_TO_LOGIN'), onClick: returnToLoginPage }} isOpen={showSuccessModal} onClose={returnToLoginPage} onSave={returnToLoginPage} label={t('RESET_EMAIL_SENT')}>
                <p className='text-gray-700 text-center'>{t("RESET_EMAIL_SENT_DESCRIPTION").replace('#', getValues("email"))}</p>
            </Modal>
            <div className="absolute top-0 left-0 p-5">
                <img src={logo} alt="Asureis Logo" className='h-10 mr-auto' />
            </div>
            <div className='md:w-1/2 w-full h-full flex flex-col justify-center px-20'>
                <div className='font-bold text-2xl px-3'>{t('FORGOT_YOUR_PASSWORD')}</div>
                <div className='text-neutral-400 font-light px-3'>{t('ENTER_EMAIL_BELOW')}</div>

                {errorMessage && <div className='px-4 py-3 flex items-center border border-red-500 rounded mt-4 md:max-w-xl'>
                    <XMarkIcon className='text-red-500 h-6 w-6 mr-2' />
                    <div className='text-red-500'>{errorMessage}</div>
                </div>}
                <Form handleOnSubmit={handleSubmit(onSubmit)}>
                    <ReactiveFormInput
                        control={control}
                        className='px-0 mt-5'
                        label={t("EMAIL")}
                        name="email"
                        isRequired />
                    <div className='w-full flex flex-col md:flex-row justify-between mt-2'>
                        <Button
                            classNames='w-full text-lg font-semibold py-2 flex flex-row justify-center mx-3'
                            isTypeSubmit
                            isDisabled={isLoading}>
                            <div className=' mr-2'>{t('SEND_EMAIL')}</div>
                            {isLoading && <SpinCircle />}
                        </Button>
                        <div className='flex w-fit self-center justify-end mr-3 mt-4 md:mt-0'>
                            <a href={LoginPath} className='w-full flex self-end text-sm text-gray-500 hover:text-gray-900 transition duration-300'>
                                <ChevronLeftIcon width={15} height={15} className='self-end' /> <p className='ml-1'>{t('BACK_TO_LOGIN')}</p></a>
                        </div>
                    </div>
                </Form>
            </div>
            <div className='hidden md:block grow relative'>
                <div
                    className='w-full h-full bg-no-repeat bg-center bg-cover bg-blue shadow-lg'
                    style={{ backgroundImage: `url('${medico}')` }}>
                </div>
                <div className='absolute bottom-0 left-0 ml-4 mb-6 max-w-xl'>
                    <div className='text-white text-4xl font-bold mb-3'>Safeguarding Your Future with Confidence</div>
                    <div className='text-white text-lg'>Welcome to Asureis! Here in our home, we don't just sell insurance; we build
                        bridges to peace of mind and security. Our commitment is to stand by your side,
                        offering personalized insurance solutions that align with your unique needs and
                        goals.
                    </div>
                </div>
            </div>
        </div>
    )
}