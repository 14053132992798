import { useTranslation } from "react-i18next";
import { Client } from "../../../models/Client"
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { useEffect, useMemo, useState } from "react";
import { IncomeSource } from "../../../models/IncomeSource";
import { Toast } from "../../Toast";
import { deleteClientIncomeSource, fetchIncomeSourcesByClientId, needToLoadClientIncomeSource } from "../../../state/incomeSources/actions";
import { toast, ToastContainer } from "react-toastify";
import Button from "../../Button";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import FilteredTable from "../../FilteredTable";
import { LoadingMask } from "../../LoadingMask";
import { ClientIncomeModal } from "./ClientIncomeModal";
import { DeleteClientIncomeModal } from "./DeleteClientIncomeModal";
import { EmployeeTypeLabel } from "../../../enums/EmployeerType";

interface Props {
    client: Client | null
}

export const ClientIncomeTable = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [incomes, setIncomes] = useState<IncomeSource[]>([]);
    const clientIncomes = useSelector((state: RootState) => state.incomeSources.incomeSources);
    const isLoadingIncomes = useSelector((state: RootState) => state.incomeSources.isLoading);
    const needLoadClientIncomes = useSelector((state: RootState) => state.incomeSources.needToLoadIncomes);
    const error = useSelector((state: any) => state.incomeSources.error);
    const [showIncomeError, setShowIncomeError] = useState(false);

    const [clientIncomeInfoModal, setClientIncomeInfoModal] = useState<{
        show: boolean;
        update: boolean;
        income?: IncomeSource;
        index?: number;
        client?: Client;
    }>({ show: false, update: false });

    const [ showDeleteClientIncomeModal, setShowDeleteClientIncomeModal] = useState<{
        income?: IncomeSource;
        show: boolean;
    }>({ show: false, income: undefined });

    useEffect(() => {
        if (showIncomeError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showIncomeError, error, t]);

    useEffect(() => {
        //TODO: Review state and get data
        if(needLoadClientIncomes) {
            dispatch(fetchIncomeSourcesByClientId({clientId: props.client?.id!})).then((e) => {
                let incomes = (e.payload || []) as IncomeSource[];
                setIncomes(incomes);
            });
            dispatch(needToLoadClientIncomeSource(false));
        } else {
            setIncomes(clientIncomes);
        }
    }, []);

    const addIncomeSource = (income: IncomeSource) => {
        setIncomes(incomes => [...incomes, income])
    }

    const updateIncomeSource = (index: number, income: IncomeSource) => {
        setIncomes(incomes => incomes.map((ele, i) => {
            if (i === index) { return income }
            return ele
        }))
    }

    const removeIncomeSource = (income: IncomeSource) => {
        //TODO: remove address from database
        dispatch(deleteClientIncomeSource({ clientId: props.client?.id!, incomeId: income.id })).then((e) => {
            if (e.type === "client/deleteClientIncomeSource/rejected") {
                setShowIncomeError(true);
            } else {
                toast(t("SUCCESSFULLY_DELETE_CLIENT_INCOME_SOURCE"));
                let incomes = (e.payload || []) as IncomeSource[];
                setIncomes(incomes);
            }
        });
    }

    const columns = useMemo(
        () => [
            {
                Header: t("EMPLOYER_NAME"),
                accessor: "employer_name",
            },
            {
                Header: t("EMPLOYEE_TYPE"),
                Cell: (row: any) => row.row.original.employee_type_id ? `${EmployeeTypeLabel(row.row.original.employee_type_id)}` : "-",
            },
            {
                Header: t("PHONE"),
                accessor: "phone_number",
            },
            {
                Header: t("OCCUPATION"),
                accessor: "occupation",
            },
            {
                Header: t("INCOME"),
                accessor: "income",
            },
            {
                id: "actions",
                Header: "",
                Cell: (row: any) => {
                    return (
                        <div className="flex flex-row justify-center divide-x">
                            <Button
                                isTerciary
                                iconOnly
                                tooltip={t("UPDATE_INCOME_SOURCE")}
                                onClick={() => 
                                    setClientIncomeInfoModal({
                                        show: true,
                                        update: true,
                                        income: row.row.original,
                                        index: row.row.index,
                                        client: props.client!
                                    })
                                }
                                classNames="mr-2"
                            >
                                <PencilIcon className="h-5 aspect-square text-blue" />
                            </Button>
                            <Button
                                isTerciary
                                iconOnly
                                tooltip={t("DELETE_INCOME_SOURCE")}
                                onClick={() => setShowDeleteClientIncomeModal({ show: true, income: row.row.original })}
                                classNames="mr-2"
                            >
                                <TrashIcon className="h-5 aspect-square text-blue" />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [removeIncomeSource, t, updateIncomeSource]
    );

    return (
        <>
            <FilteredTable
                columns={columns}
                data={incomes}
                hasSearchBar={false}
                secondaryActionButton={
                    <Button onClick={() => setClientIncomeInfoModal({ show: true, update: false, income: undefined, client: props.client!, index:undefined })}>
                        <span className="flex items-center font-semibold pr-3">
                            <div className="w-8 p-1 aspect-square mr-2">
                                <PlusIcon />
                            </div>
                            {t("ADD_INCOME_SOURCE")}
                        </span>
                    </Button>
                }
            />
            <ClientIncomeModal
                info={clientIncomeInfoModal}
                onSubmited={(income, index) => {
                    if (index !== undefined) {
                        updateIncomeSource(index, income)
                    } else {
                        addIncomeSource(income)
                    }
                }}
                onClose={() => setClientIncomeInfoModal({ show: false, update: false, income: undefined, index:undefined })}
            ></ClientIncomeModal>
            <DeleteClientIncomeModal
              info={showDeleteClientIncomeModal}
              onClose={() => setShowDeleteClientIncomeModal({show : false, income: undefined})}
              deleteIncome={(income: IncomeSource) => removeIncomeSource(income)}
            />
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            { isLoadingIncomes && <LoadingMask />}
        </>
    )
}