import { useTranslation } from "react-i18next";
import { DocusignConfig } from "../../../models/DocusignConfig";
import { CheckIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ConfirmationModal } from "../../ConfirmationModal";

interface Props {
    info: {
        show: boolean;
        docusignConfig?: DocusignConfig;
    };
    onClose: () => void;
    deleteConfig: (config: DocusignConfig) => void
}

export const AgencyDeleteDocusignConfigModal: React.FC<Props> = ({
    info,
    onClose,
    deleteConfig
}) => {

    const { t } = useTranslation();

    const onDeleteConfig = () => {
        deleteConfig(info.docusignConfig!)
        onClose();
    };

    return(
        <ConfirmationModal 
            label={t("DELETE_DOCUSIGN_CONFIG")}
            isDeleteConfirm={true}
            isOpen={info.show}
            onClose={onClose}
            cancelButton={{ label: t("CLOSE"), icon: <XMarkIcon />, onClick: onClose, }}
            saveButton={{ label: t("DELETE"), icon: <TrashIcon />, onClick: onDeleteConfig }}
        >
            <div className='w-full flex flex-wrap items-center justify-center'>
                <label className="text-lg font-semibold py-4 px-2 text-center">
                    {t('DELETE_DOCUSIGN_CONFIG_MESSAGE', {label: info.docusignConfig?.label})}
                </label>
            </div>
        </ConfirmationModal>
    )
}