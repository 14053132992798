import i18next from "i18next";

export enum MessageTypeEnum {
    SUCCESS = 1,
    FAILED = 2
}

export const MessageTypeLabel = (key: string) => ({ 
    [MessageTypeEnum.SUCCESS]: i18next.t('SUCCESS'),
    [MessageTypeEnum.FAILED]: i18next.t('FAILED')
})[key];

export const getMessageType = () =>
    Object.values(MessageTypeEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: MessageTypeLabel(value) ?? ""
        }
    });