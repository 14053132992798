import { CheckIcon, DocumentArrowDownIcon, DocumentArrowUpIcon, PhoneArrowUpRightIcon, PhoneIcon, PhoneXMarkIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as xlsx from 'xlsx';
import uploadPhoneListTemplate from '../../assets/templates/uploadPhoneListTemplate.xlsx';
import { BurnerCall } from "../../models/TwilioCall";
import { AppDispatch } from "../../state/store";
import { callNextPhoneInBurner, cleanPhoneBurner, removePhoneFromBurner, setPhoneBurner } from "../../state/twilio/actions";
import Button from "../Button";
import { ReactiveFormUploadFile } from "../form/ReactiveFormUploadFile";
import { LoadingMask } from "../LoadingMask";
import { Modal } from "../Modal";
import NewLead from "../NewLead";
import Table from "../Table";

interface Props {
    endCall: () => void;
}

export const PhoneBurner = (props: Props) => {
    const { t } = useTranslation();
    const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
    const [isUploadingPhones, setIsUploadingPhones] = useState(false);
    const [isLeadFormSubmitted, setIsLeadFormSubmitted] = useState(false);
    const twilio = useSelector((state: any) => state.twilio);
    const dispatch = useDispatch<AppDispatch>();

    const methods = useForm<{}>();
    const { control } = methods;

    const currentCall = useMemo(() => twilio?.phoneBurner?.currentCall, [twilio?.phoneBurner])

    const isCallingList = useMemo(() => twilio?.phoneBurner?.remainingList?.length > 0 || twilio?.phoneBurner?.currentCall, [twilio?.phoneBurner]);

    const phonesFromFile = useMemo(() => twilio?.phoneBurner?.remainingList, [twilio?.phoneBurner])

    const openUploadPhoneListModal = () => {
        setIsOpenUploadModal(true);
    };

    const closeUploadPhoneListModal = () => {
        setIsOpenUploadModal(false);
    };

    const handleDownload = useCallback(() => {
        const link = document.createElement('a');
        link.href = link.href = uploadPhoneListTemplate;
        link.download = 'uploadPhoneListTemplate.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, []);

    const handlePhoneListFile = (e: any) => {
        setIsUploadingPhones(true);
        setIsOpenUploadModal(false);
        const reader = new FileReader();
        reader.readAsBinaryString(e[0]);
        reader.onload = (e: any) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "binary", cellDates: true, cellNF: false, cellText: false });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = xlsx.utils.sheet_to_json(sheet);
            const phoneList = parsedData.map((row: any) => {
                const d = {
                    name: row['Name'],
                    phone: `+${row['Phone']}`
                } as BurnerCall
                return d;
            });
            dispatch(setPhoneBurner(phoneList));
            setIsUploadingPhones(false);
        }
    }

    const callNext = useCallback(() => {
        dispatch(callNextPhoneInBurner());
        const params = { To: phonesFromFile[0].phone, label: phonesFromFile[0].name };
        twilio.device.connect(params);
    }, [dispatch, phonesFromFile, twilio.device]);

    /*
    const callSelectedClients = async () => {
        setIsCallingList(true);
        for (let index = 0; index < selectedClients.length; index++) {
            setIsPreparingCall(true);
            const client = selectedClients[index];
            setSelectedClients(prevValues => prevValues.filter(x => x.id !== client.id));
            if (twilio.device) {
                setPhoneNumber(client.phone);
                const params = { To: client.phone };
                const connection = twilio.device.connect(params);

                connection.on('accept', () => {
                    setIsCurrentCall(true);
                    setIsPreparingCall(false);
                });

                // Use Promise to wait for the 'disconnect' event
                await new Promise<void>((resolve) => {
                    connection.on('disconnect', () => {
                        props.endCall();
                        setIsCurrentCall(false);
                        resolve(); // Resolve the Promise when the call is disconnected
                    });
                });
            }
        }
        setIsCallingList(false);
    };
    */

    const getUploadedPhoneNumbers = useMemo(() => {
        return phonesFromFile?.map((phone: BurnerCall, index: number) => {
            return {
                columns: [
                    { value: phone.name },
                    { value: phone.phone },
                    {
                        value: <td className="flex flex-row justify-end divide-x">
                            <Button
                                isTerciary
                                iconOnly
                                tooltip={t("REMOVE")}
                                onClick={() => dispatch(removePhoneFromBurner(index))} classNames="mr-2 justify-self-end"><XMarkIcon className="h-5 aspect-square stroke-blue" />
                            </Button>
                        </td>
                    }
                ]
            }
        }) ?? [];
    }, [dispatch, phonesFromFile, t]);

    const showUploadPhoneListModal = () => {
        return <Modal thirdButton={{
            label: t("PHONE_LIST_TEMPLATE"),
            onClick: () => handleDownload(),
            icon: <DocumentArrowDownIcon />
        }} isOpen={isOpenUploadModal} onClose={closeUploadPhoneListModal} label={t('UPLOAD_PHONE_LIST')} >
            {isUploadingPhones && <LoadingMask />}
            <div className='w-full flex flex-wrap'>
                <ReactiveFormUploadFile
                    control={control}
                    className='md:w-1/1'
                    label={t("LEADS_FILE")}
                    name="leads_file"
                    handleOnChange={handlePhoneListFile}
                    handleOnDrop={handlePhoneListFile} />
            </div>
        </Modal>
    }

    const saveAndCall = () => {
        setIsLeadFormSubmitted(true);
    }

    return <div className="flex flex-col w-full h-full p-5">
        <div className="flex-grow flex min-h-0">
            <div
                className={classNames({
                    "relative flex flex-col overflow-auto": true,
                    "lg:w-1/2": isCallingList,
                    "w-full": !isCallingList,
                })}
            >
                {showUploadPhoneListModal()}
                <div className="flex justify-between mb-4">
                    <h2 className="text-md font-semibold self-center">
                        {t("PHONE_BURNER")}
                    </h2>
                    {isCallingList && (
                        <Button
                            tooltip={t("CLEAR_PHONE_BURNER")}
                            tooltipClassName="right-12 top-1 h-fit"
                            iconOnly
                            isSecondary
                            onClick={() => dispatch(cleanPhoneBurner())}
                        >
                            <TrashIcon className="w-6 text-asureis-gray" />
                        </Button>
                    )}
                </div>
                {isCallingList ? (
                    <div className="w-full px-4 mb-3">
                        {currentCall && (
                            <div>
                                <h2 className="text-md font-semibold">{t("CURRENT_CALL")}</h2>
                                <div className="flex mt-4 mb-6 px-6 gap-8 py-2 bg-asureis-gray text-white rounded-md">
                                    <p className="font-semibold">{currentCall.name}</p>
                                    <p className="font-semibold">{currentCall.phone}</p>
                                </div>
                            </div>
                        )}
                        <h2 className="text-md font-semibold">{t("NEXT_IN_LINE")}</h2>
                        <Table
                            columns={[
                                { header: `${t("NAME")}` },
                                { header: `${t("PHONE")}` },
                                { header: "" },
                            ]}
                            data={getUploadedPhoneNumbers}
                        />
                    </div>
                ) : (
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 gap-4 flex flex-col items-center">
                        <Button onClick={openUploadPhoneListModal}>
                            <span className="flex items-center font-semibold pr-3">
                                <div className="w-8 p-1 aspect-square mr-2">
                                    <DocumentArrowUpIcon />
                                </div>
                                {t("UPLOAD_PHONE_LIST")}
                            </span>
                        </Button>
                    </div>
                )}
            </div>
            {isCallingList && (
                <div className="flex flex-col relative w-full h-fit lg:w-1/2">
                    <NewLead setIsSubmittedFalse={() => setIsLeadFormSubmitted(false)} isSubmitted={isLeadFormSubmitted} onSuccess={callNext} />
                </div>
            )}
        </div>
        {isCallingList && twilio?.phoneBurner?.currentCall && twilio?.phoneBurner?.remainingList?.length > 0 && (
            <div className="flex gap-4 justify-end">
                <Button
                    onClick={callNext}
                    isTerciary
                    classNames="relative w-full mt-2"
                >
                    <span className="flex items-center font-semibold place-content-center">
                        <div className="w-8 p-1 aspect-square mr-2">
                            <PhoneXMarkIcon />
                        </div>
                        {t("NEXT_WITHOUT_SAVING")}
                    </span>
                </Button>
                <Button
                    onClick={saveAndCall}
                    classNames="relative w-full mt-2"
                >
                    <span className="flex items-center font-semibold place-content-center">
                        <div className="w-8 p-1 aspect-square mr-2">
                            <PhoneArrowUpRightIcon />
                        </div>
                        {t("SAVE_NEXT")}
                    </span>
                </Button>
            </div>
        )}
        {isCallingList && !twilio?.phoneBurner?.currentCall && (
            <div className="flex gap-4 justify-end">
                <Button
                    onClick={callNext}
                    classNames="relative w-full mt-2"
                >
                    <span className="flex items-center font-semibold place-content-center">
                        <div className="w-8 p-1 aspect-square mr-2">
                            <PhoneIcon />
                        </div>
                        {t("CALL_NEXT_IN_LINE")}
                    </span>
                </Button>
            </div>
        )}
        {isCallingList && twilio?.phoneBurner?.currentCall && twilio?.phoneBurner?.remainingList?.length === 0 && (
            <div className="flex gap-4 justify-end">
                <Button
                    onClick={() => dispatch(cleanPhoneBurner())}
                    isTerciary
                    classNames="relative w-full mt-2"
                >
                    <span className="flex items-center font-semibold place-content-center">
                        <div className="w-8 p-1 aspect-square mr-2">
                            <XMarkIcon />
                        </div>
                        {t("CLOSE_PHONE_BURNER")}
                    </span>
                </Button>
                <Button
                    onClick={saveAndCall}
                    classNames="relative w-full mt-2"
                >
                    <span className="flex items-center font-semibold place-content-center">
                        <div className="w-8 p-1 aspect-square mr-2">
                            <CheckIcon />
                        </div>
                        {t("SAVE_AND_CLOSE")}
                    </span>
                </Button>
            </div>
        )}
    </div>

}