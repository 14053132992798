import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../axiosClient";
import { Carrier, EditCarrier, NewCarrier } from "../../models/Carrier";
import { AxiosResponse } from "axios";

export const fetchAgencyCarriers = createAsyncThunk("carriers/fetchAgencyCarriers", async (agencyId: number) => {
    const response: AxiosResponse<Carrier[]> = await (await axiosClient()).get(`/agencies/${agencyId}/carriers`);
    return response.data;
});

export const createAgencyCarrier = createAsyncThunk("campaign/createAgencyCarrier", async (params:{agencyId: number, carrierNew: NewCarrier}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Carrier> = await (await axiosClient()).post(`/agencies/${params.agencyId}/carriers`, {...params.carrierNew});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateAgencyCarrier = createAsyncThunk("campaign/updateAgencyCarrier", async (params:{agencyId: number, carrierId: number, campaignNew: EditCarrier}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Carrier> = await (await axiosClient()).patch(`/agencies/${params.agencyId}/carriers/${params.carrierId}`, {...params.campaignNew});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteAgencyCarrier = createAsyncThunk("campaign/deleteAgencyCarrier", async (params:{agencyId: number, carrierId: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Carrier[]> = await (await axiosClient()).delete(`/agencies/${params.agencyId}/carriers/${params.carrierId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchCarriersForPolicyType = createAsyncThunk("carriers/fetchCarriersForPolicyType", async (policyTypeId: number) => {
    const response: AxiosResponse<Carrier[]> = await (await axiosClient()).get(`/policy-types/${policyTypeId}/carriers`);
    return response.data;
});