import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import { useRef } from "react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";

interface Props {
    name: string
    value?: string | number
    label: string
    onClick?: () => void;
    handleOnChange?: (event: any) => void
    handleOnDrop?: (event: any) => void
    className?: string
    placeholder?: string
    error?:string
    isDisabled?:boolean
}

export const FormUploadFile = (props: Props) => {
    const { t } = useTranslation();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleUploadClick = () => {
        // 👇 We redirect the click event onto the hidden input element
        inputRef.current?.click();
    };

    return (
        <div className={"w-full px-3 " + props.className ?? ""}>
            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                {props.label}
            </label>
            <div className={classNames({
                "appearance-none block w-full border border-dashed border-2 border-gray-200 text-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:border-blue": true,
                "border-red-500": props.error,
                "bg-slate-100": props.isDisabled == true
            })}
            onDrop={props.handleOnDrop}
            onDragOver={(event) => event.preventDefault()}
            >
                <div className="w-full flex justify-center px-3 pb-2 pt-8">
                    <ArrowUpTrayIcon className="w-6 text-gray-400"/>
                </div>
                <div className="w-full flex justify-center px-3 pb-2">
                    <label>
                        {t("DROP_FILES")}
                    </label>
                </div>
                <div className="w-full flex justify-center pb-8">
                    <Button isTypeSubmit={false} onClick={handleUploadClick}>
                        <span className='flex items-center font-semibold px-3 py-2'>
                            {t('SELECT_FILES')}
                        </span>
                    </Button>
                </div>
                <input
                    className="hidden"
                    type="file"
                    multiple={true}
                    ref={inputRef}
                    placeholder={props.placeholder ?? ""}
                    name={props.name}
                    onChange={props.handleOnChange}
                    value={props.value}
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                />
            </div>
        </div>
    );
}