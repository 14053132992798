import { Reminder } from "../../models/Reminder"
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createUserReminder, deleteUserReminder, fetchUserReminders, removeUserNotificationReminder, setUserNotificationReminder, setUserTodayReminders, updateUserReminder } from "./action";

interface ReminderState {
    reminders: Reminder[],
    isReminderLoading: boolean,
    isReminderLoaded: boolean,
    error: string | null,
    todayReminders: Reminder[],
    noticationReminders: Reminder[]
}

const initialState: ReminderState = {
    reminders: [],
    isReminderLoading: false,
    isReminderLoaded: false,
    error: null,
    todayReminders: [],
    noticationReminders: []
}

const reminderSlice = createSlice({
    name: "reminders",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserReminders.pending, (state) => {
                state.isReminderLoading = true;
                state.error = null
            })
            .addCase(fetchUserReminders.fulfilled, (state, action: PayloadAction<Reminder[]>) => {
                state.isReminderLoading = false;
                state.reminders = action.payload;
                state.isReminderLoaded = true;
            })
            .addCase(fetchUserReminders.rejected, (state, action: any) => {
                state.isReminderLoading = false;
                state.error = action.payload;
            })
            .addCase(createUserReminder.pending, (state) => {
                state.isReminderLoading = true;
                state.error = null
            })
            .addCase(createUserReminder.fulfilled, (state, action: PayloadAction<Reminder>) => {
                state.isReminderLoading = false;
                if(action.payload) {
                    state.reminders = state.reminders.concat(action.payload);
                }
            })
            .addCase(createUserReminder.rejected, (state, action: any) => {
                state.isReminderLoading = false;
                state.error = action.payload;
            })
            .addCase(updateUserReminder.pending, (state) => {
                state.isReminderLoading = true;
                state.error = null
            })
            .addCase(updateUserReminder.fulfilled, (state, action: PayloadAction<Reminder>) => {
                state.isReminderLoading = false;
                const updatedReminder = action.payload;
                const updatedReminders = state.reminders.map((reminder) => {
                    return reminder.id === updatedReminder.id ? updatedReminder : reminder
                });
                state.reminders = updatedReminders;
            })
            .addCase(updateUserReminder.rejected, (state, action: any) => {
                state.isReminderLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteUserReminder.pending, (state) => {
                state.isReminderLoading = true;
                state.error = null
            })
            .addCase(deleteUserReminder.fulfilled, (state, action: PayloadAction<Reminder>) => {
                const deletedReminder = action.payload;
                state.isReminderLoading = false;
                const idx = state.reminders.findIndex(x => x.id === deletedReminder.id);
                state.reminders.splice(idx, 1);
            })
            .addCase(deleteUserReminder.rejected, (state, action: any) => {
                state.isReminderLoading = false;
                state.error = action.payload;
            })
            .addCase(setUserTodayReminders.fulfilled, (state, action: PayloadAction<Reminder[]>) => {
                state.todayReminders = action.payload;
            })
            .addCase(setUserNotificationReminder.fulfilled, (state, action: PayloadAction<Reminder>) => {
                let nReminder = state.noticationReminders;
                let index = nReminder.findIndex((r) => r.id === action.payload.id);
                if(index === -1) {
                    nReminder.push(action.payload);
                    state.noticationReminders = nReminder;
                }
            })
            .addCase(removeUserNotificationReminder.fulfilled, (state, action: PayloadAction<Reminder>) => {
                let nReminder = state.noticationReminders;
                let index = nReminder.findIndex((r) => r.id === action.payload.id);
                if(index !== -1) {
                    nReminder.splice(index, 1);
                    state.noticationReminders = nReminder;
                }
            });
    }
})

export default reminderSlice.reducer;