import classNames from "classnames";
import { memo } from "react";

const IdentifierDot = memo(({ isClient }: { isClient?: boolean }) => (
  <span
    className={classNames({
      "w-3 h-3 min-w-3 rounded-full": true,
      "bg-blue": isClient,
      "bg-asureis-gray-4": !isClient,
    })}
  />
));

export default IdentifierDot;
