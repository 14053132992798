import { useTranslation } from "react-i18next"
import { Beneficiary } from "../../../../models/Beneficiary"
import { Client } from "../../../../models/Client"
import { Policy } from "../../../../models/Policies"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../state/store"
import { useEffect, useState } from "react"
import { Toast } from "../../../Toast"
import { PolicyBeneficiariesTable } from "./PolicyBeneficiariesTable"
import Button from "../../../Button"
import { PlusIcon } from "@heroicons/react/24/outline"
import { ToastContainer } from "react-toastify"
import { LoadingMask } from "../../../LoadingMask"
import { PolicyBeneficiaryModal } from "./PolicyBeneficiaryModal"
import { fetchPolicyBeneficiaries } from "../../../../state/policies/actions"

interface Props {
    isNewClient:boolean
    beneficiaries?:Beneficiary[]
    //client: Client | null
    policy: Policy | null
    beneficiariesHasChanged?: (beneficiaries:Beneficiary[]) => void
}

export const PolicyBeneficiariesForm = (props:Props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    //const [cPolicies, setCPolicies] = useState<Policy[]>([]);
    //const clientPolicies = useSelector((state: RootState) => state.policies.clientPolicies);
    const isLoadingBeneficiaries = useSelector((state: RootState) => state.policies.isLoadingBeneficiaries);
    const error = useSelector((state: any) => state.policies.error);
    const [showBeneficiaryError, setShowBeneficiaryError] = useState(false);

    const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>(props.beneficiaries ? props.beneficiaries : [])
    
    const [showPolicyBeneficiaryModal, setPolicyBeneficiaryModal] = useState<{
        show: boolean;
        update: boolean;
        beneficiary?: Beneficiary;
        index?: number;
        //client?: Client;
        //isNewClient: boolean;
    }>({ show: false, update: false, /*isNewClient: props.isNewClient*/ });

    /*const [ showDeleteClientPolicyModal, setShowDeleteClientPolicyModal] = useState<{
        policy?: Policy;
        show: boolean;
    }>({ show: false, policy: undefined });*/

    useEffect(() => {
        if (showBeneficiaryError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showBeneficiaryError, error, t]);

    useEffect(() => {
        //TODO: Review state and get data
        if(!props.isNewClient && props.policy !== null) {
            dispatch(fetchPolicyBeneficiaries({policyId: props.policy?.id!})).then((e) => {
                let beneficiaries = (e.payload || []) as Beneficiary[];
                setBeneficiaries(beneficiaries);
            });
        }
    }, []);

    const removeBeneficiary = (index:number) => {
        console.log(index);
        setBeneficiaries(value => value.filter((_, i) => i !== index))
    }

    const appendBeneficiary = (beneficiary: Beneficiary) => {
        setBeneficiaries(beneficiaries => [...beneficiaries, beneficiary])
    }

    const updateBeneficiary = (index:number, beneficiary: Beneficiary) => {
        setBeneficiaries(beneficiaries => beneficiaries.map((ele,i) => {
            if(i === index) { return beneficiary}
            return ele
        }))
    }

    useEffect(() => {
        if(props.beneficiariesHasChanged){props.beneficiariesHasChanged(beneficiaries)}
    }, [beneficiaries])

    /*const deleteBeneficiary = (beneficiary: Beneficiary) => {
        //TODO: remove address from database
        dispatch(deleteClientPolicy({ clientId: props.client?.id!, policyId: policy.id! })).then((e) => {
            if (e.type === "client/deleteClientPolicy/rejected") {
                setShowPolicyError(true);
            } else {
                toast(t("SUCCESSFULLY_DELETE_CLIENT_POLICY"));
                let policies = (e.payload || []) as Policy[];
                setPolicies(policies);
            }
        });
    }*/

    return (
        <>
            <div className="w-full mt-4">
                <div className="flex flex-row justify-between items-center px-3">
                    <span className="text-gray-600 text-lg">{t("BENEFICIARIES")}</span>
                    {   beneficiaries.length < 5 && 
                        <Button onClick={() => setPolicyBeneficiaryModal({ 
                                show: true,
                                update: false,
                                //isNewClient: props.isNewClient, 
                                //client: props.client !== null ? props.client : undefined
                            })}
                        >
                            <span className="flex items-center font-semibold pr-3">
                                <div className="w-8 p-1 aspect-square mr-1">
                                    <PlusIcon />
                                </div>
                                {t("ADD_BENEFICIARY")}
                            </span>
                        </Button>
                    }
                </div>
                <div className="px-3 py-4">
                    {
                        isLoadingBeneficiaries && 
                        <div className="flex items-center justify-center mt-4">
                            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-gold"></div>
                        </div>
                    }
                    {
                        !isLoadingBeneficiaries && 
                        <PolicyBeneficiariesTable
                            //isNewClient={props.isNewClient}
                            beneficiaries={beneficiaries}
                            removeBeneficiary={
                                (index: number) => removeBeneficiary(index)
                            }
                            updateBeneficiary={(index: any, beneficiary: Beneficiary) =>
                                setPolicyBeneficiaryModal({
                                    show: true,
                                    update: true,
                                    beneficiary: beneficiary,
                                    index: index,
                                    //client: props.client !== null ? props.client : undefined,
                                    //isNewClient: props.isNewClient
                                })
                            }
                        />
                    }
                </div>
            </div>
            <PolicyBeneficiaryModal
                info={showPolicyBeneficiaryModal}
                onClose={() => setPolicyBeneficiaryModal({ show: false, update: false, /*isNewClient: props.isNewClient*/ })}
                addBeneficiary={(beneficiary: Beneficiary) => appendBeneficiary(beneficiary)}
                updateBeneficiary={(index: number, beneficiary: Beneficiary) =>
                    updateBeneficiary(index, beneficiary)
                }
            />
            {/*<DeleteClientPolicyModal
                info={showDeleteClientPolicyModal}
                onClose={() => setShowDeleteClientPolicyModal({show : false, policy: undefined})}
                deletePolicy={(policy: Policy) => deletePolicy(policy)}
            />*/}
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            {/*isLoadingPolicies && <LoadingMask />*/}
        </>
    )
}