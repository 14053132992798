import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { getPolicyStates } from "../../../enums/PolicyState";
import {
  newPolicyInitialValues,
  Policy,
} from "../../../models/Policies";
import { Modal } from "../../Modal";
import { ReactiveFormInput } from "../../form/ReactiveFormInput";
import { ReactiveFormRadioButtonSelect } from "../../form/ReactiveFormRadioButtonSelect";
import { ReactiveFormSelect } from "../../form/ReactiveFormSelect";
import { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RoleEnum } from "../../../enums/Role";
import { User } from "../../../models/User";
import { AgentCommissionRule } from "../../../models/AgentCommissionRule";
import { fetchAgencyUserCommissionRules } from "../../../state/agencies/action";
import { AppDispatch, RootState } from "../../../state/store";
import { ReactiveFormLocalDateInput } from "../../form/ReactiveFormLocalDateInput";
import moment from "moment";
import { createClientPolicy, updateClientPolicy } from "../../../state/policies/actions";
import { Client } from "../../../models/Client";
import { Toast } from "../../Toast";
import { ConfirmationModal } from "../../ConfirmationModal";
import { PaperAirplaneIcon, PencilIcon, PlusIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { AgencyPolicyType, PolicyMarket, PolicyType } from "../../../models/PolicyMarket";
import { MessageTypeEnum } from "../../../enums/MessageType";
import { SuccessErrorModal } from "../../SuccessErrorModal";
import Button from "../../Button";
import { PolciyMarketEnum } from "../../../enums/PolicyMarket";
import { PolicyBeneficiariesTable } from "./beneficiary/PolicyBeneficiariesTable";
import { LoadingMask } from "../../LoadingMask";
import { Beneficiary } from "../../../models/Beneficiary";
import { PolicyBeneficiariesForm } from "./beneficiary/PolicyBeneficiariesForm";
import { Carrier } from "../../../models/Carrier";
import { fetchCarriersForPolicyType } from "../../../state/carriers/actions";

interface Props {
  addPolicy: (policy: Policy) => void;
  updatePolicy: (index: number, policy: Policy) => void;
  info: {
    show: boolean;
    update: boolean;
    policy?: Policy;
    client?: Client;
    index?: number;
    isNewClient:boolean;
  };
  onClose: () => void;
}

export const ClientPolicyModal: React.FC<Props> = ({
  addPolicy,
  info,
  onClose,
  updatePolicy,
}) => {
  const { t } = useTranslation();

  const methods = useForm<Policy>({
    defaultValues: newPolicyInitialValues,
  });
  const { handleSubmit, control, setValue, getValues, reset } = methods;

  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const agents = useSelector((state: any) => state.users.users);
  const agentAvailableMarkets = useSelector((state: RootState) => state.policyMarkets.agencyPolicyTypes);

  const [selectedPolicyMarket, setSelectedPolicyMarket] = useState<PolicyMarket>()
  const [selectedPolicyType, setSelectedPolicyType] = useState<PolicyType>()
  const [policyTypes, setPolicyTypes] = useState<PolicyType[]>([]);
  const [loadingTypes, setLoadingTypes] = useState(false);

  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [loadingCarriers, setLoadingCarriers] = useState(false);

  const [commissionRules, setCommissionRules] = useState<AgentCommissionRule[]>([]);
  const [selectedCommissionRule, setSelectedCommissionRule] = useState<AgentCommissionRule | undefined>(undefined);
  const [loadingRules, setLoadingRules] = useState(false);

  const isLoading = useSelector((state: RootState) => state.policies.isLoading);
  const error = useSelector((state: any) => state.dependents.error);
  const [showPolicyError, setShowPolicyError] = useState(false);

  const [isWRenewalConfirmationModalOpen, setIsWRenewalConfirmationModalOpen] = useState(false);
  let hasToSendWRenewalMessage = false;
  const [newPolicyContinueData, setNewPolicyContinueData] = useState<Policy>();

  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([])

  const [showSuccessErrorModal, setShowSuccessErrorModal] = useState<{
    show: boolean;
    messageType: number;
    message: string;
  }>({ show: false, messageType: 2, message: "" });

  useEffect(() => {
      if (showPolicyError) {
          Toast(
              t(error?.reason ?? "SOMETHING_WENT_WRONG"),
              t(error?.cause_info)
          );
      }
  }, [showPolicyError, error, t]);

  useEffect(() => {
    info.policy ? reset(info.policy) : reset(newPolicyInitialValues);

    if (!info.policy) {
      setValue("sold_at", moment().toDate());
    }

    if (info.policy) {
      if (info.policy.agent_id) {
        if (agents.find((x: any) => x.id === +info.policy!.agent_id!)) {
          setLoadingRules(true)
          dispatch(fetchAgencyUserCommissionRules({agencyId: currentUser.agency_id, userId: +info.policy.agent_id!})).then((e) => {
            let rules = (e.payload || []) as AgentCommissionRule[];
            setCommissionRules(rules)
  
            if (info.policy?.agent_commission_rule_id) {
              setSelectedCommissionRule(rules.find((x: AgentCommissionRule) => x.id === +info.policy?.agent_commission_rule_id!))
            }
  
            setLoadingRules(false)
          })
        } else {
          info.policy.agent_id = undefined;
        }
      }
  
      if (info.policy.policy_market_id) {
        let aAvailableMarket = agentAvailableMarkets.find((market) => market.market_id === +info.policy?.policy_market_id!);
        setPolicyTypes(aAvailableMarket?.policy_types!);
        setSelectedPolicyMarket({id: aAvailableMarket?.market_id!, name: aAvailableMarket?.market_name!});
        setBeneficiaries(info.policy?.beneficiaries ?? []);

        if(aAvailableMarket?.policy_types !== undefined) {
          let policyType = aAvailableMarket.policy_types.find((p) => p.id === +info.policy?.policy_type_id!);
          if(policyType !== undefined) {
            setSelectedPolicyType(policyType);
            setLoadingCarriers(true)
            dispatch(fetchCarriersForPolicyType(+info.policy?.policy_type_id!)).then((e) => {
              setCarriers((e.payload || []) as Carrier[])
              setLoadingCarriers(false)
            })
          }
        }
      }
    }
    
  }, [info.policy, reset]);
  
  const onSubmitInvalid: SubmitErrorHandler<Policy> = (data) => {
    toast(t("PLEASE_ENTER_ALL_POLICY_REQUIRED_FIELDS"));
  }

  const onSubmit: SubmitHandler<Policy> = async (data: Policy) => {
    if (checkInvalidEndDate(data)) {
      handleShowErrorModal(t("POLICY_EFFECTIVE_END_DATE_MESSAGE"));
    } else if (checkPolicyMembers(data)) {
      handleShowErrorModal(t("POLICY_MEMBERS_MESSAGE"));
    } else if (checkPolicyState(data)) {
      handleShowErrorModal(t("POLICY_STATE_MESSAGE"));
    } else {
      const currentDate = moment(new Date());
      const soldAt = moment(data.sold_at).hours(currentDate.hours()).minutes(currentDate.minutes()).seconds(currentDate.seconds()).toDate();
      data.sold_at = soldAt;
      data.beneficiaries = beneficiaries;

      if(info.isNewClient) {
        info.update ? updatePolicy(Number(info.index), data) : addPolicy(data);
        closeModal();
      } else {
        if(info.update) {
          //TODO: update
          dispatch(updateClientPolicy({policy: data, policyId: info.policy?.id!, clientId: info.client?.id!})).then((e) => {
              if (e.type === "client/updateClientPolicy/rejected") {
                setShowPolicyError(true);
              } else {
                let policy = (e.payload || {}) as Policy;
                toast(t("SUCCESSFULLY_UPDATE_CLIENT_POLICY"));
                updatePolicy(Number(info.index), policy)
                closeModal();
              }
          });
        } else {
          setIsWRenewalConfirmationModalOpen(true);
          setNewPolicyContinueData(data);
        }
      }
    }
  };

  const createNewPolicy = async(data: Policy) => {
    //Todo: add
    dispatch(createClientPolicy({policy: data, clientId: info.client?.id!, hasToSendRenewalMessage: hasToSendWRenewalMessage})).then((e) => {
      if (e.type === "client/createClientPolicy/rejected") {
          setShowPolicyError(true);
      } else {
          let policy = (e.payload || {}) as Policy;
          toast(t("SUCCESSFULLY_SAVED_CLIENT_POLICY"));
          addPolicy(policy)
          closeModal();
      }
    });
  } 

  const closeModal = () => {
    reset();
    setSelectedCommissionRule(undefined);
    setCommissionRules([])
    setPolicyTypes([])
    setSelectedPolicyMarket(undefined);
    setSelectedPolicyType(undefined);
    setBeneficiaries([]);
    setCarriers([]);
    onClose();
  };

  const showPolicyMessageConfirmationModal = () => {
    return (
      <ConfirmationModal
        isOpen={isWRenewalConfirmationModalOpen}
        onClose={cancelWelcomeRenewalMessage}
        label={t("WELCOME_RENEWAL_MESSAGE")}
        cancelButton={{
          label: t("NO"),
          icon: <XMarkIcon />,
          onClick: cancelWelcomeRenewalMessage,
        }}
        saveButton={{
          label: t("SEND"),
          icon: <PaperAirplaneIcon />,
          onClick: sendWelcomeRenewalMessage,
        }}
      >
        {/*isConfirmationLoading && <LoadingMask />*/}
        <div className="w-full flex flex-wrap items-center justify-center">
          <label className="text-lg font-medium py-4">
            {t("SEND_WELCOME_RENEWAL_MESSAGE")}
          </label>
        </div>
      </ConfirmationModal>
    );
  };

  const cancelWelcomeRenewalMessage = () => {
      setIsWRenewalConfirmationModalOpen(false);
      hasToSendWRenewalMessage = false;
      if (newPolicyContinueData != undefined) {
        createNewPolicy(newPolicyContinueData);
      }
  };

  const sendWelcomeRenewalMessage = () => {
      setIsWRenewalConfirmationModalOpen(false);
      hasToSendWRenewalMessage = true;
      if (newPolicyContinueData != undefined) {
        createNewPolicy(newPolicyContinueData);
      }
  };

  const checkInvalidEndDate = (data: Policy) => {
    let policiesInvalidDates = 0;

    if (selectedPolicyType && selectedPolicyType.has_effective_end_date) {
      if (data != undefined) {
        if (data.carrier_id != undefined) {
          if (data.id == undefined) {
            if (data.effective_end_date != undefined) {
              if (moment(data.effective_end_date).isValid()) {
                if (moment(data.effective_end_date).isSameOrBefore(data.effective_start_date)) {
                  policiesInvalidDates++;
                }
              } else {
                policiesInvalidDates++;
              }
            } else {
              policiesInvalidDates++;
            }
          }
        }
      }
    }

    return policiesInvalidDates > 0;
  };

  const checkPolicyMembers = (data: Policy) => {
    let policiesMembers = 0;

    if (data != undefined) {
      if (data.carrier_id != undefined) {
        if (data.members_insured != undefined) {
          if (data.members_insured <= 0) {
            policiesMembers++;
          }
        } else {
          policiesMembers++;
        }
      }
    }

    return policiesMembers > 0;
  };

  const checkPolicyState = (data: Policy) => {
    let policiesState = 0;

    if (data != undefined) {
      if (data.carrier_id != undefined) {
        if (data.policy_state == undefined || data.policy_state == null) {
          policiesState++;
        }
      }
    }

    return policiesState > 0;
  };

  const handleShowErrorModal = (message: string) => {
    setShowSuccessErrorModal({
      show: true,
      messageType: MessageTypeEnum.FAILED,
      message: message,
    });
  };

  const handleCloseErrorModal = () => {
    setShowSuccessErrorModal({
      show: false,
      messageType: MessageTypeEnum.FAILED,
      message: "",
    });
  };

  const policyBeneficiariesHasChanged = (cBeneficiaries: Beneficiary[]) => {
    setBeneficiaries(cBeneficiaries)
  }

  return (
    <>
      <Modal
        isOpen={info.show}
        onClose={closeModal}
        label={info.update ? t("UPDATE_POLICY") : t("ADD_POLICY")}
        onSave={handleSubmit(onSubmit, onSubmitInvalid)}
      >
        <div className="w-full">
          <div className="w-full flex flex-wrap">
            <Fragment>
              {/*<ReactiveFormInput
                control={control}
                className="md:w-1/3"
                label={t("POLICY_NUMBER")}
                name={`number`}
              />*/}
              {
                agentAvailableMarkets.length > 0 &&
                <>
                  <ReactiveFormSelect
                    className="md:w-1/3"
                    name={`policy_market_id`}
                    label={t("POLICY_MARKET")}
                    options={agentAvailableMarkets.map((x: AgencyPolicyType) => { return {label: `${x.market_name}`, value: `${x.market_id}`} })}
                    control={control}
                    handleOnChange={(x: any) => {
                      methods.resetField("policy_type_id")
                      setSelectedPolicyType(undefined);
                      let aPolicyM = agentAvailableMarkets.find((market) => market.market_id === +x.target.value);
                      if(aPolicyM !== undefined) {
                        setPolicyTypes(aPolicyM.policy_types!)
                        setSelectedPolicyMarket({id: aPolicyM.market_id, name: aPolicyM.market_name});
                      } else {
                        setSelectedPolicyMarket(undefined);
                        setPolicyTypes([]);
                      }
                    }}
                    isRequired
                  />

                  <ReactiveFormSelect
                    className="md:w-1/3"
                    name={`policy_type_id`}
                    label={t("POLICY_TYPE")}
                    options={policyTypes.map((t: PolicyType) => { return {label: `${t.name}`, value: `${t.id}`} })}
                    control={control}
                    isDisabled={methods.getValues("policy_market_id") === undefined || loadingTypes}
                    isRequired
                    handleOnChange={(e: any) => {
                      methods.resetField("carrier_id")
                      let policyType = policyTypes.find((x: PolicyType) => x.id === +e.target.value);
                      methods.setValue("policy_type", policyType?.name);
                      setSelectedPolicyType(policyType);
                      setLoadingCarriers(true)
                      dispatch(fetchCarriersForPolicyType(+e.target.value)).then((e) => {
                        setCarriers((e.payload || []) as Carrier[])
                        setLoadingCarriers(false)
                      })
                    }}
                  />

                  <ReactiveFormSelect
                    className="md:w-1/3"
                    name={`carrier_id`}
                    label={t("CARRIER")}
                    control={control}
                    options={carriers.map((c: Carrier) => { return {label: `${c.value}`, value: `${c.id}`}})}
                    isDisabled={methods.getValues("policy_type_id") === undefined || loadingCarriers}
                    handleOnChange={(e: any) => {
                      let carrier = carriers.find((x: Carrier) => x.id === +e.target.value);
                      methods.setValue("carrier", carrier?.value);
                    }}
                    isRequired
                  />
                </>
              }
              <ReactiveFormInput
                control={control}
                type="date"
                className="md:w-1/3"
                label={t("EFFECTIVE_START_DATE")}
                name={`effective_start_date`}
                isRequired
              />
              {
                ((selectedPolicyType && selectedPolicyType.has_effective_end_date) || !selectedPolicyType) &&
                <ReactiveFormInput
                  control={control}
                  type="date"
                  className="md:w-1/3"
                  label={t("EFFECTIVE_END_DATE")}
                  name={`effective_end_date`}
                  isRequired
                />
              }
              <ReactiveFormInput
                control={control}
                className="md:w-1/3"
                label={t("MEMBERS_INSURED")}
                name={`members_insured`}
                type="number"
                isRequired
              />
              <ReactiveFormSelect
                className="md:w-1/3"
                name={`policy_state`}
                label={t("STATUS")}
                options={getPolicyStates()}
                //isDisabled={getValues("is_new")}//TODO: Check THIS
                control={control}
                isRequired
              />
              <ReactiveFormRadioButtonSelect
                className="md:w-1/3"
                control={control}
                name={`free_plan`}
                label={t("FREE_PLAN")}
                options={[
                  { label: t("YES"), value: "1" },
                  { label: t("NO"), value: "0" },
                ]}
              />
              <ReactiveFormLocalDateInput
                control={control}
                type="date"
                className="md:w-1/3"
                label={t("SOLD_DATE")}
                name={`sold_at`}
                isRequired
              />
            </Fragment>

            {
              selectedPolicyMarket && selectedPolicyMarket.id === PolciyMarketEnum.LIFE &&
              <Fragment>
                <ReactiveFormInput
                  control={control}
                  className="md:w-1/3"
                  label={t("ANUAL_PREMIUM")}
                  name={`anual_premium`}
                  type="number"
                  isRequired
                />

                <ReactiveFormInput
                  control={control}
                  className="md:w-1/3"
                  label={t("FACE_VALUE")}
                  name={`face_value`}
                  type="number"
                  isRequired
                />
              </Fragment>
            }
            
            { currentUser?.role_id === RoleEnum.SUPER_ADMIN && <Fragment>
              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_id`}
                label={t("AGENT")}
                options={agents.map((x: User) => { return {label: `${x.first_name} ${x.last_name}`, value: `${x.id}`} })}
                control={control}
                handleOnChange={(x: any) => {
                  methods.resetField("agent_commission_rule_id")
                  setSelectedCommissionRule(undefined)
                  setLoadingRules(true)
                  dispatch(fetchAgencyUserCommissionRules({agencyId: currentUser.agency_id, userId: +x.target.value})).then((e) => {
                      setCommissionRules((e.payload || []) as AgentCommissionRule[])
                      setLoadingRules(false)
                  })
                }}
                isRequired
              />

              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_commission_rule_id`}
                label={t("AGENT_COMMISSION_RULE")}
                options={commissionRules.map((r: AgentCommissionRule) => { return {label: `${r.name} - ${r.is_relative ? r.value!*100+'%' : '$'+r.value}`, value: `${r.id}`} })}
                control={control}
                isDisabled={methods.getValues("agent_id") === undefined || loadingRules}
                isRequired
                handleOnChange={(e: any) => setSelectedCommissionRule(commissionRules.find((x: AgentCommissionRule) => x.id === +e.target.value))}
              />

              { selectedCommissionRule !== undefined && 
                <p className="text-slate-500 text-xs px-3">{selectedCommissionRule.description}</p> }
            </Fragment>}

            { currentUser?.role_id !== RoleEnum.SUPER_ADMIN && info.update && <Fragment>
              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_id`}
                label={t("AGENT")}
                options={agents.map((x: User) => { return {label: `${x.first_name} ${x.last_name}`, value: `${x.id}`} })}
                control={control}
                isDisabled
              />

              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_commission_rule_id`}
                label={t("AGENT_COMMISSION_RULE")}
                options={commissionRules.map((r: AgentCommissionRule) => { return {label: `${r.name} - ${r.is_relative ? r.value!*100+'%' : '$'+r.value}`, value: `${r.id}`} })}
                control={control}
                isDisabled
              />

              { selectedCommissionRule !== undefined && 
                <p className="text-slate-500 text-xs px-3">{selectedCommissionRule.description}</p> }
            </Fragment>}
          </div>
          
          {
            selectedPolicyMarket && selectedPolicyMarket.id === PolciyMarketEnum.LIFE &&
            <PolicyBeneficiariesForm
              isNewClient={info.isNewClient}
              beneficiaries={beneficiaries}
              policy={info.policy ?? null}
              beneficiariesHasChanged={policyBeneficiariesHasChanged}
            />
          }
        </div>
      </Modal>
      <SuccessErrorModal
        info={showSuccessErrorModal}
        onClose={handleCloseErrorModal}
      />
      {showPolicyMessageConfirmationModal()}
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      {isLoading && <LoadingMask />}
    </>
  );
};
