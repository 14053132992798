import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImmigrationStatus } from "../../models/InmigrationStatus";
import { createClientImmigrationStatus, fetchImmigrationStatusByClientId, needToLoadClientImmigrationStatus, updateClientImmigrationStatus } from "./actions";

interface ImmigrationStatusState {
  immigrationStatus: ImmigrationStatus,
  isLoading: boolean,
  isLoadingImmigration: boolean,
  error: string | null,
  needToLoadImmigration: boolean
}

const initialState: ImmigrationStatusState = {
    immigrationStatus: {},
    isLoading: false,
    isLoadingImmigration: false,
    error: null,
    needToLoadImmigration: true
}

// slice
const immigrationStatusSlice = createSlice({
  name: "immigrationStatus",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchImmigrationStatusByClientId.pending, (state) => {
        state.isLoadingImmigration = true;
        state.error = null
      })
      .addCase(fetchImmigrationStatusByClientId.fulfilled, (state, action: PayloadAction<ImmigrationStatus>) => {
        state.isLoadingImmigration = false;
        state.immigrationStatus = action.payload;
      })
      .addCase(fetchImmigrationStatusByClientId.rejected, (state, action: any) => {
        state.isLoadingImmigration = false;
        state.error = action.payload;
      })
      .addCase(createClientImmigrationStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createClientImmigrationStatus.fulfilled, (state, action: PayloadAction<ImmigrationStatus>) => {
        state.isLoading = false;
        state.immigrationStatus = action.payload;
      })
      .addCase(createClientImmigrationStatus.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateClientImmigrationStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateClientImmigrationStatus.fulfilled, (state, action: PayloadAction<ImmigrationStatus>) => {
        state.isLoading = false;
        state.immigrationStatus = action.payload;
      })
      .addCase(updateClientImmigrationStatus.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(needToLoadClientImmigrationStatus.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.needToLoadImmigration = action.payload;
        if(action.payload) {
          state.immigrationStatus = {};
        }
      });
  },
});

export default immigrationStatusSlice.reducer;