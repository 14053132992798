import classNames from "classnames";
import { ConversationType, ConversationTypeLabel } from "../../enums/ConversationType";

export const Badge = (props: {type: ConversationType}) => {
    return <div className={classNames({
        "mx-2 px-3 rounded-full text-white h-fit": true,
        "bg-gold": props.type === ConversationType.LEADS,
        "bg-blue": props.type === ConversationType.CLIENTS,
        "hidden": props.type === ConversationType.OTHER,
    })}>
        {ConversationTypeLabel(`${props.type}`)}
    </div>
};