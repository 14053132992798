import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { AgentsTwilioNumbers, EditUser, NewUser, User } from "../../models/User";
import { Agency } from "../../models/Agency";

export const fetchUsers = createAsyncThunk("users/getUsers", async (agencyId: number) => {
    const response: AxiosResponse<User[]> = await (await axiosClient()).get(`/agencies/${agencyId}/users`);
    return response.data
});

export const createUser = createAsyncThunk("users/createUser", async (params: { user: NewUser, agencyId: number }, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<User> = await (await axiosClient()).post(`/agencies/${params.agencyId}/users`, { ...params.user });
        return {
            ...params.user,
            id: response.data.id
        };
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateUser = createAsyncThunk("users/updateUser", async (params: { user: EditUser, agencyId: number }, { rejectWithValue }) => {
    try {
        await (await axiosClient()).patch(`/agencies/${params.agencyId}/users/${params.user.id}`, { ...params.user });
        return {
            ...params.user,
            id: params.user.id
        };
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchUser = createAsyncThunk("users/currentUser", async (_, {rejectWithValue} ) => {
    try {
        const response: AxiosResponse<User> = await (await axiosClient()).get(`/users/current_user`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const removeUser = createAsyncThunk("users/removeCurrentUser", async () => {
    sessionStorage.removeItem('auth');
    return true;
});

export const updateMainTwilioPhone = createAsyncThunk("users/updateMainTwilioPhone", async (data: any) => {
    return data;
});

export const fetchUserTwilioPhones = createAsyncThunk('getTwilioPhones', async () => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/users/twilio-numbers`);
    return response.data
});

export const createUserTwilioNumber = createAsyncThunk("createTwilioNumber", async (data: any, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<any> = await (await axiosClient()).post(`/users/twilio-numbers`, { ...data });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchUserTwilioNumber = createAsyncThunk('getTwilioNumber', async (twilioNumberId: number) => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/users/twilio-numbers/${twilioNumberId}`);
    return response.data
});

export const updateUserTwilioNumber = createAsyncThunk("updateTwilioNumber", async (params: {twilioNumberId: number, data: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<any> = await (await axiosClient()).patch(`/users/twilio-numbers/${params.twilioNumberId}`, { ...params.data });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const setMainUserTwilioNumber = createAsyncThunk("setMainTwilioNumber", async (numberId: number, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<any> = await (await axiosClient()).patch(`/users/current_user/mainTwilioNumber/${numberId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const setCurrentUserAgency = createAsyncThunk("setCurrentUserAgency", async (agency: Agency) => {
    return agency
});

export const fetchAgentsTwilioNumbers = createAsyncThunk("users/getAgentsTwilioNumbers", async () => {
    const response: AxiosResponse<AgentsTwilioNumbers[]> = await (await axiosClient()).get(`/users/agents-twilio-numbers`);
    return response.data
});