import { Campaign, NECampaign, NECampaignFixedPrice, NECampaignForTime } from "../../models/Campaign";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { Toast } from "../Toast";
import { toast, ToastContainer } from "react-toastify";
import { createCampaign, updateCampaign } from "../../state/campaigns/actions";
import { Modal } from "../Modal";
import { ReactiveFormInput } from "../form/ReactiveFormInput";
import { ReactiveFormRadioButtonSelect } from "../form/ReactiveFormRadioButtonSelect";
import { LoadingMask } from "../LoadingMask";
import { ReactiveFormSelect } from "../form/ReactiveFormSelect";
import { CampaignEnum, getCampaigns } from "../../enums/CampaignType";
import { getFrequencys } from "../../enums/Frequency";
import { AgencyPolicyType, PolicyMarket, PolicyType } from "../../models/PolicyMarket";
import { fetchMarketPolicyTypes } from "../../state/policyMarkets/actions";

interface Props {
  info: {
    show: boolean;
    update: boolean;
    campaign?: Campaign;
    index?: number;
  };
  onClose: () => void;
}

export const CampaignModal: React.FC<Props> = ({
  info,
  onClose
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: RootState) => state.users.currentUser);
    const isLoading = useSelector((state: RootState) => state.campaigns.isLoading);
    const error = useSelector((state: any) => state.campaigns.error);
    const [showCampaignError, setShowCampaignError] = useState(false);
    const [campaignTypeSelected, setCampaignTypeSelected] = useState<Number>();
    const [enterfixedPriceAmountLeads, setEnterFixedPriceAmountLeads] = useState(false);
    const [fixedPriceAmountLeads, setFixedPriceAmountLeads] = useState<number>();
    const [enterFixedPriceTotal, setEnterFixedPriceTotal] = useState(false);
    const [fixedPriceTotal, setFixedPriceTotal] = useState<number>();
    const agentAvailableMarkets = useSelector((state: RootState) => state.policyMarkets.agencyPolicyTypes);
    
    const [policyTypes, setPolicyTypes] = useState<PolicyType[]>([]);
    const [loadingTypes, setLoadingTypes] = useState(false);

    const methods = useForm<Campaign>({
            defaultValues: {},
    });
    const { handleSubmit, control, setValue, getValues, reset } = methods;

    useEffect(() => {
        if(info.campaign) {
            reset(info.campaign);
            setCampaignTypeSelected(info.campaign.campaign_type_id);
        } else {
            reset({});
            setCampaignTypeSelected(0);
        }

        if(info.campaign && info.campaign.policy_market_id) {
            let aAvailableMarket = agentAvailableMarkets.find((market) => market.market_id === info.campaign?.policy_market_id);
            setPolicyTypes(aAvailableMarket?.policy_types!);
        }
    }, [info.campaign, reset]);
    
    useEffect(() => {
        if (showCampaignError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showCampaignError, error, t]);

    const onSubmitInvalid: SubmitErrorHandler<Campaign> = (data) => {
        toast(t("PLEASE_ENTER_ALL_CAMPAIGN_REQUIRED_FIELDS"));
    }
    
    const onSubmit: SubmitHandler<Campaign> = async (data: Campaign) => {
        if(info.update) {
            data.id = info.campaign?.id!;
            dispatch(updateCampaign({campaignId: info.campaign!.id!, campaignUpdate: data})).then((e) => {
                if (e.type === "campaign/updateCampaign/rejected") {
                    setShowCampaignError(true);
                } else {
                    toast(t("SUCCESSFULLY_UPDATE_CAMPAIGN"));
                    closeModal();
                }
            });
        } else {
            dispatch(createCampaign({campaignNew: data})).then((e) => {
                if (e.type === "campaign/createCampaign/rejected") {
                    setShowCampaignError(true);
                } else {
                    toast(t("SUCCESSFULLY_CREATED_CAMPAIGN"));
                    closeModal();
                }
            });
        }
    };
        
    const closeModal = () => {
        setCampaignTypeSelected(0);
        setValue("fixed_price.cost_per_lead", undefined);
        setFixedPriceAmountLeads(undefined);
        setFixedPriceTotal(undefined);
        setEnterFixedPriceAmountLeads(false);
        setEnterFixedPriceTotal(false);
        setPolicyTypes([])
        reset({});
        onClose();
    };

    const onHandleCampaignTypeSelect = (type: any) => {
        setCampaignTypeSelected(Number(type.target.value));
    }

    useEffect(() => {
        async function setCostPerLead() {
            if(fixedPriceAmountLeads !== undefined && fixedPriceTotal !== undefined) {
                let costPerLead = (fixedPriceTotal / fixedPriceAmountLeads).toFixed(2);
                setValue("fixed_price.cost_per_lead", parseFloat(costPerLead));
            }
        }
    
        if (enterfixedPriceAmountLeads && enterFixedPriceTotal) {
            setCostPerLead();
        }
    }, [fixedPriceAmountLeads, enterfixedPriceAmountLeads, fixedPriceTotal, enterFixedPriceTotal]);

    const onHandleChangeFPAmountLeads = (e: any) => {
        setFixedPriceAmountLeads(e.target.value);
    }

    const handleBlurFPAmountLeads = (e: any) => {
        setEnterFixedPriceAmountLeads(true);
    };

    const onHandleChangeFPTotal = (e: any) => {
        setFixedPriceTotal(e.target.value);
    }

    const handleBlurFPTotal = (e: any) => {
        setEnterFixedPriceTotal(true);
    };

    return(
        <div>
            <Modal
                isOpen={info.show}
                onClose={closeModal}
                label={info.update ? t("UPDATE_CAMPAIGN") : t("NEW_CAMPAIGN")}
                onSave={handleSubmit(onSubmit, onSubmitInvalid)}
            >
                <div className="w-full flex flex-wrap">
                    <Fragment>
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("CODE")}
                            name={`code`}
                            isRequired
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("NAME")}
                            name={`name`}
                            isRequired
                        />
                        <ReactiveFormSelect
                            className="md:w-1/3 mb-3"
                            name={`campaign_type_id`}
                            label={t("CAMPAIGN_TYPE")}
                            options={getCampaigns()}
                            handleOnChange={onHandleCampaignTypeSelect}
                            control={control}
                            isRequired
                        />
                        {
                            agentAvailableMarkets.length > 0 &&
                            <Fragment>
                                <ReactiveFormSelect
                                    className="md:w-1/3"
                                    name={`policy_market_id`}
                                    label={t("POLICY_MARKET")}
                                    options={agentAvailableMarkets.map((x: AgencyPolicyType) => { return {label: `${x.market_name}`, value: `${x.market_id}`} })}
                                    control={control}
                                    handleOnChange={(x: any) => {
                                        methods.resetField("policy_type_id")
                                        let aPolicyM = agentAvailableMarkets.find((market) => market.market_id === +x.target.value);
                                        setPolicyTypes(aPolicyM?.policy_types!)
                                    }}
                                    isRequired
                                />
                                <ReactiveFormSelect
                                    className="md:w-1/3"
                                    name={`policy_type_id`}
                                    label={t("POLICY_TYPE")}
                                    options={policyTypes.map((t: PolicyType) => { return {label: `${t.name}`, value: `${t.id}`} })}
                                    control={control}
                                    isDisabled={methods.getValues("policy_market_id") === undefined || loadingTypes}
                                    isRequired
                                    handleOnChange={(e: any) => {
                                        let policyType = policyTypes.find((x: PolicyType) => x.id === +e.target.value);
                                        methods.setValue("policy_type", policyType?.name);
                                    }}
                                />
                            </Fragment>
                            }
                    </Fragment>
                    {
                        campaignTypeSelected === CampaignEnum.FIXED_PRICE &&
                        <Fragment>
                            <ReactiveFormInput
                                control={control}
                                className="md:w-1/3"
                                label={t("NUMBER_OF_LEADS")}
                                type="number"
                                name={`fixed_price.amount_of_leads`}
                                handleOnChange={onHandleChangeFPAmountLeads}
                                handleOnBlur={handleBlurFPAmountLeads}
                                isRequired
                            />
                            <ReactiveFormInput
                                control={control}
                                className="md:w-1/3"
                                label={t("TOTAL")}
                                type="number"
                                name={`fixed_price.total`}
                                handleOnChange={onHandleChangeFPTotal}
                                handleOnBlur={handleBlurFPTotal}
                                isRequired
                            />
                            <ReactiveFormInput
                                control={control}
                                className="md:w-1/3"
                                type="number"
                                label={t("COST_PER_LEAD")}
                                name={`fixed_price.cost_per_lead`}
                            />
                        </Fragment>
                    }
                    {
                        campaignTypeSelected === CampaignEnum.FOR_TIME &&
                        <Fragment>
                            <ReactiveFormInput
                                control={control}
                                type="date"
                                className="md:w-1/3"
                                label={t("START_DATE")}
                                name={`for_time.start_date`}
                            />
                            <ReactiveFormInput
                                control={control}
                                type="date"
                                className="md:w-1/3"
                                label={t("END_DATE")}
                                name={`for_time.end_date`}
                            />
                            <ReactiveFormInput
                                control={control}
                                className="md:w-1/3"
                                label={t("COST")}
                                type="number"
                                name={`for_time.cost`}
                                isRequired
                            />
                            {/*<ReactiveFormInput
                                control={control}
                                className="md:w-1/3"
                                label={t("NUMBER_OF_LEADS")}
                                name={`for_time.amount_of_leads`}
                                type="number"
                                isRequired
                            />*/}
                        </Fragment>
                    }
                </div>
            </Modal>
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            {isLoading && <LoadingMask/>}
        </div>
    )
}