import i18next from "i18next";

export enum SourceEnum {
    FACEBOOK = 1,
    INSTAGRAM,
    TWITTER,
    EMAIL,
    OTHER
}

export const SourceLabel = (key: string) => ({ 
    [SourceEnum.FACEBOOK]: i18next.t('FACEBOOK'),
    [SourceEnum.INSTAGRAM]: i18next.t('INSTAGRAM'),
    [SourceEnum.TWITTER]: i18next.t('TWITTER'),
    [SourceEnum.EMAIL]: i18next.t('EMAIL'),
    [SourceEnum.OTHER]: i18next.t('OTHER')
})[key];

export const getSources = () =>
    Object.values(SourceEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: SourceLabel(value) ?? ""
        }
    });