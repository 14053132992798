import { t } from "i18next";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Form } from "../components/form/Form";
import { EditPolicy, Policy } from "../models/Policies";
import { fetchClientById, updateClient } from "../state/clients/actions";
import {
  createClientPolicy,
  fetchPoliciesByClientId,
  updateClientPolicy,
} from "../state/policies/actions";
import { AppDispatch, RootState } from "../state/store";
import { dateFormat } from "../utils/functions";
import { FormInput } from "./form/FormInput";
import { FormSearchableSelect } from "./form/FormSearchableSelect";
import { FormSelect } from "./form/FormSelect";
import { Toast } from "./Toast";
import { Client } from "../models/Client";

interface Props {
  clientId: number | null | undefined;
}

const ClientContactForm = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const clients = useSelector((state: any) => state.twilio.phoneContacts);
  const error = useSelector((state: any) => state.clients.error);
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const currentClient = useSelector(
    (state: any) => state.clients.currentClient
  );
  const agencyCarriers = useSelector((state: RootState) => state.carriers.agencyCarriers);
  const policies = useSelector((state: any) => state.policies.clientPolicies);
  const errorPolicy = useSelector((state: any) => state.policies.error);
  const [referredBy, setReferredBy] = useState<string>();
  const [currentPolicy, setCurrentPolicy] = useState<EditPolicy>();
  const [showError, setShowError] = useState(false);
  const [showPolicyError, setShowPolicyError] = useState(false);

  useEffect(() => {
    if (showError) {
      Toast(t(error?.reason ?? "SOMETHING_WENT_WRONG"), t(error?.cause_info));
      setShowError(false);
    }
  }, [showError, error]);

  useEffect(() => {
    if (showPolicyError) {
      Toast(
        t(errorPolicy?.reason ?? "SOMETHING_WENT_WRONG"),
        t(errorPolicy?.cause_info)
      );
      setShowPolicyError(false);
    }
  }, [showPolicyError, errorPolicy]);

  useEffect(() => {
    if (props.clientId && currentUser) {
      dispatch(
        fetchPoliciesByClientId({
          clientId: props.clientId,
        })
      );
      dispatch(fetchClientById(props.clientId));
    }
  }, [props.clientId, dispatch, currentUser]);

  useEffect(() => {
    setReferredBy(currentClient?.referred_by);
    setCurrentPolicy(undefined);
  }, [currentClient]);

  const onReferredBySubmit = (value: string) => {
    if (currentClient && currentUser) {
      dispatch(
        updateClient({
          client: {
            referred_by: value,
          } as Client,
          clientId: currentClient.id
        })
      ).then((e) => {
        if (e.type === "clients/updateClient/rejected") {
          setShowError(true);
        } else {
          toast(t("SAVED_SUCCESFULLY"));
        }
      });
    }
  };

  const getClients = useMemo(() => {
    return clients.clients.map((client: any) => {
      return {
        label: `${client.name} (${client.phone})`,
        value: `${client.name}`,
        key: client.id,
      };
    });
  }, [clients]);

  const handlePolicyByChange = (e: any) => {
    const policy = policies.find((x: Policy) => `${x.id}` === e.target.value);
    setCurrentPolicy(policy);
  };

  const getPolicies = useMemo(() => {
    const policiesArray = [
      {
        label: `New Policy...`,
        value: "-1",
        key: "-1",
      },
    ];
    return policiesArray.concat(
      policies.map((policy: Policy) => {
        return {
          label: `${policy.number ?? ""} ${policy.carrier} ?? ""}`,
          value: policy.id,
          key: policy.id,
        };
      })
    );
  }, [policies]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setCurrentPolicy((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleReferredByChange = (value: any) => {
    setReferredBy(value);
  };

  const savePolicyValue = (e: any) => {
    if (currentClient && currentUser && currentPolicy) {
      if (currentPolicy.id) {
        const newPolicy = {
          id: currentPolicy.id,
          number: currentPolicy.number,
          carrier_id: currentPolicy.carrier_id,
          effective_start_date: currentPolicy.effective_start_date,
        };
        dispatch(
          updateClientPolicy({
            policy: newPolicy,
            policyId: newPolicy.id,
            clientId: currentClient.id
          })
        ).then((e) => {
          if (e.type === "client/updateClientPolicy/rejected") {
            setShowPolicyError(true);
          } else {
            toast(t("SAVED_SUCCESFULLY"));
          }
        });
      } else if (
        currentPolicy.number ||
        currentPolicy.effective_start_date ||
        currentPolicy.carrier_id
      ) {
        dispatch(
          createClientPolicy({
            policy: {
              number: currentPolicy.number,
              carrier_id: currentPolicy.carrier_id,
              effective_start_date: currentPolicy.effective_start_date,
            },
            clientId: currentClient.id
          })
        ).then((e) => {
          if (e.type === "client/createClientPolicy/rejected") {
            setShowPolicyError(true);
          } else {
            const updatedPolicy = policies.find(
              (x: Policy) =>
                x.number === currentPolicy.number &&
                x.carrier_id === currentPolicy.carrier_id &&
                x.effective_start_date === currentPolicy.effective_start_date
            );
            setCurrentPolicy(updatedPolicy);
            toast(t("SAVED_SUCCESFULLY"));
          }
        });
      }
    }
  };

  return (
    <Fragment>
      {currentClient ? (
        <>
          <ToastContainer progressStyle={{ background: "#D4AF37" }} />
          <div className="text-xl font-semibold py-5 px-4 justify-center">
            {`${currentClient.first_name ?? ""} ${
              currentClient.last_name ?? ""
            }`}
          </div>
          <div
            className="overflow-auto flex flex-col relative md:overflow-auto pb-4"
            style={{ height: "calc(100vh - 220px)" }}
          >
            <Form handleOnSubmit={onReferredBySubmit}>
              <FormSearchableSelect
                name="referred_by"
                label={t("REFERRED_BY")}
                onBlur={onReferredBySubmit}
                options={getClients}
                value={referredBy ?? ""}
                onChange={handleReferredByChange}
              />
              <FormSelect
                name="policies"
                label={t("POLICIES")}
                options={getPolicies}
                selectedValue={currentPolicy?.id ?? "-1"}
                handleOnChange={handlePolicyByChange}
              />
              <FormInput
                value={currentPolicy?.number ?? ""}
                name="number"
                handleOnChange={handleChange}
                label={t("NUMBER")}
                onBlur={savePolicyValue}
              />
              <FormSelect
                selectedValue={`${currentPolicy?.carrier_id ?? ""}`}
                name="carrier_id"
                handleOnChange={handleChange}
                label={t("CARRIER")}
                options={agencyCarriers.map((c) => { return {label: c.value, value: `${c.id}`} })}
                onBlur={savePolicyValue}
              />
              <FormInput
                value={
                  dateFormat(`${currentPolicy?.effective_start_date ?? ""}`) ??
                  ""
                }
                name="start_date"
                type="date"
                handleOnChange={handleChange}
                label={t("START_DATE")}
                onBlur={savePolicyValue}
              />
            </Form>
          </div>
        </>
      ) : (
        <div className="text-l text-center relative inset-y-2/4 lg:w-3/4 self-center">
          Phone number does not match any client
        </div>
      )}
    </Fragment>
  );
};

export default ClientContactForm;
