import { useTranslation } from "react-i18next";
import { DocusignConfig } from "../../../models/DocusignConfig";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { Fragment, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "../../Modal";
import { ReactiveFormInput } from "../../form/ReactiveFormInput";
import { ReactiveFormInputArea } from "../../form/ReactiveFormInputArea";
import { LoadingMask } from "../../LoadingMask";
import { createAgencyDocusignConfig, fetchAgencyDocusignConfigById, updateAgencyDocusignConfig } from "../../../state/docusignConfig/actions";
import { Toast } from "../../Toast";
import { ReactiveFormRadioButtonSelect } from "../../form/ReactiveFormRadioButtonSelect";

interface Props {
  info: {
    show: boolean;
    update: boolean;
    docusignConfig?: DocusignConfig;
    index?: number;
  };
  onClose: () => void;
}

export const AgencyDocusignConfigModal: React.FC<Props> = ({
  info,
  onClose,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: RootState) => state.users.currentUser);
    const isLoading = useSelector((state: RootState) => state.docusignConfig.isLoading);
    const error = useSelector((state: any) => state.docusignConfig.error);
    const [showDocusignConfigError, setShowDocusignConfigError] = useState(false);

    const methods = useForm<DocusignConfig>({
        defaultValues: {},
    });
    const { handleSubmit, control, getValues, reset } = methods;

    useEffect(() => {
        if(info.docusignConfig) {
            dispatch(fetchAgencyDocusignConfigById({agencyId: currentUser!.agency!.id!, configId: info.docusignConfig.id!})).then((e) => {
                let currentConfig = (e.payload || null) as DocusignConfig;
                console.log(currentConfig);
                if(currentConfig !== null) {
                    reset(currentConfig);
                }
            })
        }
        //info.docusignConfig ? reset(info.policy) : reset(newPolicyInitialValues);
    }, [info.docusignConfig, reset]);

    useEffect(() => {
        if (showDocusignConfigError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showDocusignConfigError, error, t]);

    const onSubmitInvalid: SubmitErrorHandler<DocusignConfig> = (data) => {
        toast(t("PLEASE_ENTER_ALL_POLICY_REQUIRED_FIELDS"));
    }
    
    const onSubmit: SubmitHandler<DocusignConfig> = async (data: DocusignConfig) => {
        console.log(data);

        if(info.update) {
            const updateData = data;
            updateData.id = info.docusignConfig?.id;
            dispatch(updateAgencyDocusignConfig({agencyId: currentUser!.agency!.id, configId: info.docusignConfig!.id!, configData: updateData})).then((e) => {
                if (e.type === "agencies/updateAgencyDocusignConfig/rejected") {
                    setShowDocusignConfigError(true);
                } else {
                    toast(t("SUCCESSFULLY_UPDATE_DOCUSIGN_CONFIG"));
                    closeModal();
                }
            });
        } else {
            dispatch(createAgencyDocusignConfig({agencyId: currentUser!.agency!.id, configData: data})).then((e) => {
                if (e.type === "agencies/createAgencyDocusignConfig/rejected") {
                    setShowDocusignConfigError(true);
                } else {
                    toast(t("SUCCESSFULLY_CREATE_DOCUSIGN_CONFIG"));
                    closeModal();
                }
            });
        }
    };
    
    const closeModal = () => {
        reset();
        onClose();
    };

    return(
        <div>
            <Modal
                isOpen={info.show}
                onClose={closeModal}
                label={info.update ? t("UPDATE_CONFIG") : t("ADD_CONFIG")}
                onSave={handleSubmit(onSubmit, onSubmitInvalid)}
            >
                <div className="w-full flex flex-wrap">
                    <Fragment>
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("INTEGRATION_NAME")}
                            name={`label`}
                            isRequired
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("INTEGRATION_KEY")}
                            name={`docusign_integration_key`}
                            isRequired
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("ACCOUNT_ID")}
                            name={`docusign_account_id`}
                            isRequired
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("USER_ID")}
                            name={`docusign_user_id`}
                            isRequired
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("SECRET_KEY")}
                            name={`docusign_secret_key`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("REDIRECT_URI")}
                            name={`docusign_redirect_uri`}
                        />
                        <div className="flex md:w-1/3">
                            <ReactiveFormRadioButtonSelect
                                className="mr-2"
                                control={control}
                                name={`is_main_config`}
                                label={t("SET_AS_MAIN_CONFIG")}
                                options={[
                                { label: t("YES"), value: "1" },
                                { label: t("NO"), value: "0" },
                                ]}
                            />
                        </div>
                        <ReactiveFormInputArea
                            control={control}
                            className="md:w-1/1"
                            label={t("PRIVATE_KEY")}
                            name={`docusign_private_key`}
                            isRequired
                        />
                    </Fragment>
                </div>
            </Modal>
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            {isLoading && <LoadingMask/>}
        </div>
    )
}