import { useTranslation } from "react-i18next";
import { Dependent } from "../../../models/Dependant";
import { ConfirmationModal } from "../../ConfirmationModal";
import { CheckIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
    info: {
        show: boolean;
        dependent?: Dependent;
    };
    onClose: () => void;
    deleteDependent: (dependent: Dependent) => void
}

export const DeleteClientDependentModal: React.FC<Props> = ({
    info,
    onClose,
    deleteDependent
}) => {

    const { t } = useTranslation();

    const onDeleteDependent = () => {
        deleteDependent(info.dependent!)
        onClose();
    };

    return(
        <ConfirmationModal 
            label={t("DELETE_DEPENDENT")}
            isDeleteConfirm={true}
            isOpen={info.show}
            onClose={onClose}
            cancelButton={{ label: t("CLOSE"), icon: <XMarkIcon />, onClick: onClose, }}
            saveButton={{ label: t("DELETE"), icon: <TrashIcon />, onClick: onDeleteDependent }}
        >
            <div className='w-full flex flex-wrap items-center justify-center'>
                <label className="text-lg font-semibold py-4 px-2 text-center">
                    {t('DELETE_DEPENDENT_MESSAGE')}
                </label>
            </div>
        </ConfirmationModal>
    )
}