import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AgentMembers } from "../../models/Dashboard";
import { RootState } from "../../state/store";
import { ModalInformation } from "../ModalInformation";
import { SearchBar } from "../SearchBar";

interface Props {
  info: {
    show: boolean;
  };
  onClose: () => void;
}

export const ViewAllMembersSellsModal: React.FC<Props> = ({
  info,
  onClose,
}) => {
  const { t } = useTranslation();
  const allAgentsTotalMembers = useSelector(
    (state: RootState) => state.dashboard.allAgentsTotalMembers
  );
  const [filteredAgentMembers, setFilteredAgentMembers] = useState<
    AgentMembers[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    setFilteredAgentMembers(allAgentsTotalMembers);
  }, [allAgentsTotalMembers]);

  const handleSearchAgent = (event: any) => {
    const searchFilter = event.target.value;
    setSearchValue(searchFilter);
    if (searchFilter.length > 0) {
      const fAgentMembers = allAgentsTotalMembers.filter((agent) =>
        agent.agent
          .toLocaleLowerCase()
          .includes(searchFilter.toLocaleLowerCase())
      );
      setFilteredAgentMembers(fAgentMembers);
    } else {
      setFilteredAgentMembers(allAgentsTotalMembers);
    }
  };

  return (
    <ModalInformation
      isOpen={info.show}
      onClose={onClose}
      label={t("AGENTS_MEMBERS_SELLS")}
      cancelButton={false}
    >
      <div className="w-full py-2 px-5">
        <div className="w-full flex flex-row justify-between items-center mb-4">
          <div className="w-full max-w-80 mr-5">
            <SearchBar value={searchValue} onChange={handleSearchAgent} />
          </div>
        </div>
        <div className="w-full">
          <div className="w-full grow grid grid-cols-5 mb-3 mt-5">
            <div className="col-span-3 text-lg font-semibold ml-3">
              {t("AGENT")}
            </div>
            <div className="col-span-2 flex flex-row justify-around items-center">
              <div className="w-full text-lg font-semibold text-center">
                {t("POLICIES")}
              </div>
              <div className="w-full text-lg font-semibold text-center">
                {t("MEMBERS")}
              </div>
            </div>
          </div>
          {filteredAgentMembers.map((item, index) => {
            return (
              <div className="w-full">
                <div className="w-full grow grid grid-cols-5 mb-3">
                  <div className="col-span-3 text-gray-700 ml-3">
                    {item.agent}
                  </div>
                  <div className="col-span-2 flex flex-row justify-around items-center">
                    <div className="w-full text-blue text-xl text-center border-r">
                      {item.totalPolicies}
                    </div>
                    <div className="w-full text-gold text-xl font-medium text-center">
                      {item.totalMembers}
                    </div>
                  </div>
                </div>
                {index !== filteredAgentMembers.length - 1 && (
                  <hr className="ml-3 mr-2 mb-3"></hr>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </ModalInformation>
  );
};
