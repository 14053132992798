import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { Modal } from "../../components/Modal";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { clearPolicySells, fetchPolicySells } from "../../state/policySells/actions";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { useEffect, useMemo, useState } from "react";
import { User } from "../../models/User";
import Select from "react-select";
import { ReactiveFormRadioButtonSelect } from "../../components/form/ReactiveFormRadioButtonSelect";
import moment from 'moment';
import { toast, ToastContainer } from "react-toastify";
import { getPolicyStates } from "../../enums/PolicyState";
import { getLocalTimeZone } from "../../utils/functions";
import { AgencyPolicyType, PolicyType } from "../../models/PolicyMarket";
import { Fragment } from "react/jsx-runtime";
import { Carrier } from "../../models/Carrier";
import { fetchCarriersForPolicyType } from "../../state/carriers/actions";

interface Props {
    isFilterModalOpen: boolean;
    setFilterModalOpen: (x: boolean) => void;
}

export const PolicySellsFilterModal = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const users = useSelector((state: any) => state.users.users);
    const policySellsState = useSelector((state: any) => state.policySells);
    const [currentAgents, setCurrentAgents] = useState<any[]>();
    const [defualtStartDate, setDefaultStartDate] = useState<any>();
    const [defualtEndDate, setDefaultEndDate] = useState<any>();

    const agentAvailableMarkets = useSelector((state: RootState) => state.policyMarkets.agencyPolicyTypes);
    const [policyTypes, setPolicyTypes] = useState<PolicyType[]>([]);
    const [loadingTypes, setLoadingTypes] = useState(false);

    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [loadingCarriers, setLoadingCarriers] = useState(false);

    const selectedAgents = useMemo(() => {
        return policySellsState.filters?.agents?.map((x: number) => {
            const user = users.find((y: User) => y.id === x);
            return {
                label: `${user.first_name} ${user.last_name}`,
                value: user.id
            }
        })
    }, [policySellsState.filters?.agents, users]);

    useEffect(() => {
        setCurrentAgents(selectedAgents)

        const endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
        const startDate = moment(endDate).hours(0).minutes(0).seconds(0).toDate();

        const end = moment(endDate).format('YYYY-MM-DD');
        const start = moment(startDate).format('YYYY-MM-DD');
        setDefaultStartDate(start);
        setDefaultEndDate(end);

    }, [selectedAgents, defualtStartDate, setDefaultStartDate, defualtEndDate, setDefaultEndDate]);

    const agents = useMemo(() => {
        return users.map((x: any) => {
            return {
                label: `${x.first_name} ${x.last_name}`,
                value: x.id
            }
        })
    }, [users]);

    const methods = useForm<any>({
        defaultValues: {
            client_first_name: policySellsState.filters?.client_first_name,
            client_last_name: policySellsState.filters?.client_last_name,
            client_phone: policySellsState.filters?.client_phone,
            carrier: policySellsState.filters?.carrier,
            agents: policySellsState.filters?.agents,
            free_plan: policySellsState.filters?.free_plan,
            policy_state: policySellsState.filters?.policy_state,
            effective_start_date: policySellsState.filters?.effective_start_date,
            policy_type: policySellsState.filters?.policy_type,
            policy_market: policySellsState.filters?.policy_market
        }
    });

    const { handleSubmit, control, reset, setValue } = methods;


    useEffect(() => {
        setValue("start_date", policySellsState.dates?.startDate);
        setValue("end_date", policySellsState.dates?.endDate);
    },[policySellsState]);

    const onSubmit: SubmitHandler<any> = async (data: any) => {

        if(moment(data.start_date).isAfter(data.end_date)) {
            toast(t("START_DATE_BEFORE_END_DATE_MESSAGE"));
        } else {
            if (currentAgents?.length && currentAgents?.length > 0) {
                data.agents = currentAgents?.map((x: any) => x.value);
            }

            const timeZone = getLocalTimeZone();
            console.log(data);
            
            dispatch(clearPolicySells())
            dispatch(fetchPolicySells({
                startDate: data.start_date,
                endDate: data.end_date,
                timeZone: timeZone,
                page: 0,
                pageSize: policySellsState.paginatedOptions.pageSize,
                filters: data 
            }));
            props.setFilterModalOpen(false);
        }
    };

    return(
        <div>
            <ToastContainer progressStyle={{"background": "#D4AF37"}} />
            <Modal isOpen={props.isFilterModalOpen} onClose={() => props.setFilterModalOpen(false)} label={t("FILTERS")} onSave={handleSubmit(onSubmit)} thirdButton={{
                label: t("CLEAR_FILTERS"),
                icon: <TrashIcon />,
                onClick: () =>  {
                    reset({
                        client_first_name: '',
                        client_last_name: '',
                        client_phone: '',
                        carrier: '',
                        free_plan: '',
                        policy_state: '',
                        effective_start_date: '',
                        policy_market: '',
                        policy_type: ''
                    });
                    setValue("start_date", defualtStartDate);
                    setValue("end_date", defualtEndDate);
                    setCurrentAgents([]);
                }
            }}>
                <div className="w-full flex flex-wrap">
                    <Fragment>
                        <div className={"w-full px-3 md:w-1/3"}>
                            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                                {t("AGENTS")}
                            </label>
                            <Select
                                isMulti
                                isSearchable
                                isClearable
                                isLoading={agents.length === 0}
                                onChange={(inputValue: any) => setCurrentAgents(inputValue)}
                                name="agent"
                                options={agents}
                                value={currentAgents}
                                placeholder="Select agents"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                maxMenuHeight={200}
                                classNames={{
                                    control: (state) =>
                                        'bg-white opacity-90 py-1 px-1.5 rounded shadow w-full min-w-[200px]',
                                }}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: 'grey-50',
                                    }),
                                }}
                            />
                        </div>
                        <ReactiveFormInput
                            className="md:w-1/3"
                            control={control}
                            value={""}
                            label={t("CLIENT_FIRST_NAME")}
                            name="client_first_name" />
                        <ReactiveFormInput
                            className="md:w-1/3"
                            control={control}
                            value={""}
                            label={t("CLIENT_LAST_NAME")}
                            name="client_last_name" />
                        <ReactiveFormInput
                            className="md:w-1/3"
                            control={control}
                            value={""}
                            label={t("CLIENT_PHONE")}
                            name="client_phone" />
                        {
                            agentAvailableMarkets.length > 0 &&
                            <>
                                <ReactiveFormSelect
                                    className="md:w-1/3"
                                    name={`policy_market`}
                                    label={t("POLICY_MARKET")}
                                    options={agentAvailableMarkets.map((x: AgencyPolicyType) => { return {label: `${x.market_name}`, value: `${x.market_id}`} })}
                                    control={control}
                                    handleOnChange={(x: any) => {
                                        methods.resetField("policy_type")
                                        let aPolicyM = agentAvailableMarkets.find((market) => market.market_id === +x.target.value);
                                        if(aPolicyM !== undefined) {
                                            setPolicyTypes(aPolicyM.policy_types!)
                                        } else {
                                            setPolicyTypes([]);
                                        }
                                    }}
                                />
                
                                <ReactiveFormSelect
                                    className="md:w-1/3"
                                    name={`policy_type`}
                                    label={t("POLICY_TYPE")}
                                    options={policyTypes.map((t: PolicyType) => { return {label: `${t.name}`, value: `${t.id}`} })}
                                    control={control}
                                    isDisabled={methods.getValues("policy_market") === undefined || loadingTypes}
                                    handleOnChange={(e: any) => {
                                        methods.resetField("carrier")
                                        let policyType = policyTypes.find((x: PolicyType) => x.id === +e.target.value);
                                        setLoadingCarriers(true)
                                        dispatch(fetchCarriersForPolicyType(policyType?.id!)).then((e) => {
                                            setCarriers((e.payload || []) as Carrier[])
                                            setLoadingCarriers(false)
                                        })
                                    }}
                                />
                
                                <ReactiveFormSelect
                                    className="md:w-1/3"
                                    name={`carrier`}
                                    label={t("CARRIER")}
                                    control={control}
                                    options={carriers.map((c: Carrier) => { return {label: `${c.value}`, value: `${c.id}`}})}
                                    isDisabled={methods.getValues("policy_type") === undefined || loadingCarriers}
                                />
                            </>
                        }
                        <ReactiveFormRadioButtonSelect
                            className="md:w-1/3"
                            control={control}
                            name="free_plan"
                            label={t("FREE_PLAN")}
                            options={[{ label: t("YES"), value: "1" }, { label: t("NO"), value: "0" }]}
                        />
                        <ReactiveFormSelect
                            className="md:w-1/3"
                            name="policy_state"
                            label={t("STATUS")}
                            options={getPolicyStates()}
                            control={control} />
                        <ReactiveFormInput
                            className="md:w-1/3"
                            control={control}
                            type='date'
                            label={t("SOLD_BEGIN_DATE")}
                            name="start_date"
                        />
                        <ReactiveFormInput
                            className="md:w-1/3"
                            control={control}
                            type='date'
                            label={t("SOLD_END_DATE")}
                            name="end_date"
                        />
                        <ReactiveFormInput
                            className="md:w-1/3"
                            control={control}
                            type='date'
                            label={t("EFFECTIVE_START_DATE")}
                            name="effective_start_date"
                        />
                    </Fragment>
                </div>
            </Modal>
        </div>
    )
}