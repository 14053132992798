import { ArrowDownTrayIcon, EyeIcon } from "@heroicons/react/24/solid";
import { Media } from "../../models/TwilioConversation";
import { fetchConversationResource } from "../../state/twilio/actions";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state/store";
import { useState } from "react";

interface MediaComponentProps {
  media: Media[];
}

interface Preview {
  [sid: string]: { src: string; type: string };
}

export const MediaComponent: React.FC<MediaComponentProps> = ({ media }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [previews, setPreviews] = useState<Preview>({});

  const showMedia = (sid: string) => {
    if (previews[sid]) {
      const link = document.createElement("a");
      link.href = previews[sid].src;
      link.download = "download";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      dispatch(fetchConversationResource({ sid }))
        .then(unwrapResult)
        .then((media: Media) => {
          setPreviews((prev) => ({
            ...prev,
            [sid]: {
              src: media.links.content_direct_temporary,
              type: media.content_type,
            },
          }));
        });
    }
  };

  return (
    <div>
      {media.map((item, index) => (
        <button
          key={index}
          onClick={() => showMedia(item.sid)}
          className="flex items-center rounded-full mb-2"
        >
          <span className="underline underline-offset-4">{item.filename}</span>
          <span className="ml-2 bg-gray-600 text-white p-2 rounded-full">
            {previews[item.sid] ? (
              <ArrowDownTrayIcon className="h-4 w-4 text-white" />
            ) : (
              <EyeIcon className="h-4 w-4 text-white" />
            )}
          </span>
        </button>
      ))}

      {Object.entries(previews).map(([sid, preview]) => (
        <div key={sid} className="mt-4">
          {preview.type.startsWith("image/") ? (
            <a href={preview.src} target="_blank" rel="noopener noreferrer">
              <img
                src={preview.src}
                alt="Preview"
                className="max-w-full max-h-80 cursor-pointer"
              />
            </a>
          ) : (
            <a
              href={preview.src}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              View {preview.type} file
            </a>
          )}
        </div>
      ))}
    </div>
  );
};
