import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { CDocument, CNewDocument, DownloadUrl, SignedUrl } from "../../models/Document";

export const fetchDocumentsByClientId = createAsyncThunk("client/fetchDocumentsByClientId", async (params: {clientId: number}) => {
    const response: AxiosResponse<CDocument[]> = await (await axiosClient()).get(`/clients/${params.clientId}/attachments`);
    return response.data
});

export const getFileSignedUrl = createAsyncThunk("client/getSignedUrl", async (params: {fileName: string}) => {
    const response: AxiosResponse<SignedUrl> = await (await axiosClient()).get(`storage/signed-url?filename=${params.fileName}&location=0`);
    return response.data;
});

export const getFileDownloadUrl = createAsyncThunk("client/getDownloadUrl", async (params: {fileName: string}) => {
    const response: AxiosResponse<DownloadUrl> = await (await axiosClient()).get(`storage/download-url?filename=${params.fileName}&location=0`);
    return response.data;
});

export const createClientDocument = createAsyncThunk("client/createClientDocument", async (params:{newAttach: CNewDocument, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<CDocument[]> = await (await axiosClient()).post(`/clients/${params.clientId}/attachments`, {...params.newAttach});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteClientDocument = createAsyncThunk("client/deleteClientDocument", async (params:{attachId: number, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<CDocument[]> = await (await axiosClient()).delete(`/clients/${params.clientId}/attachments/${params.attachId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const needToLoadClientDocuments = createAsyncThunk("client/needToLoadClientDocuments", async (needLoad: boolean) => {
    return needLoad;
});