import classNames from "classnames";

interface ItemProps {
  title: string;
  value: string;
  isLightBlue?: boolean;
}

export const DashboardData = ({ title, value, isLightBlue }: ItemProps) => {
  return (
    <div className="flex flex-col items-center justify-center py-2 px-4">
      <span
        className={classNames({
          "text-xs ": true,
          "text-light-blue": isLightBlue,
          "text-asureis-gray-3": !isLightBlue,
        })}
      >
        {title}
      </span>
      <span
        className={classNames({
          "font-bold text-xl": true,
          "text-light-blue": isLightBlue,
          "text-asureis-black": !isLightBlue,
        })}
      >
        {value}
      </span>
    </div>
  );
};
