import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import name from "../../assets/images/asureis-white.svg";
import {
  AgencyPath,
  AutomationPath,
  CampaignPath,
  ClientsPath,
  CommissionsPath,
  ConversationsPath,
  LeadsPath,
  MetadataPath,
  PhonePath,
  PoliciesPath,
  PolicySellsReportPath,
  TagsPath,
  UsersPath,
} from "../../constants/Routes";
import { NavItemKey } from "../../enums/NavItemKey";
import { RoleEnum } from "../../enums/Role";
import {
  NavItem,
  adminNavItems,
  communicationNavItems,
  defaultNavItems,
  defaultNavOptions,
  otherNavItems,
} from "../../models/defaultNavItems";
import SideBarItem from "./SideBarItem";
import { useTranslation } from "react-i18next";

type Props = {
  collapsed: boolean;
  setCollapsed(collapsed: boolean): void;
  shown: boolean;
};

interface SectionProps {
  title: string;
  items: NavItem[];
  selectedOption: NavItemKey;
  collapsed: boolean;
  selectMenuOption: (option: NavItem) => void;
}

const Section: React.FC<SectionProps> = ({
  title,
  items,
  selectedOption,
  collapsed,
  selectMenuOption,
}) => {
  return (
    <>
      <p
        className={classNames({
          "pb-2 bold": true,
          "px-0 pt-2 self-center": collapsed,
          "px-8 pt-6": !collapsed,
          "text-sm uppercase text-neutral-300": true
        })}
      >
        {collapsed ? "-" : title}
      </p>
      {items.map((item: NavItem, index: number) => {
        return (
          <SideBarItem
            key={index}
            selectedOption={selectedOption}
            collapsed={collapsed}
            itemKey={item.key}
            onClick={() => selectMenuOption(item)}
          >
            {item.icon} <span>{!collapsed && item.label}</span>
          </SideBarItem>
        );
      })}
    </>
  );
};
const Sidebar = ({ collapsed, shown, setCollapsed }: Props) => {
  const Icon = collapsed ? ChevronDoubleRightIcon : ChevronDoubleLeftIcon;
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const unreadMessages = useSelector(
    (state: any) => state.twilio.unreadMessages
  );

  const selectedOption = useMemo(() => {
    if (location.pathname.includes(ClientsPath)) {
      return NavItemKey.CLIENTS;
    }
    if (location.pathname.includes(ConversationsPath)) {
      return NavItemKey.CONVERSATIONS;
    }
    if (location.pathname.includes(PoliciesPath)) {
      return NavItemKey.POLICIES;
    }
    if (location.pathname.includes(PhonePath)) {
      return NavItemKey.PHONE;
    }
    if (location.pathname.includes(LeadsPath)) {
      return NavItemKey.LEADS;
    }
    if (location.pathname.includes(AutomationPath)) {
      return NavItemKey.LEAD_AUTOMATIONS;
    }
    if (location.pathname.includes(UsersPath)) {
      return NavItemKey.USERS;
    }
    if (location.pathname.includes(AgencyPath)) {
      return NavItemKey.AGENCY;
    }
    if (location.pathname.includes(TagsPath)) {
      return NavItemKey.TAGS;
    }
    if (location.pathname.includes(MetadataPath)) {
      return NavItemKey.METADATA;
    }
    if (location.pathname.includes(CommissionsPath)) {
      return NavItemKey.COMMISSIONS;
    }
    if (location.pathname.includes(PolicySellsReportPath)) {
      return NavItemKey.POLICY_SELLS_REPORT;
    }
    if (location.pathname.includes(CampaignPath)) {
      return NavItemKey.CAMPAIGNS
    }
    return NavItemKey.DASHBOARD;
  }, [location]);

  const selectMenuOption = useCallback(
    (option: NavItem) => {
      if (option.href) {
        navigate(option.href);
      }
      if (option.action) {
        option.action();
      }
    },
    [navigate]
  );

  return (
    <div
      className={classNames({
        "bg-blue text-zinc-50 fixed md:static md:translate-x-0 z-20": true,
        "transition-all duration-300 ease-in-out shadow-lg": true,
        "w-[250px]": !collapsed,
        "w-16": collapsed,
        "-translate-x-full": !shown,
      })}
    >
      <div
        className={classNames({
          "flex flex-col justify-between h-screen sticky inset-0 w-full": true,
        })}
      >
        <div
          className={classNames({
            "flex items-center shadow-sm transition-none": true,
            "p-4 justify-between": !collapsed,
            "py-4 justify-center": collapsed,
          })}
        >
          {!collapsed && (
            <span className="whitespace-nowrap">
              <img className={"w-full"} src={name} alt="Logo" />
            </span>
          )}
          <button
            className="grid text-white place-content-center hover:bg-gold w-10 h-10 rounded-full hidden md:flex items-center transition-colors"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon className="w-5 h-5 m-3" />
          </button>
        </div>
        <nav className="flex-grow overflow-auto flex flex-col">
          <ul
            className={classNames({
              "my-2 flex flex-col items-stretch": true,
            })}
          >
            {defaultNavItems.map((item, index) => {
              return (
                <SideBarItem
                  key={index}
                  selectedOption={selectedOption}
                  collapsed={collapsed}
                  itemKey={item.key}
                  onClick={() => selectMenuOption(item)}
                >
                  {item.key === NavItemKey.CONVERSATIONS &&
                  unreadMessages.length > 0 ? (
                    <>
                      <div className="relative flex justify-center items-center">
                        {item.icon}
                        <div className="bg-red-500 h-2 aspect-square rounded-full absolute top-0 right-0"></div>
                      </div>
                      <span>{!collapsed && item.label}</span>
                    </>
                  ) : (
                    <>
                      {item.icon} <span> {!collapsed && item.label}</span>
                    </>
                  )}
                </SideBarItem>
              );
            })}
            <Section
              title={t("COMMUNICATION")}
              items={communicationNavItems}
              selectedOption={selectedOption}
              selectMenuOption={selectMenuOption}
              collapsed={collapsed}
            />
            <Section
              title={t("OTHERS")}
              items={otherNavItems}
              selectedOption={selectedOption}
              selectMenuOption={selectMenuOption}
              collapsed={collapsed}
            />

            {currentUser?.role_id !== RoleEnum.AGENT && (
              <Section
                title={t("ADMINISTRATION")}
                items={adminNavItems}
                selectedOption={selectedOption}
                selectMenuOption={selectMenuOption}
                collapsed={collapsed}
              />
            )}
          </ul>
          <ul className="grow"></ul>
          <ul
            className={classNames({
              "my-2 flex flex-col gap-2 items-stretch bottom-0": true,
            })}
          >
            {defaultNavOptions.map((item, index) => {
              return (
                <SideBarItem
                  key={index}
                  selectedOption={selectedOption}
                  collapsed={collapsed}
                  itemKey={item.key}
                  onClick={() => selectMenuOption(item)}
                >
                  {item.icon} <span>{!collapsed && item.label}</span>
                </SideBarItem>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default Sidebar;
