import {
    UserPlusIcon
} from "@heroicons/react/24/outline";
import { useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import {
    useNavigate,
} from "react-router-dom";
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { LoadingMask } from '../../components/LoadingMask';
import { NewUserPath, NewAutomationPath, UpdateUserPath, UpdateAutomationPath } from '../../constants/Routes';
import { RoleLabel } from "../../enums/Role";
import { fetchUsers } from "../../state/users/actions";

export const Automations = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const automations = useSelector((state: any) => state.automations.automations);
    const isLoading = useSelector((state: any) => state.automations.isLoading);

    const columns = useMemo(
        () => [
            {
                Header: t('CODE'),
                accessor: "code",
            },
            {
                Header: t('NAME'),
                accessor: "name",
            },
            {
                Header: t('DEFAULT'),
                accessor: "roleId",
                Cell: ( row: any ) => {
                    const { roleId } = row.row.original;
                    return RoleLabel(`${roleId}`);
                },
            },
        ],
        [t]
    );

    const updateAutomation = (row: any) => {
        navigate(UpdateAutomationPath, { state: { automation: row } });
    };

    return (<>
        {isLoading && <LoadingMask />}
        <FilteredTable
            columns={columns}
            data={automations}
            onRowClick={updateAutomation}
            buttonAction={<Button onClick={() => navigate(NewAutomationPath)}>
                <span className='flex items-center font-semibold pr-3'>
                    <div className='w-8 p-1 aspect-square mr-2'><UserPlusIcon /></div>
                    {t('NEW_AUTOMATION')}
                </span>
            </Button>}
        />
    </>
    )
}