import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { ImmigrationStatus } from "../../models/InmigrationStatus";

export const fetchImmigrationStatusByClientId = createAsyncThunk("client/fetchImmigrationStatusByClientId", async (params: {clientId: number}) => {
    const response: AxiosResponse<ImmigrationStatus> = await (await axiosClient()).get(`/clients/${params.clientId}/immigration-status`);
    return response.data
});

export const createClientImmigrationStatus = createAsyncThunk("client/createClientImmigrationStatus", async (params:{newImmigration: ImmigrationStatus, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<ImmigrationStatus> = await (await axiosClient()).post(`/clients/${params.clientId}/immigration-status`, {...params.newImmigration});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateClientImmigrationStatus = createAsyncThunk("client/updateClientImmigrationStatus", async (params:{editImmigration: ImmigrationStatus, immigrationId: number, clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<ImmigrationStatus> = await (await axiosClient()).patch(`/clients/${params.clientId}/immigration-status/${params.immigrationId}`, {...params.editImmigration});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const needToLoadClientImmigrationStatus = createAsyncThunk("client/needToLoadClientImmigrationStatus", async (needLoad: boolean) => {
    return needLoad;
});