import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  children: any;
  cancelButton: boolean;
  saveButton?: { label: string, onClick: () => void, icon: React.ReactNode };
}

export const ModalInformation: React.FC<ModalProps> = ({ label, isOpen, onClose, children, saveButton, cancelButton}) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
  
    useEffect(() => {
      setIsModalOpen(isOpen);
    }, [isOpen]);
  
    useEffect(() => {
      if (isModalOpen) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    }, [isModalOpen]);
  
    return (
      <>
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center h-full bg-black bg-opacity-50">
            <div className="relative mx-auto p-4 rounded-md shadow-lg bg-white max-w-full max-h-[90%] flex flex-col min-h-[50%] md:w-3/5 w-4/6">
              <div className="flex items-center justify-between p-4 border-b border-neutral-100">
                <h5 className="text-xl font-medium text-neutral-800">{label}</h5>
                <button
                  type="button"
                  onClick={onClose}
                  className="p-1 border-none rounded-none hover:opacity-75 focus:opacity-100 focus:outline-none"
                  aria-label="Close"
                >
                  <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="relative flex-auto overflow-auto p-4" data-te-modal-body-ref>
                {children}
              </div>
              <div className="flex items-center justify-end p-4 border-t border-neutral-100">
                { cancelButton && <Button isTerciary onClick={onClose} classNames='text-black mt-2'>
                    <span className='flex items-center font-semibold pr-3'>
                      <div className='w-8 p-1 aspect-square mr-2'><XMarkIcon /></div>
                      {t('CLOSE')}
                    </span>
                  </Button>
                }
                { saveButton && <Button onClick={saveButton.onClick} classNames="ml-2 mt-2">
                    <span className="flex items-center font-semibold pr-3">
                      <div className="w-8 p-1 aspect-square mr-2">{saveButton.icon ?? <CheckIcon />}</div>
                      {saveButton.label ?? t('SAVE')}
                    </span>
                  </Button>
                }
              </div>
            </div>
          </div>
        )}
      </>
    );
};