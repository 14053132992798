import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { t } from "i18next";
import {
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import Button from "./Button";
import { FormSelect } from "./form/FormSelect";
import { SearchBar } from "./SearchBar";

const paginationNumberOptions = [
  {
    label: "10",
    value: "10",
    key: "10",
  },
  {
    label: "25",
    value: "25",
    key: "25",
  },
  {
    label: "50",
    value: "50",
    key: "50",
  },
];

export interface TableFilter {
  name: string;
  label: string;
  options: any[];
  setOption: (newValue: string[]) => void;
}

interface PaginationOptions {
  page: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
  setPage: (item: number) => void;
  setPageSize: (item: number) => any;
}

interface Props {
  data: any[];
  columns: any[];
  onRowClick?: (row: any) => void;
  buttonAction?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  secondaryActionButton?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  tertiaryActionButton?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  quaternaryActionButton?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  noPadding?: boolean;
  hideHeaders?: boolean;
  isSelectable?: boolean;
  secondRow?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  filter?: TableFilter;
  isPaginated?: boolean;
  paginationOptions?: PaginationOptions;
  hasSearchBar?: boolean;
  fullWidth?: boolean;
  title?: string;
  highlightRow?: (row: any) => void;
}

const FilteredTable: React.FC<Props> = ({
  data,
  columns,
  buttonAction,
  secondaryActionButton,
  tertiaryActionButton,
  quaternaryActionButton,
  onRowClick,
  paginationOptions,
  hideHeaders,
  isPaginated,
  secondRow,
  filter,
  fullWidth,
  noPadding = false,
  hasSearchBar = true,
  title,
  highlightRow
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    selectedFlatRows, // Selected rows
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect
  ) as any;

  const { globalFilter } = state;

  return (
    <>
      <div
        className={classNames({
          "w-full grow flex flex-col items-end": true,
          "p-5": !noPadding,
        })}
      >
        {(hasSearchBar ||
          buttonAction ||
          secondaryActionButton ||
          tertiaryActionButton ||
          quaternaryActionButton ||
          title) && (
          <div className=" w-full flex flex-col md:flex-row pb-5 justify-start md:justify-between gap-3 md:gap-0">
            <div className="text-right justify-end md:hidden flex">
              {buttonAction}
              {secondaryActionButton !== undefined
                ? secondaryActionButton
                : null}
              {tertiaryActionButton !== undefined ? tertiaryActionButton : null}
            </div>
            <div
              className={classNames({
                "flex flex-col md:flex-row gap-3": true,
                "md:w-3/4": secondaryActionButton === undefined && !fullWidth,
                "md:w-1/2": secondaryActionButton !== undefined && !fullWidth,
                "w-full": fullWidth,
              })}
            >
              {title && <h2 className="text-xl">{title}</h2>}
              {quaternaryActionButton !== undefined ? quaternaryActionButton : null}
              {hasSearchBar && (
                <SearchBar
                  isWhite
                  value={globalFilter || ""}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
              )}
            </div>
            <div className="text-right hidden md:flex items-center">
              {buttonAction}
              {secondaryActionButton !== undefined
                ? secondaryActionButton
                : null}
              {tertiaryActionButton !== undefined ? tertiaryActionButton : null}
            </div>
          </div>
        )}
        {secondRow}
        <div
          className={classNames({
            "flex flex-col w-full overflow-auto h-auto relative bg-white rounded-lg shadow":
              true,
          })}
        >
          <table
            {...getTableProps()}
            className="w-full text-sm text-left text-gray-500"
          >
            {!hideHeaders && (
              <thead className=" text-gray-700">
                {headerGroups.map((headerGroup: any) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="text-blue sticky top-0 bg-white border-b-2	border-gray-200"
                  >
                    {headerGroup.headers.map((column: any) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="p-4"
                      >
                        <div className="flex text-md font-medium">
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ChevronDownIcon className="w-4 h-4 ml-2 mt-1" />
                              ) : (
                                <ChevronUpIcon className="w-4 h-4 ml-2 mt-1" />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any, i: any) => {
                prepareRow(row);
                const isHighlighted = highlightRow !== undefined ? highlightRow(row.original) : false;
                return (
                  <tr
                    {...row.getRowProps()}
                    className={classNames({
                      "bg-white hover:bg-neutral-100 cursor-pointer": !isHighlighted,
                      "bg-gold-100 hover:bg-gold-200 cursor-pointer": isHighlighted,
                      "bg-gray-200": row.isSelected, // Apply selection background color
                    })}
                    onClick={() =>
                      onRowClick ? onRowClick(row.original) : undefined
                    }
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <td {...cell.getCellProps()} className="py-2 px-4">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {isPaginated && paginationOptions && (
            <div className="flex justify-end items-center text-xs text-gray-700 p-4 gap-6">
              <div className="flex items-center">
                <p>{t("ITEMS_PER_PAGE")}:</p>
                <FormSelect
                  width="w-28"
                  name="numberItems"
                  label=""
                  options={paginationNumberOptions}
                  selectedValue={paginationOptions.pageSize}
                  handleOnChange={(e) =>
                    paginationOptions.setPageSize(e.target.value)
                  }
                />
              </div>
              <p>
                {paginationOptions.page * paginationOptions.pageSize + 1} -{" "}
                {paginationOptions.page * paginationOptions.pageSize +
                  paginationOptions.pageSize}{" "}
                of {paginationOptions.totalCount}
              </p>
              <div className="flex items-center">
                <Button
                  isTerciary
                  isDisabled={paginationOptions.page === 0}
                  onClick={() =>
                    paginationOptions.setPage(paginationOptions.page - 1)
                  }
                  iconOnly
                  classNames="w-9 p-1 aspect-square mr-4 "
                >
                  <ChevronLeftIcon />
                </Button>
                <Button
                  isTerciary
                  isDisabled={
                    paginationOptions.totalPages - 1 === paginationOptions.page
                  }
                  onClick={() =>
                    paginationOptions.setPage(paginationOptions.page + 1)
                  }
                  iconOnly
                  classNames="w-9 p-1 aspect-square mr-4 "
                >
                  <ChevronRightIcon />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FilteredTable;
