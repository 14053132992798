import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { Lead, NewLead, EditLead, LeadResponse, LeadsFilters, UploadLeadResponse } from "../../models/Lead";
import { axiosClient } from "../axiosClient";
import { Client } from "../../models/Client";

export const fetchLeads = createAsyncThunk("leads", async (data: {page: number, pageSize: number, filters: LeadsFilters}) => {
    const response: AxiosResponse<LeadResponse> = await (await axiosClient()).patch(`/leads?page=${data.page}&pageSize=${data.pageSize}`, {...data.filters});
    return {
        ...response.data,
        filters: data.filters
    };
});

export const fetchLeadById = createAsyncThunk("leads/getLeadById", async (id: number) => {
    const response: AxiosResponse<Lead> = await (await axiosClient()).get(`/leads/${id}`);
    return response.data;
});

export const fetchLeadsPool = createAsyncThunk("leadsPool", async (data: {page: number, pageSize: number, filters: LeadsFilters}) => {
    const response: AxiosResponse<LeadResponse> = await (await axiosClient()).patch(`/leads/unassigned-pool?page=${data.page}&pageSize=${data.pageSize}`, {...data.filters});
    return {
        ...response.data,
        filters: data.filters
    };
});

export const createLead = createAsyncThunk("leads/createLead", async (lead: NewLead, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Lead> = await (await axiosClient()).post(`/leads`, { ...lead });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const createAutomationLead = createAsyncThunk("leads/createAutomationLead", async (params: { lead: NewLead, automationId: number }, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Lead> = await (await axiosClient()).post(`/automations/entry-lead`, { ...params.lead }, { params: { automationId: params.automationId } });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateLead = createAsyncThunk("leads/updateLead", async (params: { lead: EditLead, leadId: number }, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Lead> = await (await axiosClient()).patch(`/leads/${params.leadId}`, { ...params.lead });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const uploadAgentLeads = createAsyncThunk("leads/uploadAgentLeads", async (leads: any, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Lead[]> = await (await axiosClient()).post(`/leads/upload`, leads);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const uploadAgencyLeads = createAsyncThunk("leads/uploadAgencyLeads", async (params: {agencyId: number, isFromPool: boolean, leads: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<UploadLeadResponse> = await (await axiosClient()).post(`/leads/upload-multiple?agencyId=${params.agencyId}&isFromPool=${params.isFromPool}`, params.leads);
        return {
            ...response.data,
            isFromPool: params.isFromPool
        }
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const startLeadAutomation = createAsyncThunk("leads/startAutomation", async (params: {phone: any, automationId: number }, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<any> = await (await axiosClient()).post(`/automations/start-automation`, undefined, { params: { phone: params.phone, automationId: params.automationId } });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const assignLeadFromPool = createAsyncThunk("leads/assignLeadFromPool", async (params: {leadId: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Lead> = await (await axiosClient()).put(`/leads/${params.leadId}/assign-lead-pool`);
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const pastPage = createAsyncThunk("leads/pastPage", async () => {
    return true;
});

export const pastPoolPage = createAsyncThunk("leads/pastPoolPage", async () => {
    return true;
});

export const updateLeadClient = createAsyncThunk("leads/updateLeadClient", async(createdClient: Client) => {
    return createdClient;
});