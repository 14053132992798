import i18next from "i18next";

export enum PolciyStateEnum {
    ACTIVE = 1,
    PENDING_RENOVATION,
    CANCELLED
}

export const PolicyStatusLabel = (key: string) => ({
    [PolciyStateEnum.ACTIVE]: i18next.t('ACTIVE'),
    [PolciyStateEnum.PENDING_RENOVATION]: i18next.t('PENDING_RENOVATION'),
    [PolciyStateEnum.CANCELLED]: i18next.t('CANCELLED')
})[key];

export const getPolicyStates = () =>
    Object.values(PolciyStateEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: PolicyStatusLabel(value) ?? ""
        }
    });