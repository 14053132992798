export enum NavItemKey {
    DASHBOARD,
    CLIENTS,
    POLICIES,
    CONVERSATIONS,
    PHONE,
    LEADS,
    TAGS,
    LEAD_AUTOMATIONS,
    USERS,
    AGENCY,
    SIGN_OUT,
    METADATA,
    COMMISSIONS,
    POLICY_SELLS_REPORT,
    CAMPAIGNS
}