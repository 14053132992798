import { useTranslation } from "react-i18next";
import { CheckIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Address } from "../../../models/Address";
import { ConfirmationModal } from "../../ConfirmationModal";

interface Props {
    info: {
        show: boolean;
        address?: Address;
    };
    onClose: () => void;
    deleteAddress: (address: Address) => void
}

export const DeleteClientAddressModal: React.FC<Props> = ({
    info,
    onClose,
    deleteAddress
}) => {

    const { t } = useTranslation();

    const onDeleteAddress = () => {
        deleteAddress(info.address!)
        onClose();
    };

    return(
        <ConfirmationModal 
            label={t("DELETE_ADDRESS")}
            isDeleteConfirm={true}
            isOpen={info.show}
            onClose={onClose}
            cancelButton={{ label: t("CLOSE"), icon: <XMarkIcon />, onClick: onClose, }}
            saveButton={{ label: t("DELETE"), icon: <TrashIcon />, onClick: onDeleteAddress }}
        >
            <div className='w-full flex flex-wrap items-center justify-center'>
                <label className="text-lg font-semibold py-4 px-2 text-center">
                    {t('DELETE_ADDRESS_MESSAGE')}
                </label>
            </div>
        </ConfirmationModal>
    )
}