import { useTranslation } from "react-i18next";
import { EditPolicy, Policy } from "../../models/Policies";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch, RootState } from "../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { AgentCommissionRule } from "../../models/AgentCommissionRule";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "../../components/Modal";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { getPolicyStates } from "../../enums/PolicyState";
import { ReactiveFormRadioButtonSelect } from "../../components/form/ReactiveFormRadioButtonSelect";
import { RoleEnum } from "../../enums/Role";
import { User } from "../../models/User";
import { fetchAgencyUserCommissionRules } from "../../state/agencies/action";
import { updatePolicy } from "../../state/policies/actions";
import { Toast } from "../../components/Toast";
import { LoadingMask } from "../../components/LoadingMask";
import { AgencyPolicyType, PolicyMarket, PolicyType } from "../../models/PolicyMarket";
import { fetchMarketPolicyTypes } from "../../state/policyMarkets/actions";
import { ReactiveFormLocalDateInput } from "../../components/form/ReactiveFormLocalDateInput";
import moment from "moment";
import { Carrier } from "../../models/Carrier";
import { fetchCarriersForPolicyType } from "../../state/carriers/actions";
import { Beneficiary } from "../../models/Beneficiary";
import { PolciyMarketEnum } from "../../enums/PolicyMarket";
import { PolicyBeneficiariesForm } from "../../components/clients/policy/beneficiary/PolicyBeneficiariesForm";

interface Props {
  info: {
    show: boolean;
    policy?: any;
  };
  onClose: () => void;
}

export const PolicyUpdateModal: React.FC<Props> = ({ info, onClose }) => {
  const { t } = useTranslation();
  const [showUpdateError, setShowUpdateError] = useState(false);
  const [isPolicyUpdateLoading, setIsPolicyUpdateLoading] = useState(false);
  const errorUpdate = useSelector((state: any) => state.policies.errorUpdate);

  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const agents = useSelector((state: any) => state.users.users);
  
  const agentAvailableMarkets = useSelector((state: RootState) => state.policyMarkets.agencyPolicyTypes);
  
  const [selectedPolicyMarket, setSelectedPolicyMarket] = useState<PolicyMarket>()
  const [selectedPolicyType, setSelectedPolicyType] = useState<PolicyType>()
  const [policyTypes, setPolicyTypes] = useState<PolicyType[]>([]);
  const [loadingTypes, setLoadingTypes] = useState(false);

  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [loadingCarriers, setLoadingCarriers] = useState(false);

  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([])

  const [commissionRules, setCommissionRules] = useState<AgentCommissionRule[]>(
    []
  );
  const [selectedCommissionRule, setSelectedCommissionRule] = useState<
    AgentCommissionRule | undefined
  >(undefined);
  const [loadingRules, setLoadingRules] = useState(false);

  const methods = useForm<EditPolicy>({
    defaultValues: {},
  });
  const { handleSubmit, control, getValues, reset } = methods;

  useEffect(() => {
    if (showUpdateError) {
      Toast(
        t(errorUpdate?.reason ?? "SOMETHING_WENT_WRONG"),
        t(errorUpdate?.cause_info)
      );
      setShowUpdateError(false);
    }
  }, [showUpdateError, errorUpdate, t]);

  useEffect(() => {
    info.policy ? reset(info.policy) : reset({});

    if (info.policy && info.policy.agent_id) {
      if (agents.find((x: any) => x.id === +info.policy!.agent_id)) {
        setLoadingRules(true);
        dispatch(
          fetchAgencyUserCommissionRules({
            agencyId: currentUser.agency_id,
            userId: +info.policy.agent_id!,
          })
        ).then((e) => {
          let rules = (e.payload || []) as AgentCommissionRule[];
          setCommissionRules(rules);

          if (info.policy?.agent_commission_rule_id) {
            setSelectedCommissionRule(
              rules.find(
                (x: AgentCommissionRule) =>
                  x.id === +info.policy?.agent_commission_rule_id
              )
            );
          }

          setLoadingRules(false);
        });
      } else {
        info.policy.agent_id = undefined;
      }
    }

    if(info.policy && info.policy.policy_market_id) {
      let aAvailableMarket = agentAvailableMarkets.find((market) => market.market_id === info.policy?.policy_market_id);
      setPolicyTypes(aAvailableMarket?.policy_types!);
      setSelectedPolicyMarket({id: aAvailableMarket?.market_id!, name: aAvailableMarket?.market_name!});
      setBeneficiaries(info.policy?.beneficiaries ?? []);

      if(aAvailableMarket?.policy_types !== undefined) {
        let policyType = aAvailableMarket.policy_types.find((p) => p.id === +info.policy?.policy_type_id!);
        if(policyType !== undefined) {
          setSelectedPolicyType(policyType);
          setLoadingCarriers(true)
          dispatch(fetchCarriersForPolicyType(+info.policy?.policy_type_id!)).then((e) => {
            setCarriers((e.payload || []) as Carrier[])
            setLoadingCarriers(false)
          })
        }
      }
    }
  }, [info.policy, reset]);

  const onSubmitInvalid: SubmitErrorHandler<EditPolicy> = (data) => {
    toast(t("PLEASE_ENTER_ALL_POLICY_REQUIRED_FIELDS"));
  };

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    if (checkPolicyMembers(data)) {
      Toast(t("POLICY_MEMBERS_ERROR"), t("POLICY_MEMBERS_MESSAGE"));
    } else {
      setIsPolicyUpdateLoading(true);
      const currentDate = moment(new Date());
      const soldAt = moment(data.sold_at).hours(currentDate.hours()).minutes(currentDate.minutes()).seconds(currentDate.seconds()).toDate();
      data.sold_at = soldAt;
      data.beneficiaries = beneficiaries;

      dispatch(updatePolicy({ policy: data })).then((e) => {
        if (e.type === "policies/update/rejected") {
          setShowUpdateError(true);
          setIsPolicyUpdateLoading(false);
        } else {
          toast(t("SAVED_SUCCESFULLY"));
          setIsPolicyUpdateLoading(false);
          closeModal();
          //navigate(ClientsPath)
        }
      });
    }
  };

  const checkPolicyMembers = (data: any) => {
    let policiesMembers = 0;

    if (data.members_insured != undefined) {
      if (data.members_insured <= 0) {
        policiesMembers++;
      }
    } else {
      policiesMembers++;
    }

    return policiesMembers > 0;
  };

  const closeModal = () => {
    reset();
    setSelectedCommissionRule(undefined);
    setCommissionRules([])
    setPolicyTypes([])
    setSelectedPolicyMarket(undefined);
    setSelectedPolicyType(undefined);
    setBeneficiaries([]);
    setCarriers([]);
    onClose();
  };

  const policyBeneficiariesHasChanged = (cBeneficiaries: Beneficiary[]) => {
    setBeneficiaries(cBeneficiaries)
  }

  return (
    <>
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <Modal
        isOpen={info.show}
        onClose={closeModal}
        label={t("UPDATE_POLICY")}
        saveButton={{
          label: t("SAVE"),
          icon: <PaperAirplaneIcon />,
          onClick: handleSubmit(onSubmit, onSubmitInvalid),
        }}
      >
        {isPolicyUpdateLoading && <LoadingMask />}
        <div className="w-full flex flex-wrap">
          <Fragment>
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("POLICY_NUMBER")}
              name={`number`}
            />
            {
              agentAvailableMarkets.length > 0 &&
              <>
                <ReactiveFormSelect
                  className="md:w-1/3"
                  name={`policy_market_id`}
                  label={t("POLICY_MARKET")}
                  options={agentAvailableMarkets.map((x: AgencyPolicyType) => { return {label: `${x.market_name}`, value: `${x.market_id}`} })}
                  control={control}
                  handleOnChange={(x: any) => {
                    methods.resetField("policy_type_id")
                    setSelectedPolicyType(undefined);
                    let aPolicyM = agentAvailableMarkets.find((market) => market.market_id === +x.target.value);
                    if(aPolicyM !== undefined) {
                      setPolicyTypes(aPolicyM.policy_types!)
                      setSelectedPolicyMarket({id: aPolicyM.market_id, name: aPolicyM.market_name});
                    } else {
                      setSelectedPolicyMarket(undefined);
                      setPolicyTypes([]);
                    }
                  }}
                  isRequired
                />

                <ReactiveFormSelect
                  className="md:w-1/3"
                  name={`policy_type_id`}
                  label={t("POLICY_TYPE")}
                  options={policyTypes.map((t: PolicyType) => { return {label: `${t.name}`, value: `${t.id}`} })}
                  control={control}
                  isDisabled={methods.getValues("policy_market_id") === undefined || loadingTypes}
                  isRequired
                  handleOnChange={(e: any) => {
                    methods.resetField("carrier_id")
                    let policyType = policyTypes.find((x: PolicyType) => x.id === +e.target.value);
                    methods.setValue("policy_type", policyType?.name);
                    setSelectedPolicyType(policyType);
                    setLoadingCarriers(true)
                    dispatch(fetchCarriersForPolicyType(+e.target.value)).then((e) => {
                      setCarriers((e.payload || []) as Carrier[])
                      setLoadingCarriers(false)
                    })
                  }}
                />

                <ReactiveFormSelect
                  className="md:w-1/3"
                  name={`carrier_id`}
                  label={t("CARRIER")}
                  control={control}
                  options={carriers.map((c: Carrier) => { return {label: `${c.value}`, value: `${c.id}`}})}
                  isDisabled={methods.getValues("policy_type_id") === undefined || loadingCarriers}
                  isRequired
                />
              </>
            }
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("CLIENT")}
              name={`client`}
              isDisabled={true}
            />
            {/*<ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("AGENT")}
              name={`agent`}
              isDisabled={true}
            /> */}
            <ReactiveFormInput
              control={control}
              type="date"
              className="md:w-1/3"
              label={t("EFFECTIVE_START_DATE")}
              name={`effective_start_date`}
              isRequired
            />
            {
              ((selectedPolicyType && selectedPolicyType.has_effective_end_date) || !selectedPolicyType) &&
              <ReactiveFormInput
                control={control}
                type="date"
                className="md:w-1/3"
                label={t("EFFECTIVE_END_DATE")}
                name={`effective_end_date`}
              />
            }
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("MEMBERS_INSURED")}
              name={`members_insured`}
              type="number"
              isRequired
            />
            <ReactiveFormSelect
              className="md:w-1/3"
              name={`policy_state`}
              label={t("STATUS")}
              options={getPolicyStates()}
              control={control}
              isRequired
            />
            <ReactiveFormRadioButtonSelect
              className="md:w-1/3"
              control={control}
              name={`free_plan`}
              label={t("FREE_PLAN")}
              options={[
                { label: t("YES"), value: "1" },
                { label: t("NO"), value: "0" },
              ]}
            />
            <ReactiveFormLocalDateInput
              control={control}
              type="date"
              className="md:w-1/3"
              label={t("SOLD_DATE")}
              name={`sold_at`}
              isRequired
            />
          </Fragment>
          
          {
            selectedPolicyMarket && selectedPolicyMarket.id === PolciyMarketEnum.LIFE &&
            <Fragment>
              <ReactiveFormInput
                control={control}
                className="md:w-1/3"
                label={t("ANUAL_PREMIUM")}
                name={`anual_premium`}
                type="number"
                isRequired
              />

              <ReactiveFormInput
                control={control}
                className="md:w-1/3"
                label={t("FACE_VALUE")}
                name={`face_value`}
                type="number"
                isRequired
              />
            </Fragment>
          }

          {currentUser?.role_id === RoleEnum.SUPER_ADMIN && (
            <Fragment>
              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_id`}
                label={t("AGENT")}
                options={agents.map((x: User) => {
                  return {
                    label: `${x.first_name} ${x.last_name}`,
                    value: `${x.id}`,
                  };
                })}
                control={control}
                handleOnChange={(x: any) => {
                  methods.resetField("agent_commission_rule_id");
                  setSelectedCommissionRule(undefined);
                  setLoadingRules(true);
                  dispatch(
                    fetchAgencyUserCommissionRules({
                      agencyId: currentUser.agency_id,
                      userId: +x.target.value,
                    })
                  ).then((e) => {
                    setCommissionRules(
                      (e.payload || []) as AgentCommissionRule[]
                    );
                    setLoadingRules(false);
                  });
                }}
                isRequired
              />

              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_commission_rule_id`}
                label={t("AGENT_COMMISSION_RULE")}
                options={commissionRules.map((r: AgentCommissionRule) => {
                  return {
                    label: `${r.name} - ${
                      r.is_relative ? r.value! * 100 + "%" : "$" + r.value
                    }`,
                    value: `${r.id}`,
                  };
                })}
                control={control}
                isDisabled={
                  methods.getValues("agent_id") === undefined || loadingRules
                }
                isRequired
                handleOnChange={(e: any) =>
                  setSelectedCommissionRule(
                    commissionRules.find(
                      (x: AgentCommissionRule) => x.id === +e.target.value
                    )
                  )
                }
              />

              {selectedCommissionRule !== undefined && (
                <p className="text-slate-500 text-xs px-3">
                  {selectedCommissionRule.description}
                </p>
              )}
            </Fragment>
          )}

          {currentUser?.role_id !== RoleEnum.SUPER_ADMIN && (
            <Fragment>
              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_id`}
                label={t("AGENT")}
                options={agents.map((x: User) => {
                  return {
                    label: `${x.first_name} ${x.last_name}`,
                    value: `${x.id}`,
                  };
                })}
                control={control}
                isDisabled
              />

              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_commission_rule_id`}
                label={t("AGENT_COMMISSION_RULE")}
                options={commissionRules.map((r: AgentCommissionRule) => {
                  return {
                    label: `${r.name} - ${
                      r.is_relative ? r.value! * 100 + "%" : "$" + r.value
                    }`,
                    value: `${r.id}`,
                  };
                })}
                control={control}
                isDisabled
              />

              {selectedCommissionRule !== undefined && (
                <p className="text-slate-500 text-xs px-3">
                  {selectedCommissionRule.description}
                </p>
              )}
            </Fragment>
          )}
          {
            selectedPolicyMarket && selectedPolicyMarket.id === PolciyMarketEnum.LIFE &&
            <PolicyBeneficiariesForm
              isNewClient={false}
              beneficiaries={beneficiaries}
              policy={info.policy ?? null}
              beneficiariesHasChanged={policyBeneficiariesHasChanged}
            />
          }
        </div>
      </Modal>
    </>
  );
};
