import { CheckIcon, RectangleStackIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import states from "states-us";
import Button from "../../components/Button";
import { LoadingMask } from "../../components/LoadingMask";
import { Toast } from "../../components/Toast";
import { Form } from "../../components/form/Form";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { ReactiveFormPhoneInput } from "../../components/form/ReactiveFormPhoneInput";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { LeadsPath, NewClientPath } from "../../constants/Routes";
import { getLeadStatuses } from "../../enums/LeadStatus";
import { getSources } from "../../enums/Source";
import { EditLead, Lead } from "../../models/Lead";
import { createClientFromLead } from "../../state/clients/actions";
import { updateLead } from "../../state/leads/actions";
import { AppDispatch, RootState } from "../../state/store";
import { fetchConversations } from "../../state/twilio/actions";
import FilteredTable from "../../components/FilteredTable";
import { DocumentEnvelope } from "../../models/ConsentDocument";
import { DocumentArrowDownIcon, NoSymbolIcon } from "@heroicons/react/24/solid";
import { longLocalDateFormat } from "../../utils/functions";
import { fetchLeadDocumentEnvelopes, getEnvelopeDownloadDocument } from "../../state/consentDocuments/actions";
import { LeadConsentDocumentModal } from "../../components/leads/LeadConsentDocumentModal";
import { VoidLeadConsentDocumentModal } from "../../components/leads/VoidLeadConsentDocumentModal";

export const UpdateLead = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.leads.error);
  const location = useLocation();
  const lead = location.state.lead;
  const [showError, setShowError] = useState(false);
  const isLoading = useSelector((state: any) => state.leads.isLoading);
  const isCreateClientLoading = useSelector((state: any) => state.clients.isLoading);
  const clients = useSelector((state: any) => state.clients.clients);
  const currentUser = useSelector((state: any) => state.users.currentUser);

  const [consentDocuments, setConsentDocuments] = useState<DocumentEnvelope[]>([]);
  const leadConsentDocuments = useSelector((state: RootState) => state.consentDocuments.leadEnvelopes);
  const isLoadingConsentDocuments = useSelector((state: RootState) => state.consentDocuments.isLoading);
  const isLoadingDownload = useSelector((state: RootState) => state.consentDocuments.isLoadingDownload);

  const [leadConsentDocumentModal, setLeadConsentDocumentModal] = useState<{
    show: boolean;
    lead?: Lead;
  }>({ show: false });
  
  const [ showVoidLeadConsentDocumentModal, setShowVoidLeadConsentDocumentModal] = useState<{
      consentDocument?: DocumentEnvelope;
      lead?: Lead;
      show: boolean;
  }>({ show: false, lead: undefined, consentDocument: undefined });

  const methods = useForm<EditLead>({
    defaultValues: lead,
  });
  const { handleSubmit, control } = methods;

  useEffect(() => {
    if (showError) {
      Toast(t(error?.reason ?? "SOMETHING_WENT_WRONG"), t(error?.cause_info));
      setShowError(false);
    }
  }, [showError, error, t]);

  useEffect(() => {
    //TODO: Review state and get data
    dispatch(fetchLeadDocumentEnvelopes(lead.id)).then((e) => {
      let consentDocuments = (e.payload || []) as DocumentEnvelope[];
      setConsentDocuments(consentDocuments);
    });
  }, []);

  const getStates = useMemo(() => {
    return states.map((state: { name: any; abbreviation: any }) => {
      return {
        label: state.name,
        value: state.abbreviation,
        key: state.abbreviation,
      };
    });
  }, []);

  const onSubmit: SubmitHandler<EditLead> = async (data: EditLead) => {
    const updatedData = data;
    if (data.referred_amount) {
      updatedData.referred_amount = +data.referred_amount;
    }
    if (data.source_id) {
      updatedData.source_id = +data.source_id;
    }
    if (data.status_id) {
      updatedData.status_id = +data.status_id;
    }
    dispatch(updateLead({ lead: updatedData, leadId: lead.id })).then((e) => {
      if (e.type === "leads/updateLead/rejected") {
        setShowError(true);
      } else {
        toast(t("SAVED_SUCCESFULLY"));
        navigate(LeadsPath);
      }
    });
  };

  const createClient = () => {
    navigate(NewClientPath, { state: { lead: lead } });
  }
  
  /*const createClient: SubmitHandler<EditLead> = async (data: EditLead) => {
    const updatedData = data;
    if (data.referred_amount) {
      updatedData.referred_amount = +data.referred_amount;
    }
    if (data.source_id) {
      updatedData.source_id = +data.source_id;
    }
    if (data.status_id) {
      updatedData.status_id = +data.status_id;
    }

    dispatch(createClientFromLead({ lead: updatedData, leadId: lead.id })).then(
      (e) => {
        if (e.type === "leads/createClientFromLead/rejected") {
          setShowError(true);
        } else {
          toast(t("SAVED_SUCCESFULLY"));
          dispatch(
            fetchConversations(currentUser.main_twilio_number?.twilio_phone)
          );
          navigate(LeadsPath);
        }
      }
    );
  };*/

  const canEnableCreateClient = () => {
    const leadClient = clients.find(
      (client: any) => client.lead_id === lead.id
    );
    if (leadClient !== undefined) {
      return true;
    } else {
      return false;
    }
  };
  
  const addConsentDocument = (consentDocument: DocumentEnvelope) => {
    setConsentDocuments(documents => [...documents, consentDocument])
  }
  
  const voidConsentDocument = (consentDocuments: DocumentEnvelope[]) => {
    //TODO: remove address from database
    setConsentDocuments(consentDocuments);
  }
  
  const downloadEnvelopeDocument = (envelope: DocumentEnvelope) => {
    dispatch(getEnvelopeDownloadDocument(envelope.docusign_envelope_id)).then(
      async (e: any) => {
        if (e.type === "getDownloadDocument/rejected") {
          toast(t("COULDNT_DOWNLOAD_DOCUMENT"));
        } else {
          const data = e.payload.download_documents;
          const nameSplit = envelope.template_document_name!.split(".");
          const filename = `${nameSplit[0]} - ${lead.first_name} ${lead.last_name}.pdf`;

          var binaryLen = data.length;
          var bytes = new Uint8Array(binaryLen);
          for (var i = 0; i < binaryLen; i++) {
            var ascii = data.charCodeAt(i);
            bytes[i] = ascii;
          }

          var blob = new Blob([bytes], { type: "application/pdf" });
          var link = window.URL.createObjectURL(blob);

          const aElement = document.createElement("a");
          document.body.appendChild(aElement);
          aElement.style.display = "none";
          aElement.href = link;
          aElement.download = filename;
          aElement.click();
          document.body.removeChild(aElement);
        }
      }
    );
  };

  const capitalize = (str: string) => {
    if (!str) return ''; // Si la cadena está vacía o es null, retorna una cadena vacía
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const columns = useMemo(
    () => [
        {
            Header: t("NAME"),
            accessor: "template_name",
        },
        {
            Header: t("SENT_BY"),
            accessor: "agent_name",
        },
        {
            Header: t("SENT_DOCUMENT_DATE"),
            Cell: (row: any) => row.row.original.sent_date_time ? `${longLocalDateFormat(row.row.original.sent_date_time)}` : "-",
        },
        {
            Header: t("COMPLETED_DOCUMENT_DATE"),
            Cell: (row: any) => row.row.original.completed_date_time ? `${longLocalDateFormat(row.row.original.completed_date_time)}` : "-",
        },
        {
            Header: t("STATUS"),
            Cell: (row: any) => row.row.original.status === "completed"
                ? `${capitalize(row.row.original.status)} (${t("SIGNED")})`
                : `${capitalize(row.row.original.status)} (${t("NO_SIGNED")})`,
        },
        {
            id: "actions",
            Header: "",
            Cell: (row: any) => {
                return (
                    <div className="flex flex-row justify-center divide-x">
                        <Button
                            isTerciary
                            iconOnly
                            tooltip={t("DOWNLOAD_DOCUMENT")}
                            onClick={() => downloadEnvelopeDocument(row.row.original)}
                            classNames="mr-2"
                        >
                            <DocumentArrowDownIcon className="h-5 aspect-square text-blue" />
                        </Button>
                        <Button
                            isTerciary
                            iconOnly
                            isDisabled={
                                row.row.original.status === "completed" ||
                                row.row.original.status === "voided"
                            }
                            tooltip={t("VOID_DOCUMENT")}
                            onClick={() => setShowVoidLeadConsentDocumentModal({ show: true, lead: lead, consentDocument: row.row.original })}
                            classNames="mr-2"
                        >
                            <NoSymbolIcon className="h-5 aspect-square text-blue" />
                        </Button>
                    </div>
                );
            },
        },
    ],
    [voidConsentDocument, t, downloadEnvelopeDocument]
  );

  return (
    <div className="w-full grow flex flex-col  px-5 space-y-4">
      {(isLoading || isCreateClientLoading || isLoadingConsentDocuments) && <LoadingMask />}
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <div className="text-black max-h-full p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden">
        <div className="text-xl font-bold text-black pl-3">{t("LEAD")}</div>
        <div className="pb-5">
          <Form handleOnSubmit={handleSubmit(onSubmit)}>
            <div className="w-full text-lg font-semibold p-3">
              {t("PERSONAL_INFORMATION")}
            </div>
            <ReactiveFormInput
              control={control}
              className="md:w-1/2"
              label={t("FIRST_NAME")}
              name="first_name"
              isRequired
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/2"
              label={t("LAST_NAME")}
              name="last_name"
              isRequired
            />
            <ReactiveFormInput
              control={control}
              type="date"
              className="md:w-1/3"
              label={t("DATE_OF_BIRTH")}
              name="dob"
              isRequired
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("EMAIL")}
              name="email"
              type="email"
              isRequired
            />
            <ReactiveFormPhoneInput
              control={control}
              className="md:w-1/3"
              name="phone"
              label={t("PHONE")}
              isRequired
            />
            <ReactiveFormInput
              control={control}
              label={t("STREET_ADDRESS")}
              name={`address`}
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("CITY")}
              name={`city`}
            />
            <ReactiveFormSelect
              className="md:w-1/3"
              name={`state`}
              label={t("STATE")}
              options={getStates}
              control={control}
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("ZIP_CODE")}
              name={`zip_code`}
            />
            <div className="w-full text-lg font-semibold p-3">
              {t("LEAD_INFORMATION")}
            </div>
            <ReactiveFormSelect
              className="md:w-1/4"
              name={`status_id`}
              label={t("STATUS")}
              options={getLeadStatuses()}
              control={control}
            />
            <ReactiveFormSelect
              className="md:w-1/4"
              name={`source_id`}
              label={t("SOURCE")}
              options={getSources()}
              control={control}
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/4"
              label={t("REFERRED_BY")}
              name={`referred_by`}
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/4"
              label={t("AMOUNT")}
              name={`referred_amount`}
              type="number"
            />
          </Form>
        </div>
      </div>

      <div className="text-black max-h-full p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden">
        <div className="text-xl font-bold text-black pl-3">{t("CONSENT_DOCUMENTS")}</div>
        <div className="pb-5">
          <FilteredTable
            columns={columns}
            data={consentDocuments}
            hasSearchBar={false}
            secondaryActionButton={
                <Button onClick={() => setLeadConsentDocumentModal({ show: true, lead: lead })}>
                    <span className="flex items-center font-semibold pr-3">
                        <div className="w-8 p-1 aspect-square mr-2">
                            <RectangleStackIcon />
                        </div>
                        {t("SEND_DOCUMENT")}
                    </span>
                </Button>
            }
          />
        </div>
      </div>

      <div className="pb-5 pt-4 justify-end gap-4 flex">
        <Button onClick={createClient} isDisabled={canEnableCreateClient()}>
          <span className="flex items-center font-semibold pr-3">
            <div className="w-8 p-1 aspect-square mr-2">
              <UserPlusIcon />
            </div>
            {t("CREATE_CLIENT")}
          </span>
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>
          <span className="flex items-center font-semibold pr-3">
            <div className="w-8 p-1 aspect-square mr-2">
              <CheckIcon />
            </div>
            {t("SAVE")}
          </span>
        </Button>
      </div>

      <LeadConsentDocumentModal
        info={leadConsentDocumentModal}
        onSubmited={(consentDocument) => {
            addConsentDocument(consentDocument)
        }}
        onClose={() => setLeadConsentDocumentModal({ show: false })}
      />

      <VoidLeadConsentDocumentModal
        info={showVoidLeadConsentDocumentModal}
        onClose={() => setShowVoidLeadConsentDocumentModal({show : false, lead: undefined, consentDocument: undefined})}
        onVoided={(consentDocuments: DocumentEnvelope[]) => voidConsentDocument(consentDocuments)}
      />
    </div>
  );
};
