import { useDispatch, useSelector } from "react-redux";
import { Client } from "../../../models/Client";
import { AppDispatch, RootState } from "../../../state/store";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { Toast } from "../../Toast";
import { useForm } from "react-hook-form";
import { Modal } from "../../Modal";
import { ReactiveFormUploadFile } from "../../form/ReactiveFormUploadFile";
import Button from "../../Button";
import { TrashIcon } from "@heroicons/react/24/outline";
import { toast, ToastContainer } from "react-toastify";
import { LoadingMask } from "../../LoadingMask";
import { CDocument, CNewDocument, NewDocument } from "../../../models/Document";
import { createClientDocument, getFileSignedUrl } from "../../../state/documents/actions";
import { MessageTypeEnum } from "../../../enums/MessageType";
import { SuccessErrorModal } from "../../SuccessErrorModal";

interface Props {
    info: {
        show: boolean;
        client?: Client;
      };
    onSubmited: (attachments: CDocument[]) => void;
    onClose: () => void;
}

export const ClientDocumentModal: React.FC<Props> = ({
    info,
    onSubmited,
    onClose
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    
    const isLoading = useSelector((state: RootState) => state.documents.isLoading);
    const error = useSelector((state: any) => state.documents.error);
    const [showDocumentError, setShowDocumentError] = useState(false);
    const [newClientDocuments, setNewClientDocuments] = useState<any[]>([]);
    const [isUploadinFiles, setIsUploadingFiles] = useState(false);

    const [showSuccessErrorModal, setShowSuccessErrorModal] = useState<{
        show: boolean;
        messageType: number;
        message: string;
    }>({ show: false, messageType: 2, message: "" });
    
    useEffect(() => {
        if (showDocumentError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showDocumentError, error, t]);

    const methods = useForm<any>({
        defaultValues: {}
    })
    const { handleSubmit, control, reset } = methods;

    const onSubmit = (data: any) => {
        if (newClientDocuments.length > 0) {
            uploadDocumentFiles();
        }
    }

    const uploadDocumentFiles = async () => {
        setIsUploadingFiles(true);
    
        const uploadPromises = newClientDocuments.map(async (file) => {
            const nameSplit = file.name.split(".");
            const filename = `${nameSplit[0]}-${Date.now()}.${nameSplit[1]}`;
        
            const result = await dispatch(
                getFileSignedUrl({ fileName: filename })
            ).unwrap();
    
            const uploadResponse = await fetch(result.upload_url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/octet-stream",
                },
                body: file,
            });
    
            if (uploadResponse.ok && uploadResponse.status == 200) {
                const attachment: NewDocument = {
                    name: filename,
                    size: `${file.size}`,
                };
        
                return attachment;
            }
        
            return undefined;
        });
    
        // Esperar a que todas las subidas se completen
        const results = await Promise.all(uploadPromises);
    
        if (results.length > 0) {
          const attachaments: NewDocument[] = [];
          results.forEach((attach) => {
            if (attach != undefined) {
              attachaments.push(attach);
            }
          });
    
          setIsUploadingFiles(false);
          addClientDocuments(attachaments);
        } else {
            setShowSuccessErrorModal({
                show: true,
                messageType: MessageTypeEnum.FAILED,
                message: t("DOCUMENTS_COULDNT_UPLOADED")
            });
        }
    };

    const addClientDocuments = async (newClientAttachments: NewDocument[]) => {
        const cNewDocument: CNewDocument = {
            documents: newClientAttachments
        };
    
        dispatch(createClientDocument({clientId: info.client?.id!, newAttach: cNewDocument})).then((e) => {
          if (e.type === "client/createClientDocument/rejected") {
            setShowDocumentError(true);
          } else {
            let documents = (e.payload || []) as CDocument[];
            toast(t("SUCCESSFULLY_SAVED_CLIENT_DOCUMENT"));
            onSubmited(documents)
            closeModal();
          }
        });
    };
        
    const closeModal = () => {
        reset({});
        setNewClientDocuments([]);
        onClose();
    };

    const handleClientDocuments = (e: any) => {
        for (let i = 0; i < e.length; i++) {
          setNewClientDocuments((prev) => [...prev, e[i]]);
        }
    };

    const handleClientDocumentsDrop = (e: any) => {
        for (let i = 0; i < e.length; i++) {
          setNewClientDocuments((prev) => [...prev, e[i]]);
        }
    };

    const handleRemoveClientDocument = (index: number) => {
        const list = [...newClientDocuments];
        list.splice(index, 1);
        setNewClientDocuments(list);
    };

    const formatBytes = (bytes: number, decimals = 2) => {
        if (!+bytes) return "0 Bytes";
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    return (<>
        <Modal
            isOpen={info.show}
            onClose={closeModal}
            label={t("ADD_DOCUMENTS")}
            onSave={handleSubmit(onSubmit)}
        >
            <div className="w-full flex flex-wrap">
                <div className="w-full pb-5">
                    <Fragment>
                        <ReactiveFormUploadFile
                            control={control}
                            className="w-full"
                            label={t("UPLOAD_DOCUMENTS")}
                            name="documents"
                            handleOnChange={handleClientDocuments}
                            handleOnDrop={handleClientDocumentsDrop}
                        />
                    </Fragment>
                </div>
                {newClientDocuments.map((document, index) => {
                    return (
                        <Fragment key={document.name}>
                        <div className="w-full px-4 mb-3">
                            <div className="flex justify-between">
                            <div className="self-center">
                                <label className="text-sm self-center">{document.name}</label>
                                <label className="text-gray-600 text-sm self-center pl-3">
                                {formatBytes(document.size)}
                                </label>
                            </div>
                            <Button
                                iconOnly
                                classNames="h-7 w-7 self-end"
                                onClick={() => handleRemoveClientDocument(index)}
                            >
                                <TrashIcon className="h-3 w-3" />
                            </Button>
                            </div>
                            <hr className="mt-3"></hr>
                        </div>
                        </Fragment>
                    );
                    })}
            </div>
        </Modal>
        <SuccessErrorModal
            info={showSuccessErrorModal}
            onClose={() => setShowSuccessErrorModal({
                show: false,
                messageType: MessageTypeEnum.FAILED,
                message: "",
            })}
        />
        <ToastContainer progressStyle={{ background: "#D4AF37" }} />
        { (isLoading || isUploadinFiles) && <LoadingMask />}
    </>)
}