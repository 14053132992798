import { useTranslation } from "react-i18next";
import { Address, NewAddress, newAddressInitialValues } from "../../../models/Address";
import { useForm } from "react-hook-form";
import { Modal } from "../../Modal";
import { Fragment } from "react/jsx-runtime";
import { ReactiveFormInput } from "../../form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../form/ReactiveFormSelect";
import { useEffect, useMemo, useState } from "react";
import states from "states-us";
import { AppDispatch, RootState } from "../../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../../i18n/countries";
import { createClientAddress, updateClientAddress } from "../../../state/addresses/actions";
import { Client } from "../../../models/Client";
import { stat } from "fs";
import { toast, ToastContainer } from "react-toastify";
import { Toast } from "../../Toast";
import { LoadingMask } from "../../LoadingMask";

interface Props {
    info: {
        show: boolean;
        update: boolean;
        address?: Address;
        index?: number;
        client?: Client;
      };
    onSubmited: (address: Address, index?:number) => void;
    onClose: () => void;
}

export const ClientAddressModal: React.FC<Props> = ({
    info,
    onSubmited,
    onClose
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const isLoading = useSelector((state: RootState) => state.addresses.isLoading);
    const error = useSelector((state: any) => state.addresses.error);
    const [showAddressError, setShowAddressError] = useState(false);

    useEffect(() => {
        if (showAddressError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showAddressError, error, t]);

    const methods = useForm<Address>({
        defaultValues: newAddressInitialValues
    })
    const { handleSubmit, control, reset } = methods;

    const getStates = useMemo(() => {
        return states.map((state) => {
          return {
            label: state.name,
            value: state.abbreviation,
            key: state.abbreviation,
          };
        });
      }, []);

     useEffect(() => {
        info.address ? reset(info.address) : reset(newAddressInitialValues);
      }, [info.address, reset]);

    const onSubmit = (data: Address) => {
        if (info.update) {
            //TODO: update
            dispatch(updateClientAddress({editAddress: data, addressId: info.address?.id!, clientId: info.client?.id!})).then((e) => {
                if (e.type === "client/updateClientAddress/rejected") {
                    setShowAddressError(true);
                } else {
                    let address = (e.payload || []) as Address;
                    toast(t("SUCCESSFULLY_UPDATE_CLIENT_ADDRESS"));
                    onSubmited(address, info.index)
                    closeModal();
                }
            });
        } else {
            //Todo: add
            dispatch(createClientAddress({newAddress: data, clientId: info.client?.id!})).then((e) => {
                if (e.type === "client/createClientAddress/rejected") {
                    setShowAddressError(true);
                } else {
                    let address = (e.payload || []) as Address;
                    toast(t("SUCCESSFULLY_SAVED_CLIENT_ADDRESS"));
                    onSubmited(address)
                    closeModal();
                }
            });
        }
    }

    const closeModal = () => {
        reset({});
        onClose();
    };

    return (<>
        <Modal
            isOpen={info.show}
            onClose={closeModal}
            label={info.update ? t("UPDATE_ADDRESS") : t("ADD_ADDRESS")}
            onSave={handleSubmit(onSubmit)}
        >
            <div className="w-full flex flex-wrap">
                <Fragment>
                    <ReactiveFormInput
                        control={control}
                        className="md:w-1/3"
                        label={t("STREET_ADDRESS")}
                        name={`address_line_1`}
                    />
                    <ReactiveFormInput
                        control={control}
                        className="md:w-1/3"
                        label={t("STREET_ADDRESS2")}
                        name={`address_line_2`}
                    />
                    <ReactiveFormInput
                        control={control}
                        className="md:w-1/3"
                        label={t("CITY")}
                        name={`city`}
                    />
                    <ReactiveFormSelect
                        className="md:w-1/3"
                        name={`state`}
                        label={t("STATE")}
                        options={getStates}
                        control={control}
                    />
                    <ReactiveFormSelect
                        className="md:w-1/2 lg:w-1/3"
                        name="country"
                        label={t("COUNTRY_OF_BIRTH")}
                        options={getCountries()}
                        control={control}
                        isRequired
                    />
                    <ReactiveFormInput
                      control={control}
                      className="md:w-1/3"
                      label={t("ZIP_CODE")}
                      name={`zip_code`}
                    />
                </Fragment>
            </div>
        </Modal>
        <ToastContainer progressStyle={{ background: "#D4AF37" }} />
        { isLoading && <LoadingMask />}
    </>)

}