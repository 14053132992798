import { useState } from "react";
import { Reminder } from "../../../models/Reminder";
import { useTranslation } from "react-i18next";
import { localHourFormat } from "../../../utils/functions";
import logoAsureis from "../../../assets/images/Empty_logo.svg";

interface Props {
  closeModal: () => void;
  notificationReminders: Reminder[];
}

export const ViewNotificationRemindersModal = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className="fixed top-20 bg-white w-full max-w-[280px] p-6 rounded-md shadow-lg">
            <div className="text-gray-500 text-start mb-3">{t('REMINDERS')}</div>
            {props.notificationReminders.length > 0 && (
                props.notificationReminders.map((reminder, index) => {
                    return (
                        <div className="mb-3 w-full">
                            <div className="flex flex-row items-center">
                                <div className="flex flex-col items-start justify-center">
                                    <div className="text-blue text-start">
                                        {reminder.message}
                                    </div>
                                    {
                                        reminder.client_id &&
                                        <div className="text-start text-gray-500">
                                            {`${t("CLIENT")}: ${reminder.client_name}`}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="w-full flex flex-row justify-end">
                                <div className="text-end text-gray-500 font-light">
                                    {localHourFormat(`${reminder.date}`)}
                                </div>
                            </div>
                            {index !== props.notificationReminders.length - 1 && (
                                <hr className="w-full mt-3"></hr>
                            )}
                        </div>
                    );
                })
            )}
            {props.notificationReminders.length === 0 && (
                <div className="w-full h-full flex flex-col items-center justify-center">
                    <img src={logoAsureis} alt="Asureis Logo" className="h-24" />
                    <div className="text-gray-500 text-center text font-medium">
                    {t("NO_REMINDERS_AT_THIS_MOMENT")}
                    </div>
                </div>
            )}
        </div>
    );
};