import moment from 'moment';
import { SellsFilter } from '../enums/SellsFilter';
import { useEffect, useState } from 'react';

export const longLocalDateFormat = (date: string) => `${moment(date).format('MMM DD YYYY hh:mm a')}`;
export const fullDateFormat = (date: string) => `${moment.utc(date).format('YYYY-MM-DD HH:mm:ss')}`;
export const shortDateFormat = (date: string) => `${moment.utc(date).format('MMM DD YYYY')}`;
export const shortLocalDateFormat = (date: string) => `${moment(date).format('MMM DD YYYY')}`;
export const dateFormat = (date: string) => `${moment.utc(date).format('YYYY-MM-DD')}`;
export const monthFormat = (date: string) => `${moment.utc(date).format('YYYY-MM')}`;
export const usFormat = (date: string) => `${moment.utc(date).format('MM/DD/YYYY')}`;
export const docDateFormat = (date: string) => `${moment.utc(date).format('ll')}`;
export const actualDateFormat = () => `${moment().format('ll')}`;
export const localDayFormat = (date: string) => `${moment(date).format('ddd')}`;
export const localHourFormat = (date: string) => `${moment(date).format('LT')}`;
export const localDayMFormat = (date: string) => `${moment(date).format('DD')}`;
export const localMonthFormat = (date: string) => `${moment(date).format('MMM')}`
export const localDateFormat = (date: string) => `${moment(date).format('YYYY-MM-DD')}`;
export const localTimeFormat = (date: string) => `${moment(date).format('HH:mm')}`;
export const messageDateFormat = (date: string) => {
    const inputDate = moment(date);
    const today = moment();
  
    if (inputDate.isSame(today, 'day')) {
      // If the input date is today, return the time
      return inputDate.format('hh:mm A');
    } else {
      // If the input date is not today, return the formatted date
      return inputDate.format('MM/DD/YYYY');
    }
};
export const isLoggedIn = sessionStorage.getItem('auth')
    ? true
    : false;

export const getLocalTimeZone = () => {
  const timeZoneOffset = new Date().getTimezoneOffset();

  if (timeZoneOffset > 0) {
    const hours = Math.floor(timeZoneOffset / 60);
    const minutes = timeZoneOffset % 60;
    const sHours = `-${hours < 10 ? "0" : ""}${hours}:${String(minutes).padStart(2, "0")}`;
    return sHours;
  } else {
    const hours = Math.floor(timeZoneOffset / 60) * -1;
    const minutes = (timeZoneOffset % 60) * -1;
    const sHours = `+${hours < 10 ? "0" : ""}${hours}:${String(minutes).padStart(2, "0")}`;
    return sHours;
  }
};

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result?.toString().split(",")[1];
      resolve(base64String || "");
    };

    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const getStartEndDates = (filter: SellsFilter) => {
  const timeZone = getLocalTimeZone();
  const endDate = moment().endOf("day").toDate();
  let startDate;

  switch (filter) {
    case SellsFilter.day:
      startDate = moment(endDate).startOf("day").toDate();
      break;
    case SellsFilter.week:
      startDate = moment(endDate).startOf("isoWeek").toDate();
      break;
    case SellsFilter.month:
      startDate = moment(endDate).startOf("month").toDate();
      break;
    case SellsFilter.all:
      return { start: undefined, end: undefined };
    default:
      throw new Error("Invalid filter");
  }

  return {
    start: moment(startDate).format("YYYY-MM-DD"),
    end: moment(endDate).format("YYYY-MM-DD"),
    timeZone
  };
};

export const debounce = (fn: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout | null = null;
  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  }
}