import { useMemo, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Form } from '../../components/form/Form';
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { getGenders } from "../../enums/Gender";
import { RoleEnum } from "../../enums/Role";
import { fetchClients } from "../../state/clients/actions";
import { AppDispatch } from "../../state/store";
import { ClientsFilters } from "../../models/Client";
import { Modal } from "../../components/Modal";
import { TrashIcon } from "@heroicons/react/24/outline";
import { User } from "../../models/User";

interface Props {
    isFilterModalOpen: boolean;
    setFilterModalOpen: (x: boolean) => void;
}

export const FilterModal = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const users = useSelector((state: any) => state.users.users);
    const clientsState = useSelector((state: any) => state.clients);
    const [currentAgents, setCurrentAgents] = useState<any[]>();
    
    const selectedAgents = useMemo(() => {
        return clientsState.filters?.agents?.map((x: number) => {
            const user = users.find((y: User) => y.id === x);
            return {
                label: `${user.first_name} ${user.last_name}`,
                value: user.id
            }
        })
    }, [clientsState.filters?.agents, users]);

    useEffect(() => {
        setCurrentAgents(selectedAgents)
    }, [selectedAgents]);

    const agents = useMemo(() => {
        return users.map((x: any) => {
            return {
                label: `${x.first_name} ${x.last_name}`,
                value: x.id
            }
        })
    }, [users]);

    const methods = useForm<ClientsFilters>({
        defaultValues: {
            name: clientsState.filters?.name,
            lastName: clientsState.filters?.lastName,
            phone: clientsState.filters?.phone,
            email: clientsState.filters?.email,
            gender: clientsState.filters?.gender,
            agents: clientsState.filters?.agents
        }
    });

    const { handleSubmit, control, reset } = methods;

    const onSubmit: SubmitHandler<ClientsFilters> = async (data: ClientsFilters) => {
        if (currentAgents?.length && currentAgents?.length > 0) {
            data.agents = currentAgents?.map((x: any) => x.value);
        }
        let dataCopy:any = data
        dataCopy["last_name"] = data.lastName
        dispatch(fetchClients({ page: 0, pageSize: clientsState.paginatedOptions.pageSize, filters: dataCopy }));
        props.setFilterModalOpen(false);
    };

    const genders = useMemo(() => {
        return [{
            key: "",
            value: "",
            label: ""
        }].concat(getGenders() as any)
    }, []);

    return (
        <Modal isOpen={props.isFilterModalOpen} onClose={() => props.setFilterModalOpen(false)} label={t("FILTERS")} onSave={handleSubmit(onSubmit)} thirdButton={{
            label: t("CLEAR_FILTERS"),
            icon: <TrashIcon />,
            onClick: () =>  {
                reset({ name: '', lastName: '', phone: '', email: '', gender: '', agents: undefined });
                setCurrentAgents([]);
            }
        }}>
            <Form handleOnSubmit={handleSubmit(onSubmit)}>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {currentUser?.role_id !== RoleEnum.AGENT &&
                        <div className={"w-full px-3"}>
                            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                                {t("AGENTS")}
                            </label>
                            <Select
                                isMulti
                                isSearchable
                                isClearable
                                isLoading={agents.length === 0}
                                onChange={(inputValue: any) => setCurrentAgents(inputValue)}
                                name="agent"
                                options={agents}
                                value={currentAgents}
                                placeholder="Select agents"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                maxMenuHeight={200}
                                classNames={{
                                    control: (state) =>
                                        'bg-white opacity-90 py-1 px-1.5 rounded shadow w-full min-w-[200px]',
                                }}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: 'grey-50',
                                    }),
                                }}
                            />
                        </div>
                    }
                    <ReactiveFormInput
                        control={control}
                        value={clientsState.filters?.name ?? ""}
                        label={t("NAME")}
                        name="name" />
                    <ReactiveFormInput
                        control={control}
                        value={clientsState.filters?.last_name}
                        label={t("LAST_NAME")}
                        name="lastName" />

                    <ReactiveFormInput
                        control={control}
                        value={clientsState.filters?.email}
                        label={t("EMAIL")}
                        name="email" />

                    <ReactiveFormInput
                        control={control}
                        value={clientsState.filters?.phone}
                        label={t("PHONE")}
                        name="phone" />

                    <ReactiveFormSelect
                        control={control}
                        value={clientsState.filters?.gender}
                        label={t("GENDER")}
                        name="gender"
                        options={genders} />
                    {/*
                    <ReactiveFormInput
                        control={control}
                        type='date'
                        label={t("CREATION_BEGIN_DATE")}
                        name="from" />
                    <ReactiveFormInput
                        control={control}
                        type='date'
                        label={t("CREATION_END_DATE")}
                        name="to" />
                */}
                </div>
            </Form>
        </Modal>
    );
}