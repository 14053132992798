import { useTranslation } from "react-i18next";
import { Client } from "../../../models/Client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { DocumentEnvelope } from "../../../models/ConsentDocument";
import { useEffect, useMemo, useState } from "react";
import { Toast } from "../../Toast";
import { fetchClientDocumentEnvelopes, getEnvelopeDownloadDocument, needToLoadClientConsentDocument } from "../../../state/consentDocuments/actions";
import { longLocalDateFormat } from "../../../utils/functions";
import Button from "../../Button";
import { Root } from "react-dom/client";
import { LoadingMask } from "../../LoadingMask";
import { toast, ToastContainer } from "react-toastify";
import { DocumentArrowDownIcon, NoSymbolIcon, RectangleStackIcon } from "@heroicons/react/24/solid";
import FilteredTable from "../../FilteredTable";
import { ClientConsentDocumentModal } from "./ClientConsentDocumentModal";
import { VoidClientConsentDocumentModal } from "./VoidClientConsentDocumentModal";

interface Props {
    client: Client | null
}

export const ClientConsentDocumentTable = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [consentDocuments, setConsentDocuments] = useState<DocumentEnvelope[]>([]);
    const clientConsentDocuments = useSelector((state: RootState) => state.consentDocuments.envelopes);
    const isLoadingConsentDocuments = useSelector((state: RootState) => state.consentDocuments.isLoading);
    const isLoadingDownload = useSelector((state: RootState) => state.consentDocuments.isLoadingDownload);
    const needLoadClientConsentDocuments = useSelector((state: RootState) => state.consentDocuments.needToLoadEnvelopes);
    const error = useSelector((state: any) => state.dependents.error);
    const [showConsentDocumentError, setShowConsentDocumentError] = useState(false);

    const [clientConsentDocumentModal, setClientConsentDocumentModal] = useState<{
        show: boolean;
        client?: Client;
    }>({ show: false });

    const [ showVoidClientConsentDocumentModal, setShowVoidClientConsentDocumentModal] = useState<{
        consentDocument?: DocumentEnvelope;
        client?: Client;
        show: boolean;
    }>({ show: false, client: undefined, consentDocument: undefined });

    useEffect(() => {
        if (showConsentDocumentError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showConsentDocumentError, error, t]);

    useEffect(() => {
        //TODO: Review state and get data
        if(needLoadClientConsentDocuments) {
            dispatch(fetchClientDocumentEnvelopes(props.client?.id!)).then((e) => {
                let consentDocuments = (e.payload || []) as DocumentEnvelope[];
                setConsentDocuments(consentDocuments);
            });
            dispatch(needToLoadClientConsentDocument(false));
        } else {
            setConsentDocuments(clientConsentDocuments);
        }
    }, []);

    const addConsentDocument = (consentDocument: DocumentEnvelope) => {
        setConsentDocuments(documents => [...documents, consentDocument])
    }

    const voidConsentDocument = (consentDocuments: DocumentEnvelope[]) => {
        //TODO: remove address from database
        setConsentDocuments(consentDocuments);
    }

    const downloadEnvelopeDocument = (envelope: DocumentEnvelope) => {
        dispatch(getEnvelopeDownloadDocument(envelope.docusign_envelope_id)).then(
          async (e: any) => {
            if (e.type === "getDownloadDocument/rejected") {
              toast(t("COULDNT_DOWNLOAD_DOCUMENT"));
            } else {
              const data = e.payload.download_documents;
              const nameSplit = envelope.template_document_name!.split(".");
              const filename = `${nameSplit[0]} - ${props.client?.first_name} ${props.client?.last_name}.pdf`;
    
              var binaryLen = data.length;
              var bytes = new Uint8Array(binaryLen);
              for (var i = 0; i < binaryLen; i++) {
                var ascii = data.charCodeAt(i);
                bytes[i] = ascii;
              }
    
              var blob = new Blob([bytes], { type: "application/pdf" });
              var link = window.URL.createObjectURL(blob);
    
              const aElement = document.createElement("a");
              document.body.appendChild(aElement);
              aElement.style.display = "none";
              aElement.href = link;
              aElement.download = filename;
              aElement.click();
              document.body.removeChild(aElement);
            }
          }
        );
    };

    const capitalize = (str: string) => {
        if (!str) return ''; // Si la cadena está vacía o es null, retorna una cadena vacía
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const columns = useMemo(
        () => [
            {
                Header: t("NAME"),
                accessor: "template_name",
            },
            {
                Header: t("SENT_BY"),
                accessor: "agent_name",
            },
            {
                Header: t("SENT_DOCUMENT_DATE"),
                Cell: (row: any) => row.row.original.sent_date_time ? `${longLocalDateFormat(row.row.original.sent_date_time)}` : "-",
            },
            {
                Header: t("COMPLETED_DOCUMENT_DATE"),
                Cell: (row: any) => row.row.original.completed_date_time ? `${longLocalDateFormat(row.row.original.completed_date_time)}` : "-",
            },
            {
                Header: t("STATUS"),
                Cell: (row: any) => row.row.original.status === "completed"
                    ? `${capitalize(row.row.original.status)} (${t("SIGNED")})`
                    : `${capitalize(row.row.original.status)} (${t("NO_SIGNED")})`,
            },
            {
                id: "actions",
                Header: "",
                Cell: (row: any) => {
                    return (
                        <div className="flex flex-row justify-center divide-x">
                            <Button
                                isTerciary
                                iconOnly
                                tooltip={t("DOWNLOAD_DOCUMENT")}
                                onClick={() => downloadEnvelopeDocument(row.row.original)}
                                classNames="mr-2"
                            >
                                <DocumentArrowDownIcon className="h-5 aspect-square text-blue" />
                            </Button>
                            <Button
                                isTerciary
                                iconOnly
                                isDisabled={
                                    row.row.original.status === "completed" ||
                                    row.row.original.status === "voided"
                                }
                                tooltip={t("VOID_DOCUMENT")}
                                onClick={() => setShowVoidClientConsentDocumentModal({ show: true, client: props.client!, consentDocument: row.row.original })}
                                classNames="mr-2"
                            >
                                <NoSymbolIcon className="h-5 aspect-square text-blue" />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [voidConsentDocument, t, downloadEnvelopeDocument]
    );

    return (
        <>
            <FilteredTable
                columns={columns}
                data={consentDocuments}
                hasSearchBar={false}
                secondaryActionButton={
                    <Button onClick={() => setClientConsentDocumentModal({ show: true, client: props.client! })}>
                        <span className="flex items-center font-semibold pr-3">
                            <div className="w-8 p-1 aspect-square mr-2">
                                <RectangleStackIcon />
                            </div>
                            {t("SEND_DOCUMENT")}
                        </span>
                    </Button>
                }
            />
            <ClientConsentDocumentModal
                info={clientConsentDocumentModal}
                onSubmited={(consentDocument) => {
                    addConsentDocument(consentDocument)
                }}
                onClose={() => setClientConsentDocumentModal({ show: false })}
            ></ClientConsentDocumentModal>
            <VoidClientConsentDocumentModal
              info={showVoidClientConsentDocumentModal}
              onClose={() => setShowVoidClientConsentDocumentModal({show : false, client: undefined, consentDocument: undefined})}
              onVoided={(consentDocuments: DocumentEnvelope[]) => voidConsentDocument(consentDocuments)}
            />
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            { (isLoadingConsentDocuments || isLoadingDownload) && <LoadingMask />}
        </>
    )
}