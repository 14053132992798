import { useTranslation } from "react-i18next";
import { Client } from "../../../models/Client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { useEffect, useMemo, useState } from "react";
import { Dependent } from "../../../models/Dependant";
import { Toast } from "../../Toast";
import { deleteClientDependent, fetchDependentsByClientId, needToLoadClientDependent } from "../../../state/dependents/actions";
import { toast, ToastContainer } from "react-toastify";
import { GenderLabel } from "../../../enums/Gender";
import Button from "../../Button";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import FilteredTable from "../../FilteredTable";
import { ClientDepedentModal } from "./ClientDependentModal";
import { DeleteClientDependentModal } from "./DeleteClientDependentModal";
import { LoadingMask } from "../../LoadingMask";
import { RelationshipLabel } from "../../../enums/Relationship";
import { shortDateFormat, shortLocalDateFormat } from "../../../utils/functions";

interface Props {
    client: Client | null
}

export const ClientDependentTable = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [dependents, setDependents] = useState<Dependent[]>([]);
    const clientDependents = useSelector((state: RootState) => state.dependents.dependents);
    const isLoadingDependents = useSelector((state: RootState) => state.dependents.isLoading);
    const needLoadClientDependents = useSelector((state: RootState) => state.dependents.needToLoadDependents);
    const error = useSelector((state: any) => state.dependents.error);
    const [showDependentError, setShowDependentError] = useState(false);

    const [clientDependentInfoModal, setClientDependentInfoModal] = useState<{
        show: boolean;
        update: boolean;
        dependent?: Dependent;
        index?: number;
        client?: Client;
    }>({ show: false, update: false });

    const [ showDeleteClientDependentModal, setShowDeleteClientDependentModal] = useState<{
        dependent?: Dependent;
        show: boolean;
    }>({ show: false, dependent: undefined });

    useEffect(() => {
        if (showDependentError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showDependentError, error, t]);

    useEffect(() => {
        //TODO: Review state and get data
        if(needLoadClientDependents) {
            dispatch(fetchDependentsByClientId({clientId: props.client?.id!})).then((e) => {
                let dependents = (e.payload || []) as Dependent[];
                setDependents(dependents);
            });
            dispatch(needToLoadClientDependent(false));
        } else {
            setDependents(clientDependents);
        }
    }, []);

    const addDependent = (dependent: Dependent) => {
        setDependents(dependents => [...dependents, dependent])
    }

    const updateDependent = (index: number, dependent: Dependent) => {
        setDependents(dependents => dependents.map((ele, i) => {
            if (i === index) { return dependent }
            return ele
        }))
    }

    const removeDependent = (dependent: Dependent) => {
        //TODO: remove address from database
        dispatch(deleteClientDependent({ clientId: props.client?.id!, dependentId: dependent.id })).then((e) => {
            if (e.type === "client/deleteClientDependent/rejected") {
                setShowDependentError(true);
            } else {
                toast(t("SUCCESSFULLY_DELETE_CLIENT_DEPENDENT"));
                let dependents = (e.payload || []) as Dependent[];
                setDependents(dependents);
            }
        });
    }

    const columns = useMemo(
        () => [
            {
                Header: t("FULL_NAME"),
                accessor: "name",
            },
            {
                Header: t("SOCIAL_SECURITY_NUMBER"),
                accessor: "social_security_number",
            },
            {
                Header: t("PHONE"),
                accessor: "phone",
            },
            {
                Header: t("EMAIL"),
                accessor: "email",
            },
            {
                Header: t("DATE_OF_BIRTH"),
                Cell: (row: any) => row.row.original.date_of_birth ? `${shortDateFormat(row.row.original.date_of_birth)}` : "-",
            },
            {
                Header: t("RELATIONSHIP"),
                Cell: (row: any) => row.row.original.relationship_id ? `${RelationshipLabel(row.row.original.relationship_id)}` : "-",
            },
            {
                Header: t("GENDER"),
                Cell: (row: any) => row.row.original.gender_id ? `${GenderLabel(row.row.original.gender_id)}` : "-",
            },
            {
                id: "actions",
                Header: "",
                Cell: (row: any) => {
                    return (
                        <div className="flex flex-row justify-center divide-x">
                            <Button
                                isTerciary
                                iconOnly
                                tooltip={t("UPDATE_DEPENDENT")}
                                onClick={() => 
                                    setClientDependentInfoModal({
                                        show: true,
                                        update: true,
                                        dependent: row.row.original,
                                        index: row.row.index,
                                        client: props.client!
                                    })
                                }
                                classNames="mr-2"
                            >
                                <PencilIcon className="h-5 aspect-square text-blue" />
                            </Button>
                            <Button
                                isTerciary
                                iconOnly
                                tooltip={t("DELETE_DEPENDENT")}
                                onClick={() => setShowDeleteClientDependentModal({ show: true, dependent: row.row.original })}
                                classNames="mr-2"
                            >
                                <TrashIcon className="h-5 aspect-square text-blue" />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [removeDependent, t, updateDependent]
    );

    return (
        <>
            <FilteredTable
                columns={columns}
                data={dependents}
                hasSearchBar={false}
                secondaryActionButton={
                    <Button onClick={() => setClientDependentInfoModal({ show: true, update: false, dependent: undefined, client: props.client!, index:undefined })}>
                        <span className="flex items-center font-semibold pr-3">
                            <div className="w-8 p-1 aspect-square mr-2">
                                <PlusIcon />
                            </div>
                            {t("ADD_DEPENDENT")}
                        </span>
                    </Button>
                }
            />
            <ClientDepedentModal
                info={clientDependentInfoModal}
                onSubmited={(dependent, index) => {
                    if (index !== undefined) {
                        updateDependent(index, dependent)
                    } else {
                        addDependent(dependent)
                    }
                }}
                onClose={() => setClientDependentInfoModal({ show: false, update: false, dependent: undefined, index:undefined })}
            ></ClientDepedentModal>
            <DeleteClientDependentModal
              info={showDeleteClientDependentModal}
              onClose={() => setShowDeleteClientDependentModal({show : false, dependent: undefined})}
              deleteDependent={(dependent: Dependent) => removeDependent(dependent)}
            />
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            { isLoadingDependents && <LoadingMask />}
        </>
    )
}